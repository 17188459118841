import {Component, ElementRef, OnInit, QueryList, viewChild, ViewChild, ViewChildren} from '@angular/core';
import { User } from '../../../../models/user/user';
import {ModalService} from '../../../../services/modal.service';
import { FocusService } from '../../../../services/focus.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserRoleRequest } from '../../../../models/admin/user-role-requests/user-role-request';
import { RegionsService } from '../../../../services/regions/regions.service';
import { Region } from '../../../../models/forest-service/region';
import { Forest } from '../../../../models/forest-service/forest';
import { UserRoleRequestsService } from '../../../../services/admin/user-role-requests.service';
import { MatAccordion } from '@angular/material/expansion';
import { UserRoleRequestForest } from '../../../../models/admin/user-role-requests/user-role-rquest-forest';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { UserRoleRequestDistrict } from '../../../../models/admin/user-role-requests/user-role-request-district';
import { District } from '../../../../models/forest-service/district';
import { take } from 'rxjs';
import { UsersService } from '../../../../services/users/users.service';
import { RolesService } from '../../../../services/admin/roles.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelectionList } from '@angular/material/list';
import { Role } from '../../../../models/user/user-roles/role';
import { UserRoleRequestStatus } from '../../../../models/enums/user-role-request-status';
import { UserRolesService } from '../../../../services/admin/user-roles.service';
import { UserRole } from '../../../../models/user/user-roles/user-role';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
    standalone: false
})

export class UserManagementComponent implements OnInit {
//#region Properties   
  @ViewChild('main') main!:ElementRef;
  @ViewChild('modal') modal!:ElementRef;
  @ViewChild('userRoles') userRoles!:MatSelectionList;
  @ViewChildren('districtCheckbox') districtCheckboxes!: QueryList<any>;
  @ViewChildren('forestCheckbox') forestCheckboxes!: QueryList<any>;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;

  user!: User;
  form!: FormGroup;
  rowsForm!: FormArray;
  showDiv:boolean = false;
  checked:boolean =false;
  accordion = viewChild.required(MatAccordion);
  regions: Region[] = [];
  openPanels: number[] = [];
  roles: Role[] = [];
  pendingRequests: UserRoleRequest[] = [];
  userRoleRequest!: UserRoleRequest | null;
  requestedRegion!: Region| null;
  currentUserRole: UserRole | null | undefined;

//#endregion Properties   

//#region Construction
    constructor(
        private fb: FormBuilder,
        private usersService: UsersService,
        private regionsService: RegionsService,
        private rolesService: RolesService,
        private userRoleRequestsService: UserRoleRequestsService,
        public modalService:ModalService, 
        public focusService:FocusService,
        private toastr: ToastrService,
        private userRolesService: UserRolesService
        ) {
        

        this.focusService.onFocus.subscribe(()=>{
            this.showFocus();
          });
      
          this.modalService.onFoucsModal.subscribe(()=>{
            this.focusModal();
          });
         
    }

    ngOnInit() {
        this.checkCurrentUser();
        this.getPendingRequests();
        this.loadRegions();
        this.loadRoles();
        this.usersService.currentUser$.pipe(take(1)).subscribe({
          next: user => {
            if (user){
              this.user = user;
              this.initForm();
            }
          },
          error: error => {
            console.log(error);
          }
        });  
    }
//#endregion Construction

//#region OnInit Methods
    /******************************
            ONINIT METHODS
    ******************************/
    initForm() {
      if(this.pendingRequests != null){
        this.form = this.fb.group({
          rows: this.fb.array(this.pendingRequests.map(val => this.fb.group({
            id: new FormControl(val.id),
            userId: new FormControl(val.userId),
            firstName: new FormControl(val.user?.firstName),
            lastName: new FormControl(val.user?.lastName),
            email: new FormControl(val.user?.username),
            roleName: new FormControl(val.role?.name),
            regionName: new FormControl(val.region?.regionCode + ' ' + val.region?.regionName),
            roleId: new FormControl(val.roleId),
            status: new FormControl(val.status),
            regionId: new FormControl(val.regionId),
            action: new FormControl('existingRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(false),
          })
          ))
        }); 
  
        this.rowsForm = this.form.controls['rows'] as FormArray;
        }   
    }

    checkCurrentUser() {
      this.usersService.currentUser$.pipe(take(1)).subscribe({
          next: user => {
            if (user){
              this.user = user;     
            }else{
              console.log("User not logged in.")
            }
          }
      })
  }

  loadRegions() {
    this.regionsService.allRegions$.subscribe({
      next: response => {
          if (response) {
            this.regions = response;
            
          }
      }
    });
  }

  loadRoles() {
    this.rolesService.roles$.subscribe({
      next: response => {
          if (response) {
            this.roles = response;
            
          }
      }
    });

    
  }

  getPendingRequests(){
    this.userRoleRequestsService.getPendingRequests().subscribe({
        next: response => {           
              if(response != null){
                console.log(response);
                this.pendingRequests = response;
                this.setRows(this.pendingRequests);
              }
                
            }
    })
}


setRows(pendingRequests: UserRoleRequest[]) {
    if(pendingRequests != null){
        console.log(pendingRequests);
        let controls = this.fb.array(this.pendingRequests.map(val => this.fb.group({
            id: new FormControl(val.id),
            userId: new FormControl(val.userId),
            firstName: new FormControl(val.user?.firstName),
            lastName: new FormControl(val.user?.lastName),
            email: new FormControl(val.user?.username),
            roleName: new FormControl(val.role?.name),
            regionName: new FormControl(val.region?.regionCode + ' ' + val.region?.regionName),
            roleId: new FormControl(val.roleId),
            status: new FormControl(val.status),
            regionId: new FormControl(val.regionId),
            action: new FormControl('existingRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(false),
          })));
        this.form.setControl('rows', controls);
        this.rowsForm = this.form.controls['rows'] as FormArray;
      }
}
//#endregion OnInit Methods
        
//#region Open Panel Methods
    /******************************
            OPEN PANEL METHODS
    ******************************/
    expanded(index: number): boolean {
      return this.openPanels.filter(p => p == index)[0] != null;
    }

    opened(index: number, forestId?: number) {
      if(forestId) {
        let openIndex = this.openPanels.find((i) => i == index);
        if(openIndex == null) {
          this.openPanels.push(index);
        }
      }
    }
    
    closed(index: number, forestId?: number) {
      if(forestId) {
        let forestCheckbox = this.forestCheckboxes.filter(f => f.value == forestId)[0]; 
        let openIndex = this.openPanels.find((i) => i == index);
        if(forestCheckbox.checked) {
          if(openIndex == null) {
            this.openPanels.push(index);
          } 
        } else {
          this.openPanels =  this.openPanels.filter(p => p != index);
        }
      }

      console.log(this.openPanels);
      
    }
//#endregion Open Panel Methods

//#region Checkbox Methods
    /******************************
            CHECKBOX METHODS
    ******************************/
    selectCheckbox(checked: boolean){
      this.checkboxes.forEach(checkbox => checkbox.checked = checked);
    }

    selectAllCheckboxes(select: boolean){
      let status = (select) ? UserRoleRequestStatus[UserRoleRequestStatus.Requested] : UserRoleRequestStatus[UserRoleRequestStatus.Denied];
      console.log(this.userRoleRequest)
      if(this.userRoleRequest) {
        //this.userRoleRequest.forests = [];
        this.requestedRegion?.forests?.forEach(f => {
          let exisitingForest = this.userRoleRequest?.forests?.filter(forest => forest.forestId == f.id)[0];
          console.log(exisitingForest);
          if(exisitingForest != null && exisitingForest != undefined) {
            exisitingForest.status = status;
            console.log(exisitingForest);
            exisitingForest.districts?.forEach(d => {
              let exisitingDistrict = exisitingForest?.districts?.filter(district => district.id == d.id)[0];
              if(exisitingDistrict) { 
                exisitingDistrict.status = status;
              }
            })
          } else if(select) {
            let forest: UserRoleRequestForest = {
              forestId: f.id,
              status: status,
              districts: []
            }
            f.districts?.forEach(d => {
              let district: UserRoleRequestDistrict = {
                districtId: d.id,
                status: status
              }
              forest?.districts?.push(district);
            });
            this.userRoleRequest?.forests?.push(forest);
          }
          
        })
        this.selectCheckbox(select);
        console.log(this.userRoleRequest);
      }
      
    }

    stopPropagation($event: MouseEvent, forestId?: number) {
        $event.stopPropagation();
    }
//#endregion Checkbox Methods

//#region Event Methods
    /******************************
          EVENT METHODS
    ******************************/
   
    closeModal(modal: string) {
      this.userRoleRequest = null;
      this.requestedRegion = null;
      this.openPanels = [];
      this.accordion().closeAll();
      this.selectAllCheckboxes(false);
      this.modalService.close(modal);
    }

    showFocus(){
        this.main.nativeElement.focus();
    }
    
    focusModal(){
        this.modal.nativeElement.focus()
    }
    
    async viewStatus(id: number){
      
      this.userRoleRequest = this.pendingRequests.filter(r => r.id == id)[0];
      this.requestedRegion = this.regions.filter(r => r.id == this.userRoleRequest?.regionId)[0];
 
      console.log(this.userRoleRequest);
      this.currentUserRole = await this.userRolesService.getUserRolesByUserId(this.userRoleRequest.userId as number);
      this.modalService.open('modal-3');     
      this.showDiv = true; 
      this.openPanels = [];
      setTimeout(() => { this.setForestCheckboxes();  }, 100); 
    }

    setForestCheckboxes() {
      if(this.userRoleRequest?.forests) {
        for(let forest of this.userRoleRequest.forests) {
          if(forest.forestId) {
            let forestCheckbox = this.forestCheckboxes.filter(f => f.value == forest.forestId)[0];
            let index = this.requestedRegion?.forests.indexOf(this.requestedRegion.forests.filter(f => f.id == forest.forestId)[0]);
            if(forest.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested]) {
              forestCheckbox.checked = true;
              if(index != undefined && index >= 0)
                this.openPanels.push(index);
            }
            if(forest.districts) {
              for(let district of forest.districts) {
                let districtCheckbox = this.districtCheckboxes.filter(f => f.value == district.districtId)[0];
                districtCheckbox.checked = district.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested];;
              }
            }
          }
        }     
      };
      
      this.setExisitingRoleForestCheckboxes();
      //this.setExisitingRequestForestCheckboxes();
    }


    setExisitingRoleForestCheckboxes() {
      let userRole = this.currentUserRole;
      if(userRole?.userRoleRegions) {
        for(let region of userRole.userRoleRegions) {
          if(region?.userRoleForests) {
            console.log(region.userRoleForests)
            region.userRoleForests.forEach(f => {
              console.log(f)
              if(f.forestId) {

                let forestCheckbox = this.forestCheckboxes.filter(fc => fc.value == f.forestId)[0];
                let forestCheckboxes = this.forestCheckboxes.filter(fc => fc.value == f.forestId);
                console.log(forestCheckboxes)
                if(forestCheckbox) {
                  console.log(forestCheckbox)
                  let index = this.requestedRegion?.forests.indexOf(this.requestedRegion.forests.filter(fo => fo.id == f.id)[0]);
                  forestCheckbox.checked = true;
                  forestCheckbox.disabled = true;
                  if(f.userRoleDistricts) {
                    console.log('test')
                    let districtCounter = 0;
                    f.userRoleDistricts.forEach(d => {
                      let districtCheckbox = this.districtCheckboxes.filter(dis => dis.value == d.districtId)[0];
                      districtCheckbox.checked = districtCheckbox.disabled = true;
                      districtCounter++
                    });
                    if(f.userRoleDistricts?.length != districtCounter && index != undefined && index >= 0)
                    {
                        this.openPanels.push(index);
                    }
                  }
                }
              }
            })   
          }
        }  
      }
    }
    
    // setExisitingRequestForestCheckboxes() {
    //   console.log(this.userRoleRequest?.user);
    //   let currentUserRoleRequests = this.userRoleRequest?.user?.userRoleRequests;
    //   console.log(currentUserRoleRequests);
    //   if(currentUserRoleRequests!.length > 0) {
    //     let requests = currentUserRoleRequests!.filter(r => r.regionId == this.requestedRegion?.id && r.roleId == this.userRoleRequest?.roleId);
    //     if(requests?.length > 0) {
          
    //       console.log(requests);
    //       for(let request of requests) {
    //         if(request?.forests) {
    //           for(let forest of request.forests) {
    //             if(forest.forestId) {
    //               let forestCheckbox = this.forestCheckboxes.filter(f => f.value == forest.forestId)[0];
    //               console.log(forestCheckbox);
    //               if(forestCheckbox) {
    //                 let index = this.requestedRegion?.forests.indexOf(this.requestedRegion.forests.filter(f => f.id == forest.forestId)[0]);
    //                 if(forest.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested]) {
    //                   forestCheckbox.checked = true;
    //                   forestCheckbox.disabled = true;
    //                 }
    //                 if(forest.districts) {
    //                   let districtCounter = 0;
    //                   for(let district of forest.districts) {
    //                     let districtCheckbox = this.districtCheckboxes.filter(f => f.value == district.districtId)[0];
    //                     districtCheckbox.checked = districtCheckbox.disabled = district.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested];
    //                     districtCounter++;
    //                   }
    //                   if(forest.districts?.length != districtCounter && index != undefined && index >= 0)
    //                   {
    //                       this.openPanels.push(index);
    //                   }
    //                 }
    //               }
                  
    //             }
    //           }   
    //         }
    //       }
    //     }  
    //   };

    // }

    currentForestStatus(id: number | undefined){
      if(!id)
        return "";
      
      let currentUserRoleRequests = this.userRoleRequest?.user?.userRoleRequests;
      let forestRequests = currentUserRoleRequests!.filter(r => r.forests?.some(f => f.forestId == id));
      let forestRoles = this.currentUserRole?.userRoleRegions?.some(r => r.userRoleForests.some(f => f.forestId == id));
      
      if(forestRoles)
        return " *Current user role location.";  
      
      if(forestRequests?.length > 0)
        return " *Request pending.";
      
      return null;

    }

    currentDistrictStatus(id: number | undefined){
      if(!id)
        return "";

      let currentUserRoleRequests = this.userRoleRequest?.user?.userRoleRequests;
      let districtRoles = this.currentUserRole?.userRoleRegions?.some(r => r.userRoleForests.some(f => f.userRoleDistricts.some(d => d.districtId == id)));
      let districtRequests = currentUserRoleRequests!.filter(r => r.forests?.some(f => f.districts?.some(d => d.districtId == id)));
      
      if(districtRoles)
        return " *Current user role location.";  
      
      if(districtRequests?.length > 0)
        return " *Request pending.";
      
      return null;
    }
    
    onForestChekboxChange(event: MatCheckboxChange, selectedForest: Forest, index: number) {
      if(event.checked) {
        this.opened(index, selectedForest.id);
      } else {
        this.closed(index, selectedForest.id);
      }
      this.onForestChekboxChangeElement(event.checked, selectedForest);
    }

    onForestChekboxChangeElement(checked: boolean, selectedForest: Forest) {
      let districts = this.districtCheckboxes.filter(f => f._elementRef.nativeElement.classList.contains("districtCheckbox-" + selectedForest.forestCode));
      let requestForest = this.userRoleRequest?.forests?.filter(f => f.forestId === selectedForest.id)[0];
      districts.forEach(elem => {
        elem.checked = checked;
        let exisitingDistrict = selectedForest.districts.filter(d => d.id == elem.value)[0];
        if(exisitingDistrict) {
          this.onDistrictCheckboxChangeElement(elem.checked, exisitingDistrict)
        }
      });

      if (checked) {
        if(!requestForest) {
          let forest: UserRoleRequestForest = {
            forestId: selectedForest.id,
            status: UserRoleRequestStatus[UserRoleRequestStatus.Requested],
            districts: []
          }
          this.userRoleRequest?.forests?.push(forest);
          requestForest = forest;
        } else {
          requestForest.status = UserRoleRequestStatus[UserRoleRequestStatus.Requested];
        } 

        districts.forEach(elem => {
          let requestDistrict = requestForest?.districts?.filter(d => d.districtId == +elem.value)[0];
          if(requestDistrict) {
            requestDistrict.status = UserRoleRequestStatus[UserRoleRequestStatus.Requested];
          } else {
            if(elem.checked) {
              let district: UserRoleRequestDistrict = {
                userRoleRequestForestId: requestForest?.id,
                districtId: +elem.value,
                status: UserRoleRequestStatus[UserRoleRequestStatus.Requested]
              }
              requestForest?.districts?.push(district);
            }
          }
          
        });
      } else {
        if(requestForest && this.userRoleRequest != null) {
          requestForest.status = UserRoleRequestStatus[UserRoleRequestStatus.Denied];

        }                   
      }
    }
      
      onDistrictCheckboxChange(event: MatCheckboxChange, selectedDistrict: District){
        this.onDistrictCheckboxChangeElement(event.checked, selectedDistrict);
      }

      onDistrictCheckboxChangeElement(checked: boolean, selectedDistrict: District){
        let requestForest = this.userRoleRequest?.forests?.filter(f => f.forestId == selectedDistrict.forestId)[0];
        let requestDistrict = requestForest?.districts?.filter(d => d.districtId == selectedDistrict.id)[0];
        let forestCheckbox = this.forestCheckboxes.filter(f => f.value == selectedDistrict.forestId)[0];
        // district checkbox is checked
        if(checked) {
          if(!forestCheckbox.checked) {
            forestCheckbox.checked = true;
            if(requestForest)
              requestForest.status = UserRoleRequestStatus[UserRoleRequestStatus.Requested]
          }
          // requestForest does exist in userRoleRequest.forests[]
          if(requestForest) {
            // requestDistrict does NOT exist in requestForest.districts[]
            if(!requestDistrict) {
              let district: UserRoleRequestDistrict = {
                userRoleRequestForestId: requestForest.id,
                districtId: selectedDistrict.id,
                status: UserRoleRequestStatus[UserRoleRequestStatus.Requested]
              }
              requestForest.districts?.push(district);
            } 
            // else: ensure status is requested
            else {
              requestDistrict.status = UserRoleRequestStatus[UserRoleRequestStatus.Requested];
            }
          } 
          // requestForest does NOT exist in userRoleRequest.forests[] 
          else {
            let forest: UserRoleRequestForest = {
              forestId: selectedDistrict.forestId,
              status: UserRoleRequestStatus[UserRoleRequestStatus.Requested],
              districts: []
            }
            let district: UserRoleRequestDistrict = {
              districtId: selectedDistrict.id,
              status: UserRoleRequestStatus[UserRoleRequestStatus.Requested]
            }
            forest.districts?.push(district);
            this.userRoleRequest?.forests?.push(forest);
          }
        // district checkbox is unchecked
        } 
        // district checkbox is unchecked
        else if(requestForest && requestDistrict){
            requestDistrict.status = UserRoleRequestStatus[UserRoleRequestStatus.Denied];
            if(forestCheckbox.checked) {
              let checkedDistricts =  requestForest.districts?.filter(d => d.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested] && d.districtId != requestDistrict?.districtId);
              forestCheckbox.checked = checkedDistricts != null && checkedDistricts.length > 0;
  
            } 
        }
      }
          
      onForestCheckboxKeyUp(event: KeyboardEvent, forest: Forest, index?: number) {
        let forestCheckbox = this.forestCheckboxes.filter(f => f.value == forest.id)[0]; 
        let openIndex = this.openPanels.find((i) => i == index);
        if (event.key === ' ' || event.key === 'Enter') {
          event.stopPropagation();
          let checked = !forestCheckbox.checked;
          forestCheckbox.checked = checked;
          if(index != null) {
            if(forestCheckbox.checked && openIndex == null) {
              this.opened(index, forest.id);
            } else {
              this.closed(index, forest.id);
            }
          }
          this.onForestChekboxChangeElement(forestCheckbox.checked, forest);
        }
      }
  
      onDistrictCheckboxKeyUp(event: KeyboardEvent, district: District) {
          let districtCheckbox = this.districtCheckboxes.filter(f => f.value == district.id)[0]; 
          if (event.key === ' ' || event.key === 'Enter') {
            let checked = !districtCheckbox.checked;
            districtCheckbox.checked = checked;
            this.onDistrictCheckboxChangeElement(districtCheckbox.checked, district);
          }
        }

    
    onNotesChange(e: any) {
      if(this.userRoleRequest != null) {
        this.userRoleRequest.notes = e.target?.value;
        console.log(this.userRoleRequest.notes);
      }
      
    }

    submitRequest(status: boolean | null) {
      if(this.userRoleRequest != null) {
        if(status != null) {
          this.userRoleRequest.status = status ? UserRoleRequestStatus[UserRoleRequestStatus.Approved]: UserRoleRequestStatus[UserRoleRequestStatus.Denied];
          this.userRoleRequest.forests?.forEach(f => {
            if(status) {
              if(f.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested])
                f.status = UserRoleRequestStatus[UserRoleRequestStatus.Approved];

                f.districts?.forEach(d => {
                  if(d.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested])
                    d.status = UserRoleRequestStatus[UserRoleRequestStatus.Approved];
                });
            } else {
              f.status = UserRoleRequestStatus[UserRoleRequestStatus.Denied];

              f.districts?.forEach(d => {
                d.status = UserRoleRequestStatus[UserRoleRequestStatus.Denied];
              });
            }
            

            

          });
        } else {
          this.userRoleRequest.status = UserRoleRequestStatus[UserRoleRequestStatus.Requested];
        }
        this.userRoleRequestsService.updatePendingRequest(this.userRoleRequest).subscribe({
          next: response => {
            if(response.isSuccess) {
              if(this.userRoleRequest?.status != UserRoleRequestStatus[UserRoleRequestStatus.Requested]) {
                this.pendingRequests = this.pendingRequests.filter(r => r.id != this.userRoleRequest?.id);
              } 
              this.toastr.success('Successfully updated User Role Request as ' + this.userRoleRequest?.status + '.', );
              this.closeModal('modal-3');
              this.initForm();
            } else {
              this.toastr.error('Failed to update User Role Request as ' + this.userRoleRequest?.status + '. Error: '+ response.error);
            }
          }
        });
      }
     
    }
//#endregion Event Methods
    

    /*=====================================*/
}


