import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { UsersService } from '../../../../../services/users/users.service';
import { AppraisalGroupRatesService } from '../../../../../services/appraisal-group-rates/appraisal-group-rates.service';
import { CalculationsComponent } from '../calculations.component';
import { AppraisalGroup } from '../../../../../models/appraisal-group';

@Component({
    selector: 'app-streamlined-te-calculations',
    templateUrl: './streamlined-te-calculations.component.html',
    styleUrls: ['./streamlined-te-calculations.component.scss'],
})

export class StreamlinedTeCalculationsComponent implements OnInit {
    @Input() formGroupName!: string;
    @Output() childEvent = new EventEmitter();
    
    form!: FormGroup;
    bpaRates!: FormGroup;
    calculationsComponent: CalculationsComponent;

    appraisalGroup!: AppraisalGroup;
    appraisalGroupId: number;



    formatThumbLabel(value: number): string {
        return `${value}%`;
    }

    
    constructor(@Host() parent: CalculationsComponent,
        private fb: FormBuilder, 
        private http: HttpClient, 
        private rootFormGroup: FormGroupDirective,
        public usersService: UsersService,
        private appraisalGroupRatesService: AppraisalGroupRatesService) {
            this.calculationsComponent = parent;
            this.appraisalGroupId = parent.appraisalGroupId;
            this.appraisalGroup = parent.appraisalGroup;
           
    }


    ngOnInit() {
        //Get root estimate market value form
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.bpaRates = this.form.controls['bpaRates'] as FormGroup;
        console.log(this.bpaRates);
        this.setInitialRateValues();
        
    }

    setInitialRateValues(){
        this.bpaRates?.controls['baseSkidYard'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].stumpToTruckCost)
        this.bpaRates?.controls['baseSkidYard'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].stumpToTruckCost);
        this.bpaRates?.controls['baseHaul'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].haulCost);
        this.bpaRates?.controls['baseHaul'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].haulCost);
        this.bpaRates?.controls['baseRoadMaintenance'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].roadMaintCost);
        this.bpaRates?.controls['baseRoadMaintenance'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].roadMaintCost);
        this.bpaRates?.controls['baseTempDevelopments'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].tempDevCost);
        this.bpaRates?.controls['baseTempDevelopments'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].tempDevCost);
        this.bpaRates?.controls['baseSlashDisposal'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].envProCost);
        this.bpaRates?.controls['baseSlashDisposal'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].envProCost);
        console.log(this.bpaRates);
    }

    get uomControl(): FormControl{
        return this.calculationsComponent.uomControl;
    }

    bpaRateControl(name: string): FormControl{
        let rateControl = this.bpaRates?.controls[name]?.get('rate') as FormControl;
        return rateControl;
    }

    bpaRatePercentageControl(name: string): FormControl{
        let percentControl = this.bpaRates?.controls[name].get('percentage') as FormControl;
        return percentControl;
    }

    setRate(name: string) : void {
        let rate = this.bpaRates?.controls[name].get('initialValue')?.value;
        let percentage = this.bpaRates?.controls[name].get('percentage')?.value;
        let adjustedRate = rate * (percentage / 100);
        this.bpaRates?.controls[name].get('rate')?.patchValue(adjustedRate.toFixed(2));
    }

    setSlider($event: Event, name: string) : void{
        let element = ($event.target as HTMLInputElement);
        let rate = Number(this.bpaRates?.controls[name].get('initialValue')?.value);
        let max = rate * 2;
        element.max = max.toString();
        if (element.value) {
            let value = Number(element.value); 
            if(value > max){
                element.value = max.toString();
                this.bpaRates?.controls[name].get('percentage')?.patchValue(200);
            } else {
                let percentage = (value / this.bpaRates?.controls[name].get('initialValue')?.value) * 100;
                this.bpaRates?.controls[name].get('percentage')?.patchValue(percentage);
            }
            
        }   
    }
    
    resetSlider(name: string) : void{
        let intitalValue = this.bpaRates?.controls[name].get('initialValue')?.value;
        this.bpaRates?.controls[name].get('rate')?.patchValue(intitalValue);
        this.bpaRates?.controls[name].get('percentage')?.patchValue(100);
        
    }

    
    
    
}