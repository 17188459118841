<ng-container [formGroup]="form" *ngIf="appraiserRoleId == user_Roles.Guest; else adminRoles">
    <div class="market-value-accordion-panel">
        <h4 class="usa-accordion__heading market-value-accordion-heading">
            <button
            type="button"
            id="panel-1-button"
            class="usa-accordion__button"
            aria-expanded="true"
            aria-controls="b-a1"
            >
                <svg class="usa-icon usa-icon--size-5 accordion-icon u-icon" aria-hidden="true" focusable="false">
                    <use xlink:href="/assets/img/sprite.svg#info"></use>
                </svg>
                <span>General Information</span>
                <div class="accordion-button-details" *ngIf="appraiserRoleId != user_Roles.Guest">
                    Count of Cutting Units: {{ cuttingUnitsCount }},  Total Acreage: {{ totalAcreage }}
                </div>
            </button>
           
        </h4>
        <div id="b-a1" class="usa-accordion__content usa-prose market-value-accordion-content">
            <div class="grid-container accordion-grid-container" style="margin-bottom:50px;">
                <div class="grid-row">
                    <div class="tablet:grid-col inputs">
                        <div class="usa-form-group">
                            <label class="usa-label" for="appraiser-name">Appraiser Name</label>
                            <app-text-input [formControl]="$any(form.controls['appraiserName'])" [label]="'Appraiser Name'"></app-text-input>
                        </div>

                        <div class="usa-form-group">
                            <label class="usa-label" id="appraisal-date-label" for="appraisal-date">Appraisal Date </label>
                            <div class="usa-hint" id="appraisal-date-hint"></div>
                          
                          <div class="usa-date-picker">
                            <app-date-picker [formControl]="$any(form.controls['appraisalDate'])" [label]="'Appraisal Date'"></app-date-picker>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="grid-row">
                    <div class="tablet:grid-col inputs">
                        <div class="usa-form-group">
                            <label class="usa-label" for="regionCode">Region<sup>*</sup></label>
                            <ng-select formControlName="regionCode" name="regionCode" id="regionCode" class="usa-rfd" (change)="setForests()"  placeholder="Select a Region" [loading]="loadingHandler.isLoading" [ariaLabel]="'Select a Region'">
                                <ng-option *ngFor="let region of regions" [value]="region.regionCode">{{region.regionCode}} - {{region.regionName}}</ng-option>
                            </ng-select>
                           
                        </div>

                        <div class="usa-form-group">
                            <label class="usa-label" for="forestCode">Forest <sup>*</sup></label>
                            <ng-select formControlName="forestCode" name="forestCode" id="forestCode" class="usa-rfd" (change)="setDistricts()"  placeholder="Select a Forest" [ariaLabel]="'Select a Forest'">
                                <ng-option *ngFor="let forest of forests" [value]="forest.forestCode">{{forest.forestCode}} - {{forest.forestName}}</ng-option>
                            </ng-select>
                           
                        </div>

                    </div>
                </div>
                <div class="grid-row">
                    <div class="tablet:grid-col inputs">
                        <div class="usa-form-group">
                            <label class="usa-label" for="districtCode">District<sup>*</sup></label>
                            <ng-select formControlName="districtCode" name="districtCode" id="districtCode" class="usa-rfd" (change)="setAppraisalZone()" placeholder="Select a District" [ariaLabel]="'Select a Region'">
                               <ng-option *ngFor="let district of districts" [value]="district.districtCode">{{district.districtCode}} - {{district.districtName}}</ng-option>
                            </ng-select>
                           
                        </div>

                        <div class="usa-form-group">
                            <label class="usa-label" for="appraisalZoneCode">Appraisal Zone</label>
                            <input formControlName="appraisalZoneCode" class="usa-input" id="appraisalZoneCode" name="appraisalZoneCode" placeholder="Zone number"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="grid-container accordion-grid-container" style="padding-right:0 !important;">
                <p>Do any of these apply to your sale?</p>

                <div class="grid-row" id="formGroup" cdkScrollable>
                        <mat-button-toggle-group name="applySale" formArrayName="saleTypes" aria-label="applySale" multiple>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="SBA Bidders" (change)="setSalesTypes($event, 0)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">SBA Bidders</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if you are in a Small Business Administration (SBA) appraisal zone.">
                                                <span class="material-icons">help</span>
                                            </mat-icon> 
                                        </div>
                                    </mat-button-toggle> 

                                    <mat-button-toggle tabindex="" value="Special Conditions" (change)="setSalesTypes($event, 1)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Special Conditions/Circumstances</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if this is a ski area settlement, damage appraisal, or rate redetermination appraisal.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>                                    
                                </div>
                            </div>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="Road construction" (change)="setSalesTypes($event, 2)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Road Construction/Reconstruction</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if your project requires specified road construction, reconstruction, or the construction of any feature requiring engineering design.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>
                                    
                                    <mat-button-toggle tabindex="" value="Cable or Aerial Logging Systems" (change)="setSalesTypes($event, 3)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Cable or Aerial Logging Systems</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if terrain is too steep for ground-based equipment.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>
                                </div>
                            </div>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="Trade Goods for Services" (change)="setSalesTypes($event, 4)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Trade Goods for Services</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select to model integrated resource contracting scenarios.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>

                                    <mat-button-toggle tabindex="" value="Timber Property Value" (change)="setSalesTypes($event, 5)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Timber Property Value</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if your sale contains any investment in value added processing. Value added processing activities can include felling, bucking, skidding and other activities and can apply to either a portion of or the total volume in the sale.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>

                                </div>
                            </div>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="Required reforestation or site prep" (change)="setSalesTypes($event, 6)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Required Reforestation or Site Prep</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if you have prescribed a final regeneration harvest to include collections for reforestation.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>    

                                    <mat-button-toggle tabindex="" value="" (change)="setSalesTypes($event, 7)" style="visibility:hidden">
                                    </mat-button-toggle>  
                                </div>
                            </div>
                        </mat-button-toggle-group>
                </div>
            </div>
            <div class="usa-form-group">
                <button type="button" class="usa-button usa-button--outline continue-button" (click)="continue($event)">Continue</button>
            </div> 
        </div>
    </div>
</ng-container>

<ng-template [formGroup]="form" #adminRoles>
    <div class="market-value-accordion-panel">
        <h4 class="usa-accordion__heading market-value-accordion-heading">
            <button
            type="button"
            id="panel-1-button"
            class="usa-accordion__button"
            aria-expanded="true"
            aria-controls="b-a1"
            >
                <svg class="usa-icon usa-icon--size-5 accordion-icon u-icon" aria-hidden="true" focusable="false">
                    <use xlink:href="/assets/img/sprite.svg#info"></use>
                </svg>
                <span>General Information</span>
                <div class="accordion-button-details" *ngIf="appraiserRoleId != user_Roles.Guest">
                    Count of Cutting Units: {{ cuttingUnitsCount }},  Total Acreage: {{ totalAcreage }}
                </div>
            </button>
           
        </h4>
        <div id="b-a1" class="usa-accordion__content usa-prose market-value-accordion-content">
            <div class="grid-container accordion-grid-container">
                <div class="usa-table-container--scrollable">
                    <div class="info-container--callToAction">
                        <div>
                            <p>Cutting Units: 5</p>
                            <p>Total sq. Miles: 4200</p>
                        </div>
                        <div>
                            <button type="button" tabindex="0" class="btn btn-sm btn-outline-success" aria-label="Add New Sale">Add New Sale</button>
                        </div>
                    </div>
                    <div class="table-container">
                        <table class="usa-table" style="width:100% !important">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">Cutting Unit ID #</th>
                                <th scope="col">Net Acreage</th>
                                <th scope="col">Harvest Prescription</th>
                                <th scope="col">Logging System</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr>
                                    <td>CU00001</td>
                                    <td>500 Sq. Miles</td>
                                    <td>220 Commercial Thinning</td>
                                    <td>451 - Grabinski</td>
                                </tr>
                                <tr>
                                    <td>CU00002</td>
                                    <td>700 Sq. Miles</td>
                                    <td>101 Coppice cut (EA/FA/RH)</td>
                                    <td>480 - Helicoptor</td>
                                </tr>
                            </ng-container>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="grid-container accordion-grid-container" style="padding-right:0 !important;">
                <p>Do any of these apply to your sale?</p>

                <div class="grid-row" id="formGroup" cdkScrollable>
                        <mat-button-toggle-group name="applySale" formArrayName="saleTypes" aria-label="applySale" multiple>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="SBA Bidders" (change)="setSalesTypes($event, 0)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">SBA Bidders</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if you are in a Small Business Administration (SBA) appraisal zone.">
                                                <span class="material-icons">help</span>
                                            </mat-icon> 
                                        </div>
                                    </mat-button-toggle> 

                                    <mat-button-toggle tabindex="" value="Special Conditions" (change)="setSalesTypes($event, 1)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Special Conditions/Circumstances</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if this is a ski area settlement, damage appraisal, or rate redetermination appraisal.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>                                    
                                </div>
                            </div>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="Road construction" (change)="setSalesTypes($event, 2)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Road Construction/Reconstruction</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if your project requires specified road construction, reconstruction, or the construction of any feature requiring engineering design.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>
                                    
                                    <mat-button-toggle tabindex="" value="Cable or Aerial Logging Systems" (change)="setSalesTypes($event, 3)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Cable or Aerial Logging Systems</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if terrain is too steep for ground-based equipment.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>
                                </div>
                            </div>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="Trade Goods for Services" (change)="setSalesTypes($event, 4)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Trade Goods for Services</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select to model integrated resource contracting scenarios.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>

                                    <mat-button-toggle tabindex="" value="Partially Processed Volume" (change)="setSalesTypes($event, 5)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Partially Processed Volume</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if the project includes any investment in Value Added Processing, (such as felling, bucking, skidding, road maintenance or any additional site prep work.)">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>

                                </div>
                            </div>
                            <div class="grid-row" style="width:100%;">
                                <div class="tablet:grid-col cols">
                                    <mat-button-toggle tabindex="" value="Required reforestation or site prep" (change)="setSalesTypes($event, 6)">
                                        <div class="containerbtn" style="display:flex;justify-content: space-between; align-items: center;">
                                            <div class="title">Required Reforestation or Site Prep</div>
                                            <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="Select if you have prescribed a final regeneration harvest to include collections for reforestation.">
                                                <span class="material-icons">help</span>
                                            </mat-icon>
                                        </div>
                                    </mat-button-toggle>    

                                    <mat-button-toggle tabindex="" value="" (change)="setSalesTypes($event, 7)" style="visibility:hidden">
                                    </mat-button-toggle>  
                                </div>
                            </div>
                        </mat-button-toggle-group>
                </div>
            </div>
            <div class="usa-form-group">
                <button type="button" class="usa-button usa-button--outline continue-button" (click)="continue($event)">Continue</button>
            </div> 
        </div>
    </div>
</ng-template>

