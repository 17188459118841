import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { TreeSpecies } from '../../models/forest-service/tree-species';
import { TreeSpeciesParams } from '../../models/params/treeSpeciesParams'
import { getList, getListHeaders } from '../pagination/listHelper';

@Injectable({
  providedIn: 'root'
})

export class TreeSpeciesService {
    baseUrl = environment.apiUrl;
    treeSpeciess: TreeSpecies[] = [];
    treeSpeciesParams: TreeSpeciesParams | undefined;

  constructor(private http: HttpClient) { 
    this.treeSpeciesParams = new TreeSpeciesParams();
  }

  getTreeSpeciesParams() {
    return this.treeSpeciesParams;
  }

  setTreeSpeciesParams(treeSpeciesParams: TreeSpeciesParams) {
    this.treeSpeciesParams = treeSpeciesParams;
  }

  resetTreeSpeciesParams() {
    if (this.treeSpeciesParams) {
      this.treeSpeciesParams = new TreeSpeciesParams();
      return this.treeSpeciesParams;
    }
    return;
  }  

  setTreeSpecies(treeSpecies: TreeSpecies[]) {
    localStorage.setItem('treeSpecies', JSON.stringify(treeSpecies));
  }

  getTreeSpecies() {
    const treeSpeciessString = localStorage.getItem('treeSpecies');
    let treeSpecies: TreeSpecies[] = treeSpeciessString != null ? JSON.parse(treeSpeciessString) : null;
    
    if(treeSpecies != null && treeSpecies.length == 0) return of(treeSpecies);

    let params = getListHeaders();

    return getList<TreeSpecies[]>(this.baseUrl + 'treespecies', params, this.http).pipe(
      map(response => {
        this.setTreeSpecies(response as TreeSpecies[]);
        return response;
      })
    )
  }

  getTreeSpeciesById(treeSpeciesId: number) {    
    return this.http.get<TreeSpecies>(this.baseUrl + 'treespecies/' + treeSpeciesId);
  }

  
}