export class LoadingHandler{

    private timeout: any
    isLoading = false;

    start(){
       this.timeout = setTimeout(()=>{
          this.isLoading = true
       },2500)
    }

    finish(){
       this.isLoading = false;
       clearTimeout(this.timeout)
    }
}