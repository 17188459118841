import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-estimator',
  templateUrl: './estimator.component.html',
  styleUrl: './estimator.component.scss'
})
export class EstimatorComponent implements OnInit {
  @Input() formGroupName!: string
  @Output() childEvent = new EventEmitter();
  form!: FormGroup;
  calculations!: FormGroup;
  volumeInformation!: FormGroup;
  totalSaleValueControl!: FormControl;
  contractBaseValueControl!: FormControl;
  appraisalRatePerUomControl!: FormControl;

  constructor(private fb: FormBuilder, 
      private rootFormGroup: FormGroupDirective,
      private http: HttpClient) {
  }


  ngOnInit() {
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
      this.volumeInformation = this.rootFormGroup.control.get("volumeInformation") as FormGroup;
      this.calculations = this.rootFormGroup.control.get("calculations") as FormGroup;
      this.totalSaleValueControl = this.calculations.controls['totalSaleValue'] as FormControl;
      this.contractBaseValueControl = this.form.controls['contractBaseValue'] as FormControl;
      this.appraisalRatePerUomControl = this.form.controls['appraisalRatePerUom'] as FormControl;

  }

  get uom(){
    return this.volumeInformation.controls["uomShortName"].value;
  }

  get totalSaleValue(): number{
    let totalGroupValue = this.calculations.controls["totalGroupValue"].value;
    let totalVolume = this.volumeInformation.controls["totalVolume"].value;
    let standardRate = this.calculations.controls["standardRate"].value;
     //THIS NEEDS TO BE CALCULATED DIFFERENTLY FOR MULITPLE GROUPS
    this.totalSaleValueControl.patchValue(totalVolume * standardRate);
    return this.totalSaleValueControl.value;
  }

  get contractBaseValue(){
    //FIX
    let totalVolume = this.volumeInformation.controls["totalVolume"].value;
    let minimumRate = this.calculations.controls["minimumRate"].value;
    let result = totalVolume * minimumRate;
    if(isNaN(result)){
      return 0;
    } else {
      this.contractBaseValueControl.patchValue(result);
      return this.contractBaseValueControl.value;
    }
   
}

  get appraisalRatePerUom(){
    let totalVolume = this.volumeInformation.controls["totalVolume"].value;
    let result = this.totalSaleValue / totalVolume;
    if(isNaN(result)){
      return 0;
    } else {
      this.appraisalRatePerUomControl.patchValue(result);
      return this.appraisalRatePerUomControl.value;
    }
  }

  
  
}
