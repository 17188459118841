import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})

export class ProductsComponent implements OnInit {

    constructor() {
        console.log("constructor not implemented.");
    }

    ngOnInit() {
        console.log("ngOnInit not implemented.");
    }

    
}


