import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs';
import { User } from '../models/user/user';
import { UsersService } from '../services/users/users.service';

@Directive({
  selector: '[appHasNoRole]'
})
export class HasNoRoleDirective {
  @Input() appHasNoRole: string[] = [];
  user: User = {} as User;

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>, 
    private usersService: UsersService) { 
      this.usersService.currentUser$.pipe(take(1)).subscribe({
        next: user => {
          if (user) this.user = user;
        }
      })
    }

  ngOnInit(): void {
    console.log(this.user);
    if (!this.user.userRoles.some(role => this.appHasNoRole.includes(role.role?.name))) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);  
    } else {
      this.viewContainerRef.clear();
    }
  }

}