import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { AppraisalGroup } from '../../../../../models/appraisals/appraisal-group';
import { EstimateSaleOperatingCostsComponent } from '../estimate-sale-operating-costs.component';
import { IVisited } from '../../../../../models/visitors/visited';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';


@Component({
    selector: 'app-standard-rate-calculations',
    templateUrl: './standard-rate-calculations.component.html',
    styleUrls: ['./standard-rate-calculations.component.scss'],
    standalone: false
})

export class StandardRateCalculationsComponent implements OnInit, IVisited {

    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    
    range = new FormControl(0);
    form!: FormGroup;
    calculationsForm!: FormGroup;
    calculationsComponent: EstimateSaleOperatingCostsComponent;
    
    title:string = "Appraisal Groups";

    appraisalGroupCollection:any[] =[
        {speciesname:"Ponderosa Pine", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Engelmann Spruce", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Douglas Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Subalpine Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Grand Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"White Pine", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Balsam Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Corkbark Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"California Red Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Shasta Red Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Noble Fir", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Port Oford Cedar", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
        {speciesname:"Alaska Yellow Cedar", volume:'1000/CCF', standard_rate:"15.66", rate:"15,660"},
    ];

    p:number = 1;
    appraisalGroupForms: any;
    appraiserRoleIdControl!: FormControl;
    appraiserRoleId: number;

    constructor(@Host() parent: EstimateSaleOperatingCostsComponent, 
    private rootFormGroup: FormGroupDirective,
    private calculationsService: CalculationsService) {
        this.calculationsComponent = parent;
        this.appraisalGroupForms = parent.appraisalGroupForms;
        this.appraiserRoleId = parent.appraiserRoleId;
    }

    public readProperty<T>(prop: string):T {
        let property = this[prop as keyof StandardRateCalculationsComponent];
        if (property == null) {
           throw Error(`Property "${prop}" does not exists`);
        }
        return property as T;
     } 


    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    }



   
    
}