import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { WwpaIndicesService } from '../../../../services/wwpa-indices/wwpa-indices.service';
import { WwpaIndex } from '../../../../models/wwpa/wwpa-index-v';
import { User } from '../../../../models/user/user';
import { ModalService } from '../../../../services/modal.service';
import { WwpaIndexDto } from '../../../../models/wwpa/wwpa-index-dto';
import { FocusService } from '../../../../services/focus.service';


@Component({
    selector: 'app-wwpa-indices',
    templateUrl: './wwpa-indices.component.html',
    styleUrls: ['./wwpa-indices.component.scss'],
    standalone: false
})

export class WwpaIndicesComponent implements OnInit {
    showForm:boolean = true;
    currentUrl: any ='';
  
    @ViewChild('skip')  skip!:ElementRef;
    @ViewChild('toggleBtn') toggleBtn!:ElementRef;
    @ViewChild('statusBtns') statusBtns!:ElementRef;
    @ViewChild('statusForm') statusForm!:ElementRef;
    @ViewChild('statusTitle') statusTitle!:ElementRef;
    @ViewChild('saveStatus') saveStatus!:ElementRef;
    @ViewChild('main') main!:ElementRef;
    @ViewChild('modal') modal!:ElementRef;
    @ViewChild('isActive') isActive!:ElementRef;
    @ViewChild('inActive') inActive!:ElementRef;
    
    @Input() labelPosition!: 'before' | 'after'; 
 
    wwpaIndices: WwpaIndex[] = [];
    filteredWwpaIndices: WwpaIndex[] = [];
    
    form!: FormGroup;
    rowsForm!: FormArray;
    newIndexYYYYMM: string;
    currentActiveMonth: string;
    statuses: string[] = ['Active', 'Inactive'];
    selectedStatuses: string[] = [];
    currentQuarterStart: number;

    constructor(private fb:FormBuilder,
        private wwpaIndicesService: WwpaIndicesService, public modalService:ModalService, public focusService:FocusService) {
        this.currentQuarterStart = 3; //, 6, 9, 12;
        // Quarterly Indices Logic for:
        // 03 March           > Inserted in 04 April
        // 06 June               > Inserted in 07 July
        // 09 September   > Inserted in 10 October
        // 12 December    > Inserted in 01 January
        // Set As Active
        const month = (new Date().getMonth().toString()).length > 1 ? new Date().getMonth() : '0' + (new Date().getMonth())
        this.currentActiveMonth = month + '/' + new Date().getFullYear().toString();
        this.newIndexYYYYMM = new Date().getFullYear().toString() + month;
        
        this.modalService.onSaveSubmit.subscribe(()=>{
          this.onSave();
        })
        this.focusService.onFocus.subscribe(()=>{
          this.showFocus();
        });
    
        this.modalService.onFoucsModal.subscribe(()=>{
          this.focusModal();
        })
    }

    ngOnInit(){
        this.initForm();
        this.getWwpaIndices();
    }

    initForm(){
      this.form = this.fb.group({
        status: new FormControl(1, Validators.required),
        rows: this.fb.array(this.wwpaIndices.map(val => this.fb.group({
            parent_Id: new FormControl(),
            wwpaIndexName: new FormControl(),
            wwpaIndexCode: new FormControl(),
            tsaSpeciesCode: new FormControl(),
            child_Id: new FormControl(),
            wwpaIndexId: new FormControl(),
            indexYYYYMM: new FormControl(),
            indexValue: new FormControl(),
            unitOfMeasureId: new FormControl(),
            uomShortName: new FormControl(),
            child_InactivatedWhenUtc: new FormControl(),
            child_IsActiveFlag: new FormControl(),
            child_InsertedById: new FormControl(),
            child_InsertedByName: new FormControl(),
            child_InsertedWhenUtc: new FormControl(),
            child_UpdatedById: new FormControl(),
            child_UpdatedByName: new FormControl(),
            child_UpdatedWhenUtc: new FormControl(),
            
            action: new FormControl('existingRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(false),
            error: new FormControl(""),
            hasError: new FormControl(false),
        })
      ))
    }); 
    }

    getWwpaIndices(){
        this.wwpaIndicesService.wwpaIndices$.subscribe({
            next: response => {
            console.log(response);
              if(response != null){
                this.wwpaIndices = response;
                this.filteredWwpaIndices = this.wwpaIndices;
                this.setRows();
              }
                
            }
        })
    }

    setRows(){
        if(this.filteredWwpaIndices != null){
            this.form = this.fb.group({
                status: new FormControl('active', Validators.required),
                rows: this.fb.array(this.filteredWwpaIndices.map(val => this.fb.group({
                    parent_Id: new FormControl(val.parent_Id),
                    wwpaIndexName: new FormControl(val.wwpaIndexName),
                    wwpaIndexCode: new FormControl(val.wwpaIndexCode),
                    tsaSpeciesCode: new FormControl(val.tsaSpeciesCode),
                    child_Id: new FormControl(val.child_Id),
                    wwpaIndexId: new FormControl(val.wwpaIndexId),
                    indexYYYYMM: new FormControl(val.indexYYYYMM),
                    indexValue: new FormControl(val.indexValue),
                    unitOfMeasureId: new FormControl(val.unitOfMeasureId),
                    uomShortName: new FormControl(val.uomShortName),
                    child_InactivatedWhenUtc: new FormControl(new Date(val.child_InactivatedWhenUtc)),
                    child_IsActiveFlag: new FormControl(val.child_IsActiveFlag),
                    child_InsertedById: new FormControl(val.child_InsertedById),
                    child_InsertedByName: new FormControl(val.child_InsertedByName),
                    child_InsertedWhenUtc: new FormControl(new Date(val.child_InsertedWhenUtc)),
                    child_UpdatedById: new FormControl(val.child_UpdatedById),
                    child_UpdatedByName: new FormControl(val.child_UpdatedByName),
                    child_UpdatedWhenUtc: val.child_UpdatedWhenUtc ? new FormControl(new Date(val.child_UpdatedWhenUtc)) : null,
                    
                    action: new FormControl('existingRecord'),
                    isEditable: new FormControl(false),
                    isNewRow: new FormControl(false),
                    error: new FormControl(""),
                    hasError: new FormControl(false),
                })
              )),
              insertedRows: null
            }); 
      
            this.rowsForm = this.form.controls['rows'] as FormArray;
            
        }
    }

    addRows(){
      if(!this.showForm && this.rowsForm.length == this.wwpaIndices.length){
        for(let i = 0; i < this.wwpaIndices.length; i++){
          this.rowsForm.insert(i,
            this.fb.group({
              parent_Id: new FormControl(this.wwpaIndices[i].parent_Id),
              wwpaIndexName: new FormControl(this.wwpaIndices[i].wwpaIndexName),
              wwpaIndexCode: new FormControl(this.wwpaIndices[i].wwpaIndexCode),
              tsaSpeciesCode: new FormControl(this.wwpaIndices[i].tsaSpeciesCode),
              child_Id: new FormControl(),
              wwpaIndexId: new FormControl(),
              indexYYYYMM: new FormControl(this.newIndexYYYYMM),
              indexValue: new FormControl(0.00),
              unitOfMeasureId: new FormControl(this.wwpaIndices[i].unitOfMeasureId),
              uomShortName: new FormControl(this.wwpaIndices[i].uomShortName),
              child_InactivatedWhenUtc: new FormControl(null),
              child_IsActiveFlag: new FormControl(true),
              child_InsertedById: new FormControl(),
              child_InsertedByName: new FormControl(),
              child_InsertedWhenUtc: new FormControl(),
              child_UpdatedById: new FormControl(null),
              child_UpdatedByName: new FormControl(null),
              child_UpdatedWhenUtc: new FormControl(null),
              
              action: new FormControl('existingRecord'),
              isEditable: new FormControl(true),
              isNewRow: new FormControl(true),
              error: new FormControl(""),
              hasError: new FormControl(false),
          })
          )
        }
      }
     
    
    }

    removeRows(){
      if(this.showForm && this.rowsForm.length == 2 * this.wwpaIndices.length){
        for(let i = 0; i < this.wwpaIndices.length; i++) {
         this.rowsForm.removeAt(0);
        }
      }
     
    }

    toggleForm(){
        this.showForm = !this.showForm;
        this.toggleBtn.nativeElement.style.display= "none"; /*Green toggle btn*/
        this.saveStatus.nativeElement.style.display="none";
        this.statusTitle.nativeElement.style.display= "none";
        this.statusBtns.nativeElement.style.display= "none";
        this.statusForm.nativeElement.style.display= "flex";
        if(!this.showForm && this.rowsForm.length == this.wwpaIndices.length)
          this.addRows();
    }

    toggleStatus(statuses: string[]){
      let length = statuses.length;
      console.log(statuses, statuses.length);
      if(length == 2){
        this.filteredWwpaIndices = this.wwpaIndices;
      } else if(length == 1) {
        let status = statuses[0];
        if(status == "Active"){
          this.filteredWwpaIndices = this.wwpaIndices.filter(i => i.child_IsActiveFlag == 1)
        }else if(status == "Inactive"){
          this.filteredWwpaIndices = this.wwpaIndices.filter(i => i.child_IsActiveFlag == 0)
        }
      } else {
        this.filteredWwpaIndices = this.wwpaIndices.filter(i => i.child_IsActiveFlag != 0 && i.child_IsActiveFlag != 1)
      }
      this.setRows();
    }

    showPrevControls(){
        this.showForm = !this.showForm;
        this.toggleBtn.nativeElement.style.display= "block"; 
        this.statusBtns.nativeElement.style.display= "flex";
        this.statusTitle.nativeElement.style.display= "block";
        this.statusForm.nativeElement.style.display= "block";/*form*/
        this.saveStatus.nativeElement.style.display="none";
       
        if(this.showForm && this.rowsForm.length > this.wwpaIndices.length)
          this.removeRows();
    }

    checkSave(){
      let hasErrors = false;
      this.rowsForm.controls.forEach(obj => {
        let indexValue = obj.get('indexValue')?.value
        if(isNaN(indexValue) || indexValue === null){
          obj.get('hasError')?.patchValue(true);
          obj.get('error')?.patchValue('Field is required.');
          hasErrors = true;
        }else{
          obj.get('hasError')?.patchValue(false);
          obj.get('error')?.patchValue('');
        }
      });
      if(hasErrors)
        return;
      
      this.modalService.open('modal-1');
    }

    onSave(){

      let hasErrors = false;
      let status = this.form.controls['status'].value;
      console.log(status);
      this.rowsForm.controls.forEach(obj => {
        let indexValue = obj.get('indexValue')?.value
        if(isNaN(indexValue) || indexValue === null){
          obj.get('hasError')?.patchValue(true);
          obj.get('error')?.patchValue('Field is required.');
          hasErrors = true;
        }else{
          obj.get('hasError')?.patchValue(false);
          obj.get('error')?.patchValue('');
        }
      });
      if(hasErrors)
        return;

    let newIndices = this.rowsForm?.controls
          .filter(r => r.get('child_Id')?.value === null)
          .map(i => ({ 
            parentId: i.get('parent_Id')?.value, 
            indexValue: i.get('indexValue')?.value, 
            indexYYYYMM: i.get('indexYYYYMM')?.value, 
            isActive: status === "active",
            unitOfMeasureId: i.get("unitOfMeasureId")?.value 
          })) as WwpaIndexDto[];

      console.log(newIndices)
      this.wwpaIndicesService.createWwpaIndices(newIndices).subscribe({
        next: _ => {
          this.showForm = !this.showForm;
          this.toggleBtn.nativeElement.style.display= "block"; 
          this.statusBtns.nativeElement.style.display= "flex";
          this.statusTitle.nativeElement.style.display= "block";
          this.saveStatus.nativeElement.style.display="none";  
          this.modalService.close('modal-1');
        }, error: err => {
          console.log(err);
          this.cancelSave()
        }
      });
      
    }

    cancelSave(){
      this.showForm = !this.showForm;
      this.toggleBtn.nativeElement.style.display= "block"; 
      this.statusBtns.nativeElement.style.display= "flex";
      this.statusTitle.nativeElement.style.display= "block";
      this.saveStatus.nativeElement.style.display="none";
      this.removeRows();
  }
    
    editWwpaIndex(id: number, i: number) { 
      let originalIndex!: WwpaIndex;
      let index!: FormGroup;
      let indexValue: number;
      //TODO: set all in edit mode other than this one back to original values
      
      if(id !== null){
        let index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
        index?.controls['isEditable'].patchValue(true);
      }else {
        let index = this.rowsForm.at(i) as FormGroup;
        index?.controls['isEditable'].patchValue(true);
      }       
    }
    
    getWwpaIndexControl(id: number, i: number) : FormControl{
      let index!: FormGroup;
      if(id !== null){
        index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
      }else {
        index = this.rowsForm.at(i) as FormGroup;
      } 
      return index.controls["indexValue"] as FormControl;
    }
    
    saveWwpaIndex(id: number, i: number) {
      let originalIndex!: WwpaIndex;
      let index!: FormGroup;
      let indexValue: number;

      if(id !== null){
        originalIndex = this.wwpaIndices?.filter(r => r.child_Id == id)[0];
        index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
        indexValue = index?.controls['indexValue'].value;
        if(isNaN(indexValue) || indexValue === null){
          index.controls['hasError'].patchValue(true);
          index.controls['error'].patchValue('Field is required.');
          return;
        }else{
          index.controls['hasError'].patchValue(false);
          index.controls['error'].patchValue('');
        }
        originalIndex.indexValue = indexValue;
        this.wwpaIndicesService.updateWwpaIndex(originalIndex).subscribe({
          next: _ => {
            console.log("updating single index", originalIndex);
            index?.controls['isEditable'].patchValue(false);
          }
        });
      } 
           
         
    }
    
    cancelSaveWwpaIndex(id: number, i: number) {
      let originalIndex!: WwpaIndex;
      let index!: FormGroup;
      let indexValue: number;
      
      if(id !== null){
        originalIndex = this.wwpaIndices?.filter(r => r.child_Id == id)[0];
        indexValue = originalIndex.indexValue;
        index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
        index?.controls['indexValue'].patchValue(indexValue);
      } else {
        indexValue = 0.00;
        index = this.rowsForm.at(i) as FormGroup;
        index?.controls['indexValue'].patchValue(indexValue);
      } 
           
      index?.controls['isEditable'].patchValue(false);
    }
    
    showFocus(){
      this.main.nativeElement.focus();
    }
  
    focusModal(){
      this.modal.nativeElement.focus()
    }

    get statusControl(): FormControl{
      return this.form.controls['status'] as FormControl;
    }

    setActive(){
      //console.log(this.statusControl.value);
      let active = this.statusControl.value;
      if(active === "active"){
        this.form.controls['status'].patchValue(1);
        this.isActive.nativeElement.checked=true;
        this.inActive.nativeElement.checked=false;
        console.log("active",this.statusControl.value);
      } else if(active === "inactive"){
        this.form.controls['status'].patchValue(0);
        console.log("IN ACTIVE",this.statusControl.value);
        this.isActive.nativeElement.checked=false;
        this.inActive.nativeElement.checked=true;
      }
    }

  
    
}


