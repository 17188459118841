import {Component} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebook} from '@fortawesome/free-brands-svg-icons';
import { faTwitter} from '@fortawesome/free-brands-svg-icons';
import { faYoutube} from '@fortawesome/free-brands-svg-icons';
import { faInstagram} from '@fortawesome/free-brands-svg-icons';
import { faFlickr } from '@fortawesome/free-brands-svg-icons';


@Component({
    selector: 'app-footer',
    templateUrl: "./footer.component.html",
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {

    facebook: IconProp= faFacebook as IconProp;
    twitter: IconProp = faTwitter as IconProp;
    youtube: IconProp = faYoutube as IconProp;
    instagram: IconProp = faInstagram as IconProp;
    flickr: IconProp = faFlickr as IconProp;


    scrollTop({ev}: { ev: any }) {
        ev.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

}
