import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-appraisal-groups',
    templateUrl: './appraisal-groups.component.html',
    styleUrl: './appraisal-groups.component.scss',
    standalone: false
})
export class AppraisalGroupsComponent implements OnInit {

  constructor() {
      console.log("constructor not implemented.");
  }

  ngOnInit() {
      console.log("ngOnInit not implemented.");
  }


}
