import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { getList, getListHeaders } from '../pagination/listHelper';
import { UserRole } from '../../models/user/user-roles/user-role';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {
  baseUrl = environment.apiUrl;

  private userRolesSource = new BehaviorSubject<UserRole[] | null>(null);
  userRoles$ = this.userRolesSource.asObservable();

  constructor(private http: HttpClient) {  }

  private setUserRoles(userRoles: UserRole[]) {
    this.userRolesSource.next(userRoles);
  }

  getUserRolesByUserId(userId: number) {
    let params = getListHeaders();
    params = params.set('userId', userId);
   // params = params.set('roleId', roleId);
    //alternate syntax
    //params = params.append('key', 'value');
    console.log("sending user roles api call", userId);

     return  getList<UserRole>(this.baseUrl + 'user-roles/user-role-by-userid', params, this.http).toPromise();
   
  }


  
}