import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../../services/modal.service';



@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalComponent implements OnInit, OnDestroy {

    @Input() id!:string;
    private element:any;

  constructor(private modalService: ModalService, private el:ElementRef){
    this.element = el.nativeElement;
  }
  ngOnInit(): void {
    //check if id attribute exist
    if(!this.id){
      console.error("Model must have an id");
      return
    }
    else{
      console.log(this);
    }

    //move elment to bottom of the page above the body to be displayed above webpage.
    document.body.appendChild(this.element)

    //close modal when background is clicked
    /*this.element.addEventListener('click', el =>{
      if(el.target.className === 'app-modal'){
        this.close();
      }
    });*/

    this.element.addEventListener('click',(el:any)=>{
      if(el.target.className === 'app-modal' && this.id != 'modal-4'){
        this.close();
      }
    })

    this.modalService.add(this)
  }
  
  
  
  ngOnDestroy(): void {
    this.modalService.remove(this.id)
      this.element.remove();
  }

  open():void{
    this.element.style.display ='block';
    document.body.classList.add('app-modal-open')
  }

  close():void{
    this.element.style='none';
    document.body.classList.remove('app-modal-open')
    
  }

}
