import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-data-analysis-reporting',
    templateUrl: './data-analysis-reporting.component.html',
    styleUrls: ['./data-analysis-reporting.component.scss'],
})

export class DataAnalysisReportingComponent implements OnInit {

    constructor() {
        console.log("constructor not implemented.");
    }

    ngOnInit() {
        console.log("ngOnInit not implemented.");
    }

    
}


