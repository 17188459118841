<header class="usa-header usa-header--extended">
    <div class="usa-navbar nc-full-width">
        <div class="usa-log-tagline-container">
            <div id="hideimage64"><img class="responsiveimg" src="assets/img/US-FS-LOGO3.jpg" alt="USFS logo" class="usda-logo"></div>
            <div class="usa-logo" id="extended-logo">
                <em class="usa-logo__text"><a routerLink="/home" title="Home" aria-label="Home">{{ title }}</a></em>
            </div>
        </div>
        <div class="usa-nav__secondary mobile-desktop">
            <div class="" *ngIf="(usersService.currentUser$ | async) as user">
                <div>
                <a class="greeting" routerLink="">Hi, {{user.firstName}}!</a> 
                </div>
                <div class="role-display">
                    <mat-form-field class="Mat-formContainer">
                        <mat-icon #matIcon>expand_more</mat-icon>
                        <mat-select class="role-select" [(value)]="activeUserRoleId" tabindex="0" (valueChange)="setUserRole($event)" (openedChange)="onOpenedChange($event)">
                           
                           <ng-container *ngFor="let userRole of userRoles">
                                <mat-option [value]="userRole.role.id">{{userRole.role.name}}</mat-option>
                          </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                
            </div>
            
         
        </div>
        <button class="usa-menu-btn">Menu</button>
    </div>
    
    <nav aria-label="Primary navigation" class="usa-nav">
           <div class="usa-nav__inner">
                <button class="usa-nav__close"><img src="../../../assets/img/close.svg" alt="close"></button>

                <div _ngcontent-ng-c699085922="" class="display-flex">
                    <ul *ngIf="(usersService.currentUser$ | async)" class="usa-nav__primary usa-accordion">
                        <li class="usa-nav__primary-item">
                            <a class="usa-nav__link" [routerLinkActive]="['usa-current']" routerLink="/home"><span>Home</span></a>
                        </li>

                        <li class="usa-nav__primary-item" *appHasRole="['Appraiser Administrator','Base Data Administrator']">
                            <a class="usa-nav__link" [routerLinkActive]="['usa-current']" routerLink="/admin"><span>Admin Panel</span></a>
                        </li>
        
                        <li class="usa-nav__primary-item">
                            <a class="usa-nav__link" [routerLinkActive]="['usa-current']" routerLink="/estimate-market-value"><span>Estimate Fair Market Value</span></a>
                        </li>
        
                        <li class="usa-nav__primary-item">
                            <a class="usa-nav__link" [routerLinkActive]="['usa-current']" routerLink="/view-data"><span>View Data</span></a>
                        </li>
                        
                        <li class="usa-nav__primary-item">
                            <a class="usa-nav__link" [routerLinkActive]="['usa-current']" routerLink="/help"><span>Help</span></a>
                        </li>
                    </ul>
        
                    <div *ngIf="(usersService.currentUser$ | async)" class="logout right" (click)="logout()">
                        <a class="logout-icon">
                            <svg class="usa-icon" aria-hidden="true" focusable="false" role="img">
                                <use xlink:href="/assets/img/sprite.svg#logout"></use>
                            </svg>
                        </a>
                        <br>
                        <span>logout</span>
                    </div> 
                    <div *ngIf="!(usersService.currentUser$ | async)" class="logout right" (click)="login()">
                        <a class="login-icon">
                            <svg class="usa-icon" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11 7 9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z"/></svg>
                        </a>
                        <br>
                        <span>login</span>
                    </div> 
                    <div class="clearfix"></div>
        
                    <div class="usa-nav__secondary" id="mobile-view">
                        <div class="" *ngIf="(usersService.currentUser$ | async) as user">
                            <div>
                            <a class="greeting" routerLink="">Hi, {{user.firstName}}!</a> 
                            </div>
                            
                            <div class="role-display">
                                <mat-form-field class="Mat-formContainer">
                                    <mat-icon #matIcon>expand_more</mat-icon>
                                    <mat-icon #matIcon>expand_more</mat-icon>
                                    <mat-select class="role-select" [(value)]="activeUserRole" tabindex="0" (openedChange)="onOpenedChange($event)">
                                       <ng-container *ngFor="let userRole of userRoles">
                                            <mat-option [value]="userRole">{{userRole}}</mat-option>
                                      </ng-container>
                                    </mat-select>
                                </mat-form-field>
                         
                            </div>
                            
                        </div>
                        
                    
                    </div>
                </div>
            </div>
    </nav>

</header>
