import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { User } from '../../models/user/user';
import { UsersService } from '../../services/users/users.service';
import { Region } from '../../models/forest-service/region';
import { take } from 'rxjs';
import { UserRole } from '../../models/user/user-roles/user-role';
import { IVisited, Visited } from '../../models/visitors/visited';


@Component({
    selector: 'app-estimate-market-value',
    templateUrl: './estimate-market-value.component.html',
    styleUrls: ['./estimate-market-value.component.scss'],
    providers: [DatePipe],
    standalone: false
})

export class EstimateMarketValueComponent implements OnInit, IVisited {
  @Output() cancelRegister = new EventEmitter();
  user!: User;
  userRoles: UserRole[] = [];
  activeUserRole!: UserRole;
  appraisalForm: FormGroup = new FormGroup({});
  validationErrors: string[] = [];
  baseUrl = environment.apiUrl;
  date: string | number | Date = new Date().toLocaleDateString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
  appraisalZones: Region[] = [];

  constructor(private fb: FormBuilder,  
    private usersService: UsersService) {
  }

  
  readProperty<T>(prop: string): T {
    throw new Error('Method not implemented.');
  } 

  ngOnInit() {
    this.usersService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user){
          this.user = user;
          this.userRoles = this.user?.userRoles;
          this.activeUserRole = user.currentUserRole;
        }
      }
    });
      this.createForm();
     
  }
  
  createForm() {
      this.appraisalForm = this.fb.group({
        appraiserName: [{value: this.user?.firstName + ' ' + this.user?.lastName, disabled: true}, Validators.required],
        appraiserRoleId: [{ value: this.activeUserRole?.roleId, disabled: true}, Validators.required],
        generalInformation: this.fb.group({    
          appraiserName: [{value: this.user?.firstName + ' ' + this.user?.lastName, disabled: true}, Validators.required],    
          appraisalDate: [{value: this.date, disabled: true}, Validators.required],
          regionCode: [null, Validators.required],
          forestCode: [null, Validators.required],
          districtCode: [null, Validators.required],
          appraisalZoneCode: [{ value: "", disabled: true}, Validators.required],
          regionName: [{value:"", disabled: false}, Validators.required],
          forestName: [{value:"", disabled: false}, Validators.required],
          districtName: [{value:"", disabled: false}, Validators.required],
          appraisalZone: [{value:"", disabled: false}, Validators.required],
   
          saleTypes: this.fb.array([ 
            this.fb.control({sbaBiddersNote: [false]}),
            this.fb.control({specialConditionsCircumstances: [false]}),
            this.fb.control({roadConstruction: [false]}),
            this.fb.control({cableOrAreialLoggingSystems: [false]}),
            this.fb.control({tradeGoodsServices: [false]}), 
            this.fb.control({partiallyProcessedVolume: [false]}),
            this.fb.control({requiredReforestationofPrepSite: [false]})
          ])
        }),
        volumeInformation: this.fb.group({
          appraisalGroups: this.fb.array([
            this.fb.group({
              treeCondition: ['Live', Validators.required],
              appraisalGroupId: [null],//remove required validator.
              appraisalGroupName: ['', Validators.required],
              appraisalGroup: [null, Validators.required],
              treeProductId: [null, Validators.required],
              //treeProduct: [{value: null, disabled: true}, Validators.required],
              treeSpeciesId: [null, Validators.required],
              //treeSpecies: [{value: null, disabled: true}, Validators.required],
              treeProductName: ['', Validators.required],
              treeSpeciesName: ['', Validators.required],
              uomId: [1, Validators.required],
              uomShortName: ['', Validators.required],
              totalVolume: [0, Validators.required],
              standardRate: [0.00, Validators.required],
              minimumRate: [0.00, Validators.required],
            })
            
          ])   
         
        }),
        chooseAppraisalType: this.fb.group({    
          appraisalType: [null, Validators.required]
        }),
        estimateSaleOperatingCosts: this.fb.group({  
     
          standardRateCalculations: this.fb.group({
            test:['standard-rate-test', Validators.required],
          }),
          streamlinedTeCalculations: this.fb.group({ 
            basePeriodRate: [{ value: 0.00, disabled: true}, Validators.required],
            bpaRates: this.fb.group({
              baseSkidYard: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseHaul: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseRoadMaintenance: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseTempDevelopments: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseSlashDisposal: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              })
            }),
          })
        }),
        contractCalculations: this.fb.group({ 
          partiallyProcessed: [false, Validators.required],
          reforestationRequired: [false, Validators.required], 
          timberPropertyValue: [0.00, Validators.required],
          regenerationCost: [0.00, Validators.required],
          requiredCollections: [{ value: 0.00, disabled: true}, Validators.required],
        }),
        estimator: this.fb.group({   
          totalSaleValue: [0.00, Validators.required], 
          contractBaseValue: [0.00, Validators.required],
          appraisalRatePerUom: [0.00, Validators.required],
        })
      });

    }

    get appraiserRoleId(): number {
      return this.activeUserRole?.roleId;
    }

    get appraisalFormDebug(){
      return this.appraisalForm.value;
    }
   
    continue(panel: number){
      let element: HTMLElement = document.getElementById("panel-"+ panel +"-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth',block:'start'});
    }
  
    submit(){
      return false;
    }

  
}


