import { Component } from '@angular/core';

@Component({
    selector: 'app-bidding-records',
    templateUrl: './bidding-records.component.html',
    styleUrl: './bidding-records.component.scss'
})
export class BiddingRecordsComponent {

}
