<div class="mb-3">
    <!-- Do something with toggle buttons (ie. take in a set of fields and iterate through ght e array and create the buttons) -->
    <div *ngFor="let value of buttonValues">
        <label for="toggle-buttons">value.label</label>
        <input id="toggle-buttons" type="{{ type }}" 
        [class.is-invalid]="control.touched && control.invalid"
        class="form-control usa-input input-number" 
        [formControl]="control"
        placeholder={{label}}
        value="value.value"/>
    </div>
    

    <div class="invalid-feedback" *ngIf="control.errors?.['required']">
        Please enter a {{label}}
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['minlength']">
        {{label}} must be at least {{control.errors?.['minlength'].requiredLength}} characters
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['maxlength']">
        {{label}} must be at most {{control.errors?.['maxlength'].requiredLength}} characters
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['notMatching']">
        Passwords do not match
    </div>
</div>