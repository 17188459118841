import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserRoleRequest } from '../../models/admin/user-role-requests/user-role-request';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRoleRequestsService {
  
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getPendingRequests() {
    return this.http.get<UserRoleRequest[]>(this.baseUrl + 'user-role-requests/pending-requests');
  }

  updatePendingRequest(request: UserRoleRequest){
      return this.http.put<UserRoleRequest>(this.baseUrl + 'user-role-requests/pending-requests', request).pipe(
        map(response => {
            return response;
        })
      )
  }
}