import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { of, map, take } from 'rxjs';
import { DistrictParams } from '../../models/params/districtParams';
import { getPaginatedResult, getPaginationHeaders } from '../pagination/paginationHelper';
import { District } from '../../models/forest-service/district';

@Injectable({
  providedIn: 'root'
})
export class DistrictsService {
  baseUrl = environment.apiUrl;
  districts: District[] = [];
  districtCache = new Map();
  districtParams: DistrictParams | undefined;

  constructor(private http: HttpClient) { 
    this.districtParams = new DistrictParams();
  }

  getDistrictParams() {
    return this.districtParams;
  }

  setDistrictParams(districtParams: DistrictParams) {
    this.districtParams = districtParams;
  }

  resetUserParams() {
    if (this.districtParams) {
      this.districtParams = new DistrictParams();
      return this.districtParams;
    }
    return;
  }

  getDistricts(districtParams: DistrictParams) {
    const response = this.districtCache.get(Object.values(districtParams).join('-'));

    if (response) return of(response);

    let params = getPaginationHeaders(districtParams.pageNumber, districtParams.pageSize);

    return getPaginatedResult<District[]>(this.baseUrl + 'districts', params, this.http).pipe(
      map(response => {
        this.districtCache.set(Object.values(DistrictParams).join('-'), response);
        return response;
      })
    )
  }

  getDistrict(districtCode: string) {
    const district = [...this.districtCache.values()]
      .reduce((arr, elem) => arr.concat(elem.result), [])
      .find((district: District) => district.districtCode == districtCode);

    if (district) return of(district);
    
    return this.http.get<District>(this.baseUrl + 'districts/' + districtCode);
  }

  
}