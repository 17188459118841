import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { UsersService } from '../../../../../services/users/users.service';
import { CalculationsComponent } from '../calculations.component';
import { AppraisalGroupRatesService } from '../../../../../services/appraisal-group-rates/appraisal-group-rates.service';
import { AppraisalGroup } from '../../../../../models/appraisal-group';


@Component({
    selector: 'app-standard-rate-calculations',
    templateUrl: './standard-rate-calculations.component.html',
    styleUrls: ['./standard-rate-calculations.component.scss'],
})

export class StandardRateCalculationsComponent implements OnInit {

    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    
    range = new FormControl(0);
    form!: FormGroup;
    testControl!: FormControl;
    calculationsComponent: CalculationsComponent;
    appraisalGroupId: number;
    appraisalGroup: AppraisalGroup;
    totalVolumeControl!: FormControl;
    totalGroupValueControl!: FormControl;
    minimumRateControl!: FormControl;
    standardRateControl!: FormControl;
    requiredCollectionsControl!: FormControl;

    formatThumbLabel(value: number): string {
        return `${value}%`;
      }

    
    constructor(@Host() parent: CalculationsComponent,
    private fb: FormBuilder, 
    private http: HttpClient, 
    private rootFormGroup: FormGroupDirective,
    public usersService: UsersService,
    private appraisalGroupRatesService: AppraisalGroupRatesService) {
        this.calculationsComponent = parent;
        this.appraisalGroupId = parent.appraisalGroupId;
        this.appraisalGroup = parent.appraisalGroup;
    }


    ngOnInit() {
        //Get root estimate market value form
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.testControl = this.form.controls['test'] as FormControl;
        console.log(this.form); 
        this.totalVolumeControl = this.calculationsComponent.totalVolumeControl;
        this.totalGroupValueControl = this.calculationsComponent.totalGroupValueControl;
        this.minimumRateControl = this.calculationsComponent.minimumRateControl;
        this.standardRateControl = this.calculationsComponent.standardRateControl;
        this.requiredCollectionsControl = this.calculationsComponent.requiredCollectionsControl;

       
        
    }

    get totalGroupValue(): number{
        let totalVolume = this.totalVolumeControl.value;
        let standardRate = this.standardRateControl.value;
        this.totalGroupValueControl.patchValue(totalVolume * standardRate);
        return this.totalGroupValueControl.value;
    }

   
    
}