import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { getList, getListHeaders } from '../pagination/listHelper';
import { Role } from '../../models/user/user-roles/role';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  baseUrl = environment.apiUrl;

  private rolesSource = new BehaviorSubject<Role[] | null>(null);
  roles$ = this.rolesSource.asObservable();

  constructor(private http: HttpClient) { 
    this.getRoles();
  }

  setRoles(roles: Role[]) {
    this.rolesSource.next(roles);
  }

  private getRoles() {
    let params = getListHeaders();
      console.log("sending roles api call");
      getList<Role[]>(this.baseUrl + 'roles', params, this.http).subscribe({
        next: (response => {
          console.log("received roles api response");
          console.log(response);
          this.setRoles(response as Role[]);
        })
      })
  }


  
}