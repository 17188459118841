import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { UsersService } from '../../../../services/users/users.service';
import { Subscription, map } from 'rxjs';
import { TooltipPosition } from '@angular/material/tooltip';
import { HandlecheckboxesService } from '../../../../services/handlecheckboxes.service';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types'


@Component({
    selector: 'app-choose-appraisal-type',
    templateUrl: './choose-appraisal-type.component.html',
    styleUrls: ['./choose-appraisal-type.component.scss'],
})

export class ChooseAppraisalTypeComponent implements OnInit {
  appraisalTypes = AppraisalTypes;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[2]);
  @Input() formGroupName!: string
  @Output() childEvent = new EventEmitter();
  @ViewChild('standardrateappraisal', {static:true}) standardrateappraisal?:ElementRef;
  @ViewChild('standardrateappraisalinput', {static:true}) standardrateappraisalinput?:ElementRef;
  @ViewChild('comparisonappraisal', {static:false}) comparisonappraisal?:ElementRef;
  @ViewChild('comparisonappraisalinput', {static:false}) comparisonappraisalinput?:ElementRef;
  @ViewChild('standardRatetooltip', {static:true})StandardRatetooltip?:ElementRef;
    btnstate:any;
    form!: FormGroup;
    guest: any;
    isDisabled:boolean = false;

    appraisals = new FormControl({})
    
  
    clickEventSubscription!: Subscription;
    clickDisabledEventSubscription!: Subscription;
    appraisalTypeControl!: FormControl;

    
    constructor(private fb: FormBuilder, 
      private rootFormGroup: FormGroupDirective, 
      private http: HttpClient, 
      public usersService: UsersService, 
      private checkboxHandler: HandlecheckboxesService,
      private renderer: Renderer2
      ) {
        this.clickEventSubscription = this.checkboxHandler.getClickEvent().subscribe(()=>{
           this.changeButtonState()
        })

        this.clickDisabledEventSubscription = this.checkboxHandler.getDisabledClickEvent().subscribe(()=>{
          this.changeDisableState()
       })

    }


    ngOnInit() { 
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
      this.appraisalTypeControl = this.form.controls["appraisalType"] as FormControl
      

       this.guest = this.usersService.currentUser$.pipe(
        map(user => {
          if (!user) return null;
          if (user.userRoles.some(role => role.role.name === 'Guest')) {
            return true;
          } else {
            console.log('You cannot enter this area');
            return false;
          }
        })
      )
    }

    setAppraisalType(appraisalType: AppraisalTypes){
      this.appraisalTypeControl.patchValue(appraisalType);
    }

    selectType(event: any){
      Array.prototype.forEach.call(document.getElementsByClassName('appraisal-type'), 
      item => item.classList.remove("appraisal-type-active"));
      event.currentTarget.classList.add("appraisal-type-active");  
  }

  continue(event: any){
        event.preventDefault();
        let element: HTMLElement = document.getElementById("panel-4-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth', block:'start'});
        
  }
  onKeydown(event:any){
    if(event.target){
      event.target.children[0].children[0].checked = true
    }
  }

  changeButtonState(){
       this.standardrateappraisal?.nativeElement.setAttribute('disabled','');
       this.standardrateappraisalinput?.nativeElement.setAttribute('disabled',true);
       this.StandardRatetooltip?.nativeElement.children[1].setAttribute('tabindex',-1)
       
  }
  
  changeDisableState(){
    this.standardrateappraisal?.nativeElement.removeAttribute('disabled','');
    this.standardrateappraisalinput?.nativeElement.removeAttribute('disabled','');
    this.StandardRatetooltip?.nativeElement.children[1].setAttribute('tabindex',0)
  }

  
 
}