import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { getList, getListHeaders } from '../pagination/listHelper';
import { WwpaIndex } from '../../models/wwpa/wwpa-index-v';
import { WwpaIndexDto } from '../../models/wwpa/wwpa-index-dto';
import { WwpaIndexValue } from '../../models/wwpa/wwpa-index-value';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})

export class WwpaIndicesService {
    baseUrl = environment.apiUrl;
    wwpaIndicesCache = new Map();
    private wwpaIndicesSource = new BehaviorSubject<WwpaIndex[] | null>(null);
    wwpaIndices$ = this.wwpaIndicesSource.asObservable();

  constructor(private usersService: UsersService, private http: HttpClient) { 
    console.log("constructor");
    this.getCurrentUserRole();
  }

  setWwpaIndices(wwpaIndices: WwpaIndex[]) {
    //this.wwpaIndicesCache.set('wwpaIndices', wwpaIndices);
    this.wwpaIndicesSource.next(wwpaIndices);
  }

  private getCurrentUserRole(){
    this.usersService.currentUserRole$.subscribe({
        next: _ => {
          console.log("User Role Updated.");
          this.getWwpaIndicesView();   
        }
    })
}


  private getWwpaIndicesView() {
    let params = getListHeaders();
      getList<WwpaIndex[]>(this.baseUrl + 'wwpaindices', params, this.http).subscribe({
        next:(response => {
          this.setWwpaIndices(response as WwpaIndex[]);
        }),
        error: err => {
          console.log(err);
        }
      })
    // const wwpaIndices = this.wwpaIndicesCache.get('wwpaIndices');
    // if (!wwpaIndices || wwpaIndices.length == 0){
      
    // }
  }

  getWwpaIndexById(wwpaIndexId: number) {  
    // const wwpaIndex = [...this.wwpaIndicesCache.values()]
    //   .reduce((arr, elem) => arr.concat(elem.result), [])
    //   .find((wwpaIndex: WwpaIndex) => wwpaIndex.child_Id = wwpaIndexId);

    // if(wwpaIndex) return of(wwpaIndex);
    
    return this.http.get<WwpaIndex>(this.baseUrl + 'wwpaindices/' + wwpaIndexId);
  }

  updateWwpaIndex(index: WwpaIndex){
    let wwpaIndices = this.wwpaIndicesSource.value as WwpaIndex[];
    let indexToUpdate = wwpaIndices.findIndex((wwpaIndex: WwpaIndex) => wwpaIndex.child_Id === index.child_Id);
    let wwpaIndex = wwpaIndices[indexToUpdate];
    wwpaIndex.indexValue = index.indexValue;

    return this.http.put<WwpaIndexValue>(this.baseUrl + 'wwpaindices', wwpaIndex).pipe(
      map(response => {
        wwpaIndex.child_UpdatedById = response.updatedById;
        wwpaIndex.child_UpdatedWhenUtc = response.updatedWhen_UTC;
        wwpaIndex.child_UpdatedByName = response.updatedBy.username;
        wwpaIndices[indexToUpdate] = wwpaIndex;
        this.setWwpaIndices(wwpaIndices);
      })
    )
  }

  createWwpaIndices(wwpaIndices: WwpaIndexDto[]){
    return this.http.post<WwpaIndex[]>(this.baseUrl + 'wwpaindices', wwpaIndices).pipe(
      map(response => {
        this.setWwpaIndices(response);
        return response;
        })
      )
      .pipe(
        catchError(err => {
          return throwError(() => err);
        })
    )
  }
 
}