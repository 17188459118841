import { Component } from "@angular/core";
import * as Highcharts from "highcharts";

@Component({
  selector: "highcharts-test",
  templateUrl: "./highcharts-test.component.html",
  styleUrls: ["./highcharts-test.component.scss"]
})
export class HighChartsTestComponent {
  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) { console.log('highcharts callback test') } // optional function, defaults to null
  updateFlag: boolean = false; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false

  data1 = [500, 700, 555, 444, 777, 877, 944, 567, 666, 789, 456, 654];
  data2 = [677, 455, 677, 877, 455, 778, 888, 567, 785, 488, 567, 654];
  name1= "ItSolutionStuff.com";

  chartOptions: Highcharts.Options = {
    title: {
      text: "NationalTEA Highcharts Data"
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    },
    yAxis: {
      title: {
        text: "Visitors"
      }
    },
    series: [
      {
        name: this.name1,
        type: "spline",
        data: this.data1
      },
      {
        type: "spline",
        data: this.data2,
        name: "Nicesnippets.com",
        color: "#3183F5"
      }
    ]
  };
}
