import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { RouteReuseStrategy, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { UsaBannerComponent } from './components/shared/usa-banner/usa-banner.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LandingComponent } from './components/landing/landing.component';
import { EstimateMarketValueComponent } from './components/estimate-market-value/estimate-market-value.component';
import { ViewDataComponent } from './components/view-data/view-data.component';
import { HelpComponent } from './components/help/help.component';
import { UsersService } from './services/users/users.service';
import { HomeGuestComponent } from './components/home/guest/home-guest.component';
import { HomeUserComponent } from './components/home/user/home-user.component';
import { GeneralInformationComponent } from './components/estimate-market-value/estimate-market-value-accordion/general-information/general-information.component';
import { VolumeInformationComponent } from './components/estimate-market-value/estimate-market-value-accordion/volume-information/volume-information.component';
import { ChooseAppraisalTypeComponent } from './components/estimate-market-value/estimate-market-value-accordion/choose-appraisal-type/choose-appraisal-type.component';
import { EstimatorComponent } from './components/estimate-market-value/estimator/estimator.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { CustomRouteReuseStrategy } from './services/customRouteReuseStrategy';
import { RegionsService } from './services/regions/regions.service';
import { SharedModule } from './modules/shared.module';
import { ForestsService } from './services/forests/forests.service';
import { DistrictsService } from './services/districts/districts.service';
import { TextInputComponent } from './components/forms/controls/text-input/text-input.component';
import { DatePickerComponent } from './components/forms/controls/date-picker/date-picker.component';
//import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NumberInputComponent } from './components/forms/controls/number-input/number-input.component';
import { ToggleButtonsComponent } from './components/forms/controls/toggle-buttons/toggle-buttons.component';
import { MinimumRatesComponent } from './components/admin/admin-panel/minimum-rates/minimum-rates.component';
import { StandardRatesComponent } from './components/admin/admin-panel/standard-rates/standard-rates.component';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { DisabledirectiveDirective } from './directives/disabledirective.directive';
import { AppraisalZonesService } from './services/appraisal-zones/appraisal-zones.service';
import { DialogBoxComponent } from './components/forms/dialog-boxes/dialog-box.component';
import { MaterialModule } from './material-module';
import { FilterPipe } from './pipes/filter.pipe';
import { UserManagementComponent } from './components/admin/admin-panel/user-management/user-management.component';
import { DataAnalysisReportingComponent } from './components/admin/admin-panel/data-analysis-reporting/data-analysis-reporting.component';
import { BasePeriodDataComponent } from './components/admin/admin-panel/base-period-data/base-period-data.component';
import { ExternalMarketDataComponent } from './components/admin/admin-panel/external-market-data/external-market-data.component';
import { AppraisalZonesComponent } from './components/admin/admin-panel/appraisal-zones/appraisal-zones.component';
import { ProductsComponent } from './components/admin/admin-panel/products/products.component';
import { HasNoRoleDirective } from './directives/has-no-role.directive';
import { TruncateTextvalPipe } from './pipes/truncate-textval.pipe';
import { ModalModule } from './shared/modal';
import { HighchartsChartModule } from 'highcharts-angular';
import { HighChartsTestComponent } from './highcharts/highcharts-test.component';
import { HighChartsDashboardTestComponent } from './highcharts/dashboards/highcharts-dashboard-test.component';
import { WwpaIndicesComponent } from './components/admin/admin-panel/wwpa-indices/wwpa-indices.component';
import { AppraisalGroupsComponent } from './components/admin/admin-panel/appraisal-groups/appraisal-groups.component';
import { FocustrapDirective } from './directives/focustrap.directive';
import { NgSelectModule} from '@ng-select/ng-select';
import { ViewWwpaIndicesComponent } from './components/view-data/wwpa-indices/wwpa-indices.component';
import { RequestPermissionsComponent } from './components/request-permissions/request-permissions';
import { CalculationsService } from './services/calculations/calculations.service';

import { NgxPaginationModule } from 'ngx-pagination';
import { StandardRateCalculationsComponent } from './components/estimate-market-value/estimate-market-value-accordion/estimate-sale-operating-costs/standard-rate/standard-rate-calculations.component';
import { StreamlinedTeCalculationsComponent } from './components/estimate-market-value/estimate-market-value-accordion/estimate-sale-operating-costs/streamlined-te/streamlined-te-calculations.component';
import { EstimateSaleOperatingCostsComponent } from './components/estimate-market-value/estimate-market-value-accordion/estimate-sale-operating-costs/estimate-sale-operating-costs.component';
import { ContractCalculationsComponent } from './components/estimate-market-value/estimate-market-value-accordion/contract-calculations/contract-calculations.component';
import { DistrictCheckboxValueDirective } from './directives/checkbox-values/district-checkbox-value-directive';
import { ForestCheckboxValueDirective } from './directives/checkbox-values/forest-checkbox-value-directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'; 

@NgModule({ declarations: [
        AdminPanelComponent,
        AppComponent,
        AppraisalGroupsComponent,
        AppraisalZonesComponent,
        BasePeriodDataComponent,
        ChooseAppraisalTypeComponent,
        ContractCalculationsComponent,
        DataAnalysisReportingComponent,
        DatePickerComponent,
        DialogBoxComponent,
        DisabledirectiveDirective,
        DistrictCheckboxValueDirective,
        EstimateMarketValueComponent,
        EstimateSaleOperatingCostsComponent,
        EstimatorComponent,
        ExternalMarketDataComponent,
        FilterPipe,
        FocustrapDirective,
        FooterComponent,
        ForestCheckboxValueDirective,
        GeneralInformationComponent,
        HasNoRoleDirective,
        HasRoleDirective,
        HeaderComponent,
        HelpComponent,
        HighChartsDashboardTestComponent,
        HighChartsTestComponent,
        HomeComponent,
        HomeGuestComponent,
        HomeUserComponent,
        LandingComponent,
        MinimumRatesComponent,
        NumberInputComponent,
        ProductsComponent,
        RequestPermissionsComponent,
        StandardRateCalculationsComponent,
        StandardRatesComponent,
        StreamlinedTeCalculationsComponent,
        TextInputComponent,
        ToggleButtonsComponent,
        TruncateTextvalPipe,
        UsaBannerComponent,
        UserManagementComponent,
        ViewDataComponent,
        ViewWwpaIndicesComponent,
        VolumeInformationComponent,
        WwpaIndicesComponent,
    ],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        //BsDatepickerModule,
        CommonModule,
        FormsModule,
        HighchartsChartModule,
        MaterialModule,
        ModalModule,
        NgSelectModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        RouterOutlet,
        SharedModule,
        FontAwesomeModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
        })], providers: [
        [UsersService],
        [AppraisalZonesService],
        [RegionsService],
        [ForestsService],
        [DistrictsService],
        [FormGroupDirective],
        [CalculationsService],
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
