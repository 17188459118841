import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateTextval'
})
export class TruncateTextvalPipe implements PipeTransform {

  transform(value: string, limit:number = 15, suffix:string ='...'): string {
    if(!value) return ''
    if(value.length <= limit){
      return value;
    }
    return value.substring(0, limit) + suffix;
  }

}
