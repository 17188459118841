import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { UsersService } from '../../../../services/users/users.service';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types';
import { AppraisalGroup } from '../../../../models/appraisal-group';

@Component({
    selector: 'app-calculations',
    templateUrl: './calculations.component.html',
    styleUrls: ['./calculations.component.scss'],
})

export class CalculationsComponent implements OnInit {

    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    @Input() arialabel = "Test"
    
    range = new FormControl(0);
    form!: FormGroup;
    generalInfoForm!: FormGroup;
    volumeInfoForm!: FormGroup;
    chooseAppraisalTypeForm!: FormGroup;
    appraisalsForm!:FormControl;
    saleTypes!: FormArray;
    condition!: string;
    totalVolume: any;
    uomGroupControl!:FormControl;
    uom: any;
    regionNameControl!: FormControl;
    forestNameControl!: FormControl;
    districtNameControl!: FormControl;
    appraisalZoneCodeControl!: FormControl;
    treeProductNameControl!: FormControl;
    treeSpeciesNameControl!: FormControl;
    treeProductIdControl!: FormControl;
    treeSpeciesIdControl!: FormControl;
    treeConditionControl!: FormControl;
    conditionControl!: FormControl;
    totalVolumeControl!: FormControl;
    appraisalGroupIdControl!: FormControl;
    appraisalGroupNameControl!: FormControl;
    appraisalGroupControl!: FormControl;
    appraisalTypeControl!: FormControl;
    totalGroupValueControl!: FormControl;
    baseRateControl!: FormControl;
    minimumRateControl!: FormControl;
    standardRateControl!: FormControl;
    timberPropertyValueControl!: FormControl;
    regenerationCostControl!: FormControl;
    requiredCollectionsControl!: FormControl;

    appraisalTypes = AppraisalTypes;

    formatThumbLabel(value: number): string {
        return `${value}%`;
      }

    
    constructor(private fb: FormBuilder, 
        private http: HttpClient, 
        private rootFormGroup: FormGroupDirective,
        public usersService: UsersService) {
           
    }


    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.generalInfoForm = this.rootFormGroup.control.get('generalInformation') as FormGroup;
        this.volumeInfoForm = this.rootFormGroup.control.get('volumeInformation') as FormGroup;
        this.chooseAppraisalTypeForm = this.rootFormGroup.control.get('chooseAppraisalType') as FormGroup;
        this.saleTypes = this.generalInfoForm.controls["saleTypes"] as FormArray;
        console.log(this.saleTypes);
        // assigning control by reference allows for realtime updating of values across form
        this.appraisalTypeControl = this.chooseAppraisalTypeForm.controls["appraisalType"] as FormControl;
        this.regionNameControl = this.generalInfoForm.controls['regionName'] as FormControl;
        this.forestNameControl = this.generalInfoForm.controls['forestName'] as FormControl;
        this.districtNameControl = this.generalInfoForm.controls['districtName'] as FormControl;
        this.appraisalZoneCodeControl = this.generalInfoForm.controls['appraisalZoneCode'] as FormControl;
        this.treeProductNameControl = this.volumeInfoForm.controls['treeProductName'] as FormControl;
        this.treeSpeciesNameControl = this.volumeInfoForm.controls['treeSpeciesName'] as FormControl;
        this.treeProductIdControl = this.volumeInfoForm.controls['treeProductId'] as FormControl;
        this.treeSpeciesIdControl = this.volumeInfoForm.controls['treeSpeciesId'] as FormControl;
        this.treeConditionControl = this.volumeInfoForm.controls['treeCondition'] as FormControl;
        
        this.conditionControl = this.volumeInfoForm.controls['condition'] as FormControl;
        this.totalVolumeControl = this.volumeInfoForm.controls['totalVolume'] as FormControl;
        this.appraisalGroupIdControl = this.volumeInfoForm.controls['appraisalGroupId'] as FormControl;
        this.appraisalGroupNameControl = this.volumeInfoForm.controls['appraisalGroupName'] as FormControl;
        this.appraisalGroupControl = this.volumeInfoForm.controls['appraisalGroup'] as FormControl;
        this.totalGroupValueControl = this.form.controls['totalGroupValue'] as FormControl;
        this.baseRateControl = this.form.controls['baseRate'] as FormControl;
        this.minimumRateControl = this.form.controls['minimumRate'] as FormControl;
        this.standardRateControl = this.form.controls['standardRate'] as FormControl;
        
        this.timberPropertyValueControl = this.form.controls["timberPropertyValue"] as FormControl;
        this.regenerationCostControl = this.form.controls["regenerationCost"] as FormControl;
        this.requiredCollectionsControl = this.form.controls["requiredCollections"] as FormControl;
        this.timberPropertyValueControl.disable();
        this.regenerationCostControl.disable();
    }



    get uomControl(): FormControl{
        return this.volumeInfoForm.controls['uomShortName'] as FormControl;
    }

    get timberPropertyValue(): FormControl{
        if(this.saleTypes.controls[5].value === true){ 
            this.timberPropertyValueControl.enable();
        }else{
            this.timberPropertyValueControl.disable();
        }
        return this.timberPropertyValueControl;
    }

    get regenerationCost(): FormControl{
        if(this.saleTypes.controls[6].value === true){ 
            this.regenerationCostControl.enable();
        }else{
            this.regenerationCostControl.disable();
        }
        return this.regenerationCostControl;
    }

    get requiredCollections(): FormControl{ 
        let timberPropertyValue = this.timberPropertyValueControl.value as number;
        let regenerationCost = this.regenerationCostControl.value as number;
        let totalVolume = this.totalVolumeControl.value as number;
        let nff = 0.25 ;
        if(totalVolume > 0){
            let equation1 = (Number(timberPropertyValue) + Number(regenerationCost)) / ( Number(totalVolume));
            let result = +equation1 + nff;
            if(result > this.minimumRate){
                this.requiredCollectionsControl.patchValue(result.toFixed(2));
                return this.requiredCollectionsControl;

            }else{
                this.requiredCollectionsControl.patchValue(this.minimumRate.toFixed(2));
                return this.requiredCollectionsControl;
            }
        }else{
            this.requiredCollectionsControl.patchValue(0.00);
            return this.requiredCollectionsControl;
        }
    }
    
    get appraisalZoneCode(){
        return this.appraisalZoneCodeControl.value;
    }

    get appraisalGroupId(){
        return this.appraisalGroupIdControl.value;
    }

    get appraisalGroupName(){
        return this.appraisalGroupNameControl.value;
    }

    get appraisalGroup(): AppraisalGroup{
        return this.appraisalGroupControl.value;
    }

    get appraisalType(){
        return this.appraisalTypeControl.value;
    }

    get treeProductId(){
        return this.treeProductIdControl.value;
    }

    get treeSpeciesId(){
        return this.treeSpeciesIdControl.value;
    }

    get treeCondition(){
        return this.treeConditionControl.value;
    }

    get partiallyProcesedVolume(){
        console.log(this.saleTypes.controls[5]);
        return this.saleTypes.controls[5].value
    }

    get minimumRate(){
        return this.minimumRateControl.value;
    }

    get standardRate(){
        return this.standardRateControl.value;
    }

    get totalGroupValue(){
        return this.totalGroupValueControl.value;
    }
   
    onVolumeProcessedChange(event: any) {
        this.form.controls['partiallyProcessed'].patchValue(event.value);
    }
    onReforestationChange(event: any) {
        this.form.controls['reforestationRequired'].patchValue(event.value);
    }

    
    
}