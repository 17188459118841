import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { of, map, take, BehaviorSubject } from 'rxjs';
import { RegionParams } from '../../models/params/regionParams';
import { getPaginatedResult, getPaginationHeaders } from '../pagination/paginationHelper';
import { Region } from '../../models/region';
import { getList, getListHeaders } from '../pagination/listHelper';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  baseUrl = environment.apiUrl;
  regionCache = new Map();
  private regionsSource = new BehaviorSubject<Region[] | null>(null);
  regions$ = this.regionsSource.asObservable();

  constructor(private http: HttpClient) { 
    this.getRegions();
  }

  setRegions(regions: Region[]) {
    this.regionsSource.next(regions);
  }

  private getRegions() {
    const regions = this.regionCache.get('regions');
    console.log(regions);
    if (!regions || regions.length == 0){
      let params = getListHeaders();
      console.log("sending region api call");
      getList<Region[]>(this.baseUrl + 'regions', params, this.http).subscribe({
        next: (response => {
          console.log("received regions api response");
          console.log(response);
          this.regionCache.set('regions', response);
          this.setRegions(response as Region[]);
        })
      })
    } else {
      this.setRegions(regions);
    }

    
  }

  getRegion(regionCode: string) {
    const region = [...this.regionCache.values()]
      .reduce((arr, elem) => arr.concat(elem.result), [])
      .find((region: Region) => region.regionCode == regionCode);

    if (region) return of(region);
    
    return this.http.get<Region>(this.baseUrl + 'regions/' + regionCode);
  }

  
}