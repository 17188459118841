import { Component, Input, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() label = '0.00';
  @Input() type = 'number'; //can be range
  @Input() step!: number;
  @Input() min!: number;
  @Input() max!: number;
  @Input() unitOfMeasure!:string;
  @Input() name!: string;
  @Input() arialabel!: string;
 

  value: any;

  constructor(@Self() public ngControl: NgControl) { 
    this.ngControl.valueAccessor = this;
  }

  writeValue(obj: any): void {
    //console.log("writeValue not implemented.");
  }

  registerOnChange(fn: any): void {
    //console.log("registerOnChange not implemented.");
  }

  registerOnTouched(fn: any): void {
    //console.log("registerOnTouched not implemented.");
  }

  get control(): FormControl {
    return this.ngControl.control as FormControl
  }
}