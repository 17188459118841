<div class="estimator" [ngClass] ="{'_estimator':totalSaleValue >=0, 'negative': totalSaleValue < 0 }">
    <div class="grid-row">
        <div class="grid-col-12">
            <div class="estimator-inner-top">
                <p class="estimator-title">Total Sale Value <span class="colon">:</span></p>
                <p class="estimator-value">{{ totalSaleValue | currency : 'USD' : 'symbol' }} </p>
                <!-- <p>{{discountedTotal | number: '1.2-2'}}</p>  -->
            </div>
        </div>  
    </div>
    <div class="grid-row appraisalgroup-wrapper" >
        <div class="grid-col-6 appraisalgroupevalue">
            <div class="estimator-inner" [ngClass]="{'estimator-error': contractBaseValue > appraisalRatePerUom}">
                <p class="estimator-inner-title estimator-inner-title-left">Contract Base Value</p>
                <p class="estimator-inner-value estimator_spacing">{{ contractBaseValue | currency : 'USD' : 'symbol' }}</p>
            </div>
        </div>  
        <div class="grid-col-6 appraisalgroupevalue">
            <div class="estimator-inner">
                <p class="estimator-inner-title">Appraisal Rate  Per Unit of Measure</p>
                <p class="estimator-inner-value">{{ appraisalRatePerUom | currency : 'USD' : 'symbol' }}<span *ngIf="uom != null">/{{ uom }}</span></p>
            </div>
        </div>  
    </div>

</div>
