<ng-container [formGroup]="form">
    <div class="market-value-accordion-panel">
        <h4 class="usa-accordion__heading market-value-accordion-heading">
            <button
            type="button"
            id="panel-2-button"
            class="usa-accordion__button focus-visible"
            aria-expanded="false"
            aria-controls="b-a2"
            >
                <svg class="usa-icon usa-icon--size-5 accordion-icon u-icon" aria-hidden="true" focusable="false" role="img" width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.2377 5.78418C21.0596 6.28854 20.9625 6.83127 20.9625 7.39668C20.9625 8.63297 21.4263 9.76094 22.1893 10.6161L19.3444 13.461C18.4892 12.6979 17.3613 12.2342 16.125 12.2342C13.4533 12.2342 11.2875 14.4 11.2875 17.0717V23.1186C11.2875 26.0128 13.6338 28.3592 16.5281 28.3592H22.575C25.2467 28.3592 27.4125 26.1934 27.4125 23.5217C27.4125 22.2854 26.9487 21.1574 26.1857 20.3023L29.0306 17.4574C29.8857 18.2204 31.0137 18.6842 32.25 18.6842C32.8153 18.6842 33.3581 18.5871 33.8625 18.4089V31.5842C33.8625 33.3653 32.4187 34.8092 30.6375 34.8092H8.06249C6.28137 34.8092 4.83749 33.3653 4.83749 31.5842V9.00918C4.83749 7.22806 6.28137 5.78418 8.06249 5.78418H21.2377ZM31.8469 5.78418C32.9601 5.78418 33.8625 6.6866 33.8625 7.7998V13.8467C33.8625 14.7372 33.1406 15.4592 32.25 15.4592C31.3594 15.4592 30.6375 14.7372 30.6375 13.8467V11.2896L20.0179 21.9092H22.575C23.4656 21.9092 24.1875 22.6311 24.1875 23.5217C24.1875 24.4123 23.4656 25.1342 22.575 25.1342H16.5281C15.4149 25.1342 14.5125 24.2318 14.5125 23.1186V17.0717C14.5125 16.1811 15.2344 15.4592 16.125 15.4592C17.0156 15.4592 17.7375 16.1811 17.7375 17.0717V19.6288L28.3571 9.00918H25.8C24.9094 9.00918 24.1875 8.28723 24.1875 7.39668C24.1875 6.50613 24.9094 5.78418 25.8 5.78418H31.8469Z" fill="#538200"/>
                    </svg>
                <span>Volume Information</span>        
            </button>
        </h4>


        <div id="b-a2" class="usa-accordion__content usa-prose market-value-accordion-content" hidden>
            
            <div *ngIf="appraisalGroupForms.length <= 1" [formGroup]="initialAppraisalGroupForm" class="grid-container accordion-grid-container" style="padding-right:0 !important;">
                <div class="grid-row" id="formGroup">
                    <div class="tablet:grid-col cols">
                        <div class="usa-form-group">
                            <label class="usa-label" for="treeProductId">Product<sup>*</sup></label>
                            
                            <ng-select formControlName="treeProductId" name="treeProductId" id="treeProductId" class="usa-rfd" (change)="setProductName()"  placeholder="Select a Product" [loading]="loadingHandler.isLoading" aria-label="Select a Product" [inputAttrs]="{'aria-label': 'Select a Product'}">
                                <ng-option *ngFor="let product of treeProducts" [value]="product.id"> {{product.productCode}} - {{product.productName}}</ng-option>
                            </ng-select>
                        </div>

                        <div class="usa-form-group">
                            <label class="usa-label" for="treeSpeciesId">Species<sup>*</sup></label>
                            <ng-select formControlName="treeSpeciesId" name="treeSpeciesId" id="treeSpeciesId" class="usa-rfd" (change)="setSpeciesName()"  placeholder="Select a Species" [loading]="loadingHandler.isLoading" aria-label="Select a Specie" [inputAttrs]="{'aria-label': 'Select a Specie'}">
                                <ng-option *ngFor="let species of treeSpecies" [value]="species.id">{{species.fiaCode}} - {{species.commonName}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="grid-row">
                    <div class="tablet:grid-col inputs">
                        <div class="usa-form-group usa-form-group--modifier">
                            <label class="usa-label" for="input-type-text">Condition <sup>*</sup></label>
                            <mat-button-toggle-group name="species_status" [formControl]="$any(initialAppraisalGroupForm.controls['treeCondition'])" aria-label="Tree Condition" class="usa-button-toggle speciesbtn">
                                <mat-button-toggle value="Live">Live</mat-button-toggle>
                                <mat-button-toggle value="Dead">Dead</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="usa-form-group">
                            <label class="usa-label" for="appraisalGroup">Appraisal/Sample Group</label>
                            <input *ngIf="!initialAppraisalGroupNameControl.value" class="usa-input input-full ag-sg" id="appraisalGroup" name="appraisalGroup" placeholder="AG/SG" disabled/>  
                            <input *ngIf="initialAppraisalGroupNameControl.value" class="usa-input input-full ag-sg" id="appraisalGroup" name="appraisalGroup" placeholder="AG/SG" value="{{ initialAppraisalGroupNameControl.value }}" disabled/>       
                        </div>
                    </div>
                </div>
                <div class="grid-row">
                    <div class="tablet:grid-col cols">
                        <div class="usa-form-group usa-form-group--modifier">
                            <label class="usa-label" for="input-type-text">Total Volume <sup>*</sup></label>
                            <app-number-input [formControl]="$any(initialAppraisalGroupForm.controls['totalVolume'])" [label]="'Total volume'" [step]="0.01" [min]="0.00" [unitOfMeasure]="initialUnitOfMeasure"></app-number-input>
                        </div>
                    </div>
                </div>
                <div class="grid-row" *ngIf="initialUom">
                    <div class="tablet:grid-col">
                        <div class="usa-form-group usa-form-group--modifier totalvolume">
                            <mat-button-toggle-group name="total_volume" [formControl]="$any(initialAppraisalGroupForm.controls['uomId'])" aria-label="Total volume of unit measure" class="usa-button-toggle" (change)="setInitialUom()">
                                <mat-button-toggle *ngFor="let u of initialUom" [value]="u.id">{{u.uomShortName}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="appraisalGroupForms.length > 1" class="grid-container accordion-grid-container" style="padding-right:0 !important;">
                <div class="usa-form-group">
                    <div class="usa-appraisal-groups-wrapper" style="width:100%">
                        <h3 class="title">{{title}}</h3>
                        <div class="grid_appraisal_container">
                            <div class="grid-item" *ngFor="let appraisalGroup of appraisalGroupForms.controls | paginate: {itemsPerPage: 6, currentPage:p}; let i = index">
                                <p>{{appraisalGroup.controls["treeSpeciesName"].value}}</p>
                                <div class="rates_vol_wrapper">
                                    <div class="volume">Volume: {{appraisalGroup.controls["totalVolume"].value}}/{{appraisalGroup.controls["uomShortName"].value}}</div>  <div class="standardrates">Standard Rate: {{appraisalGroup.controls["standardRate"].value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                </div>
                                <div class="volume">Volume x Standard Rate: {{(appraisalGroup.controls["totalVolume"].value * appraisalGroup.controls["standardRate"].value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                <div>
                                    <button type="button" tabindex="0" class="usa-button-text" (click)="editAppraisalGroup(i)" aria-label="Edit">Edit</button>
                                    &nbsp;&nbsp;
                                    <button type="button" tabindex="0" class="usa-button-text" (click)="deleteAppraisalGroup(i)" aria-label="Delete">Delete</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="appraiserRoleId > 1" class="has-text-centered">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div class="usa-form-group usa-btnflex">
                <div>
                    <button type="button" class="usa-button usa-button--outline continue-button" (click)="continue($event)">Continue</button>
                    <!-- <button type="button" class="usa-button usa-button--outline note-button"><img src="assets/img/tea-icons/edit-clipboard_svgrepo.com.svg"/>Add Note</button> -->
                </div>
                <button [disabled]="checkDisabled" type="button" class="usa-button-text"(click)="modalService.open('modal-4')">+Add Appraisal Group</button>
            </div> 
        </div>
    </div>
</ng-container>

<app-modal id="modal-4" aria-modal="true" aria-labelledby="Appraisal Group Management" >
    <div class="content-container" tabindex="-1" #modal Focustrap><!--Focustrap-->
      <button type="button"  class="closeBtn" (click)="modalService.close('modal-4')"  aria-label="Close Modal" tabindex="0" cdkFocusRegionStart>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M20.7456 3.33001C20.3551 2.93948 19.7219 2.93948 19.3314 3.33001L12.037 10.6244L4.74263 3.33001C4.35211 2.93948 3.71894 2.93948 3.32842 3.33001C2.93789 3.72053 2.93789 4.3537 3.32842 4.74422L10.6228 12.0386L3.32844 19.3329C2.93791 19.7235 2.93791 20.3566 3.32844 20.7472C3.71896 21.1377 4.35213 21.1377 4.74265 20.7472L12.037 13.4528L19.3314 20.7472C19.7219 21.1377 20.3551 21.1377 20.7456 20.7472C21.1361 20.3566 21.1361 19.7235 20.7456 19.333L13.4512 12.0386L20.7456 4.74422C21.1361 4.3537 21.1361 3.72053 20.7456 3.33001Z" fill="#757575"/>
        </svg>
      </button>
      
    
      <div class="modalForm">
        <h2 class="heading"><strong>Add an Appraisal Group</strong></h2>
        <p class="subtext">Please select your products, unit of measure, and tree condition to populate an additional appraisal group.</p>
       
            
            <ng-container [formGroup]="newAppraisalGroupForm" >
                <div class="grid-row" id="formGroup">
                    <div class="tablet:grid-col cols">
                        <div class="usa-form-group">
                            <label class="usa-label" for="treeProductId">Product<sup>*</sup></label>
                            
                            <ng-select formControlName="treeProductId" name="treeProductId" id="treeProductId" class="usa-rfd" (change)="setNewProductName()"  placeholder="Select a Product" [loading]="loadingHandler.isLoading" aria-label="Select a Product" [inputAttrs]="{'aria-label': 'Select a Product'}">
                                <ng-option *ngFor="let product of treeProducts" [value]="product.id"> {{product.productCode}} - {{product.productName}}</ng-option>
                            </ng-select>
                        </div>

                        <div class="usa-form-group">
                            <label class="usa-label" for="treeSpeciesId">Species<sup>*</sup></label>
                            <ng-select formControlName="treeSpeciesId" name="treeSpeciesId" id="treeSpeciesId" class="usa-rfd" (change)="setNewSpeciesName()"  placeholder="Select a Species" [loading]="loadingHandler.isLoading" aria-label="Select a Specie" [inputAttrs]="{'aria-label': 'Select a Specie'}">
                                <ng-option *ngFor="let species of treeSpecies" [value]="species.id">{{species.fiaCode}} - {{species.commonName}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="grid-row">
                    <div class="tablet:grid-col inputs">
                        <div class="usa-form-group usa-form-group--modifier">
                            <label class="usa-label" for="input-type-text">Condition <sup>*</sup></label>
                            <mat-button-toggle-group name="species_status" [formControl]="$any(newAppraisalGroupForm.controls['treeCondition'])" aria-label="Tree Condition" class="usa-button-toggle speciesbtn">
                                <mat-button-toggle value="Live">Live</mat-button-toggle>
                                <mat-button-toggle value="Dead">Dead</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="usa-form-group">
                            <label class="usa-label" for="appraisalGroup">Appraisal/Sample Group</label>
                            <input *ngIf="!newAppraisalGroupNameControl.value" class="usa-input input-full ag-sg" id="appraisalGroup" name="appraisalGroup" placeholder="AG/SG" disabled/>  
                            <input *ngIf="newAppraisalGroupNameControl.value" class="usa-input input-full ag-sg" id="appraisalGroup" name="appraisalGroup" placeholder="AG/SG" value="{{ newAppraisalGroupNameControl.value }}" disabled/>       
                        </div>
                    </div>
                </div>
                <div class="grid-row">
                    <div class="tablet:grid-col cols">
                        <div class="usa-form-group usa-form-group--modifier">
                            <label class="usa-label" for="input-type-text">Total Volume <sup>*</sup></label>
                            <app-number-input [formControl]="$any(newAppraisalGroupForm.controls['totalVolume'])" [label]="'Total volume'" [step]="0.01" [min]="0.00" [unitOfMeasure]="newUnitOfMeasure"></app-number-input>
                        </div>
                    </div>
                </div>
                <div class="grid-row" *ngIf="initialUom">
                    <div class="tablet:grid-col">
                        <div class="usa-form-group usa-form-group--modifier totalvolume">
                            <mat-button-toggle-group name="total_volume" [formControl]="$any(newAppraisalGroupForm.controls['uomId'])" aria-label="Total volume of unit measure" class="usa-button-toggle" [disabled]="true">
                                <mat-button-toggle *ngFor="let u of initialUom" [value]="u.id">{{u.uomShortName}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
                <div class="usa-form-group usa-btnflexModal">
                    <button type="submit" class="usa-button usa-button--outline continue-button" (click)="insertAppraisalGroup()">Insert New Appraisal Group</button>
                    <button type="button" class="usa-button-text" tabindex="0" (click)="modalService.close('modal-4')">Cancel</button>
                </div> 

            </ng-container>
          
      </div>
        
        
  
              
            
  
            
      
  
      
        
    </div>
  
  </app-modal>