import {Component, ElementRef, OnInit, QueryList, ViewChild, viewChild, ViewChildren} from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { User } from '../../models/user/user';
import { take } from 'rxjs';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Region } from '../../models/forest-service/region';
import { Forest } from '../../models/forest-service/forest';
import { District } from '../../models/forest-service/district';
import { environment } from '../../../environments/environment';
import { RegionsService } from '../../services/regions/regions.service';
import { MatSelectionList, MatSelectionListChange} from '@angular/material/list';
import { UserRoleRequest } from '../../models/admin/user-role-requests/user-role-request';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material/expansion';
import { UserRoleRequestForest } from '../../models/admin/user-role-requests/user-role-rquest-forest';
import { UserRoleRequestDistrict } from '../../models/admin/user-role-requests/user-role-request-district';
import { RolesService } from '../../services/admin/roles.service';
import { Role } from '../../models/user/user-roles/role';
import { UserRoleRequestsService } from '../../services/admin/user-role-requests.service';
import { ToastrService } from 'ngx-toastr';
import { UserRoleRequestStatus } from '../../models/enums/user-role-request-status';
import { UserRole } from '../../models/user/user-roles/user-role';
import { UserRolesService } from '../../services/admin/user-roles.service';


@Component({
    selector: 'app-request-permissions',
    templateUrl: './request-permissions.html',
    styleUrls: ['./request-permissions.scss'],
    standalone: false
})

export class RequestPermissionsComponent implements OnInit {
//#region Properties   
    user!: User;
    form!: FormGroup;
    baseUrl = environment.apiUrl;
    selectedRegion?: Region | null;
    showDiv:boolean = false;
    userRoleRequest!: UserRoleRequest;
    checked:boolean =false;
  
    @ViewChild('userRoles') userRoles!:MatSelectionList;
    @ViewChildren('districtCheckbox') districtCheckboxes!: QueryList<any>;
    @ViewChildren('forestCheckbox') forestCheckboxes!: QueryList<any>;

    @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) districtBoxes!: QueryList<MatCheckbox>;
  
    accordion = viewChild.required(MatAccordion);
    regions: Region[] = [];

    openPanels: number[] = [];
    roles: Role[] = [];
    currentUserRoleRequests: UserRoleRequest[] = [];
    currentUserRoles: UserRole[] = [];
    selectedRoleId?: number | null;
//#endregion Properties   

//#region Construction
    constructor(
      private fb: FormBuilder,
        private usersService: UsersService,
        private regionsService: RegionsService,
        private rolesService: RolesService,
        private userRoleRequestsService: UserRoleRequestsService,
        private userRolesService: UserRolesService,
        private toastr: ToastrService
       
    ) {  
      
    }
  
    ngOnInit(): void {
      this.checkCurrentUser();
      this.loadRegions();
      this.loadRoles();
      this.usersService.currentUser$.pipe(take(1)).subscribe({
        next: user => {
          if (user){
            this.user = user;
            console.log(user);
            this.initForm();
          }
        },
        error: error => {
          console.log(error);
        }
      });  
    }
//#endregion Construction

//#region OnInit Methods
    /******************************
            ONINIT METHODS
    ******************************/
    initForm() {
      if(this.user) {
        this.userRoleRequest = {
          userId: this.user.id,
          forests: []
        }  
        this.form = this.fb.group({   
          regionId: [null, Validators.required],
          roleId: [null, Validators.required]
        }); 
        this.selectedRegion = null;
        this.openPanels = [];
        this.showDiv = false;
      } else {
        throw Error("User not logged in.");
      }
    }

    checkCurrentUser() {
      this.usersService.currentUser$.subscribe({
          next: user => {
            if (user){
              this.user = user; 
              this.currentUserRoleRequests = user.userRoleRequests; 
              this.currentUserRoles = user.userRoles;  
            }else{
              console.log("User not logged in.")
            }
          }
      })
  }

  loadRegions() {
    this.regionsService.allRegions$.subscribe({
      next: response => {
          if (response) {
            this.regions = response;
            //console.log(response);
            
          }
      }
    });
  }

  loadRoles() {
    this.rolesService.roles$.subscribe({
      next: response => {
          if (response) {
            this.roles = response;
          // console.log(response);
            
          }
      }
    });
  }
//#endregion OnInit Methods

//#region Open Panel Methods
    /******************************
            OPEN PANEL METHODS
    ******************************/
    expanded(index: number): boolean {
      return this.openPanels.filter(p => p == index)[0] != null;
    }

    opened(index: number, forestId?: number) {
      if(forestId) {
        let openIndex = this.openPanels.find((i) => i == index);
        console.log(openIndex)
        if(openIndex == null) {
          this.openPanels.push(index);
        }
        console.log(this.openPanels);
      }
    }
    
    closed(index: number, forestId?: number) {
      if(forestId) {
        let forestCheckbox = this.forestCheckboxes.filter(f => f.value == forestId)[0]; 
        let openIndex = this.openPanels.find((i) => i == index);
        console.log("closed: ", forestCheckbox.checked);
        if(forestCheckbox.checked) {
          if(openIndex == null) {
            this.openPanels.push(index);
          } 
        } else {
          this.openPanels =  this.openPanels.filter(p => p != index);
        }
      }

      console.log(this.openPanels);
      
    }
//#endregion Open Panel Methods

//#region Checkbox Methods
    /******************************
            CHECKBOX METHODS
    ******************************/
    selectCheckbox(checked: boolean){
      this.checkboxes.forEach(checkbox => checkbox.checked = checked);
    }

    selectAllCheckboxes(){
      this.userRoleRequest.forests = [];
      this.selectedRegion?.forests?.forEach(f => {
        let forest: UserRoleRequestForest = {
          forestId: f.id,
          status: UserRoleRequestStatus[UserRoleRequestStatus.Requested],
          districts: []
        }
        f.districts?.forEach(d => {
          let district: UserRoleRequestDistrict = {
            districtId: d.id,
            status: UserRoleRequestStatus[UserRoleRequestStatus.Requested]
          }
          forest?.districts?.push(district);
        });
        this.userRoleRequest?.forests?.push(forest);
      })
      this.selectCheckbox(true);

    }
      
    deselectAllCheckboxes(){
      this.userRoleRequest.forests = [];
      this.selectCheckbox(false);
    }

    stopPropagation($event: MouseEvent, forestId?: number) {
        $event.stopPropagation();
    }
//#endregion Checkbox Methods

//#region Event Methods
    /******************************
            EVENT METHODS
    ******************************/

    
    onRoleSelectionChange(event:MatSelectionListChange){
      let roleId = this.form.controls['roleId'].value;
      this.selectedRoleId = roleId;
      this.userRoleRequest.roleId = +roleId; 
      this.setForests();   
    }

    onRegionSelectionChange(event:MatSelectionListChange){
      this.setForests();   
      this.showDiv = true; 
      this.openPanels = [];
    }

    setForests() {
      let r = this.regions?.filter(r => r.id == this.form.controls['regionId'].value)[0];
      this.selectedRegion = r;
      this.userRoleRequest.regionId = r.id;
      this.userRoleRequest.forests = [];
      setTimeout(() => { this.setForestCheckboxes();  }, 100); 
    }

    setForestCheckboxes() {
      this.setExisitingRoleForestCheckboxes();
      this.setExisitingRequestForestCheckboxes();
    }

    setExisitingRoleForestCheckboxes() {
      if(this.currentUserRoles.length > 0) {
        let userRole = this.currentUserRoles.filter(ur => ur.roleId == this.selectedRoleId)[0];
        //let forestRoles = this.currentUserRoles.filter(urr => urr.userRoleRegions?.some(r => r.userRoleForests.some(forest => forest.forestId == f.)));
        console.log(userRole, this.currentUserRoles);
        if(userRole?.userRoleRegions) {
          for(let region of userRole.userRoleRegions) {
            if(region?.userRoleForests) {
              console.log(region.userRoleForests)
              region.userRoleForests.forEach(f => {
                console.log(f)
                if(f.forestId) {
                  
                  
                  let forestCheckbox = this.forestCheckboxes.filter(fc => fc.value == f.forestId)[0];
                  let forestCheckboxes = this.forestCheckboxes.filter(fc => fc.value == f.forestId);
                  console.log(this.forestCheckboxes)
                  if(forestCheckbox) {
                    console.log(forestCheckbox)
                    let index = this.selectedRegion?.forests.indexOf(this.selectedRegion.forests.filter(fo => fo.id == f.id)[0]);
                    forestCheckbox.checked = true;
                    forestCheckbox.disabled = true;
                    if(f.userRoleDistricts) {
                      console.log('test')
                      let districtCounter = 0;
                      f.userRoleDistricts.forEach(d => {
                        let districtCheckbox = this.districtCheckboxes.filter(dis => dis.value == d.districtId)[0];
                        districtCheckbox.checked = districtCheckbox.disabled = true;
                        districtCounter++
                      });
                      if(f.userRoleDistricts?.length != districtCounter && index != undefined && index >= 0)
                      {
                          this.openPanels.push(index);
                      }
                    }
                  }
                }
              })   
            }
          }  
        }
        
      }
    }

    setExisitingRequestForestCheckboxes() {
      if(this.currentUserRoleRequests.length > 0) {
        let requests = this.currentUserRoleRequests.filter(r => r.regionId == this.selectedRegion?.id && r.roleId == this.selectedRoleId);
        if(requests?.length > 0) {
          for(let request of requests) {
            if(request?.forests) {
              for(let forest of request.forests) {
                if(forest.forestId) {
                  let forestCheckbox = this.forestCheckboxes.filter(f => f.value == forest.forestId)[0];
                  if(forestCheckbox) {
                    let index = this.selectedRegion?.forests.indexOf(this.selectedRegion.forests.filter(f => f.id == forest.forestId)[0]);
                    if(forest.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested]) {
                      forestCheckbox.checked = true;
                      forestCheckbox.disabled = true;
                    }
                    if(forest.districts) {
                      let districtCounter = 0;
                      for(let district of forest.districts) {
                        let districtCheckbox = this.districtCheckboxes.filter(f => f.value == district.districtId)[0];
                        districtCheckbox.checked = districtCheckbox.disabled = district.status == UserRoleRequestStatus[UserRoleRequestStatus.Requested];
                        districtCounter++;
                      }
                      if(forest.districts?.length != districtCounter && index != undefined && index >= 0)
                      {
                          this.openPanels.push(index);
                      }
                    }
                  }
                  
                }
              }   
            }
          }
        }  
      };

    }

    currentForestStatus(id: number | undefined){
      if(!id)
        return "";
      let forestRequests = this.currentUserRoleRequests.filter(r => r.forests?.some(f => f.forestId == id));
      let forestRoles = this.currentUserRoles.filter(urr => urr.roleId == this.selectedRoleId && urr.userRoleRegions?.some(r => r.userRoleForests.some(f => f.forestId == id)));
      
      if(forestRoles?.length > 0)
        return " *Current user role location.";  
      
      if(forestRequests?.length > 0)
        return " *Request pending.";
      
      return null;

    }

    currentDistrictStatus(id: number | undefined){
      if(!id)
        return "";
      let districtRoles = this.currentUserRoles.filter(ur => ur.roleId == this.selectedRoleId && ur.userRoleRegions.some(r => r.userRoleForests.some(f => f.userRoleDistricts.some(d => d.districtId == id))));
      let districtRequests = this.currentUserRoleRequests.filter(r => r.forests?.some(f => f.districts?.some(d => d.districtId == id)));
      
      if(districtRoles?.length > 0)
        return " *Current user role location.";  
      
      if(districtRequests?.length > 0)
        return " *Request pending.";
      
      return null;
    }
  
    
    onForestChekboxChange(event: MatCheckboxChange, selectedForest: Forest, index: number) {
        console.log("onForestChekboxChange: line 244");
        if(event.checked) {
          this.opened(index, selectedForest.id);
        } else {
          this.closed(index, selectedForest.id);
        }

        this.onForestChekboxChangeElement(event.checked, selectedForest);
    }

    onForestChekboxChangeElement(checked: boolean, selectedForest: Forest) {
      console.log("onForestChekboxChangeElement: line 255");
      let districts = this.districtCheckboxes.filter(f => f._elementRef.nativeElement.classList.contains("districtCheckbox-" + selectedForest.forestCode));
      districts.forEach(elem => {
        elem.checked = checked;
        let exisitingDistrict = selectedForest.districts.filter(d => d.id === elem.value)[0];
        if(exisitingDistrict) {
          this.onDistrictCheckboxChangeElement(elem.checked, exisitingDistrict)
        }
      });

      if (checked) {
        let requestForest = this.userRoleRequest.forests?.filter(f => f.forestId === selectedForest.id)[0];
        if(!requestForest) {
          let forest: UserRoleRequestForest = {
            forestId: selectedForest.id,
            status: UserRoleRequestStatus[UserRoleRequestStatus.Requested],
            districts: []
          }
          this.userRoleRequest?.forests?.push(forest);
          requestForest = forest;
        } 
        requestForest.districts = [];
        districts.forEach(elem => {
          if(elem.checked) {
            let district: UserRoleRequestDistrict = {
              districtId: +elem.value,
              status: UserRoleRequestStatus[UserRoleRequestStatus.Requested]
            }
            requestForest?.districts?.push(district);
          }
        });
      } else {
        this.userRoleRequest.forests = this.userRoleRequest.forests?.filter(f => f.forestId != selectedForest.id) as Forest[]; 
      }
    }
      
      onDistrictCheckboxChange(event: MatCheckboxChange, selectedDistrict: District){
        this.onDistrictCheckboxChangeElement(event.checked, selectedDistrict);
      }

      onDistrictCheckboxChangeElement(checked: boolean, selectedDistrict: District){
        let requestForest = this.userRoleRequest.forests?.filter(f => f.forestId == selectedDistrict.forestId)[0];
        let forestCheckbox = this.forestCheckboxes.filter(f => f.value == selectedDistrict.forestId)[0];
        // district checkbox is checked
        if(checked) {
          if(!forestCheckbox.checked && !forestCheckbox.disabled)
            forestCheckbox.checked = true;
          // requestForest does exist in userRoleRequest.forests[]
          if(requestForest) {
            let requestDistrict = requestForest.districts?.filter(f =>f.id === selectedDistrict.id)[0];
            // requestDistrict does NOT exist in requestForest.districts[]
            if(!requestDistrict) {
              let district: UserRoleRequestDistrict = {
                userRoleRequestForestId: requestForest.id,
                districtId: selectedDistrict.id,
                status: UserRoleRequestStatus[UserRoleRequestStatus.Requested]
              }
              requestForest.districts?.push(district);
            } 
            // else: do nothing
          } 
          // requestForest does NOT exist in userRoleRequest.forests[] 
          else {
            let forest: UserRoleRequestForest = {
              forestId: selectedDistrict.forestId,
              status: UserRoleRequestStatus[UserRoleRequestStatus.Requested],
              districts: []
            }
            let district: UserRoleRequestDistrict = {
              districtId: selectedDistrict.id,
              status: UserRoleRequestStatus[UserRoleRequestStatus.Requested]
            }
            forest.districts?.push(district);
            this.userRoleRequest?.forests?.push(forest);
          }
        // district checkbox is unchecked
        } else if(requestForest){
          if(forestCheckbox.checked && requestForest.districts?.length == 1 && this.userRoleRequest?.forests != null && this.userRoleRequest?.forests.length > 0) {
            if(!forestCheckbox.disabled)
              forestCheckbox.checked = false;
            this.userRoleRequest.forests = this.userRoleRequest?.forests?.filter(f => f.forestId != requestForest?.forestId) as Forest[];
          } else {
            // requestForest does exist in userRoleRequest.forests[]
            requestForest.districts = requestForest.districts?.filter(d => d.districtId != selectedDistrict.id) as District[]; 
          } 
        }
      }
          
    onForestCheckboxKeyUp(event: KeyboardEvent, forest: Forest, index?: number) {
      let forestCheckbox = this.forestCheckboxes.filter(f => f.value == forest.id)[0]; 
      let openIndex = this.openPanels.find((i) => i == index);
      if (event.key === ' ' || event.key === 'Enter') {
        event.stopPropagation();
        console.log(openIndex);
        let checked = !forestCheckbox.checked;
        forestCheckbox.checked = checked;
        if(index != null) {
          console.log(this.openPanels)
          if(forestCheckbox.checked && openIndex == null) {
            this.opened(index, forest.id);
          } else {
            this.closed(index, forest.id);
          }
        }
        this.onForestChekboxChangeElement(forestCheckbox.checked, forest);
      }
    }

    onDistrictCheckboxKeyDown(event: KeyboardEvent, district: District) { 
      let districtCheckbox = this.districtCheckboxes.filter(f => f.value == district.id)[0]; 
      if (event.key === ' ' || event.key === 'Enter') {
        event.preventDefault();
        let checked = !districtCheckbox.checked;
        districtCheckbox.checked = checked;
        this.onDistrictCheckboxChangeElement(districtCheckbox.checked, district);
      }
    }

    async submitRequest() {
      console.log("SUBMIT REQUEST: ", this.userRoleRequest);
      this.userRoleRequestsService.createPendingRequest(this.userRoleRequest).subscribe({
        next: response => {
          if(response.isSuccess) {
            this.toastr.success('Successfully created a new User Role Request.');
            this.accordion().closeAll();
            this.deselectAllCheckboxes();
            this.initForm();
            
          }
          console.log(response);
        }
      });
      //get updated user role request with new service and api call
     // this.user.userRoles = await this.userRolesService.getUserRolesByUserId(this.user.id as number);
    }
//#endregion Event Methods

    
}
