import { Component } from '@angular/core';

@Component({
    selector: 'app-sale-appraisal',
    templateUrl: './sale-appraisal.component.html',
    styleUrl: './sale-appraisal.component.scss'
})
export class SaleAppraisalComponent {

}
