import {NgModule} from '@angular/core';
import { ChildActivationEnd, ChildActivationStart, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule, Routes, RoutesRecognized } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { EstimateMarketValueComponent } from './components/estimate-market-value/estimate-market-value.component';
import { ViewDataComponent } from './components/view-data/view-data.component';
import { HelpComponent } from './components/help/help.component';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { TestErrorsComponent } from './components/errors/test-errors/test-errors.component';
import { NotFoundComponent } from './components/errors/not-found/not-found.component';
import { ServerErrorComponent } from './components/errors/server-error/server-error.component';
import { authGuard } from './guards/auth.guard';
import { adminGuard } from './guards/admin.guard';
import { RequestPermissionsComponent } from './components/request-permissions/request-permissions';
import { StandardRatesComponent } from './components/admin/admin-panel/standard-rates/standard-rates.component';
import { MinimumRatesComponent } from './components/admin/admin-panel/minimum-rates/minimum-rates.component';
import { UserManagementComponent } from './components/admin/admin-panel/user-management/user-management.component';
import { DataAnalysisReportingComponent } from './components/admin/admin-panel/data-analysis-reporting/data-analysis-reporting.component';
import { BasePeriodDataComponent } from './components/admin/admin-panel/base-period-data/base-period-data.component';
import { ExternalMarketDataComponent } from './components/admin/admin-panel/external-market-data/external-market-data.component';
import { AppraisalZonesComponent } from './components/admin/admin-panel/appraisal-zones/appraisal-zones.component';
import { ProductsComponent } from './components/admin/admin-panel/products/products.component';
import { HighChartsTestComponent } from './highcharts/highcharts-test.component';
import { HighChartsDashboardTestComponent } from './highcharts/dashboards/highcharts-dashboard-test.component';
import { BaseDataInZoneComponent } from './components/view-data/base-data-in-zone/base-data-in-zone.component';
import { SaleAppraisalComponent } from './components/view-data/sale-appraisal/sale-appraisal.component';
import { BiddingRecordsComponent } from './components/view-data/bidding-records/bidding-records.component';
import { SalesInBaseDataComponent } from './components/view-data/sales-in-base-data/sales-in-base-data.component';
import { DisplayProductComponent } from './components/view-data/display-product/display-product.component';
import { StandardRatesInEffectComponent } from './components/view-data/standard-rates-in-effect/standard-rates-in-effect.component';
import { WwpaIndicesComponent } from './components/admin/admin-panel/wwpa-indices/wwpa-indices.component';
import { AppraisalGroupsComponent } from './components/admin/admin-panel/appraisal-groups/appraisal-groups.component';
import { ViewWwpaIndicesComponent } from './components/view-data/wwpa-indices/wwpa-indices.component';

const routes: Routes = [
    {path: '', redirectTo: 'landing', pathMatch: 'full'},
    {path: 'landing', component: LandingComponent},
    {path: 'request-permissions', canActivate: [authGuard], component: RequestPermissionsComponent},
    {path: 'home', canActivate: [authGuard], component: HomeComponent},
    {path: 'estimate-market-value', canActivate: [authGuard], component: EstimateMarketValueComponent},
    {path: 'view-data', canActivate: [authGuard], component: ViewDataComponent},
    {path: 'view-data/base-data-in-zone', canActivate: [authGuard], component: BaseDataInZoneComponent, title:"Base-Data-In-Zone"},
    {path: 'view-data/sale-appraisal', canActivate: [authGuard], component: SaleAppraisalComponent, title:"Sale-Appraisal"},
    {path: 'view-data/bidding-records', canActivate: [authGuard], component: BiddingRecordsComponent, title:"Bidding Records"},
    {path: 'view-data/sales-in-base-data', canActivate: [authGuard], component: SalesInBaseDataComponent, title:"Sales In Base Data"},
    {path: 'view-data/standard-rates', canActivate: [authGuard], component: StandardRatesComponent, title:"Standard Rates"},
    {path: 'view-data/display-products', canActivate: [authGuard], component: DisplayProductComponent, title:"Display Products"},
    {path: 'view-data/wwpa-indices', canActivate: [authGuard], component: ViewWwpaIndicesComponent, title:"WWPA Indices"},
    {path: 'view-data/standard-rates-in-effect', canActivate: [authGuard], component: StandardRatesInEffectComponent, title:"Standard Rates-In Effect"},


    {path: 'help', canActivate: [authGuard], component: HelpComponent},
    {path: 'high-charts', canActivate: [authGuard], component: HighChartsTestComponent},
    {path: 'high-charts-dashboard', canActivate: [authGuard], component: HighChartsDashboardTestComponent},
    {path: 'admin', canActivate: [adminGuard], component: AdminPanelComponent}, 
    {path: 'admin/user-management', canActivate: [adminGuard], component: UserManagementComponent}, 
    {path: 'admin/standard-rates', canActivate: [adminGuard], component: StandardRatesComponent}, 
    {path: 'admin/minimum-rates', canActivate: [adminGuard], component: MinimumRatesComponent},  
    {path: 'admin/data-analysis-reporting', canActivate: [adminGuard], component: DataAnalysisReportingComponent}, 
    {path: 'admin/base-period-data', canActivate: [adminGuard], component: BasePeriodDataComponent}, 
    {path: 'admin/external-market-data', canActivate: [adminGuard], component: ExternalMarketDataComponent},  
    {path: 'admin/wwpa-indices', canActivate: [adminGuard], component: WwpaIndicesComponent}, 
    {path: 'admin/appraisal-zones', canActivate: [adminGuard], component: AppraisalZonesComponent}, 
    {path: 'admin/appraisal-groups', canActivate: [adminGuard], component: AppraisalGroupsComponent}, 
    {path: 'admin/products', canActivate: [adminGuard], component: ProductsComponent}, 
    

    {path: 'errors', component: TestErrorsComponent},
    {path: 'not-found', component: NotFoundComponent},
    {path: 'server-error', component: ServerErrorComponent},
    {path: '**', component: NotFoundComponent, pathMatch: 'full'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            useHash:false,
            onSameUrlNavigation:'reload'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    isLoading: boolean = false;
    errorMessage?: string;

    constructor(private router: Router){
        //Router events for future user behavior logging
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
              this.isLoading = true;
            } else if (event instanceof RoutesRecognized) {
              console.log('RoutesRecognized event:', event);
            } else if (event instanceof RouteConfigLoadStart) {
              console.log('RouteConfigLoadStart event:', event);
            } else if (event instanceof RouteConfigLoadEnd) {
              console.log('RouteConfigLoadEnd event:', event);
            } else if (event instanceof NavigationEnd) {
              this.isLoading = false;
            } else if (event instanceof NavigationCancel) {
              this.isLoading = false;
              this.errorMessage = 'Navigation cancelled';
            } else if (event instanceof NavigationError) {
              this.isLoading = false;
              this.errorMessage = 'Navigation error';
            } else if (event instanceof ChildActivationStart) {
              const childRoute = event.snapshot.routeConfig?.path;
              console.log(`ChildActivationStart event for ${childRoute}`);
              // Load data specific to the child component here
            } else if (event instanceof ChildActivationEnd) {
              console.log('ChildActivationEnd event:', event);
              // Perform any necessary cleanup tasks here
            }
          });
    }
    
}
