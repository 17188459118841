import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, 
    private toastr: ToastrService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
          switch (error.status) {
            case 0:
              console.log(error);
              this.toastr.error(error.message,"API connection error:");
              break;
            case 400:
              if (error.error.errors) {
                const modelStateErrors = [];
                for (const key in error.error.errors) {
                  if (error.error.errors[key]) {
                    modelStateErrors.push(error.error.errors[key])
                  }
                }
                throw modelStateErrors.flat();
              } else {
                console.log(error.error, error.status);
                
              }
              this.toastr.error(error.errors, error.status);
              break;
            case 401:
              this.toastr.error('Unauthorized', error.status);
              this.router.navigateByUrl('/landing?logout=true');
              break;
            case 404: 
              this.router.navigateByUrl('/not-found');
              break;
            case 500: {
              const navigationExtras: NavigationExtras = {state: {error: error.error}};
              this.router.navigateByUrl('/server-error', navigationExtras);
              break;
            } 
            default:
              console.log('Something unexpected went wrong');
              console.log(error);
              this.toastr.error(error.status, "Unexpected error:");
              break;
          }
        
        
        throw error;
      })
    )
  }
}
