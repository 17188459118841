<div class="usa-width-one-whole nt-info-area">
    <div class="not-found">
        <div class="not-found-img">
           
        </div> 
        <div class="not-found-content">
            <div class="not-found-message">
                <span>404</span>
                <p>We are unable to log you in because you are either not part of USDA or do not have an EAuthentication. </p>
                <p>If you have received this error, please contact the Help Desk for assistance. You will not be automatically redirected.</p>
            </div>
            <div class="not-found-btns">
                <button type="button" class="usa-button usa-button--outline back-btn" (click)="back()">back</button>
                <a type="button" class="usa-button" href="tel:18669451354">Help Desk 1-866-945-1354</a>
            </div>
        </div>
        
    </div>
    <div class="clearfix"></div>
</div>