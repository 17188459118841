import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserRoleRequest } from '../../models/admin/user-role-requests/user-role-request';
import { map, Observable } from 'rxjs';
import { ApiResult } from '../../models/api/api-result';

@Injectable({
  providedIn: 'root'
})
export class UserRoleRequestsService {
  
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getPendingRequests() {
    return this.http.get<UserRoleRequest[]>(this.baseUrl + 'user-role-requests/pending-requests');
  }

  createPendingRequest(request: UserRoleRequest){
    return this.http.post<ApiResult>(this.baseUrl + 'user-role-requests', request).pipe(
      map(response => {
          return response;
      })
    )
  }

  updatePendingRequest(request: UserRoleRequest){
      return this.http.put<ApiResult>(this.baseUrl + 'user-role-requests', request).pipe(
        map(response => {
            return response;
        })
      )
  }
}