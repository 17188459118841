import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { take } from 'rxjs';
import { User } from '../../models/user/user';
import { ToastrService } from 'ngx-toastr';
import { UserRole } from '../../models/user/user-roles/user-role';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})

export class HomeComponent implements OnInit {
    hasLoaded = true;
    fullName: any;
    forestNames: string[] = []
    rangerDistricts: string [] = []
    regions: string [] = []
    selectedRegion: string = ''
    selectedForest: string = ''
    user!: User;
    guest: boolean = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public usersService: UsersService,
        private toastr: ToastrService
    ) {
    }


    ngOnInit() {
        this.usersService.currentUser$.pipe(take(1)).subscribe({
            next: user => {
              if (user){
                this.user = user;
                console.log(this.user);
                console.log(this.user.userRoles as UserRole[]);
                let test = this.user.userRoles.find(ur => ur.role.name == 'Guest');
                console.log(test)
                this.guest = this.user.userRoles.some(role => role.role?.name == 'Guest')
                console.log(this.guest);
                console.log(this.user);
      
              }
            },
            error: error => {
              console.log(error);
              this.toastr.error(error.message + " Error:" + error.error);
          }
          })
    }

    
}


