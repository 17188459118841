import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UsersService } from './services/users/users.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'Transaction Evidence Appraisal Application';


  constructor(private http: HttpClient,
    private usersService: UsersService) {


  }

  ngOnInit(): void {
    this.usersService.checkCurrentUser();
  }
  
}

