import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { User } from '../../models/user/user';
import { take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Authorize } from '../../models/authorize';
import { environment } from '../../../environments/environment';


@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})

export class LandingComponent implements OnInit {    
    model?: Authorize | null;
    user?: User | null;
    logout: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private toastr: ToastrService
    ) {

        this.route.queryParams.subscribe(params => {
            if(params['code'] && params['state']){
                this.model = new Authorize(); 
                this.model.code = params['code'];
                this.model.state = params['state'];
            }else if(params['logout']){
                this.logout = params['logout'];
                console.log(this.logout);
                this.usersService.logout();
            }
           
        });
    }


    ngOnInit(): void {
        this.checkCurrentUser();
    }

    login() {
        this.router.navigateByUrl('/landing');
    }

    checkCurrentUser() {
        this.usersService.currentUser$.pipe(take(1)).subscribe({
            next: user => {
              if(user){
                this.user = user;
                this.router.navigate(['../home'], { relativeTo: this.route });      
              }else if(this.model){
                    this.usersService.login(this.model).subscribe({
                        next: _ => {
                            this.router.navigate(['/home']);
                            this.model = null;
                        },
                        error: error => {
                            console.log(error);
                            this.toastr.error(error.message + " Error:" + error.error);
                        }
                    })
                }else if(this.logout){
                    // DO nothing??
                }else{
                    //FSApps Gateway
                    window.location.href = environment.authUrl;
                }
                    
              
            }
        })
    }

    

    
}


