import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { Uom } from '../../models/forest-service/uom';
import { getList, getListHeaders } from '../pagination/listHelper';

@Injectable({
  providedIn: 'root'
})

export class UomService {
    baseUrl = environment.apiUrl;
    uomCache = new Map();
    private uomSource = new BehaviorSubject<Uom[] | null>(null);
    uom$ = this.uomSource.asObservable();

  constructor(private http: HttpClient) { 
    this.getUom();
  }

  setUom(uom: Uom[]) {
    console.log(uom);
    this.uomCache.set('uom', uom);
    this.uomSource.next(uom);
  }

  private getUom() {
    const uom = this.uomCache.get('uom');
    console.log(uom);

    if (!uom || uom.length == 0){
      console.log('test uom');
      let params = getListHeaders();
      console.log(params);
      getList<Uom[]>(this.baseUrl + 'unitsofmeasure', params, this.http).subscribe({
        next:(response => {
          console.log(response)
          this.setUom(response as Uom[]);
        })
      })
    }
  }

  getUomById(uomId: number) {  
    const uom = [...this.uomCache.values()]
      .reduce((arr, elem) => arr.concat(elem.result), [])
      .find((uom: Uom) => uom.id = uomId);

    if(uom) return of(uom);
    
    return this.http.get<Uom>(this.baseUrl + 'unitsofmeasure/' + uomId);
  }


  
}