import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IVisited } from '../../models/visitors/visited';
import { ICalculationsVisitor } from '../../models/visitors/visitor';
import { AppraisalTypes } from '../../models/enums/appraisal-types';
import { UserRoles } from '../../models/enums/user-roles';

@Injectable({
  providedIn: 'root'
})
export class CalculationsService implements ICalculationsVisitor{
//#region Observables

    private requiredCollectionsSource = new BehaviorSubject<number>(0.00);
    requiredCollections$ = this.requiredCollectionsSource.asObservable();

    private totalSaleValueSource = new BehaviorSubject<number>(0.00);
    totalSaleValue$ = this.totalSaleValueSource.asObservable();

    private appraisalRatePerUomSource = new BehaviorSubject<number>(0.00);
    appraisalRatePerUom$ = this.appraisalRatePerUomSource.asObservable();

    private contractBaseValueSource = new BehaviorSubject<number>(0.00);
    contractBaseValue$ = this.contractBaseValueSource.asObservable();

//#endregion

//#region Private Properties

    // National Forest Foundation Fund
    private readonly nff = 0.25;
    private totalSaleVolume: number = 0.00;
    private timberPropertyValue: number = 0.00;
    private regenerationCost: number = 0.00;
    private basePeriodRate: number = 0.00;
    private baseSkidYard: number = 0.00;
    private baseHaul: number = 0.00;
    private baseRoadMaintenance: number = 0.00;
    private baseTempDevelopments: number = 0.00;
    private baseSlashDisposal: number = 0.00;
    private basePeriodRateInitial: number = 0.00;
    private baseSkidYardInitial: number = 0.00;
    private baseHaulInitial: number = 0.00;
    private baseRoadMaintenanceInitial: number = 0.00;
    private baseTempDevelopmentsInitial: number = 0.00;
    private baseSlashDisposalInitial: number = 0.00;
    private appraisalType!: AppraisalTypes;
    private userRole: UserRoles = 1;
    private contractBaseValueCoefficient: number = 0.00;
    private appraisalGroups!: any[];
//#endregion
   
//#region Construction
    constructor(private http: HttpClient) { }
//#endregion

//#region Visitor Methods
    /******************************
            VISITOR METHODS
    ******************************/
    public visitEstimateMarketValueComponent(component: IVisited) {
        let arControl = component.readProperty<FormControl>("appraiserRoleId");
        if(!arControl)
            throw Error("Error subscribing to appraiserRoleIdControl");

        this.userRole = arControl?.value as number;
        if(arControl){
            arControl.valueChanges.subscribe((value: number | null) => {
                console.log("UR: " + value);
                if(value != null)
                    this.appraisalType = value;
            });
        }

    }

    public visitChooseAppraisalTypeComponent(component: IVisited) {
        let atControl = component.readProperty<FormControl>("appraisalType");
        if(!atControl)
            throw Error("Error subscribing to appraisalTypeControl");

        if(atControl){
            atControl.valueChanges.subscribe((value: number | null) => {
                console.log("AT: " + value);
                if(value != null)
                    this.appraisalType = value;
                
                this.calculateTotalSaleValue();
            });
        }

    }

    public visitVolumeInformationComponent(component: IVisited) {
        let agArray = component.readProperty<FormArray<FormGroup>>("appraisalGroups");
        if(!agArray)
            throw Error("Error subscribing to appraisalGroups");

        if(agArray){
            agArray.valueChanges.subscribe((values: FormGroup[]) => {
                console.log(values);
                if(values != null)
                    this.appraisalGroups = values;
                console.log(this.appraisalGroups);
                this.calculateTotalVolume();
                this.calculateContractBaseRate();
                this.calculateTotalSaleValue();
            });
        }
    }

    public visitContractCalculationsComponent(component: IVisited) {
        let tpvControl = component.readProperty<FormControl>("timberPropertyValue");
        if(!tpvControl)
            throw Error("Error subscribing to tpvControl");

        this.timberPropertyValue = tpvControl?.value as number;
        if(tpvControl){
            tpvControl.valueChanges.subscribe((value: number | null) => {
                //console.log("TPV: " + value);
                if(value != null)
                    this.timberPropertyValue = value;
                this.calculateContractBaseRate();
            });
        }

        let rcControl = component.readProperty<FormControl>("regenerationCost");
        if(!rcControl)
            throw Error("Error subscribing to rcControl");

        this.regenerationCost = rcControl?.value as number;
        if(rcControl){
            rcControl.valueChanges.subscribe((value: number | null) => {
                //console.log("RC: " + value);
                if(value != null)
                    this.regenerationCost = value;
                this.calculateContractBaseRate();
            });
        }

        // let minRateControl = component.readProperty<FormArray>("appraisalGroupForms");
        // if(!minRateControl)
        //     throw Error("Error subscribing to minRateControl");

        // this.minimumRate = minRateControl?.value as number;
        // if(minRateControl){
        //     minRateControl.valueChanges.subscribe(value => {
        //         console.log("MR: " + value);
        //         if(value != null)
        //             this.minimumRate = value as number;
        //         this.calculateContractBaseRate();
        //     });
        // }

        // let standardRateControl = component.readProperty<FormControl>("standardRate"); 
        // if(!standardRateControl)
        //     throw Error("Error subscribing to standardRateControl");

        // this.standardRate = standardRateControl?.value as number;
        // standardRateControl.valueChanges.subscribe(value => {
        //     this.standardRate = value as number;
        //     console.log(this.standardRate);
        //     this.calculateTotalSaleValue();
        // });
    }

    public visitStandardRateCalulationsComponent(component: IVisited) {
        
        // let totalGroupValueControl = component.readProperty<FormControl>("totalGroupValue"); 
        // if(!totalGroupValueControl)
        //     throw Error("Error subscribing to baseSlashDisposalControl");

        // this.totalGroupValue = totalGroupValueControl?.value as number;
        // totalGroupValueControl.valueChanges.subscribe(value => {
        //     if(value != null)
        //         this.totalGroupValue = value.rate as number;
        //     this.calculateTotalSaleValue();
        // });

        // this.calculateTotalSaleValue();
    }

    public visitStreamlinedTeCalulationsComponent(component: IVisited) {
        let basePeriodRateControl = component.readProperty<FormControl>("basePeriodRate"); 
        if(!basePeriodRateControl)
            throw Error("Error subscribing to basePeriodRateControl");

        this.basePeriodRate = basePeriodRateControl.value as number;
        this.basePeriodRateInitial = basePeriodRateControl.value as number;
        basePeriodRateControl.valueChanges.subscribe((value: number) => {
            this.basePeriodRate = value;
            this.calculateTotalSaleValue();
        });     

        let baseSkidYardControl = component.readProperty<FormControl>("baseSkidYard");
        if(!baseSkidYardControl)
            throw Error("Error subscribing to baseSkidYardControl");

        this.baseSkidYard = baseSkidYardControl?.get('rate')?.value as number;
        this.baseSkidYardInitial = baseSkidYardControl?.get('initialValue')?.value as number;
        baseSkidYardControl.valueChanges.subscribe((value: any)  => {
            if(value.rate)
                this.baseSkidYard = value.rate as number;
            this.calculateTotalSaleValue();
        });
        

        let baseHaulControl = component.readProperty<FormControl>("baseHaul"); 
        if(!baseHaulControl)
            throw Error("Error subscribing to baseHaulControl");
        
        this.baseHaul = baseHaulControl?.get('rate')?.value as number;
        this.baseHaulInitial = baseHaulControl?.get('initialValue')?.value as number;
        baseHaulControl.valueChanges.subscribe((value: any) => {
            if(value.rate)
                this.baseHaul = value.rate as number;
            this.calculateTotalSaleValue();
        });

        let baseRoadMaintenanceControl = component.readProperty<FormControl>("baseRoadMaintenance");
        if(!baseRoadMaintenanceControl)
            throw Error("Error subscribing to baseRoadMaintenanceControl");
            
        this.baseRoadMaintenance = baseRoadMaintenanceControl?.get('rate')?.value as number;
        this.baseRoadMaintenanceInitial = baseRoadMaintenanceControl?.get('initialValue')?.value as number;
        baseRoadMaintenanceControl.valueChanges.subscribe((value: any) => {
            if(value.rate)
                this.baseRoadMaintenance = value.rate as number;
            this.calculateTotalSaleValue();
        });
        

        let baseTempDevelopmentsControl = component.readProperty<FormControl>("baseTempDevelopments");
        if(!baseTempDevelopmentsControl)
            throw Error("Error subscribing to baseTempDevelopmentsControl");
        
        this.baseTempDevelopments = baseTempDevelopmentsControl?.get('rate')?.value as number;
        this.baseTempDevelopmentsInitial = baseTempDevelopmentsControl?.get('initialValue')?.value as number;
        baseTempDevelopmentsControl.valueChanges.subscribe((value: any) => {
            if(value.rate)
                this.baseTempDevelopments = value.rate as number;
            this.calculateTotalSaleValue();
        });

        let baseSlashDisposalControl = component.readProperty<FormControl>("baseSlashDisposal"); 
        if(!baseSlashDisposalControl)
            throw Error("Error subscribing to baseSlashDisposalControl");

        this.baseSlashDisposal = baseSlashDisposalControl?.get('rate')?.value as number;
        this.baseSlashDisposalInitial = baseSlashDisposalControl?.get('initialValue')?.value as number;
        baseSlashDisposalControl.valueChanges.subscribe((value: any) => {
            if(value.rate)
                this.baseSlashDisposal = value.rate as number;
            this.calculateTotalSaleValue();
        });

        this.calculateTotalSaleValue();
    }
//#endregion

//#region Private Methods
    /***************************************
            PRIVATE CALCULATION METHODS
    ***************************************/

    private calculateTotalVolume() {
        let totalSaleVolume = 0;
        if(this.appraisalGroups.length > 0) {
            for(const ag of this.appraisalGroups)
            {
                let totalVolume = ag.totalVolume as number;
                totalSaleVolume =  +totalSaleVolume + +totalVolume;
            }
            this.totalSaleVolume = totalSaleVolume;
        }
        
       
    }

    private calculateTotalSaleValue() {
        let appraisalType = this.appraisalType as AppraisalTypes;
        if(appraisalType != null)
        {
            switch(appraisalType)
            {
                case AppraisalTypes.StandardRate:
                    this.calculateStandardRateTotalSaleValue();
                    break;
                case AppraisalTypes.StreamlinedTe:
                    this.calculateStreamlinedTeTotalSaleValue();
                    break;
                case AppraisalTypes.WeightedAverageTe:
                    this.calculateWeightedAverageTeTotalSaleValue();
                    break;
                case AppraisalTypes.ResidualValue:
                    this.calculateResidualValueTotalSaleValue();
                    break;
                case AppraisalTypes.MultipleRegressionTe:
                    this.calculateMultipleRegressionTeTotalSaleValue();
                    break;
                case AppraisalTypes.RateDetermination:
                    this.calculateRateDeterminationTotalSaleValue();
                    break;
            }
        }
       
    }

    private calculateStandardRateTotalSaleValue() {
        let userRole = this.userRole as UserRoles;
        switch(userRole)
        {
            case UserRoles.Guest:
                break;
            case UserRoles.Appraiser:
                break;
            case UserRoles.AppraiserAdministrator:
                break;
            case UserRoles.DataAdministrator:
                break;
        }
        let totalSaleValue = 0.00;
        if(this.totalSaleVolume > 0){
            for(const ag of this.appraisalGroups)
            {
                let standardRate = ag.standardRate as number;
                let totalVolume = ag.totalVolume as number; 
                let agSaleTotal = 0.00; 
                agSaleTotal = totalVolume * standardRate;
                totalSaleValue += agSaleTotal; 
            }
            this.totalSaleValueSource.next(totalSaleValue);

            let appraisalRatePerUom = totalSaleValue / this.totalSaleVolume;
            this.appraisalRatePerUomSource.next(appraisalRatePerUom);
            
        }else{
            this.totalSaleValueSource.next(0.00);
            this.appraisalRatePerUomSource.next(0.00);
        }
        
    }

    private calculateStreamlinedTeTotalSaleValue() {
        let basePeriodRateDiff = this.basePeriodRateInitial - this.basePeriodRate;
        let baseSkidYardDiff = this.baseSkidYardInitial - this.baseSkidYard;
        let baseHaulDiff = this.baseHaulInitial - this.baseHaul;
        let baseRoadMaintenanceDiff = this.baseRoadMaintenanceInitial - this.baseRoadMaintenance;
        let baseTempDevelopmentsDiff = this.baseTempDevelopmentsInitial - this.baseTempDevelopments;
        let baseSlashDisposalDiff = this.baseSlashDisposalInitial - this.baseSlashDisposal;

        let adjustments = basePeriodRateDiff 
            + baseSkidYardDiff 
            + baseHaulDiff 
            + baseRoadMaintenanceDiff 
            + baseTempDevelopmentsDiff 
            + baseSlashDisposalDiff;

        let adjustedTotal = this.basePeriodRate + adjustments;
        let totalSaleValue: number = 0.00;
        totalSaleValue = adjustedTotal * this.totalSaleVolume;
        this.totalSaleValueSource.next(totalSaleValue);

        let appraisalRatePerUom = totalSaleValue / this.totalSaleVolume;
        this.appraisalRatePerUomSource.next(appraisalRatePerUom);
    }

    private calculateWeightedAverageTeTotalSaleValue() {
        let userRole = this.userRole as UserRoles;
        switch(userRole)
        {
            case UserRoles.Guest:
                break;
            case UserRoles.Appraiser:
                break;
            case UserRoles.AppraiserAdministrator:
                break;
            case UserRoles.DataAdministrator:
                break;
        }
    }

    private calculateResidualValueTotalSaleValue() {
        let userRole = this.userRole as UserRoles;
        switch(userRole)
        {
            case UserRoles.Guest:
                break;
            case UserRoles.Appraiser:
                break;
            case UserRoles.AppraiserAdministrator:
                break;
            case UserRoles.DataAdministrator:
                break;
        }
    }
   
    private calculateMultipleRegressionTeTotalSaleValue() {
        let userRole = this.userRole as UserRoles;
        switch(userRole)
        {
            case UserRoles.Guest:
                break;
            case UserRoles.Appraiser:
                break;
            case UserRoles.AppraiserAdministrator:
                break;
            case UserRoles.DataAdministrator:
                break;
        }
    }

    private calculateRateDeterminationTotalSaleValue() {
        let userRole = this.userRole as UserRoles;
        switch(userRole)
        {
            case UserRoles.Guest:
                break;
            case UserRoles.Appraiser:
                break;
            case UserRoles.AppraiserAdministrator:
                break;
            case UserRoles.DataAdministrator:
                break;
        }
    }
    
    private calculateContractBaseRate() {
        let appraisalType = this.appraisalType as AppraisalTypes;
        if(appraisalType != null)
        {
            switch(appraisalType)
            {
                case AppraisalTypes.StandardRate:
                    this.calculateStandardRateContractBaseRate();
                    break;
                case AppraisalTypes.StreamlinedTe:
                    this.calculateStreamlinedTeContractBaseRate();
                    break;
                case AppraisalTypes.WeightedAverageTe:
                    this.calculateWeightedAverageTeContractBaseRate();
                    break;
                case AppraisalTypes.ResidualValue:
                    this.calculateResidualValueContractBaseRate();
                    break;
                case AppraisalTypes.MultipleRegressionTe:
                    this.calculateMultipleRegressionTeContractBaseRate();
                    break;
                case AppraisalTypes.RateDetermination:
                    this.calculateRateDeterminationContractBaseRate();
                    break;
            }
        }
       
    }

    calculateStandardRateContractBaseRate() {
        let contractBaseValue = 0.00;
        if(this.totalSaleVolume > 0){
            let contractBaseValueTotals = 0.00;
            for(const ag of this.appraisalGroups)
            {
                let minimumRate = +ag.minimumRate;
                let totalVolume = +ag.totalVolume; 
                console.log(minimumRate, totalVolume);
                let agContractBaseRate = 0.00; 
                agContractBaseRate = +totalVolume * +minimumRate;
                contractBaseValueTotals += +agContractBaseRate; 
            }
            contractBaseValue = contractBaseValueTotals / this.totalSaleVolume; 
            this.contractBaseValueSource.next(+contractBaseValue.toFixed(2))
            
        }else{
            this.contractBaseValueCoefficient = 0.00;
            this.requiredCollectionsSource.next(0.00);
            this.contractBaseValueSource.next(+contractBaseValue.toFixed(2));
        }
        this.calculateTotalSaleValue();
    }

    calculateStreamlinedTeContractBaseRate() {
        let contractBaseValue = 0.00;
        if(this.appraisalGroups.length > 0 && this.totalSaleVolume > 0){
            let appraisalGroup = this.appraisalGroups[0];
            let timberPropertyValue = this.timberPropertyValue;
            let regenerationCost = this.regenerationCost;
            let totalVolume = appraisalGroup.totalVolume as number;
            let minimumRate = appraisalGroup.minimumRate as number;
            console.log(appraisalGroup);
            console.log(timberPropertyValue, regenerationCost, totalVolume, minimumRate);
            let equation1 = (Number(timberPropertyValue) + Number(regenerationCost)) / ( Number(totalVolume));
            console.log(equation1);
            let result = +equation1 + this.nff;
            if(result > minimumRate){
                this.contractBaseValueCoefficient = result;
                this.requiredCollectionsSource.next(+result.toFixed(2));
                contractBaseValue = this.contractBaseValueCoefficient * totalVolume; 
                this.contractBaseValueSource.next(+contractBaseValue.toFixed(2));

            }else{
                this.contractBaseValueCoefficient = minimumRate;
                this.requiredCollectionsSource.next(+minimumRate.toFixed(2));
                contractBaseValue = minimumRate * totalVolume; 
                this.contractBaseValueSource.next(+contractBaseValue.toFixed(2))
            }
        }else{
            this.contractBaseValueCoefficient = 0.00;
            this.requiredCollectionsSource.next(0.00);
            this.contractBaseValueSource.next(+contractBaseValue.toFixed(2));
        }
        this.calculateTotalSaleValue();
        
        
    }

    calculateWeightedAverageTeContractBaseRate() {
        throw new Error('Method not implemented.');
    }

    calculateMultipleRegressionTeContractBaseRate() {
        throw new Error('Method not implemented.');
    }

    calculateRateDeterminationContractBaseRate() {
        throw new Error('Method not implemented.');
    }
    
    calculateResidualValueContractBaseRate() {
        throw new Error('Method not implemented.');
    }
   
     /***************************************
            PRIVATE UTILITY METHODS
    ***************************************/
    
    toFixedNoRoundUp(num: number, decimalPlaces: number): number {
        const multiplier = 100 ** decimalPlaces;
        return +(Math.floor(num * multiplier) / multiplier).toFixed(decimalPlaces);
    }
   
   
    
//#endregion 

}

