<div class="usa-width-one-whole nt-info-area">
    <div class="grid-container">
        <div class="nt-title-text">
            <h2>National TEA - Request Permissions</h2>
        </div>
        <form [formGroup]="form" >
            <div class="list-grid-wrapper">
                <div class="list-grid-container">
                    <h4 class="listbox-heading">Select Your Desired role(s)*</h4>
                    <div class="listbox">
                        <mat-selection-list [formControl]="$any(form.controls['roleId'])" (selectionChange)="onRoleSelectionChange($event);" [multiple]="false" #userRoles name="userRoles" aria-label="Roles">
                            <mat-list-option value="{{role.id}}" *ngFor="let role of roles" class="custom-radio-button">{{role.name}}</mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
                <div class="list-grid-container"> 
                    <h4 class="listbox-heading">Select Your Desired Region(s)*</h4>  
                    <div class="listbox">
                        <mat-selection-list [formControl]="$any(form.controls['regionId'])" #selectedRegionList (selectionChange)="onRegionSelectionChange($event);" aria-label="Select a Region" [multiple]="false">
                            <mat-list-option *ngFor="let region of regions" value="{{region.id}}" class="matListOption">
                                {{region.regionCode}}-{{region.regionName}}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
            </div>
            <div class="list-grid-wrapper" #forests_districts *ngIf="showDiv">
                <div class="listbox_">
                    <div class="btnselectioncontainer">
                        <button mat-button tabindex="0" class="btnSelection"(click)="accordion().openAll();selectAllCheckboxes();" aria-label="Select All">Select All</button>
                        <button mat-button tabindex="0" class="btnSelection" (click)="accordion().closeAll();deselectAllCheckboxes();" aria-label="Deselect All">Deselect All</button> 
                        <button mat-button tabindex="0" class="btnSelection" (click)="accordion().openAll();" aria-label="Expand All">Expand All</button> 
                    </div>
                    <mat-accordion multi>
                        <mat-expansion-panel *ngFor="let forest of selectedRegion?.forests; let i = index" [expanded]="expanded(i)" (opened)="opened(i, forest.id)"  (closed)="closed(i, forest.id)" aria-label="Collapsable Accordion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-checkbox 
                                        tabindex="0"  
                                        class="mat-checkbox" 
                                        #forestCheckbox 
                                        #checkbox="matCheckbox" 
                                        [forestCheckboxValue]="checkbox" 
                                        forestId="{{forest.id}}" 
                                        aria-label="Select a Forest {{forest.forestName}}"
                                        (change)="onForestChekboxChange($event, forest, i);"  
                                        (keyup)="onForestCheckboxKeyUp($event, forest, i)"
                                        (click)="stopPropagation($event, forest.id)">
                                        {{forest.forestCode}}-{{forest.forestName}}  <strong class=""> {{ currentForestStatus(forest?.id)  }}</strong>
                                    </mat-checkbox>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul>
                                <li *ngFor="let district of forest.districts">
                                    <mat-checkbox 
                                        class="mat-checkbox"  
                                        [checked]="false" 
                                        tabindex="0" 
                                        #districtCheckbox 
                                        #checkbox="matCheckbox" 
                                        [districtCheckboxValue]="checkbox"
                                        districtId="{{district.id}}" 
                                        [class]="'districtCheckbox-' + forest.forestCode" 
                                        aria-label="Select a District {{district.districtName}}" 
                                        (change)="onDistrictCheckboxChange($event, district)"
                                        (keydown)="onDistrictCheckboxKeyDown($event, district)">
                                        {{district.districtCode}}-{{district.districtName}} <strong  class=""> {{ currentDistrictStatus(district?.id) }}</strong>
                                    </mat-checkbox>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="list-button-wrapper--rightAlign">
                <div class="btn-container" style="padding-left:0rem;">
                    <button type="submit" class="usa-button usa-button--Secondary" [disabled]="form.invalid" aria-label="Submit Button" (click)="submitRequest()">Submit Request</button>
                </div>
                <div class="btn-container"></div>
            </div>
        </form>      
    </div>
     
</div>
