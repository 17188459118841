import {Directive, Input, Optional, Self} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';

@Directive({
    selector: 'mat-checkbox[districtCheckboxValue]',
    exportAs: 'districtCheckboxValue',
    standalone: false
})
export class DistrictCheckboxValueDirective {

  @Input('districtCheckboxValue') checkbox!: MatCheckbox;
  @Input() falseValue: string = '0';
  @Input() trueValue: string = '1';
  @Input() districtId: string = '';


  ngOnInit() {
    this.checkbox.value = this.districtId;
    this.checkbox.registerOnChange((checked: boolean) => {
      this.checkbox.value = this.districtId;  //checked ? this.trueValue : this.falseValue;
    })
  }
}