import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-home-user',
    templateUrl: './home-user.component.html',
    styleUrls: ['./home-user.component.scss'],
})

export class HomeUserComponent implements OnInit {

    constructor() {
        console.log("constructor not implemented.");
    }


    ngOnInit() {
        console.log("ngOnInit not implemented.");
    }

    
}