import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { TreeProductsService } from '../../../../services/tree-products/tree-products.service';
import { UomService } from '../../../../services/uom/uom.service';
import { TreeSpeciesService } from '../../../../services/tree-species/tree-species.service';
import { TreeProduct } from '../../../../models/forest-service/tree-product';
import { TreeSpecies } from '../../../../models/forest-service/tree-species';
import { Uom } from '../../../../models/forest-service/uom';
import { AppraisalGroupsService } from '../../../../services/appraisal-groups/appraisal-groups.service';
import { AppraisalGroup } from '../../../../models/appraisals/appraisal-group';
import { LoadingHandler } from '../../../../services/loading-handler';
import { ModalService } from '../../../../services/modal.service';
import { FocusService } from '../../../../services/focus.service';
import { CalculationsService } from '../../../../services/calculations/calculations.service';
import { IVisited } from '../../../../models/visitors/visited';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types';
import { TreeProductUom } from '../../../../models/forest-service/tree-product-uom';
import { UserRoles } from '../../../../models/enums/user-roles';



@Component({
    selector: 'app-volume-information',
    templateUrl: './volume-information.component.html',
    styleUrls: ['./volume-information.component.scss'],
    standalone: false
})

export class VolumeInformationComponent implements OnInit, IVisited {
    loadingHandler = new LoadingHandler()
    @Input() formGroupName!: string;
    @Output() childEvent = new EventEmitter();
    appraiserRoleIdControl!: FormControl;
    form!: FormGroup;
    modalForm!:FormGroup;
    generalInfoForm!: FormGroup;
    calculationsForm!: FormGroup;
    conditionButtonValues: any[] = [ {live: true}, {dead: false} ];
    uomButtonValues: any[] = [{CCF: 1}, {Ton: 2}, {MBF: 3} ];
    treeProducts!: TreeProduct[];
    treeSpecies!: TreeSpecies[];
    uom!: Uom[];
    appraisalZoneCodeControl!: FormControl;
    regionIdControl!: FormControl;
    forestIdControl!: FormControl;
    districtIdControl!: FormControl;
    regionCodeControl!: FormControl;
    forestCodeControl!: FormControl;
    districtCodeControl!: FormControl;

    initialAppraisalGroupIdControl!: FormControl;
    initialAppraisalGroupNameControl!: FormControl;
    initialAppraisalGroupControl!: FormControl;
    initialTreeProductIdControl!: FormControl;
    initialTreeSpeciesIdControl!: FormControl;
    initialTreeConditionControl!: FormControl;
    initialTotalVolumeControl!: FormControl;
    initialTreeSpeciesNameControl!: FormControl;
    initialTreeProductNameControl!: FormControl;
    initialUomIdControl!: FormControl;
    initialUomShortNameControl!: FormControl;
    initialAppraisalGroup!: AppraisalGroup;
    initialMinimumRateControl!: FormControl;
    initialStandardRateControl!: FormControl;

    newAppraisalGroupIdControl!: FormControl;
    newAppraisalGroupNameControl!: FormControl;
    newAppraisalGroupControl!: FormControl;
    newTreeProductIdControl!: FormControl;
    newTreeSpeciesIdControl!: FormControl;
    newTreeConditionControl!: FormControl;
    newTotalVolumeControl!: FormControl;
    newTreeSpeciesNameControl!: FormControl;
    newTreeProductNameControl!: FormControl;
    newUomIdControl!: FormControl;
    newUomShortNameControl!: FormControl;
    newAppraisalGroup!: AppraisalGroup;
    newMinimumRateControl!: FormControl;
    newStandardRateControl!: FormControl;

    @ViewChild('modal') modal!:ElementRef;
    @ViewChild('main') main!:ElementRef;
  
    appraisalGroupForms!: FormArray<FormGroup>;
    initialAppraisalGroupForm!: FormGroup;
    newAppraisalGroupForm!: FormGroup;
    chooseAppraisalTypeForm!: FormGroup;
    appraisalTypeControl!: FormControl;
    appraisalTypes = AppraisalTypes;
  
    constructor(private fb: FormBuilder,
      private rootFormGroup: FormGroupDirective,
        public modalService:ModalService,
        public focusService:FocusService,
        private treeProductsService: TreeProductsService,
        private treeSpeciesService: TreeSpeciesService,
        private uomService: UomService,
        private appraisalGroupsService: AppraisalGroupsService,
        private calculationsService: CalculationsService) {

            this.focusService.onFocus.subscribe(()=>{
                this.showFocus();
              });
          
              this.modalService.onFoucsModal.subscribe(()=>{
                this.focusModal();
              })
        
    }

    title:string = "Appraisal Groups";
    p:number = 1;

    public readProperty<T>(prop: string):T {
        let property = this[prop as keyof VolumeInformationComponent];
        if (property == null) {
           throw Error(`Property "${prop}" does not exists`);
        }
        return property as T;
     } 

    showFocus(){
        this.main.nativeElement.focus();
      }
    
      focusModal(){
        this.modal.nativeElement.focus()
      }

    ngOnInit() {
        this.appraiserRoleIdControl = this.rootFormGroup.control.get('appraiserRoleId') as FormControl;
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.generalInfoForm = this.rootFormGroup.control.get("generalInformation") as FormGroup;
        this.calculationsForm = this.rootFormGroup.control.get("contractCalculations") as FormGroup;
        this.chooseAppraisalTypeForm = this.rootFormGroup.control.get("chooseAppraisalType") as FormGroup;
        this.appraisalZoneCodeControl = this.generalInfoForm.controls["appraisalZoneCode"] as FormControl;
        this.regionIdControl = this.generalInfoForm.controls["regionId"] as FormControl;
        this.forestIdControl = this.generalInfoForm.controls["forestId"] as FormControl;
        this.districtIdControl = this.generalInfoForm.controls["districtId"] as FormControl;
        this.regionCodeControl = this.generalInfoForm.controls["regionCode"] as FormControl;
        this.forestCodeControl = this.generalInfoForm.controls["forestCode"] as FormControl;
        this.districtCodeControl = this.generalInfoForm.controls["districtCode"] as FormControl;
        this.appraisalTypeControl = this.chooseAppraisalTypeForm.controls["appraisalType"] as FormControl;
        
        this.appraisalGroupForms = this.form.controls["appraisalGroups"] as FormArray;
        this.initialAppraisalGroupForm = this.appraisalGroupForms.controls[0];
        
        this.initialAppraisalGroupIdControl = this.initialAppraisalGroupForm.controls['appraisalGroupId'] as FormControl;
        this.initialAppraisalGroupNameControl = this.initialAppraisalGroupForm.controls['appraisalGroupName'] as FormControl;
        this.initialAppraisalGroupControl = this.initialAppraisalGroupForm.controls['appraisalGroup'] as FormControl;
        this.initialTreeSpeciesNameControl = this.initialAppraisalGroupForm.controls['treeSpeciesName'] as FormControl;
        this.initialTreeProductNameControl = this.initialAppraisalGroupForm.controls['treeProductName'] as FormControl;
        this.initialTreeProductIdControl = this.initialAppraisalGroupForm.controls['treeProductId'] as FormControl;
        this.initialTreeSpeciesIdControl = this.initialAppraisalGroupForm.controls['treeSpeciesId'] as FormControl;
        this.initialTreeConditionControl = this.initialAppraisalGroupForm.controls['treeCondition'] as FormControl;
        this.initialUomIdControl = this.initialAppraisalGroupForm.controls['uomId'] as FormControl;
        this.initialUomShortNameControl = this.initialAppraisalGroupForm.controls['uomShortName'] as FormControl;
        this.initialTotalVolumeControl = this.initialAppraisalGroupForm.controls['totalVolume'] as FormControl;
        this.initialMinimumRateControl = this.initialAppraisalGroupForm.controls['minimumRate'] as FormControl;
        this.initialStandardRateControl = this.initialAppraisalGroupForm.controls['standardRate'] as FormControl;

        this.initializeNewAppraisalGroupForm();
        this.getTreeProducts();
        this.getTreeSpecies();
        this.getUom();
        this.calculationsService.visitVolumeInformationComponent(this);
       
        
    }

    initializeNewAppraisalGroupForm() {
      this.newAppraisalGroupForm = this.fb.group({
        treeCondition: ['Live', Validators.required],
        appraisalGroupId: [null],//remove required validator.
        appraisalGroupName: ['', Validators.required],
        appraisalGroup: [null, Validators.required],
        treeProductId: [null, Validators.required],
        //treeProduct: [{value: null, disabled: true}, Validators.required],
        treeSpeciesId: [null, Validators.required],
        //treeSpecies: [{value: null, disabled: true}, Validators.required],
        treeProductName: ['', Validators.required],
        treeSpeciesName: ['', Validators.required],
        uomId: [1, Validators.required],
        uomShortName: ['', Validators.required],
        totalVolume: [0, Validators.required],
        standardRate: [0.00, Validators.required],
        minimumRate: [0.00, Validators.required],
      });

      this.newAppraisalGroupIdControl = this.newAppraisalGroupForm.controls['appraisalGroupId'] as FormControl;
      this.newAppraisalGroupNameControl = this.newAppraisalGroupForm.controls['appraisalGroupName'] as FormControl;
      this.newAppraisalGroupControl = this.newAppraisalGroupForm.controls['appraisalGroup'] as FormControl;
      this.newTreeSpeciesNameControl = this.newAppraisalGroupForm.controls['treeSpeciesName'] as FormControl;
      this.newTreeProductNameControl = this.newAppraisalGroupForm.controls['treeProductName'] as FormControl;
      this.newTreeProductIdControl = this.newAppraisalGroupForm.controls['treeProductId'] as FormControl;
      this.newTreeSpeciesIdControl = this.newAppraisalGroupForm.controls['treeSpeciesId'] as FormControl;
      this.newTreeConditionControl = this.newAppraisalGroupForm.controls['treeCondition'] as FormControl;
      this.newUomIdControl = this.initialAppraisalGroupForm.controls['uomId'] as FormControl;
      this.newUomShortNameControl = this.newAppraisalGroupForm.controls['uomShortName'] as FormControl;
      this.newTotalVolumeControl = this.newAppraisalGroupForm.controls['totalVolume'] as FormControl;
      this.newMinimumRateControl = this.newAppraisalGroupForm.controls['minimumRate'] as FormControl;
      this.newStandardRateControl = this.newAppraisalGroupForm.controls['standardRate'] as FormControl;


    }

    insertAppraisalGroup(): void {
      if(this.newAppraisalGroupForm?.controls['appraisalGroupId']?.value > 0) {
        let index = this.appraisalGroupForms.length;
        //this.initialMinimumRateControl.disable();
        //this.initialStandardRateControl.disable();
        this.appraisalGroupForms.insert(index,
          this.fb.group({
            treeCondition: this.newAppraisalGroupForm.controls['treeCondition'].value,
            appraisalGroupId: this.newAppraisalGroupForm.controls['appraisalGroupId'].value,
            appraisalGroupName: this.newAppraisalGroupForm.controls['appraisalGroupName'].value,
            appraisalGroup: this.newAppraisalGroupForm.controls['appraisalGroup'].value,
            treeProductId: this.newAppraisalGroupForm.controls['treeProductId'].value,
            //treeProduct: this.newAppraisalGroupForm.controls['treeProduct'].value,
            treeSpeciesId: this.newAppraisalGroupForm.controls['treeSpeciesId'].value,
            //treeSpecies: this.newAppraisalGroupForm.controls['treeSpecies'].value,
            treeProductName: this.newAppraisalGroupForm.controls['treeProductName'].value,
            treeSpeciesName: this.newAppraisalGroupForm.controls['treeSpeciesName'].value,
            uomId: this.newAppraisalGroupForm.controls['uomId'].value,
            uomShortName: this.newAppraisalGroupForm.controls['uomShortName'].value,
            totalVolume: this.newAppraisalGroupForm.controls['totalVolume'].value,
            minimumRate: this.newAppraisalGroupForm.controls['minimumRate'].value,
            standardRate: this.newAppraisalGroupForm.controls['standardRate'].value,
          })
        );

        this.modalService.close('modal-4');
        this.initializeNewAppraisalGroupForm();
      }
     
    }
    
    deleteAppraisalGroup(index: number) {
      this.appraisalGroupForms.removeAt(index);
    }

    editAppraisalGroup(index: number) {
      throw new Error('Method not implemented.');
    }

    getTreeProducts(){
        this.loadingHandler.start()
        this.treeProductsService.getTreeProducts().subscribe({
            next: response => {
            console.log(response);
              if(response != null){
                this.treeProducts = response;
                this.loadingHandler.finish();
              }
                
            }
        })
    }

    getTreeSpecies(){
        this.treeSpeciesService.getTreeSpecies().subscribe({
            next: response => {
            console.log(response);
              if(response != null){
                this.treeSpecies = response;
              }
                
            }
        })
    }

    getUom(){
        this.uomService.uom$.subscribe({
            next: response => {
              if(response != null){
                this.uom = response;
                this.setInitialUom();
              }
                
            }
        })
    }

    get initialUom(): Uom[] | undefined {
      if(this.initialAppraisalGroup) {
        let rates = this.initialAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1);
        let uoms: Uom[] = [];
        rates.forEach(u => {
          uoms.push(u.unitOfMeasure);
        });
        return uoms;
      }
      return undefined;
    }

    get appraisalGroups(): FormArray<FormGroup> {
      return this.appraisalGroupForms;
    }

    get appraisalType(): AppraisalTypes{
      return this.appraisalTypeControl.value as AppraisalTypes;
    }

    setProductName() {
        this.initialAppraisalGroupIdControl.patchValue(null);
        this.initialAppraisalGroupNameControl.patchValue(null);
        this.initialAppraisalGroupControl.patchValue(null);
        if(this.initialAppraisalGroupForm.controls['treeProductId']?.value > 0) {
          let treeProduct = this.treeProducts?.filter(p => p.id == this.initialAppraisalGroupForm.controls['treeProductId'].value)[0];
          this.initialTreeProductIdControl.patchValue(treeProduct.id);
          this.initialTreeProductNameControl.patchValue(treeProduct.productName);
          this.setInitialAppraisalGroup(this.initialTreeProductIdControl.value, this.initialTreeSpeciesIdControl.value, this.initialTreeConditionControl.value);
        }
    }

    setSpeciesName() {
      this.initialAppraisalGroupIdControl.patchValue(null);
      this.initialAppraisalGroupNameControl.patchValue(null);
      this.initialAppraisalGroupControl.patchValue(null);
      if(this.initialAppraisalGroupForm.controls['treeSpeciesId']?.value > 0){

        let treeSpecies = this.treeSpecies?.filter(p => p.id == this.initialAppraisalGroupForm.controls['treeSpeciesId'].value)[0];
        this.initialTreeSpeciesIdControl.patchValue(treeSpecies.id);
        this.initialTreeSpeciesNameControl.patchValue(treeSpecies.commonName);
        this.setInitialAppraisalGroup(this.initialTreeProductIdControl.value, this.initialTreeSpeciesIdControl.value, this.initialTreeConditionControl.value);
      }
    }

    setNewProductName() {
      this.newAppraisalGroupIdControl.patchValue(null);
      this.newAppraisalGroupNameControl.patchValue(null);
      this.newAppraisalGroupControl.patchValue(null);
      if(this.newAppraisalGroupForm.controls['treeProductId']?.value > 0) {
        let treeProduct = this.treeProducts?.filter(p => p.id == this.newAppraisalGroupForm.controls['treeProductId'].value)[0];
        this.newTreeProductIdControl.patchValue(treeProduct.id);
        this.newTreeProductNameControl.patchValue(treeProduct.productName);
        this.setNewAppraisalGroup(this.newTreeProductIdControl.value, this.newTreeSpeciesIdControl.value, this.newTreeConditionControl.value, this.initialUomIdControl.value);
      }
     
    }

    setNewSpeciesName() {
      this.newAppraisalGroupIdControl.patchValue(null);
      this.newAppraisalGroupNameControl.patchValue(null);
      this.newAppraisalGroupControl.patchValue(null);
      if(this.newAppraisalGroupForm.controls['treeSpeciesId']?.value > 0){
        let treeSpecies = this.treeSpecies?.filter(p => p.id == this.newAppraisalGroupForm.controls['treeSpeciesId'].value)[0];
        this.newTreeSpeciesIdControl.patchValue(treeSpecies.id);
        this.newTreeSpeciesNameControl.patchValue(treeSpecies.commonName);
        this.setNewAppraisalGroup(this.newTreeProductIdControl.value, this.newTreeSpeciesIdControl.value, this.newTreeConditionControl.value, this.initialUomIdControl.value);
      }

    }

    setInitialUom() {
        let uom = this.uom?.filter(u => u.id == this.initialAppraisalGroupForm.controls['uomId'].value)[0];
        this.initialUomIdControl.patchValue(uom.id);
        this.initialUomShortNameControl.patchValue(uom.uomShortName);
        
    }

    get initialUnitOfMeasure(): string{
        return this.initialUomShortNameControl.value;
    }

    get initialTotalVolume(): FormControl {
      return this.initialTotalVolumeControl;
    }

    setInitialAppraisalGroup(treeProductId: number, treeSpeciesId: number, treeCondition: string){
        if(treeProductId && treeSpeciesId && treeCondition){
            this.appraisalGroupsService.getAppraisalGroupByZone(treeProductId, treeSpeciesId, treeCondition).subscribe({
                next: response => {
                  console.log(response);
                  if(response != null){
                    this.initialAppraisalGroup = response;
                    this.initialAppraisalGroupIdControl.patchValue(this.initialAppraisalGroup.id);
                    this.initialAppraisalGroupNameControl.patchValue(this.initialAppraisalGroup.appraisalGroupName);
                    this.initialAppraisalGroupControl.patchValue(this.initialAppraisalGroup);
                    let minRates = this.initialAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1);
                    if(minRates.length > 0 && minRates[0].rate != null && !isNaN(minRates[0].rate)) {
                      let minRate = this.initialAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1)[0];
                      this.initialMinimumRateControl.patchValue(minRate.rate);
                      this.initialUomIdControl.patchValue(minRate.unitOfMeasureId);
                      this.initialUomShortNameControl.patchValue(minRate.unitOfMeasure.uomShortName);
                    }
                    let standardRate = this.initialAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2);
                    if(standardRate.length > 0 && standardRate[0].rate != null && !isNaN(standardRate[0].rate)){
                      this.initialStandardRateControl.patchValue(this.initialAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2)[0]?.rate)
                    }
                  }
                    
                }
            })
        }        
    }

    setNewUom() {
      let uom = this.uom?.filter(u => u.id == this.newAppraisalGroupForm.controls['uomId'].value)[0];
      this.newUomIdControl.patchValue(uom.id);
      this.newUomShortNameControl.patchValue(uom.uomShortName);
      
    }

    get newUnitOfMeasure(): string{
      return this.newUomShortNameControl.value;
    }

    get newTotalVolume(): FormControl {
      return this.newTotalVolumeControl;
    }

    setNewAppraisalGroup(treeProductId: number, treeSpeciesId: number, treeCondition: string, uomId: number){
        if(treeProductId && treeSpeciesId && treeCondition && uomId > 0){
            this.appraisalGroupsService.getAppraisalGroupByZone(treeProductId, treeSpeciesId, treeCondition, uomId).subscribe({
                next: response => {
                  if(response != null){
                    console.log(uomId)
                    this.newAppraisalGroup = response;
                    this.newAppraisalGroupIdControl.patchValue(this.newAppraisalGroup.id);
                    this.newAppraisalGroupNameControl.patchValue(this.newAppraisalGroup.appraisalGroupName);
                    this.newAppraisalGroupControl.patchValue(this.newAppraisalGroup);
                    this.newUomIdControl.patchValue(uomId);
                    this.newUomShortNameControl.patchValue(this.initialUomShortNameControl.value);
                    this.newAppraisalGroupControl.patchValue(this.newAppraisalGroup);
                    let minRate = this.newAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1);
                    if(minRate.length > 0 && minRate[0].rate != null && !isNaN(minRate[0].rate))
                      this.newMinimumRateControl.patchValue(this.newAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1)[0]?.rate);
                    let standardRate = this.newAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2);
                    if(standardRate.length > 0 && standardRate[0].rate != null && !isNaN(standardRate[0].rate))
                      this.newStandardRateControl.patchValue(this.newAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2)[0]?.rate)
                    
                  }
                    
                }
            })
        }        
    }

    get appraiserRoleId(): number {
      return this.appraiserRoleIdControl.value;
    }

    get checkDisabled(): boolean {
      return this.appraisalType === this.appraisalTypes.StreamlinedTe 
          || !this.initialAppraisalGroupForm.valid 
          || this.initialTotalVolumeControl.value <= 0 
          || (this.appraiserRoleId == UserRoles.Guest && this.appraisalGroups.length == 5)
    }

    

    continue(event: any){
        event.preventDefault();

        let element: HTMLElement = document.getElementById("panel-3-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth',block:'start'});
       
        
        
    }

    
}