import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppraisalZonesService } from '../../../../services/appraisal-zones/appraisal-zones.service';
import { AppraisalGroupRatesService } from '../../../../services/appraisal-group-rates/appraisal-group-rates.service';
import { Region } from '../../../../models/region';
import { AppraisalGroupRates } from '../../../../models/appraisal-group-rates';
import { environment } from '../../../../../environments/environment';
import { RegionsService } from '../../../../services/regions/regions.service';
import { AppraisalGroupsService } from '../../../../services/appraisal-groups/appraisal-groups.service';
import { Forest } from '../../../../models/forest';
import { District } from '../../../../models/district';
import { UomService } from '../../../../services/uom/uom.service';
import { Uom } from '../../../../models/uom';
import { LoadingHandler } from '../../../../services/loading-handler';
import { AppraisalZone } from '../../../../models/appraisal-zone';

@Component({
  selector: 'app-standard-rates',
  templateUrl: './standard-rates.component.html',
  styleUrls: ['./standard-rates.component.scss']
})
export class StandardRatesComponent {
  isLoading = true;

  pageNumber: number = 1;
  form!: FormGroup;
  isEditableNew: boolean = true;

  baseUrl = environment.apiUrl;
  regions: Region[] = [];
  forests!: Forest[] | null;
  districts!: District[] | null;
  appraisalZones: AppraisalZone[] = [];
  appraisalGroupRates: AppraisalGroupRates[] = [];
  regionId: number = 0;
  forestId: number = 0;
  districtId: number = 0;
  rowsForm!: FormArray | null;
  appraisalZoneForm!: FormGroup;
  appraisalZoneCode!: string | null;
  uom!: Uom[];
  maxValue = "10000";
  error:any;



  loadingHandler = new LoadingHandler();
  constructor(private fb: FormBuilder, 
    private http: HttpClient,  
    private appraisalZonesService: AppraisalZonesService,
    private regionsService: RegionsService,
    private uomService: UomService,
    private appraisalGroupsService: AppraisalGroupsService,
    private appraisalGroupRatesService: AppraisalGroupRatesService) {

  }

  ngOnInit(): void {
    this.getUom();
    this.loadAppraisalZones();
    this.setRegions();

    if(this.appraisalGroupRates != null){
      this.form = this.fb.group({
        appraisalZoneForm: this.fb.group({    
          regionCode: [null, Validators.required],
          forestCode: [null, Validators.required],
          districtCode: [null, Validators.required],
          appraisalZoneCode: [{ value: null, disabled: true}, Validators.required],
          region: [{value: null, disabled: true}, Validators.required],
          forest: [{value: null, disabled: true}, Validators.required],
          district: [{value: null, disabled: true}, Validators.required],
          appraisalZone: [{value: null, disabled: true}, Validators.required],
          uomId: [1, Validators.required],
          uomShortName: ['CCF', Validators.required],
          treeCondition: ['Live', Validators.required],
        }),
        rows: this.fb.array(this.appraisalGroupRates.map(val => this.fb.group({
          id: new FormControl(val.id),
          product: new FormControl(val.appraisalGroup.treeProduct.productName),
          species: new FormControl(val.appraisalGroup.appraisalGroupName),
          condition: new FormControl(val.appraisalGroup.treeCondition),
          appraisalZoneCode: new FormControl(val.appraisalGroup.appraisalZoneCode),
          appraisalGroupName: new FormControl(val.appraisalGroup.appraisalGroupName),
          uomShortName: new FormControl(val.unitOfMeasure.uomShortName),
          rate: new FormControl(val.rate),
          insertedBy: val.insertedBy.username,
          insertedDate: new Date(val.insertedWhen_UTC),
          updatedBy: val.insertedBy.username,
          updatedDate: new Date(val.updatedWhen_UTC),
          action: new FormControl('existingRecord'),
          isEditable: new FormControl(false),
          isNewRow: new FormControl(false),
        })
        ))
      }); 

      
      
      this.appraisalZoneForm = this.form.controls['appraisalZoneForm'] as FormGroup;
      this.rowsForm = this.form.controls['rows'] as FormArray;
    }
    

  }

  applyFilter() {
    let uomId = this.appraisalZoneForm.controls['uomId'].value;
    let treeCondition = this.appraisalZoneForm.controls['treeCondition'].value; 
    if(this.appraisalGroupRates != null){
      let filteredRates = this.appraisalGroupRates.filter(agr => 
        agr.unitOfMeasureId == uomId 
        && agr.appraisalGroup.treeCondition == treeCondition);
      this.setRows(filteredRates);
    }
  }

  setRegions() {
    const regionsString = localStorage.getItem('regions');
    if (!regionsString){
        this.loadRegions();
    } else {
        const regions: Region[] = JSON.parse(regionsString);
        this.regions = regions;
        this.regionsService.setRegions(regions);
    }
  }

  setForests() {
      let r = this.regions?.filter(r => r.regionCode == this.appraisalZoneForm.controls['regionCode'].value);
      if(r != null){
          this.appraisalZoneForm.controls['region'].patchValue(r[0]);
          this.forests = r[0].forests.sort(
              (a, b) => a.forestCode > b.forestCode ? 1 : -1);
          this.districts = null;
      }
      this.appraisalZoneForm.controls['forestCode'].patchValue(null);
      this.appraisalZoneForm.controls['districtCode'].patchValue(null);
      this.appraisalZoneForm?.controls['appraisalZoneCode'].patchValue(null);
      this.appraisalZoneCode = null;
  }

  setDistricts() {
      let f = this.forests?.filter(f => f.forestCode == this.appraisalZoneForm.controls['forestCode'].value);
      if(f != null){
          this.appraisalZoneForm.controls['forest'].patchValue(f[0]);
          this.districts = f[0].districts.sort(
              (a, b) => a.districtCode > b.districtCode ? 1 : -1);
      }     
      this.appraisalZoneForm.controls['districtCode'].patchValue(null);
      this.appraisalZoneForm.controls['appraisalZoneCode'].patchValue(null); 
      this.appraisalZoneCode = null;
  }

  getAppraisalGroupRates(appraisalZoneCode: string, appraisalRateTypeId: number) {
    this.appraisalGroupRatesService.getAppraisalGroupRatesByZone(appraisalZoneCode, appraisalRateTypeId).subscribe({
        next: response => {
          if(response != null){
            this.appraisalGroupRates = response;
            this.appraisalGroupRatesService.setAppraisalGroupRates(this.appraisalGroupRates);
            let filteredRates = this.appraisalGroupRates.filter(agr => 
              agr.unitOfMeasureId == this.appraisalZoneForm.controls['uomId'].value 
              && agr.appraisalGroup.treeCondition == this.appraisalZoneForm.controls['treeCondition'].value );
            this.setRows(filteredRates);

          }
            
        }
    })
  
  }

  getUom(){
    this.uomService.uom$.subscribe({
        next: response => {
          if(response != null){
            this.uom = response;
          }
            
        }
    })
  }
  
  setUom() {
    let uom = this.uom?.filter(u => u.id == this.form.controls['uomId'].value)[0];
    this.appraisalZoneForm.controls['uomShortName'].patchValue(uom.uomCode);
    
  }

  loadAppraisalZones() {
    this.appraisalZonesService.appraisalZones$.subscribe({
      next: response => {
          if (response) {
            this.appraisalZones = response;
          }
      }
    })
  }

  loadRegions() {
    this.loadingHandler.start();
    this.regionsService.regions$.subscribe({
      next: response => {
          if (response) {
            this.regions = response;
            this.loadingHandler.finish()
          }
      }
  })
  }

  setAppraisalZone() {
    
    let district = this.districts?.filter(d => d.districtCode == this.appraisalZoneForm.controls["districtCode"].value)[0];   
    let appraisalZone = this.appraisalZones?.filter(a => a.districts.some(d => d.id == district?.id))[0];
    if(appraisalZone){
      this.appraisalZoneCode = appraisalZone.appraisalZoneCode;
      this.appraisalZoneForm.controls['appraisalZoneCode'].patchValue(appraisalZone.appraisalZoneCode);
      this.getAppraisalGroupRates(this.appraisalZoneCode, 2);
     
    }

  }

  

  setRows(rates: AppraisalGroupRates[]){
    if(rates != null){
      console.log(rates);
      let controls = this.fb.array(rates.map(val => this.fb.group({
        id: new FormControl(val.id),
        product: new FormControl(val.appraisalGroup.treeProduct.productName),
        species: new FormControl(val.appraisalGroup.appraisalGroupName),
        condition: new FormControl(val.appraisalGroup.treeCondition),
        appraisalZoneCode: new FormControl(val.appraisalGroup.appraisalZoneCode),
        appraisalGroupName: new FormControl(val.appraisalGroup.appraisalGroupName),
        uomShortName: new FormControl(val.unitOfMeasure.uomShortName),
        rate: new FormControl(val.rate),
        insertedBy: val.insertedBy.username,
        insertedDate: new Date(val.insertedWhen_UTC),
        updatedBy: val.updatedBy?.username,
        updatedDate: val.updatedWhen_UTC ? new Date(val.updatedWhen_UTC) : null,
        action: new FormControl('existingRecord'),
        isEditable: new FormControl(false),
        isNewRow: new FormControl(false),
      })));
      console.log(controls);
      this.form.setControl('rows', controls);
      this.rowsForm = this.form.controls['rows'] as FormArray;
    } 
  }
 
  

  refreshRows() {
    let uomId = this.appraisalZoneForm.controls['uomId'].value;
    let treeCondition = this.appraisalZoneForm.controls['treeCondition'].value; 
    this.appraisalGroupRatesService.getAppraisalGroupRates().subscribe({
      next: response => {
        if(response != null){
          this.appraisalGroupRates = response;
          let filteredRates = this.appraisalGroupRates.filter(agr => 
            agr.unitOfMeasureId == uomId 
            && agr.appraisalGroup.treeCondition == treeCondition);
          this.setRows(filteredRates);
          
        }
      }
    })
    
    
  }

  editRate(id: number) {
   let rate = this.rowsForm?.controls.filter(r => r.get('id')?.value == id)[0] as FormGroup;
   rate?.controls['isEditable'].patchValue(true);
  }

  // inputMax(){
  //   let rateControl = this.rowsForm?.controls.filter(r => r.get('id')?.setValue(this.maxValue));
  //   return rateControl;
  // }

  getRateControl(id: number) : FormControl{
    let rateControl = this.rowsForm?.controls.filter(r => r.get('id')?.value == id)[0].get('rate') as FormControl;
      if(rateControl.value > this.maxValue){
         rateControl.setValue(this.maxValue)
      }
    return rateControl;
  }
  
  saveRate(id: number) {
    let originalRate = this.appraisalGroupRates?.filter(r => r.id == id)[0];
    
    let rate = this.rowsForm?.controls.filter(r => r.get('id')?.value == id)[0] as FormGroup;
    let standardRate = rate?.controls['rate'].value;
    if(isNaN(standardRate) || standardRate === null){
      this.error = 'Field is required.';
      return
    }else{
      this.error = '';
      
    }
    originalRate.rate = standardRate;

    this.appraisalGroupRatesService.updateAppraisalGroupRate(originalRate).subscribe({
      next: response => {
        this.refreshRows();
      }
    });
  }

  cancelSaveRate(id: number) {
    let originalRate = this.appraisalGroupRates?.filter(r => r.id == id)[0];
    let rate = this.rowsForm?.controls.filter(r => r.get('id')?.value == id)[0] as FormGroup;
    rate?.controls['rate'].patchValue(originalRate.rate);
    rate?.controls['isEditable'].patchValue(false);
  }

  // applyFilter(target: EventTarget | null) {
  //   var filterValue = target.
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
  //   this.dataSource.filter = filterValue;
  // }
}


