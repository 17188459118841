import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Region } from '../../../../models/region';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Forest } from '../../../../models/forest';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { RegionsService } from '../../../../services/regions/regions.service';
import { District } from '../../../../models/district';
import { UsersService } from '../../../../services/users/users.service';
import { HandlecheckboxesService } from '../../../../services/handlecheckboxes.service';
import { AppraisalZonesService } from '../../../../services/appraisal-zones/appraisal-zones.service';
import { AppraisalGroupsService } from '../../../../services/appraisal-groups/appraisal-groups.service';
import { AppraisalGroup } from '../../../../models/appraisal-group';
import { TooltipPosition } from '@angular/material/tooltip';
import { LoadingHandler } from '../../../../services/loading-handler';
import { AppraisalZone } from '../../../../models/appraisal-zone';

@Component({
    selector: 'app-general-information',
    templateUrl: './general-information.component.html',
    styleUrls: ['./general-information.component.scss'],
    viewProviders: [
    // {
    //     provide: ControlContainer,
    //     useExisting: FormGroupDirective,
    // }
    ],
})

export class GeneralInformationComponent implements OnInit {
    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    values:any;
    baseUrl = environment.apiUrl;
    form!: FormGroup;
    volumeInfoForm!: FormGroup;
    isLoading = false;
    
    regions: Region[] = [];
    forests!: Forest[] | null;
    districts!: District[] | null;
    appraisalZones: AppraisalZone[] = [];
    appraisalGroup!: AppraisalGroup;
    maxDate: Date = new Date();
    

    positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
    position = this.positionOptions[0];
    appraisalZoneCodeControl!: FormControl;
    regionCodeControl!: FormControl;
    forestCodeControl!: FormControl;
    districtCodeControl!: FormControl;
    regionNameControl!: FormControl;
    forestNameControl!: FormControl;
    districtNameControl!: FormControl;
    appraisalGroupIdControl!: FormControl;
    appraisalGroupNameControl!: FormControl;
    treeProductIdControl!: FormControl;
    treeSpeciesIdControl!: FormControl;
    treeConditionControl!: FormControl;
    saleTypes!: FormArray<FormControl>;
    
    loadingHandler = new LoadingHandler()
    constructor(private fb: FormBuilder, 
        private rootFormGroup: FormGroupDirective,
        private http: HttpClient, 
        public usersService: UsersService,  
        private AppraisalZonesService: AppraisalZonesService,
        private checkboxHandler: HandlecheckboxesService,
        private regionsService: RegionsService,
        private appraisalGroupsService: AppraisalGroupsService) {

            
    }
  

    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.volumeInfoForm = this.rootFormGroup.control.get("volumeInformation") as FormGroup;
        this.regionCodeControl = this.form.controls['regionCode'] as FormControl;
        this.forestCodeControl = this.form.controls['forestCode'] as FormControl;
        this.districtCodeControl = this.form.controls['districtCode'] as FormControl;
        this.appraisalZoneCodeControl = this.form.controls['appraisalZoneCode'] as FormControl;
        this.regionNameControl = this.form.controls['regionName'] as FormControl;
        this.forestNameControl = this.form.controls['forestName'] as FormControl;
        this.districtNameControl = this.form.controls['districtName'] as FormControl;
        this.appraisalGroupIdControl = this.volumeInfoForm.controls['appraisalGroupId'] as FormControl;
        this.appraisalGroupNameControl = this.volumeInfoForm.controls['appraisalGroupName'] as FormControl;
        this.treeProductIdControl = this.volumeInfoForm.controls['treeProductId'] as FormControl;
        this.treeSpeciesIdControl = this.volumeInfoForm.controls['treeSpeciesId'] as FormControl;
        this.treeConditionControl = this.volumeInfoForm.controls['treeCondition'] as FormControl;
        this.saleTypes = this.form.controls['saleTypes'] as FormArray;
        console.log(this.form);

        this.loadRegions();
        this.loadAppraisalZones();
       
    }

    setForests() {
        this.forestCodeControl.patchValue(null);
        this.districtCodeControl.patchValue(null);
        this.appraisalZoneCodeControl.patchValue(null);
        this.appraisalGroupIdControl.patchValue(null);
        this.appraisalGroupNameControl.patchValue(null);

        let r = this.regions?.filter(r => r.regionCode == this.regionCodeControl.value)[0];
        if(r != null){
            this.regionNameControl.patchValue(r.regionName);
            this.forests = r.forests.sort(
                (a, b) => a.forestCode > b.forestCode ? 1 : -1);
            this.districts = null;
        }

        this.setAppraisalGroup(this.treeProductIdControl.value, this.treeSpeciesIdControl.value, this.treeConditionControl.value);
    }

    setDistricts() {
        this.districtCodeControl.patchValue(null);
        this.appraisalZoneCodeControl.patchValue(null); 

        let f = this.forests?.filter(f => f.forestCode == this.form.controls['forestCode'].value)[0];
        if(f != null){
            this.forestNameControl.patchValue(f.forestName);
            console.log(f);
    
            this.districts = f.districts.sort(
                (a, b) => a.districtCode > b.districtCode ? 1 : -1);
        }  
        
        this.setAppraisalGroup(this.treeProductIdControl.value, this.treeSpeciesIdControl.value, this.treeConditionControl.value);  
    }

    
    checkAppraisalGroup() {
        throw new Error('Method not implemented.');
    }

    setAppraisalZone() {
        let district = this.districts?.filter(d => d.districtCode == this.districtCodeControl.value)[0];
        this.districtNameControl.patchValue(district?.districtName);  
        let appraisalZone = this.appraisalZones?.filter(a => a.districts.some(d => d.id == district?.id))[0];
        if(appraisalZone)
            this.appraisalZoneCodeControl.patchValue(appraisalZone.appraisalZoneCode);
        
        this.setAppraisalGroup(this.treeProductIdControl.value, this.treeSpeciesIdControl.value, this.treeConditionControl.value); 

    }

    setAppraisalGroup(treeProductId: number, treeSpeciesId: number, treeCondition: string){
        if(treeProductId && treeSpeciesId && treeCondition){
            console.log('get ag test');
            this.appraisalGroupsService.getAppraisalGroupByZone(treeProductId, treeSpeciesId, treeCondition).subscribe({
                next: response => {
                  if(response != null){
                    console.log(response);
                    this.appraisalGroup = response;
                    this.appraisalGroupIdControl.patchValue(this.appraisalGroup.id);
                    this.appraisalGroupNameControl.patchValue(this.appraisalGroup.appraisalGroupName);
                  }
                    
                }
            })
        }        
    }


    loadAppraisalZones() {
        this.AppraisalZonesService.appraisalZones$.subscribe({
            next: response => {
                if (response) {
                    this.appraisalZones = response;
                    console.log(this.appraisalZones);
                }
            }
        })
    }
    
    loadRegions() {
        this.regionsService.regions$.subscribe({
            next: response => {
                if (response) {
                    this.regions = response;
                }
            }
        })
    }

    continue(event: any){
      
        event.preventDefault();
        let element: HTMLElement = document.getElementById("panel-2-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth',block:'start'});
    }

    setSalesTypes($event: any, index: number){
        let control = this.saleTypes.controls[index];
        if(!control.touched){
            control.patchValue(true);
            control.markAsTouched();
            console.log(control);
        }else{
            control.patchValue(!control.value);
        }
        console.log(this.saleTypes);

    }

    

}

