<div class="usa-width-one-whole nt-info-area">
    <div class="grid-container">
        <h2>High Charts Demo</h2>
        <div class="grid-row">
            <div class="tablet:grid-col">
                <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
            </div>
        </div>
        <div class="grid-row">
            <div class="tablet:grid-col">
                <highcharts-chart
                [Highcharts]="Highcharts"

                [constructorType]="chartConstructor"
                [options]="chartOptions"
                [callbackFunction]="chartCallback"

                [(update)]="updateFlag"
                [oneToOne]="oneToOneFlag"
                [runOutsideAngular]="runOutsideAngular"

                style="width: 100%; height: 400px; display: block;"
            ></highcharts-chart>
            </div>
        </div>
    </div>
</div>

