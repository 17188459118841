import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { CalculationsService } from '../../../services/calculations/calculations.service';
import { DiscountService } from '../../../services/discount.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-estimator',
    templateUrl: './estimator.component.html',
    styleUrl: './estimator.component.scss',
    standalone: false
})
export class EstimatorComponent implements OnInit {
  @Input() formGroupName!: string
  @Output() childEvent = new EventEmitter();
  form!: FormGroup;
  volumeInformation!: FormGroup;
  totalSaleValueControl!: FormControl;
  contractBaseValueControl!: FormControl;
  appraisalRatePerUomControl!: FormControl;
  appraisalGroupForms!: FormArray<FormGroup>;
  initialAppraisalGroupForm!: FormGroup;
  initialUomShortNameControl!: FormControl;
  estimatorValue:any;
  
  total = 100;
  discount = 0;
  discountedTotal = 0

  private discountSubscription: Subscription;
  


  constructor(private rootFormGroup: FormGroupDirective,
      private calculationsService: CalculationsService, private discountService:DiscountService) {
        this.discountSubscription = this.discountService.currentDiscount.subscribe((discount) =>{
        this.discount = discount / 100;
        this.calculatedDiscountedTotal() 
          
        })
  }
 


  ngOnInit() {
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
      this.volumeInformation = this.rootFormGroup.control.get("volumeInformation") as FormGroup;
      this.appraisalGroupForms = this.volumeInformation.controls["appraisalGroups"] as FormArray;
      this.initialAppraisalGroupForm = this.appraisalGroupForms.controls[0];
      this.initialUomShortNameControl = this.initialAppraisalGroupForm.controls['uomShortName'] as FormControl;
      
      this.totalSaleValueControl = this.form.controls['totalSaleValue'] as FormControl;
      this.contractBaseValueControl = this.form.controls['contractBaseValue'] as FormControl;
      this.appraisalRatePerUomControl = this.form.controls['appraisalRatePerUom'] as FormControl;

      
      this.initializeEstimator();  
      this.calculatedDiscountedTotal()
  }


  initializeEstimator(){
    this.calculationsService.totalSaleValue$.subscribe({
        next: value => {
              if(value != null){
                this.totalSaleValueControl.patchValue(value);
              } 
          }
    });

    this.calculationsService.contractBaseValue$.subscribe({
      next: value => {
            if(value != null){
              this.contractBaseValueControl.patchValue(value);
            } 
        }
    });

    this.calculationsService.appraisalRatePerUom$.subscribe({
      next: value => {
            if(value != null){
              this.appraisalRatePerUomControl.patchValue(value);
            } 
        }
    });
  }

  calculatedDiscountedTotal() {

    this.calculationsService.totalSaleValue$.subscribe({
      next: value => {
            if(value != null && this.totalSaleValueControl != null){
              this.totalSaleValueControl.patchValue(value);
              this.discountedTotal =  (this.totalSaleValue * this.discount);
            } 
        }
  });
    
    //this.discountedTotal = this.total - (this.total * (this.discount / 100));
  }
  
  // calculateDiscount(discount:number):number{
    
  // }

 /* implementDiscount(){
    this.calculationsService.totalSaleValue$.subscribe({
      next: value => {
            if(value != null){
              this.totalSaleValueControl.patchValue(value);
              this.discountAmount = (this.totalSaleValue * 10) /100
              this.discountPrice = (this.totalSaleValue - this.discountAmount)
              
              console.log("TOTAL SALE VALUE",this.totalSaleValue)
            } 
        }
  });
  }*/

  get uom(){
    return this.initialUomShortNameControl.value;
  }

  get totalSaleValue(): number{
    return this.totalSaleValueControl.value;
  }

  get contractBaseValue(){
    return this.contractBaseValueControl.value;
  }
  
  get appraisalRatePerUom(){
    return this.appraisalRatePerUomControl.value;
  }

  
 
}
