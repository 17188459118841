<!--<div class="usa-width-one-whole nt-info-area">
    <div class="nt-title-text">
        <h2>National TEA - View Data</h2>
    </div>
    <div class="grid-container">
        <div class="grid-row">
            <div class="grid-col-8">
            </div>
            <div class="grid-col-4">
            </div>
        </div>
    </div>
</div>-->

<div class="usa-width-one-whole nt-info-area">
    <div class="grid-container">
        <div class="admin-panel-header">
            <!--<h2 *appHasNoRole="['Data Administrator']">Appraiser Admin Panel</h2>
            <h2 *appHasRole="['Data Administrator']">Data Admin Panel</h2>-->
            <h2>All View Data Options</h2>
        </div>
        <div class="grid-row admin-panel-row">
            <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Appraiser Administrator','Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/admin/appraisal-zones" tabindex="0" aria-label="Appraisal Zone Data">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-appraisal-zone-data.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Appraisal Zone Data</p>
                    </div>
                </button>
            </div>
            <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Appraiser Administrator','Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/view-data/base-data-in-zone" tabindex="0" aria-label="Base Data in Zone">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-base-data-zone.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Base Data in Zone</p>
                    </div>
                </button>   
            </div>
            <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/view-data/sale-appraisal" tabindex="0" aria-label="Sale Appraisal">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-sale-appraisal.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Sale Appraisal</p>
                        <div class="view-data--caption">(Individual)</div>
                    </div>
                </button>
            </div>
            <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/view-data/bidding-records" tabindex="0" aria-label="Bidding Records">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-bidding-records.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Bidding Records</p>
                    </div>
                </button>   
            </div>
           
        </div>


        <div class="grid-row admin-panel-row">
             <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/view-data/wwpa-indices" tabindex="0" aria-label="WWPA Indices">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-wwpa-indices.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>WWPA Indices</p>
                        <div class="view-data--caption">(Current/Past)</div>
                    </div>
                </button>   
            </div>

            <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/view-data/sales-in-base-data/" tabindex="0" aria-label="Sales in Base Data">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-base-data-sales.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Sales in Base Data</p>
                    </div>
                </button>
            </div>
            <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/view-data/standard-rates-in-effect" tabindex="0" aria-label="Standard Rates">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-standard-rates.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Standard Rates</p>
                        <div class="view-data--caption">(In Effect)</div>
                    </div>
                </button>   
            </div>
            <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/view-data/display-products" tabindex="0" aria-label="Display Product">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/view-data/view-data-display-product.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Display Product</p>
                        <div class="view-data--caption">(Matrix Table)</div>
                    </div>
                </button>   
            </div>
            <!-- <div class="grid-col-3 admin-panel-col-3" *appHasRole="['Data Administrator']">
                <button type="button" class="admin-panel-btn" routerLink="/admin/products" tabindex="0">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-products.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Products</p>
                    </div>
                </button>   
            </div> -->
        </div>
    </div>
</div>


