<div class="usa-width-one-whole nt-info-area">
    <div class="grid-container">
        <div class="grid-row">
            <div class="grid-col-8">
            </div>
            <div class="grid-col-4">
                <button type="button" class="usa-button usa-button--outline btn-green-outline" (click)="requestPermissions()">Request additional roles/permissions</button>
            </div>
        </div>
    </div>
</div>
