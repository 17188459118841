!
/**
* Highcharts Dashboards Layout 2.3.0 (2024-08-26)
*
* (c) 2009-2024 Highsoft AS
*
* License: www.highcharts.com/license
*/
function (t) {
  "object" == typeof module && module.exports ? (t.default = t, module.exports = t) : "function" == typeof define && define.amd ? define("dashboards/modules/layout", ["dashboards"], function (e) {
    return t(e), t.Dashboards = e, t;
  }) : t("undefined" != typeof Dashboards ? Dashboards : void 0);
}(function (t) {
  "use strict";

  var e = t ? t._modules : {};
  function s(e, s, i, o) {
    e.hasOwnProperty(s) || (e[s] = o.apply(null, i), "function" == typeof CustomEvent && t.win.dispatchEvent(new CustomEvent("DashboardsModuleLoaded", {
      detail: {
        path: s,
        module: e[s]
      }
    })));
  }
  s(e, "Dashboards/EditMode/EditRenderer.js", [e["Dashboards/EditMode/EditGlobals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s,
      createElement: i,
      defined: o
    } = e;
    function n(e, s) {
      let o;
      let n = s.options.contextMenu;
      return n && (o = i("button", {
        className: t.classNames.contextMenuBtn,
        onclick: function (t) {
          t.stopPropagation(), s.onContextBtnClick();
        }
      }, {}, e), n.icon && i("img", {
        src: n.icon,
        className: t.classNames.icon
      }, {}, o), n.text && i("span", {
        className: t.classNames.contextMenuBtnText,
        textContent: n.text
      }, {}, o), o.setAttribute("aria-label", s.lang.accessibility.contextMenu.button), o.setAttribute("aria-expanded", "false")), o;
    }
    function l(e, s) {
      let o;
      let {
          name: n,
          showToggle: l,
          onchange: a,
          isEnabled: d,
          isNested: c,
          isStandalone: h,
          lang: p
        } = s,
        u = i("div", {
          className: t.classNames[c ? "accordionNestedWrapper" : "accordionContainer"] + " " + t.classNames.collapsableContentHeader
        }, {}, e),
        m = i("div", {
          className: t.classNames.accordionHeader
        }, {}, u);
      h || (o = i("button", {
        className: t.classNames.accordionHeaderBtn
      }, {}, m)), i("span", {
        textContent: p[n] || n
      }, {}, o), l && r(m, {
        enabledOnOffLabels: !0,
        id: n,
        name: "",
        onchange: a,
        value: d || !1,
        lang: p
      });
      let b = i("span", {
          className: t.classNames.accordionHeaderIcon + " " + t.classNames.collapsedElement
        }, {}, o),
        g = i("div", {
          className: t.classNames.accordionContent + " " + (h ? t.classNames.standaloneElement : t.classNames.hiddenElement)
        }, {}, u);
      return o?.addEventListener("click", function () {
        g.classList.toggle(t.classNames.hiddenElement), b.classList.toggle(t.classNames.collapsedElement);
      }), {
        outerElement: u,
        content: g
      };
    }
    function a(o, n) {
      let l;
      if (!o) return;
      n.name && d(o, {
        title: n.name,
        isLabel: !0
      });
      let a = n.iconsURLPrefix || "",
        r = i("div", {
          className: t.classNames.dropdown + " " + t.classNames.collapsableContentHeader
        }, {}, o),
        c = i("button", {
          className: t.classNames.dropdownButton
        }, {}, r),
        h = i("div", {
          className: t.classNames.dropdownButtonContent
        }, {}, c),
        p = (e.find(n.selectOptions, t => t.name === n.value) || {}).iconURL;
      p && (l = i("img", {
        src: a + p,
        className: t.classNames.icon
      }, {}, h));
      let u = i("span", {
          textContent: n.value,
          id: n.id || ""
        }, {}, h),
        m = i("img", {
          className: t.classNames.dropdownIcon + " " + t.classNames.collapsedElement,
          src: a + "dropdown-pointer.svg"
        }, {}, c),
        b = i("ul", {
          className: t.classNames.dropdownContent + " " + t.classNames.hiddenElement
        }, {}, r);
      c.addEventListener("click", function () {
        b.classList.toggle(t.classNames.hiddenElement), m.classList.toggle(t.classNames.collapsedElement);
      });
      for (let e = 0, o = n.selectOptions.length; e < o; ++e) (function (e, s, o, n, l, a, r) {
        let d = e.iconsURLPrefix + e.iconURL,
          c = i("li", {}, {}, s),
          h = i("button", {
            className: t.classNames.customSelectButton
          }, {}, c);
        e.iconURL && i("img", {
          src: d
        }, {}, h), i("span", {
          textContent: e.name || ""
        }, {}, h), h.addEventListener("click", function () {
          if (s.classList.add(t.classNames.hiddenElement), l.classList.toggle(t.classNames.collapsedElement), o.textContent = e.name || "", a && e.iconURL && (a.src = d), r) return r(e.name);
        });
      })(s(n.selectOptions[e] || {}, {
        iconsURLPrefix: a
      }), b, u, n.id, m, l, n.onchange);
      return r;
    }
    function r(e, s) {
      if (!e) return;
      let o = s.lang,
        n = s.value,
        l = s.title || s.name,
        a = s.langKey,
        r = i("button", {
          className: t.classNames.toggleContainer,
          type: "button",
          role: "switch",
          ariaChecked: !1,
          ariaLabel: a ? o.accessibility[a][s.name] : ""
        }, {}, e);
      l && d(r, {
        title: l
      }), s.enabledOnOffLabels && d(r, {
        title: o.off,
        className: t.classNames.toggleLabels
      });
      let c = i("label", {
          className: t.classNames.toggleWrapper + " " + (s.className || "")
        }, {}, r),
        h = u(c, n),
        p = s.onchange;
      p && r.addEventListener("click", t => {
        p(!h.checked), h.checked = !h.checked, r.setAttribute("aria-checked", h.checked), t.stopPropagation();
      });
      let m = i("span", {
        className: t.classNames.toggleSlider
      }, {}, c);
      return p && m.addEventListener("click", t => {
        t.preventDefault();
      }), s.enabledOnOffLabels && d(r, {
        title: o.on,
        className: t.classNames.toggleLabels
      }), r;
    }
    function d(e, s) {
      let o;
      let {
        title: n,
        className: l,
        isLabel: a
      } = s;
      if (e) {
        let s = a ? {
          htmlFor: n
        } : {};
        o = i(a ? "label" : "div", {
          className: t.classNames.labelText + " " + (l || ""),
          textContent: n,
          ...s
        }, {}, e);
      }
      return o;
    }
    function c(t, e) {
      let {
        icon: s,
        callback: o
      } = e;
      if (!t) return;
      let n = i("div", {
        onclick: o,
        className: e.className || ""
      }, {}, t);
      n.style["background-image"] = "url(" + s + ")";
      let l = e.mousedown,
        a = e.click;
      return l && (n.onmousedown = function () {
        l.apply(this, arguments);
      }), a && n.addEventListener("click", function () {
        a.apply(this, arguments);
      }), n;
    }
    function h(t, e) {
      if (!t) return;
      e.name && d(t, {
        title: e.name,
        isLabel: !0
      });
      let s = i("input", {
          type: "text",
          onclick: e.callback,
          id: e.id || "",
          name: e.name || "",
          value: o(e.value) && e.value.toString().replace(/\"/g, "") || ""
        }, {}, t),
        n = e.onchange;
      return n && s.addEventListener("change", function (t) {
        n(t.target.value);
      }), s;
    }
    function p(t, e) {
      if (!t) return;
      e.name && d(t, {
        title: e.name,
        isLabel: !0
      });
      let s = i("textarea", {
          id: e.id,
          name: e.name,
          value: e.value || ""
        }, {}, t),
        o = e.onchange;
      return o && s.addEventListener("change", function (t) {
        o(t.target.value);
      }), s;
    }
    function u(t, e) {
      let s;
      return t && (s = i("input", {
        type: "checkbox",
        checked: !!e
      }, {}, t)), s;
    }
    function m(e, s) {
      if (!e) return;
      let o = i("button", {
        className: t.classNames.button + " " + (s.className || ""),
        onclick: s.callback,
        textContent: s.text
      }, s.style || {}, e);
      return s.icon && (o.style["background-image"] = "url(" + s.icon + ")"), o;
    }
    return {
      renderSelect: a,
      renderToggle: r,
      renderText: d,
      renderCollapseHeader: l,
      renderIcon: c,
      renderContextButton: n,
      renderInput: h,
      renderTextarea: p,
      renderCheckbox: u,
      renderButton: m,
      getRendererFunction: function (t) {
        return {
          select: a,
          toggle: r,
          text: d,
          collapse: l,
          icon: c,
          contextButton: n,
          input: h,
          textarea: p,
          checkbox: u,
          button: m
        }[t];
      }
    };
  }), s(e, "Dashboards/EditMode/Menu/MenuItem.js", [e["Dashboards/EditMode/EditGlobals.js"], e["Core/Utilities.js"], e["Dashboards/EditMode/EditRenderer.js"]], function (t, e, s) {
    let {
      createElement: i,
      merge: o
    } = e;
    class n {
      constructor(t, e) {
        this.menu = t, this.isActive = !1, this.options = o(n.defaultOptions, e), this.container = this.setContainer(), this.innerElement = this.setInnerElement();
      }
      setContainer() {
        let e = this.options,
          s = t.classNames.menuItem;
        return this.menu.options.itemsClassName && (s += " " + this.menu.options.itemsClassName), e.className && (s += " " + e.className), i("div", {
          className: s || ""
        }, o(this.options.style || {}, this.isActive ? {
          display: "block"
        } : {}), this.menu.container);
      }
      setInnerElement() {
        let t = this.options,
          e = this.container,
          i = t.langKey;
        return "toggle" === t.type ? s.renderToggle(e, {
          id: t.id,
          name: t.id,
          title: i ? this.menu.editMode.lang[i] : t.text,
          value: !!(t.getValue && t.getValue(this)),
          lang: this.menu.editMode.lang,
          langKey: i,
          onchange: t.events?.click?.bind(this)
        }) : "text" === t.type ? s.renderText(e, {
          title: i ? this.menu.editMode.lang[i] : t.text || "",
          className: t.className || ""
        }) : "icon" === t.type ? s.renderIcon(e, {
          icon: t.icon || "",
          mousedown: t.events?.onmousedown?.bind(this),
          click: t.events?.click?.bind(this)
        }) : "button" === t.type ? s.renderButton(e, {
          callback: t.events?.click?.bind(this),
          className: t.className || "",
          style: t.style || {},
          text: i ? this.menu.editMode.lang[i] : t.text || ""
        }) : void 0;
      }
      update() {
        let t = this.options;
        t.events && t.events.update && t.events.update.apply(this, arguments);
      }
      activate() {
        this.update(), this.container && (this.isActive = !0, this.container.style.display = "block");
      }
      deactivate() {
        this.container && (this.isActive = !1, this.container.style.display = "none");
      }
    }
    return n.defaultOptions = {
      type: "text"
    }, n;
  }), s(e, "Dashboards/EditMode/Menu/MenuItemBindings.js", [], function () {
    return {
      viewFullscreen: {
        id: "viewFullscreen",
        type: "button",
        langKey: "viewFullscreen",
        events: {
          click: function () {
            let t = this.menu.editMode.board.fullscreen;
            t && t.toggle();
          }
        }
      }
    };
  }), s(e, "Dashboards/EditMode/Menu/Menu.js", [e["Dashboards/EditMode/EditGlobals.js"], e["Core/Utilities.js"], e["Dashboards/EditMode/Menu/MenuItem.js"], e["Dashboards/EditMode/Menu/MenuItemBindings.js"]], function (t, e, s, i) {
    let {
      createElement: o,
      merge: n
    } = e;
    class l {
      constructor(t, e, s, i) {
        this.parentElement = t, this.isVisible = !1, this.activeItems = [], this.options = e, this.items = {}, this.editMode = s, i && (this.parent = i), this.container = this.setContainer();
      }
      setContainer() {
        return o("div", {
          className: t.classNames.menu + " " + (this.options.className || "")
        }, {}, this.parentElement);
      }
      initItems(t, e) {
        let i, o, l, a;
        let r = this.options.items || [];
        for (let d = 0, c = r.length; d < c; ++d) i = "string" == typeof (o = r[d]) ? t[o] : o.id ? t[o.id] : {}, (a = "string" == typeof o ? n(i, {
          id: o
        }) : n(i, o)).id && (l = new s(this, a), this.items[l.options.id] = l, e && (l.activate(), this.activeItems.push(l)));
      }
      setActiveItems(t) {
        let e;
        for (let e = 0, s = this.activeItems.length; e < s; ++e) -1 === t.indexOf(this.activeItems[e].options.id) && this.activeItems[e].deactivate();
        this.activeItems.length = 0;
        for (let s = 0, i = t.length; s < i; ++s) (e = this.items[t[s]]) && (e.isActive ? e.update() : e.activate(), this.activeItems.push(e));
      }
      deactivateActiveItems() {
        for (let t = 0, e = this.activeItems.length; t < e; ++t) this.activeItems[t].deactivate();
      }
      updateActiveItems() {
        let t = this.activeItems;
        for (let e = 0, s = t.length; e < s; ++e) t[e].update();
      }
      destroy() {
        this.activeItems.length = 0, this.container.remove(), this.items = {}, this.options = {};
      }
    }
    return l.items = i, l;
  }), s(e, "Dashboards/EditMode/Toolbar/EditToolbar.js", [e["Core/Utilities.js"], e["Dashboards/EditMode/Menu/Menu.js"]], function (t, e) {
    let {
      defined: s,
      createElement: i,
      css: o
    } = t;
    return class {
      constructor(t, s) {
        this.container = i("div", {
          className: s.className
        }, void 0, t.board.container), this.editMode = t, this.iconURLPrefix = t.iconsURLPrefix, this.menu = new e(this.container, s.menu, t, this), this.options = s, this.isVisible = !1, this.options.outline && (this.outline = i("div", {
          className: s.outlineClassName
        }, void 0, this.container));
      }
      hide() {
        this.setPosition(void 0, void 0);
      }
      refreshOutline(t, e, s, i = 0) {
        let n = (s || {}).container;
        this.outline && n && o(this.outline, {
          display: "block",
          left: t - i + "px",
          top: e - i + "px",
          width: n.offsetWidth + 2 * i + "px",
          height: n.offsetHeight + 2 * i + "px"
        });
      }
      hideOutline() {
        this.outline && (this.outline.style.display = "none");
      }
      setPosition(t, e) {
        this.container && o(this.container, {
          left: (t || "-9999") + "px",
          top: (e || "-9999") + "px"
        }), this.isVisible = s(t) && s(e);
      }
    };
  }), s(e, "Dashboards/EditMode/Toolbar/CellEditToolbar.js", [e["Dashboards/Layout/Cell.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/EditMode/Toolbar/EditToolbar.js"], e["Dashboards/Layout/GUIElement.js"], e["Core/Utilities.js"]], function (t, e, s, i, o) {
    let {
      merge: n,
      fireEvent: l,
      objectEach: a
    } = o;
    class r extends s {
      static getItemsConfig(s, i) {
        let o = [];
        return s.dragDrop?.enabled && o.push({
          id: "drag",
          type: "icon",
          icon: i + "drag.svg",
          events: {
            onmousedown: function (e) {
              let s = this.menu.parent,
                i = s.editMode.dragDrop;
              i && s.cell && s.cell instanceof t && i.onDragStart(e, s.cell);
            }
          }
        }), s.settings?.enabled && o.push({
          id: "settings",
          type: "icon",
          icon: i + "settings.svg",
          events: {
            click: function () {
              this.menu.parent.editMode.setEditOverlay(), this.menu.parent.onCellOptions();
            }
          }
        }), o.push({
          id: "destroy",
          type: "icon",
          className: e.classNames.menuDestroy,
          icon: i + "destroy.svg",
          events: {
            click: function () {
              let t = this.menu.parent,
                e = this.menu.parent.editMode,
                s = e.confirmationPopup;
              s.show({
                confirmButton: {
                  value: e.lang.confirmButton,
                  callback: t.onCellDestroy,
                  context: t
                },
                cancelButton: {
                  value: e.lang.cancelButton,
                  callback: () => {
                    s.closePopup();
                  }
                },
                text: e.lang.confirmDestroyCell
              });
            }
          }
        }), o;
      }
      constructor(t) {
        super(t, n(r.defaultOptions, (t.options.toolbars || {}).cell, {
          menu: {
            items: r.getItemsConfig(t.options, t.iconsURLPrefix)
          }
        })), t.customHTMLMode && this.filterOptionsAvailableInCustomHTMLMode(), this.menu.initItems({});
      }
      showToolbar(t) {
        let e = t.container,
          s = this.editMode.cellToolbar;
        if (s) {
          if (e && this.editMode.isActive() && !(this.editMode.dragDrop || {}).isActive) {
            let e = i.getOffsets(t, this.editMode.board.container),
              o = e.right - 30 - 10,
              n = e.top + 10;
            a(this.menu.items, e => {
              if (!t.options?.editMode?.toolbarItems) {
                e.activate();
                return;
              }
              let s = t.options.editMode.toolbarItems;
              if (s[e.options.id]?.enabled === !1) {
                e.deactivate();
                return;
              }
              e.activate();
            }), this.setPosition(o, n), this.cell = t, this.refreshOutline(), s.isVisible = !0;
          } else this.isVisible && (this.hide(), s.isVisible = !1);
        }
      }
      refreshOutline() {
        this.cell && this.cell.container && this.outline && super.refreshOutline(-this.cell.container.offsetWidth, 0, this.cell, -1);
      }
      onCellOptions() {
        let t = this.editMode;
        t.sidebar && (t.sidebar.show(this.cell), this.highlightCell());
      }
      onCellDestroy() {
        if (this.cell && this.cell instanceof t) {
          let t = this.cell.row,
            e = this.cell.id;
          this.resetEditedCell(), this.cell.destroy(), this.cell = void 0, this.editMode.hideToolbars(["cell", "row"]), t && t.cells && t.cells.length && (l(this.editMode.board, "cellResize", {
            cell: t.cells[0]
          }), l(t, "cellChange", {
            cell: t.cells[0],
            row: t
          }), l(this.editMode, "layoutChanged", {
            type: "cellDestroyed",
            target: e,
            board: this.editMode.board
          }));
        }
      }
      resetEditedCell() {
        this.editedCell = void 0;
      }
      filterOptionsAvailableInCustomHTMLMode() {
        this.options.menu.items = this.options.menu.items?.filter(t => "string" != typeof t && "settings" === t.id);
      }
      highlightCell() {
        this.cell && (this.cell.setHighlight ? this.cell.setHighlight() : (this.cell.container.classList.add(e.classNames.cellEditHighlight), this.editMode.board.container.classList.add(e.classNames.dashboardCellEditHighlightActive)));
      }
    }
    return r.defaultOptions = {
      enabled: !0,
      className: e.classNames.editToolbar,
      outline: !1,
      outlineClassName: e.classNames.editToolbarCellOutline,
      menu: {
        className: e.classNames.editToolbarCell,
        itemsClassName: e.classNames.editToolbarItem,
        items: []
      }
    }, r;
  }), s(e, "Dashboards/EditMode/Toolbar/RowEditToolbar.js", [e["Core/Utilities.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/EditMode/Toolbar/EditToolbar.js"], e["Dashboards/Layout/GUIElement.js"]], function (t, e, s, i) {
    let {
      fireEvent: o,
      merge: n,
      objectEach: l
    } = t;
    class a extends s {
      static getMenuItemsConfig(t, s) {
        let i = [];
        return t.dragDrop?.enabled && i.push({
          id: "drag",
          type: "icon",
          icon: s + "drag.svg",
          events: {
            onmousedown: function (t) {
              let e = this.menu.parent,
                s = e.editMode.dragDrop;
              t.preventDefault(), s && e.row && s.onDragStart(t, e.row);
            }
          }
        }), i.push({
          id: "destroy",
          type: "icon",
          className: e.classNames.menuDestroy,
          icon: s + "destroy.svg",
          events: {
            click: function () {
              let t = this.menu.parent,
                e = this.menu.parent.editMode,
                s = e.confirmationPopup;
              s.show({
                confirmButton: {
                  value: e.lang.confirmButton,
                  callback: t.onRowDestroy,
                  context: t
                },
                cancelButton: {
                  value: e.lang.cancelButton,
                  callback: () => {
                    s.closePopup();
                  }
                },
                text: e.lang.confirmDestroyRow
              });
            }
          }
        }), i;
      }
      constructor(t) {
        super(t, n(a.defaultOptions, (t.options.toolbars || {}).row, {
          menu: {
            items: a.getMenuItemsConfig(t.options, t.iconsURLPrefix)
          }
        })), this.menu.initItems({});
      }
      refreshOutline(t, e) {
        this.row && this.row.container && super.refreshOutline(t, e, this.row, 2);
      }
      showToolbar(t) {
        let e, s, o;
        let n = t.container,
          a = this.editMode.rowToolbar;
        if (a) {
          if (n && this.editMode.isActive() && !(this.editMode.dragDrop || {}).isActive) {
            let n = i.getOffsets(t, this.editMode.board.container),
              r = n.right - n.left;
            l(this.menu.items, e => {
              if (!t.options?.editMode?.toolbarItems) {
                e.activate();
                return;
              }
              let s = t.options.editMode.toolbarItems;
              if (s[e.options.id]?.enabled === !1) {
                e.deactivate();
                return;
              }
              e.activate();
            }), o = r / 2 - this.container.clientWidth / 2, e = n.left + o, s = n.top - this.container.clientHeight, this.setPosition(e, s), this.row = t, this.refreshOutline(-o, this.container.clientHeight), a.isVisible = !0;
          } else this.isVisible && (this.hide(), a.isVisible = !1);
        }
      }
      onRowOptions() {
        this.editMode.sidebar && this.editMode.sidebar.show(this.row);
      }
      onRowDestroy() {
        if (this.row) {
          let t = this.row.options.id || -1;
          this.resetEditedRow(), this.row.destroy(), this.row = void 0, this.editMode.hideToolbars(["cell", "row"]), o(this.editMode, "layoutChanged", {
            type: "rowDestroyed",
            target: t,
            board: this.editMode.board
          });
        }
      }
      resetEditedRow() {
        this.editedRow = void 0;
      }
    }
    return a.defaultOptions = {
      enabled: !0,
      className: e.classNames.editToolbar,
      outline: !0,
      outlineClassName: e.classNames.editToolbarRowOutline,
      menu: {
        className: e.classNames.editToolbarRow,
        itemsClassName: e.classNames.editToolbarItem,
        items: []
      }
    }, a;
  }), s(e, "Shared/BaseForm.js", [e["Core/Renderer/HTML/AST.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      addEvent: s,
      createElement: i
    } = e;
    return class {
      constructor(t, e) {
        this.iconsURL = e, this.container = this.createPopupContainer(t), this.closeButton = this.addCloseButton();
      }
      createPopupContainer(t, e = "highcharts-popup highcharts-no-tooltip") {
        return i("div", {
          className: e
        }, void 0, t);
      }
      addCloseButton(t = "highcharts-popup-close") {
        let e = this,
          o = this.iconsURL,
          n = i("button", {
            className: t
          }, void 0, this.container);
        return n.style["background-image"] = "url(" + (o.match(/png|svg|jpeg|jpg|gif/ig) ? o : o + "close.svg") + ")", ["click", "touchstart"].forEach(t => {
          s(n, t, e.closeButtonEvents.bind(e));
        }), s(document, "keydown", function (t) {
          "Escape" === t.code && e.closeButtonEvents();
        }), n;
      }
      closeButtonEvents() {
        this.closePopup();
      }
      showPopup(e = "highcharts-annotation-toolbar") {
        let s = this.container,
          i = this.closeButton;
        this.type = void 0, s.innerHTML = t.emptyHTML, s.className.indexOf(e) >= 0 && (s.classList.remove(e), s.removeAttribute("style")), s.appendChild(i), s.style.display = "block", s.style.height = "";
      }
      closePopup() {
        this.container.style.display = "none";
      }
    };
  }), s(e, "Dashboards/EditMode/ConfirmationPopup.js", [e["Core/Utilities.js"], e["Shared/BaseForm.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/EditMode/EditRenderer.js"]], function (t, e, s, i) {
    let {
      createElement: o
    } = t;
    return class extends e {
      constructor(t, e, s, i) {
        super(t, e = i && i.close && i.close.icon ? i.close.icon : e), this.editMode = s, this.options = i;
      }
      createPopupContainer(t, e = s.classNames.confirmationPopup) {
        return super.createPopupContainer(t, e);
      }
      addCloseButton(t = s.classNames.popupCloseButton) {
        return super.addCloseButton(t);
      }
      closeButtonEvents() {
        let t = this.contentOptions?.cancelButton.callback;
        t && t();
      }
      renderContent() {
        let t = this.contentOptions;
        if (!t) return;
        this.contentContainer = o("div", {
          className: s.classNames.popupContentContainer
        }, {}, this.container);
        let e = this.contentContainer.parentNode;
        e.style.marginTop = "0px";
        let n = e.getBoundingClientRect().top;
        e.style.marginTop = (n < 0 ? Math.abs(n - 200) : 200) + "px", i.renderText(this.contentContainer, {
          title: t.text || ""
        }), this.buttonContainer = o("div", {
          className: s.classNames.popupButtonContainer
        }, {}, this.container), i.renderButton(this.buttonContainer, {
          text: t.cancelButton.value,
          className: s.classNames.popupCancelBtn,
          callback: t.cancelButton.callback
        }), i.renderButton(this.buttonContainer, {
          text: t.confirmButton.value,
          className: s.classNames.popupConfirmBtn,
          callback: () => {
            t.confirmButton.callback.call(t.confirmButton.context), this.closePopup();
          }
        });
      }
      show(t) {
        this.contentOptions = t, this.showPopup(), this.renderContent(), this.editMode.setEditOverlay();
      }
      closePopup() {
        super.closePopup(), this.editMode.setEditOverlay(!0);
      }
    };
  }), s(e, "Dashboards/EditMode/AccordionMenu.js", [e["Dashboards/EditMode/EditRenderer.js"], e["Core/Utilities.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/EditMode/ConfirmationPopup.js"]], function (t, e, s, i) {
    let {
      createElement: o,
      merge: n,
      error: l,
      fireEvent: a
    } = e;
    return class {
      constructor(t, e) {
        this.changedOptions = {}, this.chartOptionsJSON = {}, this.oldOptionsBuffer = {}, this.waitingForConfirmation = !1, this.iconsURLPrefix = t, this.closeSidebar = e;
      }
      renderContent(e, l) {
        let a, r;
        let {
            editMode: d
          } = l.board,
          c = l.editableOptions.getOptions();
        this.component = l, this.oldOptionsBuffer = n({}, l.options), d && (this.confirmationPopup = new i(l.board.container, d.iconsURLPrefix, d, {
          close: {
            icon: ""
          }
        }));
        let h = o("div", {
          className: s.classNames.accordionMenu
        }, {}, e);
        for (let e = 0, i = c.length; e < i; e++) a = c[e], r = t.renderCollapseHeader(h, n({
          iconsURLPrefix: this.iconsURLPrefix,
          lang: (l.board?.editMode || s).lang
        }, a)).content, this.renderAccordion(a, r, l);
        let p = o("div", {
          className: s.classNames.accordionMenuButtonsContainer
        }, {}, h);
        t.renderButton(p, {
          text: (l.board?.editMode || s).lang.confirmButton,
          className: s.classNames.popupConfirmBtn,
          callback: async () => {
            await this.confirmChanges();
          }
        }), t.renderButton(p, {
          text: (l.board?.editMode || s).lang.cancelButton,
          className: s.classNames.popupCancelBtn,
          callback: () => {
            this.cancelChanges();
          }
        });
      }
      updateOptions(t, e) {
        let s, i;
        let o = t.length - 1,
          n = this.changedOptions;
        if (0 === o && "chartOptions" === t[0]) try {
          let t = JSON.parse(e);
          this.chartOptionsJSON = t;
        } catch (t) {
          l(`Dashboards Error: Wrong JSON config structure passed as a chart options. 
____________
${t}`);
        }
        for (let e = 0; e < o; e++) {
          let o = t[e];
          if (n[o] || (n[o] = {}), n = n[o], "chartOptions" === o) {
            let t = this.component.chart?.options;
            if (t) {
              let e = this.oldOptionsBuffer;
              e.chartOptions || (e.chartOptions = {}), i = e.chartOptions, s = t;
            }
          } else s && i && (s = s[o], void 0 === i[o] && (i[o] = {}), i = i[o]);
        }
        let a = t[o];
        n[a] = e, i && s && (i[a] = i[a] ?? s[a]), this.component?.update(this.changedOptions);
      }
      renderAccordion(e, s, i) {
        if ("nested" === e.type) return this.renderNested(s, e, i);
        let o = t.getRendererFunction(e.type);
        o && o(s, {
          ...e,
          iconsURLPrefix: this.iconsURLPrefix,
          value: i.getEditableOptionValue(e.propertyPath),
          onchange: t => this.updateOptions(e.propertyPath || [], t)
        });
      }
      renderNested(e, i, o) {
        if (!e || !i.nestedOptions) return;
        let n = i.nestedOptions;
        for (let i = 0, l = n.length; i < l; ++i) {
          let l = n[i].name,
            a = n[i].options,
            r = !!n[i].showToggle,
            d = n[i].propertyPath || [],
            c = t.renderCollapseHeader(e, {
              name: l,
              isEnabled: !!o.getEditableOptionValue(d),
              iconsURLPrefix: this.iconsURLPrefix,
              showToggle: r,
              onchange: t => this.updateOptions(d, t),
              isNested: !0,
              lang: (o.board?.editMode || s).lang
            });
          for (let t = 0, e = a.length; t < e; ++t) this.renderAccordion(a[t], c.content, o);
        }
      }
      cancelChanges() {
        Object.keys(this.changedOptions).length < 1 ? this.closeSidebar() : this.showCancelConfirmationPopup();
      }
      async confirmChanges() {
        let t = this.component;
        if (t) {
          if ("Highcharts" === t.type && Object.keys(this.chartOptionsJSON).length) await t.update({
            chartOptions: this.chartOptionsJSON
          });else if ("HTML" === t.type) {
            let e = this.changedOptions;
            await t.update(e, !0);
          }
          a(t.board.editMode, "componentChanged", {
            target: t,
            changedOptions: n({}, this.changedOptions),
            oldOptions: n({}, this.oldOptionsBuffer)
          }), this.changedOptions = {}, this.chartOptionsJSON = {}, this.closeSidebar();
        }
      }
      async discardChanges() {
        let t = this.component;
        t && (await t.update(this.oldOptionsBuffer), a(t.board.editMode, "componentChangesDiscarded", {
          target: t,
          changedOptions: n({}, this.changedOptions),
          oldOptions: n({}, this.oldOptionsBuffer)
        }), this.changedOptions = {}, this.chartOptionsJSON = {});
      }
      showCancelConfirmationPopup() {
        let t = this.confirmationPopup,
          e = this.component?.board?.editMode;
        t && e && !this.waitingForConfirmation && (this.waitingForConfirmation = !0, t.show({
          text: e.lang.confirmDiscardChanges,
          confirmButton: {
            value: e.lang.confirmButton,
            callback: async () => {
              await this.discardChanges(), this.waitingForConfirmation = !1, this.closeSidebar();
            },
            context: this
          },
          cancelButton: {
            value: e.lang.cancelButton,
            callback: () => {
              t.closePopup(), e.setEditOverlay(), setTimeout(() => {
                this.waitingForConfirmation = !1;
              }, 100);
            }
          }
        }));
      }
    };
  }), s(e, "Dashboards/EditMode/SidebarPopup.js", [e["Dashboards/Layout/CellHTML.js"], e["Dashboards/EditMode/AccordionMenu.js"], e["Shared/BaseForm.js"], e["Dashboards/Actions/Bindings.js"], e["Dashboards/Layout/Cell.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/EditMode/EditRenderer.js"], e["Dashboards/Layout/GUIElement.js"], e["Dashboards/Layout/Layout.js"], e["Core/Utilities.js"]], function (t, e, s, i, o, n, l, a, r, d) {
    let {
      addEvent: c,
      createElement: h,
      fireEvent: p,
      merge: u
    } = d;
    class m extends s {
      constructor(t, s, i) {
        super(t, s), this.options = {
          components: ["HTML", "row", "Highcharts", "DataGrid", "KPI"]
        }, this.isVisible = !1, this.componentsList = [], this.editMode = i, this.options = u(this.options, i.options.toolbars?.sidebar || {}), this.componentsList = this.getComponentsList(this.options.components || []), this.accordionMenu = new e(this.iconsURL, this.hide.bind(this));
      }
      detectRightSidebar(t) {
        let e = this.editMode,
          s = e.customHTMLMode ? e.board.container : e.board.layoutsWrapper;
        return !!s && a.getOffsets(t, s).left < s.offsetWidth / 2 - 10;
      }
      removeClassNames() {
        let t = n.classNames,
          e = this.container.classList;
        e.remove(t.editSidebarShow), e.remove(t.editSidebarRightShow);
      }
      addClassNames(t) {
        let e = this.container.classList;
        t ? e.add(n.classNames.editSidebarRight) : e.remove(n.classNames.editSidebarRight), setTimeout(() => {
          e.add(n.classNames[t ? "editSidebarRightShow" : "editSidebarShow"]);
        });
      }
      show(t) {
        let e = this.editMode,
          s = !!(t && this.detectRightSidebar(t));
        this.showPopup(n.classNames.editSidebarShow), this.addClassNames(s), e.resizer && e.resizer.disableResizer(), e.editCellContext instanceof o && e.editCellContext.row && e.editCellContext.row.setHighlight(), e.hideToolbars(["cell", "row"]), e.stopContextDetection(), this.isVisible = !0, this.generateContent(t);
      }
      generateContent(t) {
        if (this.renderHeader(t ? this.editMode.lang.settings : this.editMode.lang.addComponent, ""), !t) {
          this.renderAddComponentsList();
          return;
        }
        if (this.type = t.getType(), "cell-html" === this.type || "cell" === this.type) {
          let e = t.mountedComponent;
          if (!e) return;
          this.accordionMenu.renderContent(this.container, e);
        }
      }
      renderAddComponentsList() {
        let t;
        let e = this,
          s = this.componentsList,
          i = h("div", {
            className: n.classNames.editGridItems
          }, {}, e.container);
        for (let o = 0, n = s.length; o < n; ++o) (t = h("div", {}, {}, i)).addEventListener("mousedown", t => {
          if (t.preventDefault(), e.editMode.dragDrop) {
            let i = t => {
                let s = e.container.getBoundingClientRect();
                (t.clientX < s.left || t.clientX > s.right || t.clientY < s.top || t.clientY > s.bottom) && (e.hide(), document.removeEventListener("mousemove", i));
              },
              n = () => {
                document.removeEventListener("mousemove", i), document.removeEventListener("mouseup", n);
              };
            document.addEventListener("mousemove", i), document.addEventListener("mouseup", n), e.editMode.dragDrop.onDragStart(t, void 0, t => {
              if (0 === this.editMode.board.layouts.length) {
                let e = this.editMode.board,
                  s = new r(e, {
                    id: a.getElementId("layout"),
                    copyId: "",
                    parentContainerId: e.container.id,
                    rows: [{}],
                    style: {}
                  });
                s && e.layouts.push(s), t = s.rows[0];
              }
              let n = s[o].onDrop(e, t);
              n && (e.editMode.setEditCellContext(n), e.show(n), n.setHighlight()), document.removeEventListener("mousemove", i);
            });
          }
        }), t.innerHTML = s[o].text;
      }
      onDropNewComponent(t, e) {
        let s = this.editMode.dragDrop;
        if (s) {
          let o = ("cell" === t.getType() ? t.row : t).addCell({
            id: a.getElementId("col")
          });
          s.onCellDragEnd(o);
          let n = u(e, {
              cell: o.id
            }),
            l = i.addComponent(n, this.editMode.board, o);
          return this.editMode.setEditOverlay(), (async () => {
            let t = await l;
            t && p(this.editMode, "layoutChanged", {
              type: "newComponent",
              target: t
            });
          })(), o;
        }
      }
      hide() {
        let e = this.editMode,
          s = e.editCellContext;
        this.removeClassNames(), this.container.style.display = "none", e.isEditOverlayActive && e.setEditOverlay(!0), s instanceof o && s.row ? (e.showToolbars(["cell", "row"], s), s.row.setHighlight(), s.setHighlight(!0)) : s instanceof t && e.cellToolbar && (e.cellToolbar.showToolbar(s), s.setHighlight()), e.isContextDetectionActive = !0, this.isVisible = !1;
      }
      closeButtonEvents() {
        "cell" === this.type || "cell-html" === this.type ? this.accordionMenu.cancelChanges() : this.hide();
      }
      renderHeader(t, e) {
        let s = l.renderIcon(this.container, {
          icon: e,
          className: n.classNames.editSidebarTitle
        });
        s && (s.textContent = t);
      }
      getComponentsList(t) {
        let e = this.editMode,
          s = e.board.componentTypes,
          i = [];
        return t.forEach(t => {
          let o = s[t];
          o ? i.push({
            text: e.lang?.sidebar[t] || o.name,
            onDrop: function (t, e) {
              let s = o.prototype.getOptionsOnDrop(t);
              if (s) return t.onDropNewComponent(e, s);
            }
          }) : "row" === t && i.push(m.addRow);
        }), i;
      }
      addCloseButton(t = n.classNames.popupCloseButton) {
        return c(document, "click", t => {
          t.stopPropagation(), "block" === this.container.style.display && !this.container.contains(t.target) && this.container.classList.value.includes("show") && ("cell" === this.type || "cell-html" === this.type ? this.accordionMenu.cancelChanges() : this.hide());
        }), super.addCloseButton.call(this, t);
      }
      createPopupContainer(t, e = n.classNames.editSidebar) {
        return super.createPopupContainer.call(this, t, e);
      }
    }
    return m.addRow = {
      text: n.lang.sidebar.row,
      onDrop: function (t, e) {
        if (!e) return;
        let s = ("cell" === e.getType() ? e.row : e).layout.board,
          o = a.getElementId("layout"),
          n = a.getElementId("cell"),
          l = new r(s, {
            id: o,
            copyId: "",
            parentContainerId: s.container.id,
            rows: [{
              cells: [{
                id: n
              }]
            }],
            style: {}
          });
        l && (s.layouts.push(l), p(s.editMode, "layoutChanged", {
          type: "newLayout",
          target: l,
          board: s
        })), i.addComponent({
          type: "HTML",
          cell: n,
          className: "highcharts-dashboards-component-placeholder",
          html: `
                            <h2> Placeholder </h2>
                            <span> This placeholder can be deleted when you add extra
                                components to this row.
                            </span>
                            `
        }, s);
      }
    }, m;
  }), s(e, "Dashboards/EditMode/EditContextMenu.js", [e["Dashboards/EditMode/EditGlobals.js"], e["Core/Utilities.js"], e["Dashboards/EditMode/Menu/Menu.js"]], function (t, e, s) {
    let {
      addEvent: i,
      merge: o
    } = e;
    class n extends s {
      constructor(t, e, s) {
        if (super(s.board.container, o(n.defaultOptions, e || {}), s), this.editMode = s, this.options = o(n.defaultOptions, e || {}), this.editMode.board.layoutsWrapper?.parentNode.insertBefore(this.container, this.editMode.board.layoutsWrapper), this.container.style.width = this.options.width + "px", super.initItems(n.items), this.options.items) {
          let t = [];
          for (let e = 0, s = this.options.items.length; e < s; ++e) "string" == typeof this.options.items[e] ? t.push(this.options.items[e]) : this.options.items[e].id && t.push(this.options.items[e].id);
          this.setActiveItems(t);
        }
        this.initEvents();
      }
      initEvents() {
        let e = this;
        i(document, "click", s => {
          s.target !== this.container && s.target !== e.editMode.tools.contextButtonElement && !s.target.classList.contains(t.classNames.toggleSlider) && "INPUT" !== s.target.tagName && this.isVisible && this.setVisible(!1);
        });
      }
      setVisible(t) {
        let e = this.editMode.tools.contextButtonElement;
        this.container && e && (t ? (this.container.style.display = "block", this.isVisible = !0, e.setAttribute("aria-expanded", "true")) : (this.container.style.display = "none", this.isVisible = !1, e.setAttribute("aria-expanded", "false")));
      }
      updatePosition(t, e, s) {
        let i = this.options.width || 0,
          o = t ? t.offsetLeft - i + t.offsetWidth : e,
          n = t ? t.offsetTop + t.offsetHeight : s;
        o && n && (this.container.style.left = o + "px", this.container.style.top = n + "px");
      }
    }
    return n.defaultOptions = {
      enabled: !0,
      width: 150,
      className: t.classNames.contextMenu,
      itemsClassName: t.classNames.contextMenuItem,
      items: ["editMode"]
    }, n.items = o(s.items, {
      editMode: {
        id: "editMode",
        type: "toggle",
        getValue: function (t) {
          return t.menu.editMode.isActive();
        },
        langKey: "editMode",
        events: {
          click: function () {
            this.menu.editMode.toggleEditMode();
          }
        }
      }
    }), n;
  }), s(e, "Dashboards/Actions/ContextDetection.js", [e["Dashboards/Layout/GUIElement.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      defined: s
    } = e;
    class i {
      static isGUIElementOnParentEdge(t, e) {
        let s = "top" === e || "bottom" === e ? t.row.layout.getVisibleRows() : "left" === e || "right" === e ? t.row.getVisibleCells() : [],
          i = s.length - 1;
        return s[i] === t && "right" === e || s[i] === t.row && "bottom" === e || s[0] === t && "left" === e || s[0] === t.row && "top" === e;
      }
      static getContextLevel(t, e, s, i) {
        let o = t.getOverlappingLevels(i, e / 2),
          n = e / o.length,
          l = o[o.length - 1],
          a = t.row.layout.level - Math.floor(s / n);
        return a < l ? l : a > t.row.layout.level ? t.row.layout.level : a;
      }
      static getContext(e, o, n) {
        let l;
        let a = t.getOffsets(e),
          {
            width: r,
            height: d
          } = t.getDimFromOffsets(a);
        r < 2 * n && (n = r / 2);
        let c = o.clientX - a.left,
          h = o.clientY - a.top,
          p = "bottom";
        switch (c >= -n && c <= n ? p = "left" : c - r >= -n && c - r <= n ? p = "right" : h >= -n && h <= n ? p = "top" : h - d >= -n && h - d <= n && (p = "bottom"), p) {
          case "right":
            l = c - r + n;
            break;
          case "left":
            l = n - c;
            break;
          case "top":
            l = n - h;
            break;
          case "bottom":
            l = h - d + n;
        }
        let u = {
          cell: e,
          side: p
        };
        if (e.row?.layout.level && p && i.isGUIElementOnParentEdge(e, p) && s(l)) {
          let t = i.getContextLevel(e, n, l, p),
            s = e.getParentCell(t);
          s && (u.cell = s);
        }
        return u;
      }
    }
    return i;
  }), s(e, "Dashboards/Actions/DragDrop.js", [e["Core/Utilities.js"], e["Dashboards/Globals.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/Layout/GUIElement.js"], e["Dashboards/Actions/ContextDetection.js"]], function (t, e, s, i, o) {
    let {
      addEvent: n,
      merge: l,
      css: a,
      fireEvent: r,
      createElement: d
    } = t;
    class c {
      constructor(t, e) {
        this.editMode = t, this.options = l(c.defaultOptions, e), this.mockElement = d("div", {
          className: s.classNames.dragMock
        }, {}, t.board.container), this.dropPointer = {
          isVisible: !1,
          align: "",
          element: d("div", {
            className: s.classNames.dropPointer
          }, {}, t.board.container)
        }, this.isActive = !1, this.initEvents();
      }
      showDropPointer(t, e, s, i) {
        this.dropPointer.isVisible = !0, a(this.dropPointer.element, {
          display: "block",
          left: t + "px",
          top: e + "px",
          height: i + "px",
          width: s + "px"
        });
      }
      hideDropPointer() {
        this.dropPointer.isVisible && (this.dropPointer.isVisible = !1, this.dropPointer.align = "", this.dropPointer.element.style.display = "none");
      }
      setMockElementPosition(t) {
        let e = this.editMode.board.container.getBoundingClientRect(),
          s = this.mockElement.clientWidth / 2,
          i = t.clientX - e.left - s,
          o = t.clientY - e.top - s;
        a(this.mockElement, {
          left: i + "px",
          top: o + "px"
        });
      }
      initEvents() {
        n(document, "mousemove", this.onDrag.bind(this)), n(document, "mouseup", this.onDragEnd.bind(this));
      }
      onDragStart(t, s, i) {
        this.isActive = !0, this.editMode.hideToolbars(["cell", "row"]), this.editMode.resizer && this.editMode.resizer.disableResizer(), this.setMockElementPosition(t), s ? (this.context = s, s.hide(), s.getType() === e.guiElementType.cell && (r(this.editMode.board, "cellResize", {
          cell: s
        }), r(s.row, "cellChange", {
          cell: s,
          row: s.row
        }))) : i && (this.dragEndCallback = i), a(this.mockElement, {
          cursor: "grabbing",
          display: "block"
        });
      }
      onDrag(t) {
        this.isActive && (t.preventDefault(), this.setMockElementPosition(t), this.context ? this.context.getType() === e.guiElementType.cell ? this.onCellDrag(t) : this.context.getType() === e.guiElementType.row && this.onRowDrag(t) : this.dragEndCallback && this.onCellDrag(t));
      }
      onDragEnd() {
        this.isActive && (this.isActive = !1, a(this.mockElement, {
          cursor: "grab",
          display: "none"
        }), this.context ? (this.context.getType() === e.guiElementType.cell ? this.onCellDragEnd() : this.context.getType() === e.guiElementType.row && this.onRowDragEnd(), this.context = void 0, this.editMode.editCellContext && (this.editMode.showToolbars(["row", "cell"], this.editMode.editCellContext), this.editMode.resizer && this.editMode.resizer.setSnapPositions(this.editMode.editCellContext))) : this.dragEndCallback && (this.dragEndCallback(this.dropContext), this.dragEndCallback = void 0, this.hideDropPointer()));
      }
      onRowDrag(t, e) {
        let s = this.mouseCellContext,
          n = this.options.dropPointerSize || 0,
          l = this.options.rowDropOffset || 0,
          a = !1;
        if (s) {
          let r = e || o.getContext(s, t, l),
            d = r.side;
          if ((this.dropPointer.align !== d || this.dropContext !== r.cell.row) && (a = !0, this.dropPointer.align = d, this.dropContext = r.cell.row), d) {
            let t = i.getOffsets(this.dropContext, this.editMode.board.container),
              {
                width: e,
                height: s
              } = i.getDimFromOffsets(t);
            (!this.dropPointer.isVisible || a) && this.showDropPointer(t.left, t.top + ("bottom" === this.dropPointer.align ? s : 0) - n / 2, e, n);
          } else this.dropContext = void 0, this.hideDropPointer();
        }
      }
      onRowDragEnd() {
        let t = this.context,
          e = this.dropContext;
        this.dropPointer.align && (t.layout.unmountRow(t), 0 === t.layout.rows.length && t.layout.destroy(), e.layout.mountRow(t, (e.layout.getRowIndex(e) || 0) + ("bottom" === this.dropPointer.align ? 1 : 0)), t.cells[0] && (r(this.editMode.board, "cellResize", {
          cell: t.cells[0]
        }), r(t, "cellChange", {
          cell: t.cells[0],
          row: t
        }))), this.hideDropPointer(), t.show(), r(this.editMode, "layoutChanged", {
          type: "rowDragEnd",
          target: t,
          board: this.editMode.board
        });
      }
      onCellDrag(t, e) {
        let s = this.mouseCellContext,
          i = this.options.cellDropOffset || 0;
        s || e ? this.onCellDragCellCtx(t, e || o.getContext(s, t, i)) : this.mouseRowContext && this.onCellDragRowCtx(t, this.mouseRowContext);
      }
      onCellDragCellCtx(t, e) {
        let s = this.options.dropPointerSize || 0,
          o = e.side,
          n = !1;
        if ((this.dropPointer.align !== o || this.dropContext !== e.cell) && (n = !0, this.dropPointer.align = o, this.dropContext = e.cell), "right" === o || "left" === o) {
          let e = i.getOffsets(this.dropContext, this.editMode.board.container),
            {
              width: l,
              height: a
            } = i.getDimFromOffsets(e);
          if (!this.dropPointer.isVisible || n) {
            let i = this.dropContext.row.getRowLevelInfo(t.clientY),
              n = i ? i.rowLevel.bottom - i.rowLevel.top : a;
            this.showDropPointer(e.left + ("right" === o ? l : 0) - s / 2, e.top, s, n);
          }
        } else if ("top" === o || "bottom" === o) {
          let s = i.getOffsets(this.dropContext),
            n = this.dropContext.row.getRowLevelInfo(s.top);
          n && (0 === n.index && "top" === o || n.index === n.rowLevels.length - 1 && "bottom" === o) && this.onRowDrag(t, e);
        } else this.dropContext = void 0, this.hideDropPointer();
      }
      onCellDragRowCtx(t, e) {
        let s, o;
        let n = this.options.dropPointerSize || 0,
          l = i.getOffsets(e),
          a = e.getRowLevelInfo(t.clientY);
        if (a) for (let e = 0, r = a.rowLevel.cells.length; e < r; ++e) {
          s = a.rowLevel.cells[e], o = i.getOffsets(s);
          let {
              width: d,
              height: c
            } = i.getDimFromOffsets(o),
            h = this.editMode.board.container.getBoundingClientRect(),
            p = a.rowLevel.bottom - a.rowLevel.top;
          if (s.isVisible) {
            if (c < .8 * p && o.left <= t.clientX && o.right >= t.clientX) o.top > t.clientY || o.bottom < t.clientY && (this.showDropPointer(o.left - h.left, o.top - h.top + c, d, p - c), this.dropPointer.align = "nestedBottom", this.dropContext = s), e = r;else if (0 === e && o.left > t.clientX || e === r - 1 && o.right < t.clientX) {
              if (o.left > t.clientX) ;else if (o.right < t.clientX) {
                let i = l.right - o.right;
                this.showDropPointer(o.left + (0 === e && o.left > t.clientX ? 0 : d) - n / 2 - h.left, o.top - h.top, i > n ? i : n, p || c), this.dropPointer.align = "right", this.dropContext = s;
              }
              e = r;
            }
          } else s.isVisible || s !== this.context || (this.dropContext = void 0, this.hideDropPointer());
        }
      }
      onCellDragEnd(t) {
        let s = t || this.context,
          o = this.dropContext;
        if (this.dropPointer.align && o && s) {
          if (s.row.unmountCell(s), 0 === s.row.cells.length && s.row.destroy(), ("top" === this.dropPointer.align || "bottom" === this.dropPointer.align) && o.getType() === e.guiElementType.row) o.layout.addRow({}, void 0, (o.layout.getRowIndex(o) || 0) + ("bottom" === this.dropPointer.align ? 1 : 0)).mountCell(s, 0);else if ("nestedBottom" === this.dropPointer.align && o.getType() === e.guiElementType.cell) {
            let t = o.row,
              e = t.getCellIndex(o);
            t.unmountCell(o);
            let n = t.addCell({
              id: i.getElementId("col-nested"),
              layout: {
                rows: [{}, {}]
              }
            }, void 0, e);
            n.nestedLayout && (n.nestedLayout.rows[0].mountCell(o), n.nestedLayout.rows[1].mountCell(s));
          } else o.getType() === e.guiElementType.cell && o.row.mountCell(s, (o.row.getCellIndex(o) || 0) + ("right" === this.dropPointer.align ? 1 : 0));
        }
        r(this.editMode.board, "cellResize", {
          cell: s
        }), r(s.row, "cellChange", {
          cell: s,
          row: s.row
        }), this.hideDropPointer(), s.show(), r(this.editMode, "layoutChanged", {
          type: "cellDragEnd",
          target: s,
          board: this.editMode.board
        });
      }
    }
    return c.defaultOptions = {
      enabled: !0,
      rowDropOffset: 30,
      cellDropOffset: 30,
      dropPointerSize: 16
    }, c;
  }), s(e, "Dashboards/Actions/Resizer.js", [e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/Layout/GUIElement.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
      merge: i,
      addEvent: o,
      createElement: n,
      fireEvent: l,
      removeEvent: a
    } = s;
    class r {
      static fromJSON(t, e) {
        return new r(t, e.options);
      }
      constructor(t, e) {
        this.editMode = t, this.options = i({}, r.defaultOptions, t.options.resize, e), this.currentCell = void 0, this.isX = this.options.type.indexOf("x") > -1, this.isY = this.options.type.indexOf("y") > -1, this.isActive = !1, this.startX = 0, this.tempSiblingsWidth = [], this.addSnaps();
      }
      addSnaps() {
        let e = this.editMode.iconsURLPrefix,
          s = this.options.snap.width || 0,
          i = this.options.snap.height || 0,
          o = this.editMode.board.container;
        this.snapRight = n("img", {
          className: t.classNames.resizeSnap + " " + t.classNames.resizeSnapX,
          src: e + "resize-handle.svg"
        }, {
          width: s + "px",
          height: i + "px",
          left: "-9999px"
        }, o), this.snapBottom = n("img", {
          className: t.classNames.resizeSnap + " " + t.classNames.resizeSnapY,
          src: e + "resize-handle.svg"
        }, {
          width: s + "px",
          height: i + "px",
          top: "-9999px",
          left: "0px"
        }, o), this.addResizeEvents();
      }
      disableResizer() {
        this.isActive = !1, this.currentDimension = void 0, this.currentCell = void 0, this.snapRight && (this.snapRight.style.left = "-9999px"), this.snapBottom && (this.snapBottom.style.left = "-9999px");
      }
      setSnapPositions(t) {
        this.currentCell = t;
        let s = e.getOffsets(t, this.editMode.board.container),
          i = s.left || 0,
          o = s.top || 0,
          {
            width: n,
            height: l
          } = e.getDimFromOffsets(s),
          a = this.options.snap.width || 0,
          r = this.options.snap.height || 0;
        this.snapRight && (this.snapRight.style.left = i + n - a + "px", this.snapRight.style.top = o + l / 2 - r / 2 + "px"), this.snapBottom && (this.snapBottom.style.top = o + l - r + "px", this.snapBottom.style.left = i + n / 2 - a / 2 + "px");
      }
      setTempWidthSiblings() {
        let t = this.currentCell;
        if (t) {
          let s, i;
          let o = e.getOffsets(t),
            n = t.row.getRowLevelInfo(o.top),
            l = n && n.rowLevel.cells || [];
          for (let e = 0, o = l.length; e < o && (s = (i = l[e]).container, i !== t); ++e) s && (s.style.flex = "0 0 " + s.offsetWidth + "px", this.tempSiblingsWidth.push(i));
        }
      }
      revertSiblingsAutoWidth() {
        let t, e;
        let s = this.tempSiblingsWidth;
        for (let i = 0, o = s.length; i < o; ++i) (t = s[i].container) && (t.style.flex = "1 1 0%", e = s[i]);
        this.tempSiblingsWidth = [], e && (l(this.editMode.board, "cellResize", {
          cell: e
        }), l(e.row, "cellChange", {
          cell: e,
          row: e.row
        }));
      }
      addResizeEvents() {
        let t, e, s, i;
        let n = this;
        n.mouseDownSnapX = t = function (t) {
          n.isActive = !0, n.currentDimension = "x", n.editMode.hideToolbars(["row", "cell"]), n.setTempWidthSiblings(), n.startX = t.clientX;
        }, n.mouseDownSnapY = e = function () {
          n.isActive = !0, n.currentDimension = "y", n.editMode.hideToolbars(["row", "cell"]);
        }, n.mouseMoveSnap = s = function (t) {
          n.isActive && n.onMouseMove(t);
        }, n.mouseUpSnap = i = function () {
          n.isActive && (n.isActive = !1, n.currentDimension = void 0, n.revertSiblingsAutoWidth(), n.editMode.showToolbars(["row", "cell"], n.currentCell), n.currentCell && n.setSnapPositions(n.currentCell));
        }, o(this.snapRight, "mousedown", t), o(this.snapBottom, "mousedown", e), o(document, "mousemove", s), o(document, "mouseup", i);
        let l = () => {
          n.currentCell && n.setSnapPositions(n.currentCell);
        };
        if ("function" == typeof ResizeObserver) this.resizeObserver = new ResizeObserver(l), this.resizeObserver.observe(n.editMode.board.container);else {
          let t = o(window, "resize", l);
          o(this, "destroy", t);
        }
      }
      onMouseMove(t) {
        let s = this.currentCell,
          i = s && s.container,
          o = this.currentDimension;
        if (s && i && !((s.row.layout.board.editMode || {}).dragDrop || {}).isActive) {
          let i = e.getOffsets(s),
            {
              width: n
            } = e.getDimFromOffsets(e.getOffsets(s.row));
          if ("x" === o) {
            let e = Math.min(t.clientX - i.left, n) / n * 100 + "%";
            s.setSize(e), this.startX = t.clientX;
          }
          "y" === o && s.setSize(void 0, t.clientY - i.top), l(this.editMode.board, "cellResize", {
            cell: s
          }), l(s.row, "cellChange", {
            cell: s,
            row: s.row
          }), this.setSnapPositions(s);
        }
      }
      destroy() {
        let t;
        let e = ["snapRight", "snapBottom"];
        a(document, "mousemove"), a(document, "mouseup"), this.resizeObserver?.unobserve(this.editMode.board.container);
        for (let s = 0, i = e.length; s < i; ++s) a(t = this[e[s]], "mousedown"), t.remove();
      }
      toJSON() {
        let t = this.options;
        return {
          $class: "Dashboards.Action.Resizer",
          options: {
            enabled: t.enabled,
            styles: {
              minWidth: t.styles.minWidth,
              minHeight: t.styles.minHeight
            },
            type: t.type,
            snap: {
              width: t.snap.width,
              height: t.snap.height
            }
          }
        };
      }
    }
    return r.defaultOptions = {
      enabled: !0,
      styles: {
        minWidth: 20,
        minHeight: 50
      },
      type: "xy",
      snap: {
        width: 9,
        height: 17
      }
    }, r;
  }), s(e, "Dashboards/EditMode/EditMode.js", [e["Dashboards/Layout/Cell.js"], e["Dashboards/Layout/CellHTML.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/EditMode/EditRenderer.js"], e["Dashboards/EditMode/Toolbar/CellEditToolbar.js"], e["Dashboards/EditMode/Toolbar/RowEditToolbar.js"], e["Dashboards/EditMode/SidebarPopup.js"], e["Dashboards/EditMode/EditContextMenu.js"], e["Dashboards/Actions/DragDrop.js"], e["Dashboards/Actions/Resizer.js"], e["Dashboards/EditMode/ConfirmationPopup.js"], e["Dashboards/Layout/GUIElement.js"], e["Dashboards/Globals.js"], e["Dashboards/Layout/Layout.js"], e["Core/Utilities.js"]], function (e, s, i, o, n, l, a, r, d, c, h, p, u, m, b) {
    let {
      addEvent: g,
      createElement: f,
      css: C,
      merge: v
    } = b;
    return class {
      constructor(e, s) {
        this.active = !1, this.customHTMLMode = !1, this.iconsURLPrefix = "https://code.highcharts.com/dashboards/2.3.0/gfx/dashboards-icons/", this.iconsURLPrefix = s && s.iconsURLPrefix || this.iconsURLPrefix, this.options = v({
          confirmationPopup: {
            close: {
              icon: this.iconsURLPrefix + "close.svg"
            }
          },
          contextMenu: {
            icon: this.iconsURLPrefix + "menu.svg"
          },
          dragDrop: {
            enabled: !0
          },
          enabled: !0,
          resize: {
            enabled: !0
          },
          settings: {
            enabled: !0
          },
          toolbars: {
            cell: {
              enabled: !0
            },
            row: {
              enabled: !0
            }
          },
          tools: {
            addComponentBtn: {
              enabled: !0,
              icon: this.iconsURLPrefix + "add.svg"
            }
          }
        }, s || {}), this.board = e, this.lang = v({}, i.lang, this.options.lang), e.boardWrapper = e.container, e.guiEnabled && this.initLayout(), this.isInitialized = !1, this.isContextDetectionActive = !1, this.tools = {}, e.editModeEnabled && (this.customHTMLMode = !this.board.layoutsWrapper, this.contextPointer = {
          isVisible: !1,
          element: f("div", {
            className: i.classNames.contextDetectionPointer
          }, {}, e.container)
        }, this.createTools(), this.confirmationPopup = new h(e.container, this.iconsURLPrefix, this, this.options.confirmationPopup), this.editOverlay = f("div", {
          className: i.classNames.editOverlay
        }, {}, e.container), this.isEditOverlayActive = !1, e.fullscreen = new t.FullScreen(e), this.customHTMLMode && e.container.classList.add(u.classNames.boardContainer));
      }
      onContextBtnClick() {
        this.tools.contextMenu && (this.tools.contextMenu.isVisible || this.tools.contextMenu.updatePosition(this.tools.contextButtonElement), this.tools.contextMenu.setVisible(!this.tools.contextMenu.isVisible));
      }
      toggleEditMode() {
        this.active ? this.deactivate() : this.activate();
      }
      init() {
        this.options.resize?.enabled && !this.customHTMLMode && (this.resizer = new c(this, this.options.resize)), this.dragDrop = new d(this, this.options.dragDrop), !this.options.toolbars?.row?.enabled || this.rowToolbar || this.customHTMLMode || (this.rowToolbar = new l(this)), this.options.toolbars?.cell?.enabled && !this.cellToolbar && (this.cellToolbar = new n(this)), this.sidebar || (this.sidebar = new a(this.board.container, this.iconsURLPrefix, this)), this.isInitialized = !0;
      }
      initEvents() {
        let t = this,
          e = t.board;
        if (this.customHTMLMode) {
          let s = e.mountedComponents.length;
          for (let i = 0; i < s; ++i) t.setCellEvents(e.mountedComponents[i].cell);
        } else for (let s = 0, i = e.layouts.length; s < i; ++s) t.setLayoutEvents(e.layouts[s]);
        t.cellToolbar && (g(t.cellToolbar.container, "mouseenter", function () {
          t.stopContextDetection();
        }), g(t.cellToolbar.container, "mouseleave", function () {
          t.isContextDetectionActive = !0;
        })), t.rowToolbar && (g(t.rowToolbar.container, "mouseenter", function () {
          t.stopContextDetection();
        }), g(t.rowToolbar.container, "mouseleave", function () {
          t.isContextDetectionActive = !0;
        }));
        let s = this.customHTMLMode ? e.container : e.layoutsWrapper;
        g(s, "mousemove", t.onDetectContext.bind(t)), g(s, "click", t.onContextConfirm.bind(t)), g(s, "mouseleave", () => {
          t.hideContextPointer();
        });
      }
      initLayout() {
        let t = this.board;
        t.container.innerHTML = "", t.container = f("div", {
          className: u.classNames.boardContainer
        }, {}, t.boardWrapper), t.layoutsWrapper = f("div", {
          className: u.classNames.layoutsWrapper
        }, {}, t.container), t.options.gui && this.setLayouts(t.options.gui), t.options.layoutsJSON && !t.layouts.length && this.setLayoutsFromJSON(t.options.layoutsJSON);
      }
      setLayouts(t) {
        let e = this.board,
          s = t.layouts;
        for (let i = 0, o = s.length; i < o; ++i) e.layouts.push(new m(e, v({}, t.layoutOptions, s[i])));
      }
      setLayoutsFromJSON(t) {
        let e;
        let s = this.board;
        for (let i = 0, o = t.length; i < o; ++i) (e = m.fromJSON(t[i], s)) && s.layouts.push(e);
      }
      setLayoutEvents(t) {
        for (let e = 0, s = t.rows.length; e < s; ++e) {
          let s = t.rows[e];
          this.setRowEvents(s);
          for (let t = 0, e = s.cells.length; t < e; ++t) this.setCellEvents(s.cells[t]);
        }
      }
      setRowEvents(t) {
        let e = this;
        if (e.dragDrop) {
          let s = e.dragDrop;
          g(t.container, "mouseenter", function () {
            e.isContextDetectionActive && (e.mouseRowContext = t);
          }), g(t.container, "mousemove", function (e) {
            s.isActive && e.target === t.container && (s.mouseRowContext = t);
          }), g(t.container, "mouseleave", function () {
            s.isActive && s.mouseRowContext === t && (s.mouseRowContext = void 0), e.isContextDetectionActive && (e.mouseRowContext = void 0);
          });
        }
      }
      setCellEvents(t) {
        let e = this;
        if (t instanceof s) g(t.container, "mouseenter", function () {
          e.isContextDetectionActive && (e.mouseCellContext = t);
        });else if (t.nestedLayout) e.setLayoutEvents(t.nestedLayout);else if (e.cellToolbar && t.container && (g(t.container, "mouseenter", function () {
          e.isContextDetectionActive && (e.mouseCellContext = t);
        }), e.dragDrop || e.resizer)) {
          let s = e.dragDrop;
          g(t.container, "mousemove", function (e) {
            s && s.isActive && e.target === t.container && (s.mouseCellContext = t, s.mouseRowContext = void 0);
          }), g(t.container, "mouseleave", function () {
            s && s.isActive && s.mouseCellContext === t && (s.mouseCellContext = void 0), e.isContextDetectionActive && (e.mouseCellContext = void 0);
          });
        }
      }
      activate() {
        this.isInitialized || (this.init(), this.initEvents()), this.board.container.classList.add(i.classNames.editModeEnabled), this.addComponentBtn && (this.addComponentBtn.style.display = "block"), this.active = !0, this.isContextDetectionActive = !0;
      }
      deactivate() {
        this.board.container.classList.remove(i.classNames.editModeEnabled), this.hideToolbars(), this.editCellContext && this.editCellContext instanceof e && this.editCellContext.row?.setHighlight(), this.addComponentBtn && (this.addComponentBtn.style.display = "none"), this.resizer && this.resizer.disableResizer(), this.board.layoutsWrapper && (this.board.layoutsWrapper.style.width = "100%"), this.board.reflow(), this.active = !1, this.stopContextDetection(), this.editCellContext = void 0, this.potentialCellContext = void 0;
      }
      isActive() {
        return this.active;
      }
      hideToolbars(t) {
        let e = t || ["cell", "row", "sidebar"];
        for (let t = 0, s = e.length; t < s; ++t) switch (e[t]) {
          case "cell":
            this.cellToolbar && this.cellToolbar.isVisible && this.cellToolbar.hide();
            break;
          case "row":
            this.rowToolbar && this.rowToolbar.isVisible && this.rowToolbar.hide();
            break;
          case "sidebar":
            this.sidebar && this.sidebar.isVisible && this.sidebar.hide();
        }
      }
      showToolbars(t, e) {
        let s = t || ["cell", "row", "sidebar"];
        for (let t = 0, i = s.length; t < i; ++t) switch (s[t]) {
          case "cell":
            e && this.cellToolbar && this.cellToolbar.showToolbar(e);
            break;
          case "row":
            e && e.row && this.rowToolbar && this.rowToolbar.showToolbar(e.row);
            break;
          case "sidebar":
            this.sidebar && !this.sidebar.isVisible && this.sidebar.show();
        }
      }
      createTools() {
        let t = this,
          {
            board: e,
            options: s,
            tools: n
          } = t;
        if (n.container = document.createElement("div"), n.container.classList.add(i.classNames.editTools), e.layoutsWrapper ? e.layoutsWrapper.parentNode.insertBefore(n.container, e.layoutsWrapper) : e.container.insertBefore(n.container, e.container.firstChild), s.contextMenu && s.contextMenu.enabled && (n.contextButtonElement = o.renderContextButton(n.container, t), t.tools.contextMenu || (t.tools.contextMenu = new r(t.board.container, t.options.contextMenu || {}, t))), s.tools?.addComponentBtn?.enabled && s.toolbars?.cell?.enabled && !this.customHTMLMode) {
          let e = s.tools.addComponentBtn.icon;
          this.addComponentBtn = o.renderButton(n.container, {
            className: i.classNames.editToolsBtn,
            icon: e,
            text: this.lang.addComponent,
            callback: () => {
              t.sidebar && (t.sidebar.show(), t.setEditOverlay());
            },
            style: {
              display: "none"
            }
          });
        }
      }
      onDetectContext() {
        let t;
        if (this.isActive() && this.isContextDetectionActive && (this.mouseCellContext || this.mouseRowContext) && !(this.dragDrop || {}).isActive && (this.mouseCellContext ? t = this.mouseCellContext : this.mouseRowContext && (t = this.mouseRowContext.layout.parentCell), this.potentialCellContext = t, t)) {
          let e = p.getOffsets(t, this.board.container),
            {
              width: s,
              height: i
            } = p.getDimFromOffsets(e);
          this.showContextPointer(e.left, e.top, s, i);
        }
      }
      stopContextDetection() {
        this.isContextDetectionActive = !1, this.dragDrop && (this.dragDrop.mouseCellContext = void 0), this.mouseCellContext = void 0, this.hideContextPointer();
      }
      onContextConfirm() {
        this.isContextDetectionActive && this.potentialCellContext && this.editCellContext !== this.potentialCellContext && this.setEditCellContext(this.potentialCellContext, this.editCellContext);
      }
      setEditCellContext(t, e) {
        if (t instanceof s || e instanceof s) this.editCellContext = t, this.cellToolbar?.showToolbar(t);else {
          let s = e?.row;
          this.editCellContext = t, this.showToolbars(["row", "cell"], t), (!s || s !== t.row) && (s && s.setHighlight(), t.row && t.row.setHighlight()), this.resizer && this.resizer.setSnapPositions(t);
        }
      }
      showContextPointer(t, e, s, i) {
        this.contextPointer && (this.contextPointer.isVisible = !0, C(this.contextPointer.element, {
          display: "block",
          left: t + "px",
          top: e + "px",
          height: i + "px",
          width: s + "px"
        }));
      }
      hideContextPointer() {
        this.contextPointer?.isVisible && (this.contextPointer.isVisible = !1, this.contextPointer.element.style.display = "none");
      }
      setEditOverlay(t) {
        let e = this.editOverlay,
          s = e?.classList.contains(i.classNames.editOverlayActive);
        t || s ? t && s && (e?.classList.remove(i.classNames.editOverlayActive), this.isEditOverlayActive = !1) : (e?.classList.add(i.classNames.editOverlayActive), this.isEditOverlayActive = !0);
      }
    };
  }), s(e, "Dashboards/EditMode/Fullscreen.js", [e["Core/Utilities.js"], e["Dashboards/Globals.js"]], function (t, e) {
    let {
      addEvent: s
    } = t;
    return class {
      constructor(t) {
        this.isOpen = !1, this.board = t, this.board.boardWrapper.classList.add(e.classNamePrefix + "-fullscreen");
      }
      toggle() {
        this[this.isOpen ? "close" : "open"]();
      }
      open() {
        if (this.isOpen) return;
        let t = this,
          e = t.board,
          i = s(e.boardWrapper.ownerDocument, "fullscreenchange", function () {
            t.isOpen ? (t.isOpen = !1, t.close()) : (t.isOpen = !0, t.setButtonText());
          });
        t.unbindFullscreenEvent = () => {
          i();
        }, e.boardWrapper.requestFullscreen().catch(() => {
          throw Error("Full screen is not supported.");
        });
      }
      close() {
        let t = this.board;
        this.isOpen && t.boardWrapper.ownerDocument instanceof Document && t.boardWrapper.ownerDocument.exitFullscreen(), this.unbindFullscreenEvent && (this.unbindFullscreenEvent = this.unbindFullscreenEvent()), this.isOpen = !1, this.setButtonText();
      }
      setButtonText() {
        let t = this.board.editMode,
          e = t && t.tools.contextMenu,
          s = e && e.items.viewFullscreen;
        if (s && s.innerElement) {
          let e = t.lang;
          s.innerElement.innerHTML = (this.isOpen ? e.exitFullscreen : e.viewFullscreen) || "";
        }
      }
    };
  }), s(e, "masters/modules/layout.src.js", [e["Dashboards/Globals.js"], e["Dashboards/EditMode/EditMode.js"], e["Dashboards/EditMode/Fullscreen.js"]], function (t, e, s) {
    return t.EditMode = e, t.FullScreen = s, t;
  });
});