import {Component, ElementRef, OnInit, ViewChild, viewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { User } from '../../models/user/user';
import { take } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Region } from '../../models/region';
import { Forest } from '../../models/forest';
import { District } from '../../models/district';
import { environment } from '../../../environments/environment';
import { RegionsService } from '../../services/regions/regions.service';
import { UserRole } from '../../models/user/user-role';
import { MatSelectionList, MatSelectionListChange} from '@angular/material/list';
import { AppraisalGroupRates } from '../../models/appraisal-group-rates';
import { AppraisalZoneEdw } from '../../models/appraisal-zone-edw';
import { Uom } from '../../models/uom';
import { UserRoleRequestsService } from '../../services/admin/user-role-requests.service';
import { UserRoleRequest } from '../../models/admin/user-role-requests/user-role-request';
import { ForestsService } from '../../services/forests/forests.service';




@Component({
    selector: 'app-request-permissions',
    templateUrl: './request-permissions.html',
    styleUrls: ['./request-permissions.scss'],
})

export class RequestPermissionsComponent implements OnInit {    
    user!: User;
    form!: FormGroup;
    guest: boolean = true;
    isEditableNew: boolean = true;
    baseUrl = environment.apiUrl;
    adminRoleSelectForm!:FormGroup;
    adminType = new FormControl;
    regionCode = new FormControl;
    forestCode = new FormControl;
    districtCode = new FormControl;
    regions: Region[] = [];
    forests!: Forest[] | null;
    districts!: District[] | null;
    appraisalZonesEdw: AppraisalZoneEdw[] = [];
    appraisalGroupRates: AppraisalGroupRates[] = [];
    regionId: number = 0;
    forestId: number = 0;
    districtId: number = 0;
    rowsForm!: FormArray | null;
    myControl = new FormControl(['forestCode']);
    appraisalZoneForm!: FormGroup;
    appraisalZoneCodeEdw!: string | null;
    uom!: Uom[];
    roles: string[]=[];
    selectedRegions: any[] = []
    selectedValue: any;
    filteredAndMapData!: Forest[] | null;
    forestcontrol = new FormControl(['forestCode'])
    codeForest:any[] = [];
    nRegions: UserRoleRequest[] = [];
    checkboxes!: FormArray | null;
    forestData:any[][] = [];
   
   
  
    @ViewChild('adminroles') adminroles!:MatSelectionList;
    @ViewChild('btn') btn!:ElementRef;
    @ViewChild('selectedRegionList') selectedRegionList!: MatSelectionList
    @ViewChild('selectedForestList') selectedForestList!: MatSelectionList
  

    constructor(
      private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private regionsService: RegionsService,
        private forestservice:ForestsService

    ) { }

    
    ngOnInit(): void {
        this.checkCurrentUser();
        this.setRegions();
        this.loadRegions();
        
        this.usersService.currentUser$.pipe(take(1)).subscribe({
          next: user => {
            if (user){
              this.user = user;
              this.roles = this.user.userRoles
              .filter(role => role.role.name !== 'Guest')
              .map(role =>role.role.name)
              this.form.controls['adminType'].value({
                adminType: this.user.userRoles.map(role =>role.role.name)
              })
            }
          },
          error: error => {
            console.log(error);
          }
        });
      
     
        if(this.appraisalGroupRates != null){
          this.form = this.fb.group({
            appraisalZoneForm: this.fb.group({    
              regionCode: [null, Validators.required],
              forestCode: [null, Validators.required],
              districtCode: [null, Validators.required],
              checkboxes: [null, Validators.required],
              appraisalZoneCodeEdw: [{ value: null, disabled: true}, Validators.required],
              region: [{value: null, disabled: true}, Validators.required],
              forest: [{value: null, disabled: true}, Validators.required],
              district: [{value: null, disabled: true}, Validators.required],
          
            }),
           
          }); 
          this.appraisalZoneForm = this.form.controls['appraisalZoneForm'] as FormGroup;
        }

     
    }



  
   
   SelectAll(){
      this.selectedForestList.selectAll()
   }
    
   DeSelectAll(){
      this.selectedForestList.deselectAll()
   }

    checkCurrentUser() {
        this.usersService.currentUser$.pipe(take(1)).subscribe({
            next: user => {
              if (user){
                this.user = user;     
              }else{
                console.log("User not looged in.")
              }
            }
        })
    }

    

    setRegions() {
      const regionsString = localStorage.getItem('regions');
      if (!regionsString){
          this.loadRegions();
      } else {
          const regions: Region[] = JSON.parse(regionsString);
          this.regions = regions;
          this.regionsService.setRegions(regions);
      }
    }

    setForests() {
      let r = this.regions?.filter(r => r.regionCode == this.appraisalZoneForm.controls['regionCode'].value);
      if(r != null){
          this.appraisalZoneForm.controls['region'].patchValue(r[0]);
          this.forests = r[0].forests.sort((a, b) => a.forestCode > b.forestCode ? 1 : -1);

          this.districts =null;
          console.log("ALL FOREST", this.forests);
          
      }
      
      this.appraisalZoneForm.controls['forestCode'].patchValue(null);
      this.appraisalZoneForm.controls['districtCode'].patchValue(null);
      this.appraisalZoneForm?.controls['appraisalZoneCodeEdw'].patchValue(null);
      this.appraisalZoneCodeEdw = null;
      
    }
   
  setDistricts() {
      let f = this.forests?.filter(f => f.forestCode == this.appraisalZoneForm.controls['forestCode'].value);
      
      if(f != null ){
          this.appraisalZoneForm.controls['forest'].patchValue(f[0]);
          this.districts = this.districts = f[0].districts.sort((a, b) => a.districtCode > b.districtCode ? 1 : -1)

        
         
      }     
     
      this.appraisalZoneForm.controls['districtCode'].patchValue(null);
      this.appraisalZoneForm.controls['appraisalZoneCodeEdw'].patchValue(null); 
      this.appraisalZoneCodeEdw = null;
  }

  loadRegions() {
    this.regionsService.regions$.subscribe({
      next: response => {
          if (response) {
            this.regions = response;
            
          }
      }
    })
 }

//  newUser: UserRoleRequest = {
//    roleId:1,
//    userId: 1,
//    regionId: 1
//  }


/*fetchData(){
  this.regionsService.regions$.subscribe({
    next: response => {
        if (response) {
          this.regions = response;
          this.codeForest = response.map(x => x.forests)
          this.forestData = this.codeForest.flatMap(array => array.map((obj:any) => obj.forestName));
          
          console.log("Forest", this.forestData)
        }
    }
  })
}


createCheckboxes(){
  const checkboxes = this.form.get('appraisalZoneForm.checkboxes') as FormArray;
  this.forestData.forEach(forest =>{
    forest.forEach(forest =>{
      return checkboxes.push(this.fb.control(false)) //checkboxes unchecked
    })
  })
}

getSelectedForests(){
  const selectedForests:any[] = [];
  const checkboxes = this.form.get('appraisalZoneForm.checkboxes') as FormArray;

  checkboxes.controls.forEach((control, index)=>{
    if(control.value){
       const arrayIndex = Math.floor(index / this.forestData[0].length) // calculate array index
       const forestIndex = index % this.forestData[0].length //calculate forest index inside array
       selectedForests.push(this.forestData[arrayIndex][arrayIndex])
    }
  });
  return selectedForests;
}*/

 
deselectAll(){
  this.adminroles.deselectAll();
  this.btn.nativeElement.style.display ="none"
}
      

onSelectionChange(event:MatSelectionListChange){
  this.setForests();       
}

onSelectionChangeDistrict(event:MatSelectionListChange){
  this.setDistricts();
}

onSubmit(e:Event){
  console.log("FORM VALUES",this.adminRoleSelectForm.value)
}

   
}
