import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { take } from 'rxjs';
import { User } from '../../../models/user/user';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  user!: User;

  constructor(private usersService: UsersService) {
      console.log("constructor not implemented.");
  }

  ngOnInit() {
    this.usersService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user){
          this.user = user;
        }
      }
    });
  }

  
}

