<div class="usa-width-one-whole nt-info-area">
    <div class="nt-title-text">
        <h2>National TEA - Request Permissions</h2>
    </div>
    <div class="grid-container">
        <div class="grid-row">
            <div class="grid-col-12">
                <form [formGroup]="form" (ngSubmit)="onSubmit($event)">
                  <!--First List-->
                    <div class="list-grid-wrapper">
                        <div class="list-grid-container">
                            <h4 class="listbox-heading">Select Your Desired role(s)*</h4>
                            <div class="listbox">
                                 
                                    <mat-selection-list  [formControl]="adminType" [multiple]="false" #adminroles name="adminRole" aria-label="Roles">
                                            <mat-list-option *ngFor="let role of roles" [value]="role" class="custom-radio-button">{{role}}</mat-list-option>
                                    </mat-selection-list>
                                
                            </div>
                        
                        </div>
                        <div class="list-grid-container"> 
                            <h4 class="listbox-heading">Select Your Desired Region(s)*</h4>  
                            <div class="listbox">
                                <mat-selection-list formControlName="regionCode" [formControl]="$any(appraisalZoneForm.controls['regionCode'])" name="regionCode" #selectedRegionList (selectionChange)="onSelectionChange($event);" aria-label="Select a Region">
                                    <mat-list-option *ngFor="let region of regions" value="{{region.regionCode}}" class="matListOption">
                                        {{region.regionCode}}-{{region.regionName}}
                                    </mat-list-option>
                                </mat-selection-list>
                              
                            </div>
                           
                        </div>
                    </div>
                   
                    <!--Second list-->
                    <div class="list-grid-wrapper">

                      
                        <div class="usa-accordion">
                            <h4 class="usa-accordion__heading">
                              <button
                                type="button"
                                class="usa-accordion__button"
                                aria-expanded="true"
                                aria-controls="a1"
                              >
                                First Amendment
                              </button>
                            </h4>
                            
                                <div class="listbox">
                                   
                                    <mat-selection-list [formControl]="$any(appraisalZoneForm.controls['forestCode'])" name="forestCode" #selectedForestList (selectionChange)="onSelectionChangeDistrict($event)" aria-label="Select a Forest"[multiple]="true">
                                        <div class="btnselectioncontainer" *ngIf="$any(appraisalZoneForm.controls['regionCode']).value">
                                            <button type="button" class="btnSelection" (click)="SelectAll()">Select All</button>
                                            <button type="button" class="btnSelection" (click)="DeSelectAll()">Deselect All</button>
                                        </div>
                                        <mat-list-option *ngFor="let forest of forests;" [value]="forest.forestCode" class="matListOption">
                                            {{forest.forestCode}}-{{forest.forestName}}
                                        </mat-list-option>
                                       

                                    </mat-selection-list>
                                    <h1>District</h1>
                                    <mat-selection-list [formControl]="$any(appraisalZoneForm.controls['districtCode'])" aria-label="Select a District">
                                        <mat-list-option *ngFor="let district of districts" [value]="district.districtCode" class="matListOption">{{district.districtCode}} - {{district.districtName}}</mat-list-option>
                                    </mat-selection-list>
                                </div>
                            
                        </div>



                        <!-- <div class="list-grid-container">
                            <h4 class="listbox-heading">Select Your Desired forest(s)*</h4>  
                            <div class="listbox">
                               
                                <mat-selection-list formControlName="forestCode" [formControl]="$any(appraisalZoneForm.controls['forestCode'])" name="forestCode" #selectedForestList (selectionChange)="onSelectionChangeDistrict($event)" aria-label="Select a Forest">
                                    <div class="btnselectioncontainer" *ngIf="$any(appraisalZoneForm.controls['regionCode']).value">
                                        <button type="button" class="btnSelection" (click)="SelectAll()">Select All</button>
                                        <button type="button" class="btnSelection" (click)="DeSelectAll()">Deselect All</button>
                                    </div>
                                    <mat-list-option *ngFor="let forest of forests" value="{{forest.forestCode}}" class="matListOption">{{forest.forestCode}}-{{forest.forestName}}</mat-list-option>
                                </mat-selection-list>
                            </div>
                          
                        </div> -->

                        <!-- <div class="list-grid-container">
                            <h4 class="listbox-heading">Select Your Desired District(s)*</h4>  
                            <div class="listbox">
                                <mat-selection-list formControlName="districtCode" [formControl]="$any(appraisalZoneForm.controls['districtCode'])" aria-label="Select a District">
                                    <mat-list-option *ngFor="let district of districts" [value]="district.districtCode" class="matListOption">{{district.districtCode}} - {{district.districtName}}</mat-list-option>
                                </mat-selection-list>
                            </div>
                            
                        </div> -->
                    </div>
                 

                    <div class="list-button-wrapper--rightAlign">
                        <div class="btn-container" style="padding-left:0rem;">
                            <button type="submit" class="usa-button usa-button--Secondary" [disabled]="form.invalid">Submit Request</button>
                        </div>
                        <div class="btn-container"></div>
                    </div>
                  
                    
                </form>
                <pre>{{$any(appraisalZoneForm.controls['forestCode']).value}}</pre>
            </div>
            <!-- <div class="grid-col-4">
            </div> -->
        </div>
    </div>
</div>
