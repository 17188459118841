<div class="usa-width-one-whole nt-info-area">
    <div class="grid-container" id="">
      <div>
        <div class="grid-row">
          <div class="tablet:grid-col">
                <div class="usa-form-group">
                    <h2>User Management</h2>
                    

                    <div class="usa-table-container--scrollable">
                      <table class="usa-table">
                        <caption>
                          Pending Requests {{ pendingRequests.length }}
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col" style="min-width: 200px;">Email</th>
                            <th scope="col" style="min-width: 200px;">Role</th>
                            <th scope="col" style="min-width: 200px;">Region</th>
                            <th scope="col" style="min-width: 200px;">Forests</th>
                            <th scope="col" style="min-width: 200px;">Districts</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container formArray="rows" *ngFor="let request of rowsForm?.controls as FormArray">
                            <tr [ngClass]="request.get('isEditable')?.value === true ? 'edit-rate-row': ''" >
                              <th scope="row">{{request.get('firstName')?.value}}</th>
                              <td>
                                {{request.get('lastName')?.value}}
                              </td>
                              <td>
                                {{request.get('email')?.value}}
                              </td>
                              <td>
                                {{request.get('roleName')?.value}}
                              </td>
                              <td>
                                {{request.get('regionName')?.value}}
                              </td>
                              <td>
                                <!-- {{request.get('forestName')?.value}} -->
                              </td>
                              <td>
                                <!-- {{request.get('districtName')?.value}} -->
                              </td>
                              <td>
                                <span (click)="viewStatus(request.get('id')?.value)" class="openLink">View Status</span>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                </div>
          </div>
        </div>
    </div>
</div>


<app-modal id="modal-3" aria-modal="true" aria-labelledby="User Role Management" >
  <div class="content-container" tabindex="-1" #modal Focustrap><!--Focustrap-->
    <button type="button"  class="closeBtn" (click)="modalService.close('modal-3')"  aria-label="Close Modal" tabindex="0" cdkFocusRegionStart>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.7456 3.33001C20.3551 2.93948 19.7219 2.93948 19.3314 3.33001L12.037 10.6244L4.74263 3.33001C4.35211 2.93948 3.71894 2.93948 3.32842 3.33001C2.93789 3.72053 2.93789 4.3537 3.32842 4.74422L10.6228 12.0386L3.32844 19.3329C2.93791 19.7235 2.93791 20.3566 3.32844 20.7472C3.71896 21.1377 4.35213 21.1377 4.74265 20.7472L12.037 13.4528L19.3314 20.7472C19.7219 21.1377 20.3551 21.1377 20.7456 20.7472C21.1361 20.3566 21.1361 19.7235 20.7456 19.333L13.4512 12.0386L20.7456 4.74422C21.1361 4.3537 21.1361 3.72053 20.7456 3.33001Z" fill="#757575"/>
      </svg>
    </button>
    
  
    <div class="modalForm">
      <div class="labelWrapper">
        <div>Request</div>
        <div>Modifications</div>
      </div>
      
      
       <div class="dual-list-box">
          
          <div class="list-box">
            <h4><span class="title">Role</span><span class="role"><small>DataAdministrator</small></span></h4>

            <ul class="availablelist" id="order-0">
                <h4 class="remove"><b>Forest</b></h4>
                <div id="availableList" class="datalist" (keydown)="onKeyDown($event)">
                  <li *ngFor="let item of availableItems" tabindex="0" (click)="selectItem(item)" class="currentActivelist" aria-label="Available Forest">{{item.name}}</li><!--{{item.regionCode}} - {{item.regionName}}-->
                </div>
            </ul>

            <ul class="availablelist" id="order-2">
              <h4 class="remove"><b>District:</b></h4>
              <div id="availableDistrictList" class="datalist" (keydown)="handleKeyDown($event)">
                <li *ngFor="let district of districtItems" tabindex="0" (click)="selectDisItem(district)" class="currentActivelist" aria-label="Available District">{{district}}</li>
              </div>
            </ul>
          </div>
          

          <div class="list-box">
            <h4>
              <span class="title">Role</span>
              <span class="role">
              <small>DataAdministrator</small></span>
              
            </h4>
            <ul class="selectedList" id="order-1">
                <h4 class="remove">
                  <span class="title">Forest</span>
                </h4>
                <div id="selectedList" class="datalist" (keydown)="onKeyDown($event)">
                  <li *ngFor="let item of selectedItems; let i = index" 
                  tabindex="0" 
                  (click)="selectItem(item)" 
                  class="currentActivelist" aria-label="Selected Forest">{{item.name}}</li><!--{{item.regionCode}} - {{item.regionName}}-->
                </div>
            </ul>

            <ul id="selectedDistrictList order-3" (keydown)="handleKeyDown($event)">
              <h4 class="remove">
                <span class="title">District</span>
              </h4>
              <div id="selectedDistrictList" class="datalist">
                <li *ngFor="let district of selectedDisItems" 
                tabindex="0" 
                (click)="selectDisItem(district)" 
                class="currentActivelist" aria-label="Selected District">{{district}}</li>
              </div>
            </ul>
          </div>
       </div>
  
       <div class="duallistbtns">
        <button type="button" (click)="moveItemToSelected()" tabindex="0" aria-label="Forest Move To Right Arrow">&#8250;</button>
        <button type="button" (click)="moveItemToAvailable()" tabindex="0" aria-label="Forest Move To Left Arrow">&#8249;</button>
        <span class="btnControls"><small>Modify Forest</small></span>
      </div>
      <div class="duallistdistrictbtn">
        <button type="button" (click)="moveItemToDistrictSelected()" tabindex="0" aria-label="District Move To Right Arrow">&#8250;</button>
        <button type="button" (click)="moveItemToDistrictAvailable()" tabindex="0" aria-label="District Move To Left Arrow">&#8249;</button>
        <span class="btnControls"><small>Modify District</small></span>
      </div>

    
      <div class="notecontainerWrapper">
        <form [formGroup]="notes">
          <label for="notes">Add Note (options)</label>
          <textarea id="notes" formControlName="notes" rows="4" cols="50" placeholder="Enter text here"></textarea>

          <div class="buttonWrapper">
            <button class="btn btn-sm btn-success" tabindex="0">Approve</button>
            <button class="btn btn-sm btn-danger" tabindex="0">Reject</button>
            <button type="button" class="btn btn-sm btn-outline-success" tabindex="0">Save as Pending</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</app-modal>