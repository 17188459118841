<div class="usa-width-one-whole nt-info-area">
    <form class="usa-form" *ngIf="appraisalForm" [formGroup]="appraisalForm" (ngSubmit)="appraisalForm.valid && submit()" autocomplete="off" id="estimator_usa-form">
        <div class="grid-container" id="main-accordion-wrapper">
            <div class="grid-row" id="accordion-row">
                <!-- -->
                    <div class="grid-col-7" id="accordions">
                        <div class="usa-accordion usa-accordion--bordered">
                            <div class="row" *ngIf="validationErrors">
                                <ul class="text-danger">
                                    <li *ngFor="let error of validationErrors">
                                        {{error}}
                                    </li>
                                </ul>
                            </div>
                            
                            <app-general-information formGroupName="generalInformation"></app-general-information> 
                            <app-volume-information formGroupName="volumeInformation"></app-volume-information>
                            <app-choose-appraisal-type formGroupName="chooseAppraisalType"></app-choose-appraisal-type>
                            <app-estimate-sale-operating-costs formGroupName="estimateSaleOperatingCosts"></app-estimate-sale-operating-costs>
                            <app-contract-calculations formGroupName="contractCalculations"></app-contract-calculations>

                        </div>
                    </div>
                    <div class="grid-col-4" id="estimator">
                        <app-estimator formGroupName="estimator"></app-estimator> 
                    </div>
            
            
            </div>
        </div>
    </form>
</div>

