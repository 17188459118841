<div class="grid-row">
    <div class="tablet:grid-col">
        <div class="usa-form-group" id="appraisal-total-group"  style="padding:0;">
            
            <div class="tooltip" style="display:flex; justify-content: space-between!important; width:100%; padding:0 10px">
                
                <legend class="usa-legend" style="margin-top:0; padding-top:0; margin-bottom:0;">Appraisal Group Value</legend>
                    <div class="icon_trigger tooltip__button top" aria-labelledby="standardRateAppraisalToolTip">
                        
                        <svg width="15" aria-hidden="true" focusable="false" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5993 7.79671C14.5993 11.6642 11.464 14.7995 7.59652 14.7995C3.72899 14.7995 0.59375 11.6642 0.59375 7.79671C0.59375 3.92919 3.72899 0.793945 7.59652 0.793945C11.464 0.793945 14.5993 3.92919 14.5993 7.79671ZM6.89624 9.89754C6.89624 8.62213 7.55597 8.11646 8.13601 7.67185C8.59105 7.32305 8.99707 7.01184 8.99707 6.39616C8.99707 5.62585 8.36682 4.99561 7.59652 4.99561C6.82621 4.99561 6.19596 5.62585 6.19596 6.39616H4.79541C4.79541 4.84855 6.04891 3.59505 7.59652 3.59505C9.14413 3.59505 10.3976 4.84855 10.3976 6.39616C10.3976 7.29454 9.84441 7.77802 9.30575 8.24874C8.79469 8.69538 8.29679 9.13046 8.29679 9.89754H6.89624ZM8.29679 10.948V12.3485H6.89624V10.948H8.29679Z" fill="#5B5B5B"/>
                        </svg>  
                    </div>
                
                <div id="standardRateAppraisalToolTip" class="tooltip__content" role="tooltip">
                    Volume x Standard Rate = Appraisal Group Value
                </div>
            </div>    
            <span id="atg-value">
                {{ 
                    totalGroupValue.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }) 
                }}
            </span>
        </div>
        

    </div>
</div>
            
            