import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { TreeProductsService } from '../../../../services/tree-products/tree-products.service';
import { UomService } from '../../../../services/uom/uom.service';
import { TreeSpeciesService } from '../../../../services/tree-species/tree-species.service';
import { TreeProduct } from '../../../../models/tree-product';
import { TreeSpecies } from '../../../../models/tree-species';
import { Uom } from '../../../../models/uom';
import { AppraisalGroupsService } from '../../../../services/appraisal-groups/appraisal-groups.service';
import { AppraisalGroup } from '../../../../models/appraisal-group';
import { LoadingHandler } from '../../../../services/loading-handler';

@Component({
    selector: 'app-volume-information',
    templateUrl: './volume-information.component.html',
    styleUrls: ['./volume-information.component.scss']
})

export class VolumeInformationComponent implements OnInit {
    appraisals?: string;
    
    
    loadingHandler = new LoadingHandler()
    @Input() formGroupName!: string;
    @Output() childEvent = new EventEmitter();
    form!: FormGroup;
    generalInfoForm!: FormGroup;
    calculationsForm!: FormGroup;
    conditionButtonValues: any[] = [ {live: true}, {dead: false} ];
    uomButtonValues: any[] = [{CCF: 1}, {Ton: 2}, {MBF: 3} ];
    treeProducts!: TreeProduct[];
    treeSpecies!: TreeSpecies[];
    uom!: Uom[];
    uomShortNameControl!: FormControl;
    treeProductNameControl!: FormControl;
    treeSpeciesNameControl!: FormControl;
    appraisalZoneCodeControl!: FormControl;
    regionIdControl!: FormControl;
    forestIdControl!: FormControl;
    districtIdControl!: FormControl;
    regionCodeControl!: FormControl;
    forestCodeControl!: FormControl;
    districtCodeControl!: FormControl;
    appraisalGroupIdControl!: FormControl;
    appraisalGroupNameControl!: FormControl;
    appraisalGroupControl!: FormControl;
    treeProductIdControl!: FormControl;
    treeSpeciesIdControl!: FormControl;
    treeConditionControl!: FormControl;
    appraisalGroup!: AppraisalGroup;
    minimumRateControl!: FormControl;
    standardRateControl!: FormControl;

    constructor(private fb: FormBuilder, 
        private rootFormGroup: FormGroupDirective,
        private http: HttpClient,
        private treeProductsService: TreeProductsService,
        private treeSpeciesService: TreeSpeciesService,
        private uomService: UomService,
        private appraisalGroupsService: AppraisalGroupsService) {
    }


    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.generalInfoForm = this.rootFormGroup.control.get("generalInformation") as FormGroup;
        this.calculationsForm = this.rootFormGroup.control.get("calculations") as FormGroup;
        this.appraisalZoneCodeControl = this.generalInfoForm.controls["appraisalZoneCode"] as FormControl;
        this.regionIdControl = this.generalInfoForm.controls["regionId"] as FormControl;
        this.forestIdControl = this.generalInfoForm.controls["forestId"] as FormControl;
        this.districtIdControl = this.generalInfoForm.controls["districtId"] as FormControl;
        this.regionCodeControl = this.generalInfoForm.controls["regionCode"] as FormControl;
        this.forestCodeControl = this.generalInfoForm.controls["forestCode"] as FormControl;
        this.districtCodeControl = this.generalInfoForm.controls["districtCode"] as FormControl;
        this.appraisalGroupIdControl = this.form.controls['appraisalGroupId'] as FormControl;
        this.appraisalGroupNameControl = this.form.controls['appraisalGroupName'] as FormControl;
        this.appraisalGroupControl = this.form.controls['appraisalGroup'] as FormControl;
        this.treeSpeciesNameControl = this.form.controls['treeSpeciesName'] as FormControl;
        this.treeProductNameControl = this.form.controls['treeProductName'] as FormControl;
        this.treeProductIdControl = this.form.controls['treeProductId'] as FormControl;
        this.treeSpeciesIdControl = this.form.controls['treeSpeciesId'] as FormControl;
        this.treeConditionControl = this.form.controls['treeCondition'] as FormControl;
        this.uomShortNameControl = this.form.controls['uomShortName'] as FormControl;

        this.minimumRateControl = this.calculationsForm.controls['minimumRate'] as FormControl;
        this.standardRateControl = this.calculationsForm.controls['standardRate'] as FormControl;
        
        this.getTreeProducts();
        this.getTreeSpecies();
        this.getUom();

       
        
    }

    getTreeProducts(){
        this.loadingHandler.start()
        this.treeProductsService.getTreeProducts().subscribe({
            next: response => {
            console.log(response);
              if(response != null){
                this.treeProducts = response;
                this.loadingHandler.finish();
              }
                
            }
        })
    }

    getTreeSpecies(){
        this.treeSpeciesService.getTreeSpecies().subscribe({
            next: response => {
            console.log(response);
              if(response != null){
                this.treeSpecies = response;
              }
                
            }
        })
    }

    getUom(){
        this.uomService.uom$.subscribe({
            next: response => {
            console.log(response);
              if(response != null){
                this.uom = response;
                this.setUom();
              }
                
            }
        })
    }

    setProductName() {
        this.appraisalGroupIdControl.patchValue(null);
        this.appraisalGroupNameControl.patchValue(null);
        this.appraisalGroupControl.patchValue(null);
        let treeProduct = this.treeProducts?.filter(p => p.id == this.form.controls['treeProductId'].value)[0];
        this.treeProductIdControl.patchValue(treeProduct.id);
        this.treeProductNameControl.patchValue(treeProduct.productName);
        this.setAppraisalGroup(this.treeProductIdControl.value, this.treeSpeciesIdControl.value, this.treeConditionControl.value);
    }

    setSpeciesName() {
        this.appraisalGroupIdControl.patchValue(null);
        this.appraisalGroupNameControl.patchValue(null);
        this.appraisalGroupControl.patchValue(null);
        let treeSpecies = this.treeSpecies?.filter(p => p.id == this.form.controls['treeSpeciesId'].value)[0];
        this.treeSpeciesIdControl.patchValue(treeSpecies.id);
        this.treeSpeciesNameControl.patchValue(treeSpecies.commonName);
        this.setAppraisalGroup(this.treeProductIdControl.value, this.treeSpeciesIdControl.value, this.treeConditionControl.value);
    }

    setUom() {
        let uom = this.uom?.filter(u => u.id == this.form.controls['uomId'].value)[0];
        console.log(uom);
        this.uomShortNameControl.patchValue(uom.uomShortName);
        
    }

    get unitOfMeasure(): string{
        return this.uomShortNameControl.value;
    }

    setAppraisalGroup(treeProductId: number, treeSpeciesId: number, treeCondition: string){
        if(treeProductId && treeSpeciesId && treeCondition){
            console.log('get ag test');
            this.appraisalGroupsService.getAppraisalGroupByZone(treeProductId, treeSpeciesId, treeCondition).subscribe({
                next: response => {
                  if(response != null){
                    console.log(response);
                    this.appraisalGroup = response;
                    this.appraisalGroupIdControl.patchValue(this.appraisalGroup.id);
                    console.log(this.appraisalGroup.appraisalGroupName);
                    this.appraisalGroupNameControl.patchValue(this.appraisalGroup.appraisalGroupName);
                    this.appraisalGroupControl.patchValue(this.appraisalGroup);
                    this.minimumRateControl.patchValue(this.appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1)[0].rate);
                    this.standardRateControl.patchValue(this.appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2)[0].rate)
                  }
                    
                }
            })
        }        
    }

    continue(event: any){
        event.preventDefault();

        let element: HTMLElement = document.getElementById("panel-3-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth',block:'start'});
       
        
        
    }
    
}