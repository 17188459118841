import { Component } from '@angular/core';

@Component({
  selector: 'app-base-data-in-zone',
  standalone: true,
  imports: [],
  templateUrl: './base-data-in-zone.component.html',
  styleUrl: './base-data-in-zone.component.scss'
})
export class BaseDataInZoneComponent {

}
