import { Component } from '@angular/core';

@Component({
    selector: 'app-wwpa-indices',
    templateUrl: './wwpa-indices.component.html',
    styleUrl: './wwpa-indices.component.scss',
    standalone: false
})
export class ViewWwpaIndicesComponent {

}
