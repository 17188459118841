import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types';
import { IVisited } from '../../../../models/visitors/visited';

@Component({
    selector: 'app-estimate-sale-operating-costs',
    templateUrl: './estimate-sale-operating-costs.component.html',
    styleUrls: ['./estimate-sale-operating-costs.component.scss'],
    standalone: false
})

export class EstimateSaleOperatingCostsComponent implements OnInit, IVisited {

    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    @Input() arialabel = "Test"
    
    range = new FormControl(0);
    form!: FormGroup;
    generalInfoForm!: FormGroup;
    volumeInfoForm!: FormGroup;
    chooseAppraisalTypeForm!: FormGroup;
    calculationsForm!: FormGroup;
    saleTypes!: FormArray;
    appraisalTypeControl!: FormControl;
    appraisalTypes = AppraisalTypes;
    appraisalGroupForms!: FormArray<FormGroup>;
    appraiserRoleIdControl!: FormControl;
    
    constructor(private fb: FormBuilder,  
        private rootFormGroup: FormGroupDirective) {
           
    }

    public readProperty<T>(prop: string):T {
        let property = this[prop as keyof EstimateSaleOperatingCostsComponent];
        if (property == null) {
           throw Error(`Property "${prop}" does not exists`);
        }
        return property;
     }   



    ngOnInit() {
        this.appraiserRoleIdControl = this.rootFormGroup.control.get('appraiserRoleId') as FormControl;
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.generalInfoForm = this.rootFormGroup.control.get('generalInformation') as FormGroup;
        this.volumeInfoForm = this.rootFormGroup.control.get('volumeInformation') as FormGroup;
        this.appraisalGroupForms = this.volumeInfoForm.controls["appraisalGroups"] as FormArray;

        this.chooseAppraisalTypeForm = this.rootFormGroup.control.get('chooseAppraisalType') as FormGroup;
        this.calculationsForm = this.rootFormGroup.control.get("contractCalculations") as FormGroup;
        this.saleTypes = this.generalInfoForm.controls["saleTypes"] as FormArray;
        this.appraisalTypeControl = this.chooseAppraisalTypeForm.controls["appraisalType"] as FormControl;
      
    }

    get appraiserRoleId(): number {
        return this.appraiserRoleIdControl.value;
    }

    get uomControl(): FormControl{
        return this.volumeInfoForm.controls['uomShortName'] as FormControl;
    }

    get appraisalGroups() :FormArray{
        return this.appraisalGroupForms;
    }

    get appraisalType(){
        return this.appraisalTypeControl.value;
    }
    
    continue(event: any){
        event.preventDefault();
        let element: HTMLElement = document.getElementById("panel-5-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth', block:'start'});
        
    }
}