import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { AppraisalGroupRates } from '../../models/appraisals/appraisal-group-rates';
import { getList, getListHeaders } from '../pagination/listHelper';
import { AppraisalGroup } from '../../models/appraisals/appraisal-group';

@Injectable({
  providedIn: 'root'
})
export class AppraisalGroupRatesService {
  
    baseUrl = environment.apiUrl;
    appraisalGroupRatesCache = new Map();
    private appraisalGroupRatesSource = new BehaviorSubject<AppraisalGroupRates[] | null>(null);
    appraisalGroupRates$ = this.appraisalGroupRatesSource.asObservable();

  constructor(private http: HttpClient) { 

  }

  setAppraisalGroupRates(appraisalGroupRates: AppraisalGroupRates[]) {
    this.appraisalGroupRatesCache.set('appraisalGroupRates', appraisalGroupRates);
    this.appraisalGroupRatesSource.next(appraisalGroupRates);
  }

  getAppraisalGroupRates() {
    const response = this.appraisalGroupRatesCache.get('appraisalGroupRates');
    if (response) return of(response);

    let params = getListHeaders();

    return getList<AppraisalGroupRates[]>(this.baseUrl + 'appraisalgrouprates', params, this.http).pipe(
      map(response => {
        this.setAppraisalGroupRates(response as AppraisalGroupRates[]);
        return response;
      })
    )
  }

  getAppraisalGroupRatesByAgId(appraisalGroupId: number) {

    let params = new HttpParams();

    return this.http.get<AppraisalGroup>(this.baseUrl + 'appraisalgrouprates/appraisal-group/' + appraisalGroupId, { observe: 'response', params }).pipe(
      map(response => {
        return response.body;
      })
    );

  }

  getAppraisalGroupRatesByZone(appraisalZoneCode: string, appraisalRateTypeId: number) {
    let params = new HttpParams();

    return this.http.get<AppraisalGroupRates[]>(this.baseUrl + 'appraisalgrouprates/' + appraisalZoneCode + '/' + appraisalRateTypeId, { observe: 'response', params }).pipe(
      map(response => {
        console.log(response);
        return response.body;

      })
    );
  }

  updateAppraisalGroupRate(appraisalGroupRate: AppraisalGroupRates): Observable<AppraisalGroupRates>{
    let originalRate = this.appraisalGroupRatesCache.get('appraisalGroupRates')
      .find((originalRate: AppraisalGroupRates) => originalRate.id == appraisalGroupRate.id);
    
    return this.http.put<AppraisalGroupRates>(this.baseUrl + 'appraisalgrouprates', appraisalGroupRate).pipe(
      map(response => {
        originalRate.updatedBy = response.updatedBy;
        originalRate.updatedWhen_UTC = response.updatedWhen_UTC;
        return originalRate;
      })
    )
  }

  
}