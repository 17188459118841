<div class="usa-width-one-whole nt-info-area">
    <div class="grid-container">
        <div class="admin-panel-header">
            <h2 *ngIf="user.currentUserRole.roleId == 3">Appraiser Admin Panel</h2>
            <h2 *ngIf="user.currentUserRole.roleId == 4">Data Admin Panel</h2>
        </div>
        <div class="grid-row admin-panel-row">
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 3 || user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/user-management" tabindex="0" arial-label="User Management">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-user-management.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>User Management</p>
                    </div>
                </button>
            </div>
            <div class="grid-col-3 admin-panel-col-3"   *ngIf="user.currentUserRole.roleId == 3 || user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/data-analysis-reporting" tabindex="0" arial-label="Data Analysis and Reporting">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-data-analysis-reporting.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Data Analysis & Reporting</p>
                    </div>
                </button>   
            </div>
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/base-period-data" tabindex="0" arial-label="Base Period Data">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-base-period-data.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Base Period Data</p>
                    </div>
                </button>
            </div>
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/external-market-data" tabindex="0" arial-label="External Market Data">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-external-market-data.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>External Market Data</p>
                    </div>
                </button>   
            </div>
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/standard-rates" tabindex="0" aria-label="Standard Rates">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-standard-rates.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Standard Rates</p>
                    </div>
                </button>   
            </div>
        </div>
        <div class="grid-row admin-panel-row">
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/wwpa-indices" tabindex="0" aria-label="WWPA">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-wwpa.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>WWPA</p>
                    </div>
                </button>
            </div>
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/minimum-rates" tabindex="0" aria-label="Minimum Rates">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-minimum-rates.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Minimum Rates</p>
                    </div>
                </button>   
            </div>
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/appraisal-zones" tabindex="0" aria-label="Appraisal Zones">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-appraisal-zones.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Appraisal Zones</p>
                    </div>
                </button>   
            </div>
            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/products" tabindex="0" aria-label="Products">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/admin-products.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Products</p>
                    </div>
                </button>   
            </div>

            <div class="grid-col-3 admin-panel-col-3" *ngIf="user.currentUserRole.roleId == 4">
                <button type="button" class="admin-panel-btn" routerLink="/admin/appraisal-groups" tabindex="0" aria-label="Manage Appraisal Groups">
                    <div class="admin-panel-icon">
                        <img src="/assets/img/tea-icons/admin-panel/log-nature_svgrepo.png" alt="" width="83" height="87">
                    </div>
                    <div class="admin-panel-btn-description">
                        <p>Manage Appraisal Groups</p>
                    </div>
                </button>   
            </div>
        </div>
    </div>
</div>

