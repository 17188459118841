<div class="usa-width-one-whole fixed-footer">
    <footer class="usa-footer">
        <div class="usa-footer__primary-section row-one">
            <nav class="usa-footer__nav" aria-label="Footer navigation">
                <ul class="grid-row grid-gap">
                    <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                        <a
                                class="usa-footer__primary-link"
                                href="https://www.usda.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                        >
                            Documentation
                        </a>
                    </li>
                    <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                        <a
                                class="usa-footer__primary-link"
                                href="https://www.usda.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                        >
                            Features
                        </a>
                    </li>
                    <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                        <a
                                class="usa-footer__primary-link"
                                href="https://www.usda.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                        >
                            Getting started
                        </a>
                    </li>
                    <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                        <a
                                class="usa-footer__primary-link"
                                href="https://www.usda.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                        >
                            About us
                        </a>
                    </li>
                    <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                        <a
                                class="usa-footer__primary-link"
                                href="https://www.usda.gov/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                        >
                            Privacy policy
                        </a>
                    </li>
                </ul>
            </nav>
        </div>


        <div class="usa-footer__secondary-section">
            <div class="grid-container">
                <div class="grid-row grid-gap">
                    <div
                            class="usa-footer__logo grid-row mobile-lg:grid-col-6 mobile-lg:grid-gap-2"
                    >
                        <div class="mobile-lg:grid-col-auto">
                            <img
                                    class="usa-footer-logo"
                                    src="./assets/img/FSlogotag3.png"
                                    alt="FS footer icon"
                            />
                        </div>
                    </div>
                    <div class="usa-footer__contact-links mobile-lg:grid-col-6">
                        <div class="usa-footer__social-links grid-row grid-gap-1">
                            <div class="grid-col-auto">
                                <a
                                        class="social-link"
                                        target="_blank"
                                        href="https://www.facebook.com/USForestService/?ref=hl"
                                        rel="noopener noreferrer"
                                >
                                    <em class="fa fa-facebook" aria-hidden="true"></em>
                                </a>
                            </div>
                            <div class="grid-col-auto">
                                <a
                                        class="social-link"
                                        target="_blank"
                                        href="https://twitter.com/forestservice"
                                        rel="noopener noreferrer"
                                >
                                    <em class="fa fa-twitter" aria-hidden="true"></em>
                                </a>
                            </div>
                            <div class="grid-col-auto">
                                <a
                                        class="social-link"
                                        target="_blank"
                                        href="https://www.youtube.com/user/usdaForestService"
                                        rel="noopener noreferrer"
                                >
                                    <em class="fa fa-youtube" aria-hidden="true"></em>
                                </a>
                            </div>
                            <div class="grid-col-auto">
                                <a
                                        class="social-link"
                                        target="_blank"
                                        href="https://www.flickr.com/photos/usforestservice/"
                                        rel="noopener noreferrer"
                                >
                                    <em class="fa fa-rss" aria-hidden="true"></em>
                                </a>
                            </div>
                            <div class="grid-col-auto">
                                <a
                                        class="social-link"
                                        target="_blank"
                                        href="https://www.instagram.com/u.s.forestservice/"
                                        rel="noopener noreferrer"
                                >
                                    <em class="fa fa-instagram" aria-hidden="true"></em>
                                </a>
                            </div>
                        </div>
                        <h3 class="usa-footer__contact-heading">USDA-Forest Service</h3>
                        <address class="usa-footer__address">
                            <div class="usa-footer__contact-info grid-row grid-gap">
                                <p>
                                    <em class="fa fa-map-marker" aria-hidden="true"></em>
                                    1400 Independence Ave, SW
                                    <br/>
                                    Washington, DC 20250-1111
                                    <br/>
                                    <em class="fa fa-phone" aria-hidden="true"></em>
                                    Tel:
                                    <a href="tel:1-800-832-5555"> (800) 832-1355 </a>
                                </p>
                            </div>
                        </address>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>

