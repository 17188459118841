import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { UsersService } from '../../../../../services/users/users.service';
import { AppraisalGroup } from '../../../../../models/appraisals/appraisal-group';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { EstimateSaleOperatingCostsComponent } from '../estimate-sale-operating-costs.component';
import { IVisited } from '../../../../../models/visitors/visited';

@Component({
    selector: 'app-streamlined-te-calculations',
    templateUrl: './streamlined-te-calculations.component.html',
    styleUrls: ['./streamlined-te-calculations.component.scss'],
    standalone: false
})

export class StreamlinedTeCalculationsComponent implements OnInit, IVisited {
    @Input() formGroupName!: string;
    @Output() childEvent = new EventEmitter();
    
    form!: FormGroup;
    bpaRates!: FormGroup;
    calculationsComponent: EstimateSaleOperatingCostsComponent;

    result: number = 0;
    appraisalGroupForms!: FormArray<FormGroup>;
    appraisalGroupForm!: FormGroup;
    appraisalGroup: AppraisalGroup;



    formatThumbLabel(value: number): string {
        return `${value}%`;
    }

    
    constructor(@Host() parent: EstimateSaleOperatingCostsComponent,
        private fb: FormBuilder, 
        private http: HttpClient, 
        private rootFormGroup: FormGroupDirective,
        public usersService: UsersService,
        private calculationsService: CalculationsService) {
            this.calculationsComponent = parent;
            this.appraisalGroupForms = parent.appraisalGroupForms;
            this.appraisalGroupForm = parent.appraisalGroupForms.controls[0];
            this.appraisalGroup = this.appraisalGroupForm.controls['appraisalGroup'].value as AppraisalGroup;
    }

    

    public readProperty<T>(prop: string):T {
        let property = this[prop as keyof StreamlinedTeCalculationsComponent];
        if (property == null) {
           throw Error(`Property "${prop}" does not exists`);
        }
        return property as T;
     } 


    ngOnInit() {
        //Get root estimate market value form
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.bpaRates = this.form.controls['bpaRates'] as FormGroup;
        this.setInitialRateValues();
        this.calculationsService.visitStreamlinedTeCalulationsComponent(this);
        
    }

    setInitialRateValues(){
        this.form.controls['basePeriodRate'].patchValue(this.appraisalGroup?.appraisalGroupBpas[0].bpaPrice);
        this.bpaRates?.controls['baseSkidYard'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].stumpToTruckCost);
        this.bpaRates?.controls['baseSkidYard'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].stumpToTruckCost);
        this.bpaRates?.controls['baseHaul'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].haulCost);
        this.bpaRates?.controls['baseHaul'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].haulCost);
        this.bpaRates?.controls['baseRoadMaintenance'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].roadMaintCost);
        this.bpaRates?.controls['baseRoadMaintenance'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].roadMaintCost);
        this.bpaRates?.controls['baseTempDevelopments'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].tempDevCost);
        this.bpaRates?.controls['baseTempDevelopments'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].tempDevCost);
        this.bpaRates?.controls['baseSlashDisposal'].get('rate')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].envProCost);
        this.bpaRates?.controls['baseSlashDisposal'].get('initialValue')?.patchValue(this.appraisalGroup?.appraisalGroupBpas[0].envProCost);
    }

    get uomControl(): FormControl{
        return this.appraisalGroupForm.controls['uomShortName'] as FormControl;
    }

    bpaRateControl(name: string): FormControl{
        let rateControl = this.bpaRates?.controls[name]?.get('rate') as FormControl;
        return rateControl;
    }

    bpaRatePercentageControl(name: string): FormControl{
        let percentControl = this.bpaRates?.controls[name].get('percentage') as FormControl;
        return percentControl;
    }

    setRate(name: string) : void {
        let rate = this.bpaRates?.controls[name].get('initialValue')?.value;
        let percentage = this.bpaRates?.controls[name].get('percentage')?.value;
        let adjustedRate = rate * (percentage / 100);
        this.bpaRates?.controls[name].get('rate')?.patchValue(+adjustedRate.toFixed(2));
    }

    setSlider($event: Event, name: string) : void{
        let element = ($event.target as HTMLInputElement);
        let rate = Number(this.bpaRates?.controls[name].get('initialValue')?.value);
        let max = rate * 2;
        element.max = max.toString();
        if (element.value) {
            let value = Number(element.value); 
            if(value > max){
                element.value = max.toString();
                this.bpaRates?.controls[name].get('percentage')?.patchValue(200);
            } else {
                let percentage = (value / this.bpaRates?.controls[name].get('initialValue')?.value) * 100;
                this.bpaRates?.controls[name].get('percentage')?.patchValue(percentage);
            }
            
        }   
    }
    
    resetSlider(name: string) : void{
        let intitalValue = this.bpaRates?.controls[name].get('initialValue')?.value;
        this.bpaRates?.controls[name].get('rate')?.patchValue(intitalValue);
        this.bpaRates?.controls[name].get('percentage')?.patchValue(100);
        
    }

    get baseSkidYard(): FormControl{
        return this.bpaRates?.controls['baseSkidYard'] as FormControl;
    }

    get baseHaul(): FormControl{
        return this.bpaRates?.controls['baseHaul'] as FormControl;
    }

    get baseRoadMaintenance(): FormControl{
        return this.bpaRates?.controls['baseRoadMaintenance'] as FormControl;
    }

    get baseTempDevelopments(): FormControl{
        return this.bpaRates?.controls['baseTempDevelopments'] as FormControl;
    }

    get baseSlashDisposal(): FormControl{
        return this.bpaRates?.controls['baseSlashDisposal'] as FormControl;
    }

    get basePeriodRate(): FormControl{
        return this.form.controls['basePeriodRate']as FormControl;
    }
    
    
}