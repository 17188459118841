
<div class="fixed">
    <app-usa-banner></app-usa-banner>
    <app-header [title]="title"></app-header>
</div>
<div class="main-content">
    <div id="main" tabindex="-1" #main>
        <router-outlet></router-outlet>
    </div>
    
</div>    

<app-footer></app-footer>
<ngx-spinner><p style="color: white" > Loading... </p></ngx-spinner>
