<div class="" [formGroup]="form">
    <div class="grid-row">
        <div class="tablet:grid-col">
            <div class="usa-form-group">
                <h3>Click and hold to adjust price to +100% or -100%</h3>
            </div>
        </div>
    </div>
    <div class="grid-row">
        <div class="tablet:grid-col slider" >
            <div class="usa-form-group">
              <label class="usa-label" for="slider1"><small>Base Skid-Yard</small></label>
              <mat-slider discrete [displayWith]="formatThumbLabel" min="0" max="200" id="slider1" aria-label="Base Skid-Yard Slider">
                <input matSliderThumb [formControl]="bpaRatePercentageControl('baseSkidYard')" (change)="setRate('baseSkidYard')" aria-label="Base Skid-Yard Slider">
              </mat-slider>
            </div>
            
            <div class="usa-form-group">
                <div class="sliderInput">
                    <div class="slider-icon">$</div>
                    <input type="number" class="usa-input sliderInput--field" [formControl]="bpaRateControl('baseSkidYard')" (change)="setSlider($event, 'baseSkidYard')" step="0.01" min="0.00" tabindex="0" aria-label="Base Skid-Yard"/>
                    <div class="volunitofmeasure">/{{uomControl.value}}</div>
                    <div>
                        <a  class="resetLink" (click)="resetSlider('baseSkidYard')" (keyup.enter)="resetSlider('baseSkidYard')" tabindex="0" aria-label="Base Skid-Yard Reset Button">Reset</a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class="grid-row">
        <div class="tablet:grid-col slider" >
            <div class="usa-form-group">
              <label class="usa-label" for="slider2"><small>Base Haul</small></label>
              <mat-slider discrete [displayWith]="formatThumbLabel" min="-0" max="200" id="slider2" aria-label="Base Haul Slider">
                <input matSliderThumb [formControl]="bpaRatePercentageControl('baseHaul')" (change)="setRate('baseHaul')" aria-label="Base Haul Slider">
              </mat-slider>
            </div>
            
            <div class="usa-form-group">
                <div class="sliderInput">
                    <div class="slider-icon">$</div>
                    <input type="number" class="usa-input sliderInput--field" [formControl]="bpaRateControl('baseHaul')" (change)="setSlider($event, 'baseHaul')" step="0.01" min="0.00" tabindex="0"  aria-label="Base Haul"/>
                    <div class="volunitofmeasure">/{{uomControl.value}}</div>
                    <div>
                        <a  class="resetLink" (click)="resetSlider('baseHaul')" (keyup.enter)="resetSlider('baseHaul')" tabindex="0" aria-label="Base Haul Reset Button">Reset</a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class="grid-row">
        <div class="tablet:grid-col slider" >
            <div class="usa-form-group">
              <label class="usa-label" for="slider3"><small>Base Road Maintenance</small></label>
              <mat-slider discrete [displayWith]="formatThumbLabel" min="0" max="200" id="slider3" aria-label="Base Road Maintenance">
                <input matSliderThumb [formControl]="bpaRatePercentageControl('baseRoadMaintenance')" (change)="setRate('baseRoadMaintenance')" aria-label="Base Road Maintenance">
              </mat-slider>
            </div>
            
            <div class="usa-form-group">
                <div class="sliderInput">
                    <div class="slider-icon">$</div>
                    <input type="number" class="usa-input sliderInput--field" [formControl]="bpaRateControl('baseRoadMaintenance')" (change)="setSlider($event, 'baseRoadMaintenance')" step="0.01" min="0.00" tabindex="0" aria-label="Base Road Maintenance"/>
                    <div class="volunitofmeasure">/{{uomControl.value}}</div>
                    <div>
                        <a  class="resetLink" (click)="resetSlider('baseRoadMaintenance')" (keyup.enter)="resetSlider('baseRoadMaintenance')" tabindex="0" aria-label="Base Road Maintenance Reset Button">Reset</a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class="grid-row">
        <div class="tablet:grid-col slider" >
            <div class="usa-form-group">
              <label class="usa-label" for="slider4"><small>Base Temporary Developments</small></label>
              <mat-slider discrete [displayWith]="formatThumbLabel" min="0" max="200" id="slider4" aria-label="Base Temporary Developments slider">
                <input matSliderThumb [formControl]="bpaRatePercentageControl('baseTempDevelopments')"  (change)="setRate('baseTempDevelopments')" aria-label="Base Temporary Developments slider">
              </mat-slider>
            </div>
            
            <div class="usa-form-group">
                <div class="sliderInput">
                    <div class="slider-icon">$</div>
                    <input type="number" class="usa-input sliderInput--field" [formControl]="bpaRateControl('baseTempDevelopments')" (change)="setSlider($event, 'baseTempDevelopments')" step="0.01" min="0.00" tabindex="0" aria-label="Base Temporary Developments"/>
                    <div class="volunitofmeasure">/{{uomControl.value}}</div>
                    <div>
                        <a class="resetLink" (click)="resetSlider('baseTempDevelopments')" (keyup.enter)="resetSlider('baseTempDevelopments')" tabindex="0" aria-label="Base Temporary Developments Reset Button">Reset</a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class="grid-row">
        <div class="tablet:grid-col slider" >
            <div class="usa-form-group">
              <label class="usa-label" for="slider5"><small>Base Slash Disposal</small></label>
              <mat-slider discrete [displayWith]="formatThumbLabel" min="0" max="200" id="slider5" aria-label="Base Slash Disposal Slider">
                <input matSliderThumb [formControl]="bpaRatePercentageControl('baseSlashDisposal')" (change)="setRate('baseSlashDisposal')" aria-label="Base Slash Disposal Slider">
              </mat-slider>
            </div>
            
            <div class="usa-form-group">
                <div class="sliderInput">
                    <div class="slider-icon">$</div>
                    <input type="number" class="usa-input sliderInput--field" [formControl]="bpaRateControl('baseSlashDisposal')" (change)="setSlider($event, 'baseSlashDisposal')" step="0.01" min="0.00" tabindex="0" aria-label="Base Slash Disposal"/>
                    <div class="volunitofmeasure">/{{uomControl.value}}</div>
                    <div>
                        <a class="resetLink" (click)="resetSlider('baseSlashDisposal')" (keyup.enter)="resetSlider('baseSlashDisposal')" tabindex="0" aria-label="Base Slash Disposal Reset Button">Reset</a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>