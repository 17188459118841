import { Component } from '@angular/core';

@Component({
    selector: 'app-sales-in-base-data',
    templateUrl: './sales-in-base-data.component.html',
    styleUrl: './sales-in-base-data.component.scss'
})
export class SalesInBaseDataComponent {

}
