import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { UsersService } from '../services/users/users.service';
import { map } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const usersService = inject(UsersService);
  console.log("Auth Guard");
 
  return usersService.isLoggedIn;
  
};