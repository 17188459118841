import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../models/user/user';
import { take } from 'rxjs';
import { LoadingHandler } from '../../../services/loading-handler'
import { UserRole } from '../../../models/user/user-roles/user-role';

@Component({
    selector: 'app-header',
    templateUrl: "./header.component.html",
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
    @Input() title: string = "";
    user?: User | null;
    userRoles?: UserRole[] | null;
    activeUserRole?: UserRole | null;
    activeUserRoleId?: number;
    selector:any
    isLoading = false;
    isOpen = false;
 
  @ViewChild('matIcon', {read: ElementRef}) matIcon!: ElementRef;
    angle:number = 180;

    constructor(public usersService: UsersService, private router: Router, private route: ActivatedRoute) {
      
    }

    loadingHandler = new LoadingHandler()

    ngOnInit() {
      this.loadRoles();
    }

    loadRoles(){
      this.usersService.currentUser$.subscribe({
      next: user => {
        if (user){
          this.user = user;
          this.userRoles = this.user.userRoles;
          console.log(user);
          if(this.user.currentUserRole != null){
            this.activeUserRoleId = this.user.currentUserRole.roleId;
            this.activeUserRole = this.user.currentUserRole;
            console.log(this.activeUserRole);
          } 
        
        }
      }
    })
  }

  setUserRole(roleId: number){
    this.usersService.setCurrentActiveRole(roleId);
  }

  onOpenedChange(isOpen: boolean) {
    if (isOpen) {
      this.matIcon.nativeElement.classList.add('rotate')
    } else {
      this.matIcon.nativeElement.classList.remove('rotate')
    }
  }

  logout() {
    this.usersService.logout();
    this.router.navigateByUrl('/landing?logout=true');
  }

  login() {
    this.router.navigateByUrl('/landing');
  }
   
    

}
