import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { of, map, take } from 'rxjs';
import { ForestParams } from '../../models/params/forestParams';
import { getPaginatedResult, getPaginationHeaders } from '../pagination/paginationHelper';
import { Forest } from '../../models/forest-service/forest';
import { RegionParams } from '../../models/params/regionParams';

@Injectable({
  providedIn: 'root'
})
export class ForestsService {
  baseUrl = environment.apiUrl;
  forests: Forest[] = [];
  forestCache = new Map();
  forestParams: ForestParams | undefined;

  constructor(private http: HttpClient) { 
    this.forestParams = new ForestParams();
  }

  getForestParams() {
    return this.forestParams;
  }

  setForestParams(forestParams: ForestParams) {
    this.forestParams = forestParams;
  }

  resetUserParams() {
    if (this.forestParams) {
      this.forestParams = new ForestParams();
      return this.forestParams;
    }
    return;
  }

  getForests(forestParams: ForestParams, string: any) {
    const response = this.forestCache.get(Object.values(forestParams).join('-'));

    if (response) return of(response);

    let params = getPaginationHeaders(forestParams.pageNumber, forestParams.pageSize);

    return getPaginatedResult<Forest[]>(this.baseUrl + 'forests', params, this.http).pipe(
      map(response => {
        this.forestCache.set(Object.values(ForestParams).join('-'), response);
        return response;
      })
    )
  }

  getForest(forestCode: string) {
    const forest = [...this.forestCache.values()]
      .reduce((arr, elem) => arr.concat(elem.result), [])
      .find((forest: Forest) => forest.forestCode == forestCode);

    if (forest) return of(forest);
    
    return this.http.get<Forest>(this.baseUrl + 'forests/' + forestCode);
  }

  
}