import { Component, ElementRef, EventEmitter, Host, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { UomService } from '../../../../services/forest-service/uom.service';
import { TreeProduct } from '../../../../models/forest-service/tree-product';
import { TreeSpecies } from '../../../../models/forest-service/tree-species';
import { Uom } from '../../../../models/forest-service/uom';
import { AppraisalGroup } from '../../../../models/appraisals/appraisal-group';
import { LoadingHandler } from '../../../../services/loading-handler';
import { ModalService } from '../../../../services/modal.service';
import { FocusService } from '../../../../services/focus.service';
import { CalculationsService } from '../../../../services/calculations/calculations.service';
import { IVisited } from '../../../../models/visitors/visited';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types';
import { UserRoles } from '../../../../models/enums/user-roles';
import { AppraisalSale } from '../../../../models/appraisals/appraisal-sale';



@Component({
    selector: 'app-volume-information',
    templateUrl: './volume-information.component.html',
    styleUrls: ['./volume-information.component.scss'],
    standalone: false
})

export class VolumeInformationComponent implements OnInit, IVisited {
    loadingHandler = new LoadingHandler()
    @Input() formGroupName!: string;
    @Output() childEvent = new EventEmitter();
    appraiserRoleIdControl!: FormControl;
    form!: FormGroup;
    modalForm!:FormGroup;
    generalInfoForm!: FormGroup;
    calculationsForm!: FormGroup;
    conditionButtonValues: any[] = [ {live: true}, {dead: false} ];
    uomButtonValues: any[] = [{CCF: 1}, {Ton: 2}, {MBF: 3} ];
    treeProducts!: TreeProduct[];
    initialTreeSpecies: TreeSpecies[] = [];
    newTreeSpecies: TreeSpecies[] = [];
    editedTreeSpecies: TreeSpecies[] = [];
    uom!: Uom[];
    appraisalZoneCodeControl!: FormControl;
    appraisalGroupsControl!: FormControl<AppraisalGroup[]>;
    regionIdControl!: FormControl;
    forestIdControl!: FormControl;
    districtIdControl!: FormControl;
    regionCodeControl!: FormControl;
    forestCodeControl!: FormControl;
    districtCodeControl!: FormControl;

    @ViewChild('modal') modal!:ElementRef;
    @ViewChild('main') main!:ElementRef;
  
    appraisalGroupForms!: FormArray<FormGroup>;
    initialAppraisalGroup!: AppraisalGroup;
    initialAppraisalGroupForm!: FormGroup;
    newAppraisalGroup!: AppraisalGroup;
    newAppraisalGroupForm!: FormGroup;
    editedAppraisalGroup!: AppraisalGroup;
    editedAppraisalGroupForm!: FormGroup;
    chooseAppraisalTypeForm!: FormGroup;
    appraisalTypeControl!: FormControl;
    appraisalTypes = AppraisalTypes;
    appraisalZoneIdControl!: FormControl<number | null>;
    userPortfolioForm!: FormGroup;
    appraisalSaleControl!: FormControl<AppraisalSale>;

    user_Roles = UserRoles;
    appraiser_RoleId!: number;
    editedAppraisalGroupIndex: number = 0;
  
    constructor(private fb: FormBuilder,
      private rootFormGroup: FormGroupDirective,
      public modalService:ModalService,
      public focusService:FocusService,
      private uomService: UomService,
      private calculationsService: CalculationsService) {

          this.focusService.onFocus.subscribe(()=>{
              this.showFocus();
            });
        
            this.modalService.onFoucsModal.subscribe(()=>{
              this.focusModal();
            })
      
    }

    title:string = "Appraisal Groups";
    p:number = 1;

    public readProperty<T>(prop: string):T {
        let property = this[prop as keyof VolumeInformationComponent];
        if (property == null) {
           throw Error(`Property "${prop}" does not exists`);
        }
        return property as T;
     } 

    showFocus(){
        this.main.nativeElement.focus();
      }
    
      focusModal(){
        this.modal.nativeElement.focus()
      }

    ngOnInit() {
        this.appraiserRoleIdControl = this.rootFormGroup.control.get('appraiserRoleId') as FormControl;
        this.userPortfolioForm = this.rootFormGroup.control.get("userPortfolio") as FormGroup;
        this.appraisalSaleControl = this.userPortfolioForm.get('appraisalSale') as FormControl;
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.generalInfoForm = this.rootFormGroup.control.get("generalInformation") as FormGroup;
        this.calculationsForm = this.rootFormGroup.control.get("contractCalculations") as FormGroup;
        this.chooseAppraisalTypeForm = this.rootFormGroup.control.get("chooseAppraisalType") as FormGroup;
        this.appraisalZoneIdControl = this.generalInfoForm.controls["appraisalZoneId"] as FormControl;
        this.appraisalZoneCodeControl = this.generalInfoForm.controls["appraisalZoneCode"] as FormControl;
        this.appraisalGroupsControl = this.generalInfoForm.controls["appraisalGroups"] as FormControl;
        this.regionIdControl = this.generalInfoForm.controls["regionId"] as FormControl;
        this.forestIdControl = this.generalInfoForm.controls["forestId"] as FormControl;
        this.districtIdControl = this.generalInfoForm.controls["districtId"] as FormControl;
        this.regionCodeControl = this.generalInfoForm.controls["regionCode"] as FormControl;
        this.forestCodeControl = this.generalInfoForm.controls["forestCode"] as FormControl;
        this.districtCodeControl = this.generalInfoForm.controls["districtCode"] as FormControl;
        this.appraisalTypeControl = this.chooseAppraisalTypeForm.controls["appraisalType"] as FormControl;
        
        this.appraisalGroupForms = this.form.controls["appraisalGroups"] as FormArray;
        this.initialAppraisalGroupForm = this.appraisalGroupForms.controls[0];

        this.initializeNewAppraisalGroupForm();
        this.initializeEditedAppraisalGroupForm();
        this.getUom();
        this.calculationsService.visitVolumeInformationComponent(this);

        this.appraisalGroupsControl.valueChanges.subscribe(ag => {
          this.getTreeProducts();
        })
        
    }

    initializeNewAppraisalGroupForm() {
      this.newAppraisalGroupForm = this.fb.group({
        treeCondition: ['Live', Validators.required],
        appraisalGroupId: [null],//remove required validator.
        appraisalGroupName: ['', Validators.required],
        appraisalGroup: [null, Validators.required],
        treeProductId: [null, Validators.required],
        //treeProduct: [{value: null, disabled: true}, Validators.required],
        treeSpeciesId: [null, Validators.required],
        //treeSpecies: [{value: null, disabled: true}, Validators.required],
        treeProductName: ['', Validators.required],
        treeSpeciesName: ['', Validators.required],
        uomId: [null, Validators.required],
        uomShortName: ['', Validators.required],
        totalVolume: [0, Validators.required],
        standardRate: [0.00, Validators.required],
        minimumRate: [0.00, Validators.required]
      });

    }

    initializeEditedAppraisalGroupForm() {
      this.editedAppraisalGroupForm = this.fb.group({
        treeCondition: ['Live', Validators.required],
        appraisalGroupId: [null],//remove required validator.
        appraisalGroupName: ['', Validators.required],
        appraisalGroup: [null, Validators.required],
        treeProductId: [null, Validators.required],
        //treeProduct: [{value: null, disabled: true}, Validators.required],
        treeSpeciesId: [null, Validators.required],
        //treeSpecies: [{value: null, disabled: true}, Validators.required],
        treeProductName: ['', Validators.required],
        treeSpeciesName: ['', Validators.required],
        uomId: [null, Validators.required],
        uomShortName: ['', Validators.required],
        totalVolume: [0, Validators.required],
        standardRate: [0.00, Validators.required],
        minimumRate: [0.00, Validators.required]
      });


    }

    insertAppraisalGroup(): void {
      if(this.newAppraisalGroupForm?.controls['appraisalGroupId']?.value > 0) {
        let index = this.appraisalGroupForms.length;
        this.appraisalGroupForms.insert(index,
          this.fb.group({
            treeCondition: this.newAppraisalGroupForm.controls['treeCondition'].value,
            appraisalGroupId: this.newAppraisalGroupForm.controls['appraisalGroupId'].value,
            appraisalGroupName: this.newAppraisalGroupForm.controls['appraisalGroupName'].value,
            appraisalGroup: this.newAppraisalGroupForm.controls['appraisalGroup'].value,
            treeProductId: this.newAppraisalGroupForm.controls['treeProductId'].value,
            //treeProduct: this.newAppraisalGroupForm.controls['treeProduct'].value,
            treeSpeciesId: this.newAppraisalGroupForm.controls['treeSpeciesId'].value,
            //treeSpecies: this.newAppraisalGroupForm.controls['treeSpecies'].value,
            treeProductName: this.newAppraisalGroupForm.controls['treeProductName'].value,
            treeSpeciesName: this.newAppraisalGroupForm.controls['treeSpeciesName'].value,
            uomId: this.newAppraisalGroupForm.controls['uomId'].value,
            uomShortName: this.newAppraisalGroupForm.controls['uomShortName'].value,
            totalVolume: this.newAppraisalGroupForm.controls['totalVolume'].value,
            minimumRate: this.newAppraisalGroupForm.controls['minimumRate'].value,
            standardRate: this.newAppraisalGroupForm.controls['standardRate'].value
          })
        );

        this.modalService.close('modal-4');
        this.initializeNewAppraisalGroupForm();
      }
     
    }

    saveAppraisalGroup(): void {
      if(this.editedAppraisalGroupForm?.controls['appraisalGroupId']?.value > 0) {
        let appraisalGroup = this.appraisalGroupForms.controls[this.editedAppraisalGroupIndex];
       
        appraisalGroup.controls['treeCondition'].patchValue(this.editedAppraisalGroupForm.controls['treeCondition'].value);
        appraisalGroup.controls['appraisalGroupId'].patchValue(this.editedAppraisalGroupForm.controls['appraisalGroupId'].value);
        appraisalGroup.controls['appraisalGroupName'].patchValue(this.editedAppraisalGroupForm.controls['appraisalGroupName'].value);
        appraisalGroup.controls['appraisalGroup'].patchValue(this.editedAppraisalGroupForm.controls['appraisalGroup'].value);
        appraisalGroup.controls['treeProductId'].patchValue(this.editedAppraisalGroupForm.controls['treeProductId'].value);
        appraisalGroup.controls['treeSpeciesId'].patchValue(this.editedAppraisalGroupForm.controls['treeSpeciesId'].value);
        appraisalGroup.controls['treeProductName'].patchValue(this.editedAppraisalGroupForm.controls['treeProductName'].value);
        appraisalGroup.controls['treeSpeciesName'].patchValue(this.editedAppraisalGroupForm.controls['treeSpeciesName'].value);
        appraisalGroup.controls['uomId'].patchValue(this.editedAppraisalGroupForm.controls['uomId'].value);
        appraisalGroup.controls['uomShortName'].patchValue(this.editedAppraisalGroupForm.controls['uomShortName'].value);
        appraisalGroup.controls['totalVolume'].patchValue(this.editedAppraisalGroupForm.controls['totalVolume'].value);
        appraisalGroup.controls['minimumRate'].patchValue(this.editedAppraisalGroupForm.controls['minimumRate'].value);
        appraisalGroup.controls['standardRate'].patchValue(this.editedAppraisalGroupForm.controls['standardRate'].value);
        this.modalService.close('modal-5');
        this.initializeEditedAppraisalGroupForm();
      }
     
    }
    
    deleteAppraisalGroup(index: number) {
      this.appraisalGroupForms.removeAt(index);
    }

    editAppraisalGroup(index: number) {
      let appraisalGroup = this.appraisalGroupForms.controls[index];
      this.editedAppraisalGroupForm = appraisalGroup;
      this.editedAppraisalGroupIndex = index;
      if(appraisalGroup)
        this.modalService.open('modal-5');
    }

    getTreeProducts(){
        let appraisalGroups = this.appraisalGroupsControl.value;
        let treeProducts: TreeProduct[] = [];
        appraisalGroups.forEach(ag => {
          let duplicateCheck = treeProducts.some(tp => tp.id == ag.treeProductId);
          if(!duplicateCheck)
            treeProducts.push(ag.treeProduct)
        });
        this.treeProducts = treeProducts;
    }

    getUom(){
        this.uomService.uom$.subscribe({
            next: response => {
              if(response != null){
                this.uom = response;
                this.setInitialUom();
              }
                
            }
        })
    }

    get initialUom(): Uom[] | undefined {
      if(this.initialAppraisalGroup) {
        let rates = this.initialAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1);
        let uoms: Uom[] = [];
        rates.forEach(rate => {
          let uom = uoms.filter(u => u.id == rate.unitOfMeasureId)[0];
          if(!uom)
            uoms.push(rate.unitOfMeasure);
        });
        return uoms;
      }
      return undefined;
    }

    get newUom(): Uom[] | undefined {
      if(this.initialAppraisalGroupForm.controls['uomId']) {
        let uomId = this.initialAppraisalGroupForm.controls['uomId'].value;
        let rate = this.initialAppraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1 && agr.unitOfMeasureId == uomId)[0];
        let uoms: Uom[] = [rate.unitOfMeasure];
        return uoms;
      }
      return undefined;
    }


    get appraisalGroups(): FormArray<FormGroup> {
      return this.appraisalGroupForms;
    }

    get appraisalType(): AppraisalTypes{
      return this.appraisalTypeControl.value as AppraisalTypes;
    }

    setInitialProductAndSpecies() {
        this.initialAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
        this.initialAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
        this.initialAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
        if(this.initialAppraisalGroupForm.controls['treeProductId']?.value > 0) {
          let treeProduct = this.treeProducts?.filter(p => p.id == this.initialAppraisalGroupForm.controls['treeProductId'].value)[0];
          this.initialAppraisalGroupForm.controls['treeProductId'].patchValue(treeProduct.id);
          this.initialAppraisalGroupForm.controls['treeProductName'].patchValue(treeProduct.productName);
          this.getInitialSpeciesByProductId(treeProduct.id);
        }

        
    }

    getInitialSpeciesByProductId(treeProductId: number) {
      let appraisalGroups = this.appraisalGroupsControl.value;
      let treeSpecies: TreeSpecies[] = [];
      if(appraisalGroups.length > 0) {
        let filteredAppraisalGroups = appraisalGroups.filter(ag => ag.treeProductId == treeProductId);
        filteredAppraisalGroups.forEach(ag => {
          ag.appraisalGroupSpecies.forEach(ags => {
            treeSpecies.push(ags.treeSpecies)
          });
        });   
        this.initialTreeSpecies = treeSpecies;
      }
    }

    setInitialSpeciesAndAG() {
      this.initialAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
      this.initialAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
      this.initialAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
      if(this.initialAppraisalGroupForm.controls['treeSpeciesId']?.value > 0){

        let treeSpecies = this.initialTreeSpecies?.filter(p => p.id == this.initialAppraisalGroupForm.controls['treeSpeciesId'].value)[0];
        this.initialAppraisalGroupForm.controls['treeSpeciesId'].patchValue(treeSpecies.id);
        this.initialAppraisalGroupForm.controls['treeSpeciesName'].patchValue(treeSpecies.commonName);
        this.setInitialAppraisalGroup();
      }
    }

    setInitialCondition(){
      this.setInitialAppraisalGroup();
    }


    setNewProductAndSpecies() {
      this.newAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
      this.newAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
      this.newAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
      if(this.newAppraisalGroupForm.controls['treeProductId']?.value > 0) {
        let treeProduct = this.treeProducts?.filter(p => p.id == this.newAppraisalGroupForm.controls['treeProductId'].value)[0];
        this.newAppraisalGroupForm.controls['treeProductId'].patchValue(treeProduct.id);
        this.newAppraisalGroupForm.controls['treeProductName'].patchValue(treeProduct.productName);
        this.getNewSpeciesByProductId(treeProduct.id);
      }

      
  }

  getNewSpeciesByProductId(treeProductId: number) {
    let appraisalGroups = this.appraisalGroupsControl.value;
    let treeSpecies: TreeSpecies[] = [];
    if(appraisalGroups.length > 0) {
      let uomId = this.initialAppraisalGroupForm.controls['uomId'].value;
      let filteredAppraisalGroups = appraisalGroups.filter(ag => ag.treeProductId == treeProductId && ag.appraisalGroupRates.some(x => x.unitOfMeasureId == uomId));
      filteredAppraisalGroups.forEach(ag => {
        ag.appraisalGroupSpecies.forEach(ags => {
          treeSpecies.push(ags.treeSpecies)
        });
      });   
      this.newTreeSpecies = treeSpecies;
    }
  }

  setNewSpeciesAndAG() {
    this.newAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
    this.newAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
    this.newAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
    if(this.newAppraisalGroupForm.controls['treeSpeciesId']?.value > 0){

      let treeSpecies = this.newTreeSpecies?.filter(p => p.id == this.newAppraisalGroupForm.controls['treeSpeciesId'].value)[0];
      this.newAppraisalGroupForm.controls['treeSpeciesId'].patchValue(treeSpecies.id);
      this.newAppraisalGroupForm.controls['treeSpeciesName'].patchValue(treeSpecies.commonName);
      this.setNewAppraisalGroup();
    }
  }

  setNewCondition(){
    this.setNewAppraisalGroup();
  }

  setEditedProductAndSpecies() {
    this.editedAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
    this.editedAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
    this.editedAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
    if(this.editedAppraisalGroupForm.controls['treeProductId']?.value > 0) {
      let treeProduct = this.treeProducts?.filter(p => p.id == this.editedAppraisalGroupForm.controls['treeProductId'].value)[0];
      this.editedAppraisalGroupForm.controls['treeProductId'].patchValue(treeProduct.id);
      this.editedAppraisalGroupForm.controls['treeProductName'].patchValue(treeProduct.productName);
      this.getEditedSpeciesByProductId(treeProduct.id);
    }

    
  }

  getEditedSpeciesByProductId(treeProductId: number) {
    let appraisalGroups = this.appraisalGroupsControl.value;
    let treeSpecies: TreeSpecies[] = [];
    if(appraisalGroups.length > 0) {
      let uomId = this.initialAppraisalGroupForm.controls['uomId'].value;
      let filteredAppraisalGroups = appraisalGroups.filter(ag => ag.treeProductId == treeProductId && ag.appraisalGroupRates.some(x => x.unitOfMeasureId == uomId));
      filteredAppraisalGroups.forEach(ag => {
        ag.appraisalGroupSpecies.forEach(ags => {
          treeSpecies.push(ags.treeSpecies)
        });
      });   
      this.editedTreeSpecies = treeSpecies;
    }
  }

  setEditedSpeciesAndAG() {
    this.editedAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
    this.editedAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
    this.editedAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
    if(this.editedAppraisalGroupForm.controls['treeSpeciesId']?.value > 0){

      let treeSpecies = this.newTreeSpecies?.filter(p => p.id == this.editedAppraisalGroupForm.controls['treeSpeciesId'].value)[0];
      this.editedAppraisalGroupForm.controls['treeSpeciesId'].patchValue(treeSpecies.id);
      this.editedAppraisalGroupForm.controls['treeSpeciesName'].patchValue(treeSpecies.commonName);
      this.setEditedAppraisalGroup();
    }
  }

  setEditedCondition(){
    this.setEditedAppraisalGroup();
  }

    setInitialUom() {
        let uom = this.uom?.filter(u => u.id == this.initialAppraisalGroupForm.controls['uomId'].value)[0];
        if(uom) {
          this.initialAppraisalGroupForm.controls['uomId'].patchValue(uom.id);
          this.initialAppraisalGroupForm.controls['uomShortName'].patchValue(uom.uomShortName);
        }
    }

    get initialUnitOfMeasure(): string{
        return this.initialAppraisalGroupForm.controls['uomShortName'].value;
    }

    get initialTotalVolume(): FormControl {
      return this.initialAppraisalGroupForm.controls['totalVolume'] as FormControl;
    }

    private setInitialAppraisalGroup(){
      let treeProductId = this.initialAppraisalGroupForm.controls['treeProductId'].value;
      let treeSpeciesId = this.initialAppraisalGroupForm.controls['treeSpeciesId'].value;
      let treeCondition = this.initialAppraisalGroupForm.controls['treeCondition'].value; 
      let appraisalZoneId = this.appraisalZoneIdControl.value;

      console.log(treeProductId, treeSpeciesId, treeCondition, appraisalZoneId);

      if(treeProductId && treeSpeciesId && treeCondition && appraisalZoneId){
        let appraisalGroups = this.appraisalGroupsControl.value;
        let appraisalGroup = appraisalGroups.filter(ag => ag.treeProductId == treeProductId 
          && ag.treeCondition == treeCondition 
          && ag.appraisalGroupSpecies.some(ags => ags.treeSpeciesId == treeSpeciesId))[0];
        console.log(appraisalGroups);
        console.log(appraisalGroup);
        if(appraisalGroup){
          this.initialAppraisalGroup = appraisalGroup;
          this.initialAppraisalGroupForm.controls['appraisalGroupId'].patchValue(appraisalGroup.id);
          this.initialAppraisalGroupForm.controls['appraisalGroupName'].patchValue(appraisalGroup.appraisalGroupName);
          this.initialAppraisalGroupForm.controls['appraisalGroup'].patchValue(appraisalGroup);
          let minRates = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1);
          if(minRates.length > 0 && minRates[0].rate != null && !isNaN(minRates[0].rate)) {
            let minRate = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1)[0];
            this.initialAppraisalGroupForm.controls['minimumRate'].patchValue(minRate.rate);
            this.initialAppraisalGroupForm.controls['uomId'].patchValue(minRate.unitOfMeasureId);
            this.initialAppraisalGroupForm.controls['uomShortName'].patchValue(minRate.unitOfMeasure.uomShortName);
          }
          let standardRate = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2);
          if(standardRate.length > 0 && standardRate[0].rate != null && !isNaN(standardRate[0].rate)){
            this.initialAppraisalGroupForm.controls['standardRate'].patchValue(appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2)[0]?.rate)
          }
        } else {
          this.initialAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
          this.initialAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
          this.initialAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
          this.initialAppraisalGroupForm.controls['minimumRate'].patchValue(null);
          this.initialAppraisalGroupForm.controls['standardRate'].patchValue(null);
        }
        
        
      }        
    }

    addAppraisalGroup() {
      let uom = this.uom?.filter(u => u.id == this.initialAppraisalGroupForm.controls['uomId'].value)[0];
      if(uom) {
        this.newAppraisalGroupForm.controls['uomId'].patchValue(uom.id);
        this.newAppraisalGroupForm.controls['uomShortName'].patchValue(uom.uomShortName);
      }
      this.modalService.open('modal-4');
    }

  //   setNewUom() {
  //     let uom = this.uom?.filter(u => u.id == this.initialAppraisalGroupForm.controls['uomId'].value)[0];
  //     if(uom) {
  //       this.newAppraisalGroupForm.controls['uomId'].patchValue(uom.id);
  //       this.newAppraisalGroupForm.controls['uomShortName'].patchValue(uom.uomShortName);
  //     }
  // }

  //   setEditedUom() {
  //     let uom = this.uom?.filter(u => u.id == this.initialAppraisalGroupForm.controls['uomId'].value)[0];
  //     if(uom) {
  //       this.editedAppraisalGroupForm.controls['uomId'].patchValue(uom.id);
  //       this.editedAppraisalGroupForm.controls['uomShortName'].patchValue(uom.uomShortName);
  //     }
  //   }

    get newUnitOfMeasure(): string{
      return this.newAppraisalGroupForm.controls['uomShortName'].value;
    }

    get newTotalVolume(): FormControl {
      return this.newAppraisalGroupForm.controls['totalVolume'] as FormControl;
    }


    private setNewAppraisalGroup(){
      let treeProductId = this.newAppraisalGroupForm.controls['treeProductId'].value;
      let treeSpeciesId = this.newAppraisalGroupForm.controls['treeSpeciesId'].value;
      let treeCondition = this.newAppraisalGroupForm.controls['treeCondition'].value; 
      let uomId = this.newAppraisalGroupForm.controls['uomId'].value;
      let appraisalZoneId = this.appraisalZoneIdControl.value;

      console.log(treeProductId, treeSpeciesId, treeCondition, appraisalZoneId);

      if(treeProductId && treeSpeciesId && treeCondition && appraisalZoneId && uomId){
        let appraisalGroups = this.appraisalGroupsControl.value;
        let appraisalGroup = appraisalGroups.filter(ag => ag.treeProductId == treeProductId 
          && ag.treeCondition == treeCondition 
          && ag.appraisalGroupSpecies.some(ags => ags.treeSpeciesId == treeSpeciesId))[0];
        console.log(appraisalGroups);
        console.log(appraisalGroup);
        if(appraisalGroup){
          this.newAppraisalGroup = appraisalGroup;
          this.newAppraisalGroupForm.controls['appraisalGroupId'].patchValue(appraisalGroup.id);
          this.newAppraisalGroupForm.controls['appraisalGroupName'].patchValue(appraisalGroup.appraisalGroupName);
          this.newAppraisalGroupForm.controls['appraisalGroup'].patchValue(appraisalGroup);
          let minRates = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1);
          if(minRates.length > 0 && minRates[0].rate != null && !isNaN(minRates[0].rate)) {
            let minRate = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1 && agr.unitOfMeasureId == uomId)[0];
            this.newAppraisalGroupForm.controls['minimumRate'].patchValue(minRate.rate);
          }
          let standardRate = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2);
          if(standardRate.length > 0 && standardRate[0].rate != null && !isNaN(standardRate[0].rate)){
            this.newAppraisalGroupForm.controls['standardRate'].patchValue(appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2)[0]?.rate)
          }
        } else {
          this.newAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
          this.newAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
          this.newAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
          this.newAppraisalGroupForm.controls['minimumRate'].patchValue(null);
          this.newAppraisalGroupForm.controls['standardRate'].patchValue(null);
        }
        
        
      }        
    }

    private setEditedAppraisalGroup(){
      let treeProductId = this.editedAppraisalGroupForm.controls['treeProductId'].value;
      let treeSpeciesId = this.editedAppraisalGroupForm.controls['treeSpeciesId'].value;
      let treeCondition = this.editedAppraisalGroupForm.controls['treeCondition'].value; 
      let uomId = this.editedAppraisalGroupForm.controls['uomId'].value;
      let appraisalZoneId = this.appraisalZoneIdControl.value;

      console.log(treeProductId, treeSpeciesId, treeCondition, appraisalZoneId);

      if(treeProductId && treeSpeciesId && treeCondition && appraisalZoneId){
        let appraisalGroups = this.appraisalGroupsControl.value;
        let appraisalGroup = appraisalGroups.filter(ag => ag.treeProductId == treeProductId 
          && ag.treeCondition == treeCondition 
          && ag.appraisalGroupSpecies.some(ags => ags.treeSpeciesId == treeSpeciesId))[0];
        console.log(appraisalGroups);
        console.log(appraisalGroup);
        if(appraisalGroup){
          this.editedAppraisalGroup = appraisalGroup;
          this.editedAppraisalGroupForm.controls['appraisalGroupId'].patchValue(appraisalGroup.id);
          this.editedAppraisalGroupForm.controls['appraisalGroupName'].patchValue(appraisalGroup.appraisalGroupName);
          this.editedAppraisalGroupForm.controls['appraisalGroup'].patchValue(appraisalGroup);
          let minRates = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1);
          if(minRates.length > 0 && minRates[0].rate != null && !isNaN(minRates[0].rate)) {
            let minRate = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 1 && agr.unitOfMeasureId == uomId)[0];
            this.editedAppraisalGroupForm.controls['minimumRate'].patchValue(minRate.rate);
          }
          let standardRate = appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2);
          if(standardRate.length > 0 && standardRate[0].rate != null && !isNaN(standardRate[0].rate)){
            this.editedAppraisalGroupForm.controls['standardRate'].patchValue(appraisalGroup.appraisalGroupRates.filter(agr => agr.appraisalRateTypeId == 2)[0]?.rate)
          }
        } else {
          this.editedAppraisalGroupForm.controls['appraisalGroupId'].patchValue(null);
          this.editedAppraisalGroupForm.controls['appraisalGroupName'].patchValue(null);
          this.editedAppraisalGroupForm.controls['appraisalGroup'].patchValue(null);
          this.editedAppraisalGroupForm.controls['minimumRate'].patchValue(null);
          this.editedAppraisalGroupForm.controls['standardRate'].patchValue(null);
        }
        
        
      }        
    }

    get appraiserRoleId(): number {
      return this.appraiserRoleIdControl.value;
    }

    get checkDisabled(): boolean {
      return this.appraisalType === this.appraisalTypes.StreamlinedTe 
          || !this.initialAppraisalGroupForm.valid 
          || this.initialAppraisalGroupForm.controls['totalVolume'].value <= 0 
          || (this.appraiserRoleId == UserRoles.Guest && this.appraisalGroups.length == 5)
    }


    get appraisalSale() : AppraisalSale {
      return this.appraisalSaleControl.value;
  }
  
  get appraisalGroupsCount() : number {
    let totalAppraisalGroups = 0;
      if(this.appraisalSale?.cuttingUnits) {
          let cuttingUnits = this.appraisalSale?.cuttingUnits;
          if(cuttingUnits) {
              cuttingUnits.forEach(cu => {
                totalAppraisalGroups += cu.appraisalGroupVolumes.length;
              }); 
          }
      }
      
      return Math.round(totalAppraisalGroups);
  }

  get totalVolume() : number {
    let totalVolume = 0;
    if(this.appraisalSale?.cuttingUnits) {
        let cuttingUnits = this.appraisalSale?.cuttingUnits;
        if(cuttingUnits) {
            cuttingUnits.forEach(cu => {
                let volumes = cu.appraisalGroupVolumes;
                volumes.forEach(v => { 
                  totalVolume += v.sumGrossCuFt;
                });
            }); 
        }
    }
    
    return Math.round(totalVolume);
  }

  continue(event: any){
      event.preventDefault();

      let element: HTMLElement = document.getElementById("panel-3-button") as HTMLElement;
      element.click();
      element.scrollIntoView({behavior:'smooth',block:'start'});
      
      
      
  }

  //MOCK DATA
  cutting_units = [
    { id: 'CU00001', unit1: '100 /CCF', unit2: '200 /CCF', unit3: '200 /TONS', unit4: '200 /TONS'},
    { id: 'CU00002', unit1: '100 /CCF', unit2: '300 /CCF', unit3: '100 /CCF', unit4: '200 /TONS'},
    { id: 'CU00003', unit1: '200 /CCF', unit2: '100 /CCF', unit3: '200 /CCF', unit4: '200 /TONS'},
    { id: 'CU00004', unit1: '400 /CCF', unit2: '100 /CCF', unit3: '200 /CCF', unit4: '200 /TONS'},   
    { id: 'CU00005', unit1: '100 /CCF', unit2: '100 /CCF', unit3: '200 /CCF', unit4: '200 /TONS'},
  ];

  page = 1;
  pageSize = 2;
  
}