import {Directive, Input, Optional, Self} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';

@Directive({
    selector: 'mat-checkbox[forestCheckboxValue]',
    exportAs: 'forestCheckboxValue',
    standalone: false
})
export class ForestCheckboxValueDirective {

  @Input('forestCheckboxValue') checkbox!: MatCheckbox;
  @Input() falseValue: string = '0';
  @Input() trueValue: string = '1';
  @Input() forestId: string = '';


  ngOnInit() {
    this.checkbox.value = this.forestId;
    console.log(this.checkbox.value)
    this.checkbox.registerOnChange((checked: boolean) => {
      this.checkbox.value = this.forestId;  //checked ? this.trueValue : this.falseValue;
    })
  }
}