import { Component } from '@angular/core';

@Component({
  selector: 'app-wwpa-indices',
  //standalone: true,
  //imports: [],
  templateUrl: './wwpa-indices.component.html',
  styleUrl: './wwpa-indices.component.scss'
})
export class ViewWwpaIndicesComponent {

}
