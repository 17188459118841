<div class="mb-3">
    <input 
        type={{type}}
        [class.is-invalid]="control.touched && control.invalid"
        class="form-control usa-input" 
        [formControl]="control"
        placeholder={{label}}
        [value]="value"
        
    #input>
    <div class="error-wrapper" *ngIf="control.invalid && (control.dirty || control.touched)">
     <div class="invalid-feedback" *ngIf="control.errors?.['required']">Error: Please enter {{label}}</div>
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['minlength']">
        Error:{{label}} must be at least {{control.errors?.['minlength'].requiredLength}} characters
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['maxlength']">
        Error:{{label}} must be at most {{control.errors?.['maxlength'].requiredLength}} characters
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['notMatching']">
        Error: Passwords do not match
    </div> <!---->
</div>