import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modals:any[] = []

  add(modal:any){
    //add modal to array of active modals
     this.modals.push(modal)
  }

  remove(id:string){
    //remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id)
  }

  open(id:string){
    //open modal by id
    const modal = this.modals.find(x => x.id === id)
    modal.open();
    this.onFoucsModal.next('');
   
  }
  close(id:string){
    //close modal by id
    const modal = this.modals.find(x => x.id === id);
    modal.close();
  }

  onFoucsModal = new Subject()
  onSaveSubmit = new Subject()

}
