import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-toggle-buttons',
    templateUrl: './toggle-buttons.component.html',
    styleUrls: ['./toggle-buttons.component.scss'],
    standalone: false
})
export class ToggleButtonsComponent<T> implements ControlValueAccessor, OnInit {
  @Input() label = '';
  @Input() type = 'radio';
  @Input() buttonValues: any[] = [];

  control!: FormControl;
 
 
  constructor(@Self() public ngControl: NgControl) { 
    this.ngControl.valueAccessor = this;

    this.control = new FormControl;
    this.control.hasValidator(Validators.required)
    // this.control.valueChanges.subscribe(value=>{
    //   this.onChanges(value)
    // })
  }

  public get invalid() :boolean{
     return this.ngControl?.invalid ?? false
  }

  /*public get showError():boolean{
     if(!this.ngControl){
       return false
     }

     const {dirty, touched} = this.ngControl;

     return this.invalid ? (dirty || touched) || false
  }*/

  writeValue(obj: any): void {
    console.log("writeValue() not implemented;");
  }

  registerOnChange(fn: any): void {
    console.log("registerOnChange() not implemented;");
  }

  registerOnTouched(fn: any): void {
    console.log("registerOnTouched() not implemented;");
  }

  ngOnInit(): void {
    console.log("ngOnInit() not implemented;");
  }

  // get control(): FormControl {
  //   return this.ngControl.control as FormControl
  // }
}