import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-appraisal-zones',
    templateUrl: './appraisal-zones.component.html',
    styleUrls: ['./appraisal-zones.component.scss'],
    standalone: false
})

export class AppraisalZonesComponent implements OnInit {

    constructor() {
        console.log("constructor not implemented.");
    }

    ngOnInit() {
        console.log("ngOnInit not implemented.");
    }

    
}


