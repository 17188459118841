<div class="mb-3">
    <input 
        type="text"
        [class.is-invalid]="control.touched && control.invalid"
        class="usa-input"
        [formControl]="control"
        placeholder="{{label}}"
        bsDatepicker
        [bsConfig]="bsConfig"
        [maxDate]="maxDate"
        aria-labelledby="appraisal-date-label"
        aria-describedby="appraisal-date-hint"
        placeholder="Datepicker"
        
        style="margin-top:0 !important;">
    <div *ngIf="control.errors?.['required']" class="invalid-feedback">{{label}} is required</div>
</div>
