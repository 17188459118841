import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})

export class HelpComponent implements OnInit {

    constructor() {
        console.log("constructor not implemented.");
    }


    ngOnInit() {
        console.log("ngOnInit not implemented.");
    }

    
}


