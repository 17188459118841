import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: false
})
export class NotFoundComponent implements OnInit {   

  constructor(
      private router: Router,
      private route: ActivatedRoute
  ) {
  }


  ngOnInit(): void {
    console.log("ngOnInit not implemented.");
  }

  back() {
    this.router.navigate(['/home']);   
  }

}
