!
/**
* Highcharts Dashboards v2.3.0 (2024-08-26)
*
* (c) 2009-2024 Highsoft AS
*
* License: www.highcharts.com/license
*/
function (e, t) {
  "object" == typeof module && module.exports ? (t.default = t, module.exports = e && e.document ? t(e) : t) : "function" == typeof define && define.amd ? define("datagrid/datagrid", function () {
    return t(e);
  }) : (e.DataGrid && e.DataGrid.error(16, !0), e.DataGrid = t(e));
}("undefined" != typeof window ? window : this, function (e) {
  "use strict";

  var t = {};
  function i(t, i, s, n) {
    !t.hasOwnProperty(i) && (t[i] = n.apply(null, s), e && "function" == typeof CustomEvent && e.dispatchEvent(new CustomEvent("DataGridModuleLoaded", {
      detail: {
        path: i,
        module: t[i]
      }
    })));
  }
  return i(t, "Core/Globals.js", [], function () {
    var t, i;
    return (i = t || (t = {})).SVG_NS = "http://www.w3.org/2000/svg", i.product = "Highcharts", i.version = "2.3.0", i.win = void 0 !== e ? e : {}, i.doc = i.win.document, i.svg = i.doc && i.doc.createElementNS && !!i.doc.createElementNS(i.SVG_NS, "svg").createSVGRect, i.userAgent = i.win.navigator && i.win.navigator.userAgent || "", i.isChrome = i.win.chrome, i.isFirefox = -1 !== i.userAgent.indexOf("Firefox"), i.isMS = /(edge|msie|trident)/i.test(i.userAgent) && !i.win.opera, i.isSafari = !i.isChrome && -1 !== i.userAgent.indexOf("Safari"), i.isTouchDevice = /(Mobile|Android|Windows Phone)/.test(i.userAgent), i.isWebKit = -1 !== i.userAgent.indexOf("AppleWebKit"), i.deg2rad = 2 * Math.PI / 360, i.hasBidiBug = i.isFirefox && 4 > parseInt(i.userAgent.split("Firefox/")[1], 10), i.marginNames = ["plotTop", "marginRight", "marginBottom", "plotLeft"], i.noop = function () {}, i.supportsPassiveEvents = function () {
      let e = !1;
      if (!i.isMS) {
        let t = Object.defineProperty({}, "passive", {
          get: function () {
            e = !0;
          }
        });
        i.win.addEventListener && i.win.removeEventListener && (i.win.addEventListener("testPassive", i.noop, t), i.win.removeEventListener("testPassive", i.noop, t));
      }
      return e;
    }(), i.charts = [], i.composed = [], i.dateFormats = {}, i.seriesTypes = {}, i.symbolSizes = {}, i.chartCount = 0, t;
  }), i(t, "Core/Utilities.js", [t["Core/Globals.js"]], function (e) {
    let t;
    let {
      charts: i,
      doc: s,
      win: n
    } = e;
    function o(t, i, s, r) {
      let l = i ? "Highcharts error" : "Highcharts warning";
      32 === t && (t = `${l}: Deprecated member`);
      let a = c(t),
        h = a ? `${l} #${t}: www.highcharts.com/errors/${t}/` : t.toString();
      if (void 0 !== r) {
        let e = "";
        a && (h += "?"), T(r, function (t, i) {
          e += `
 - ${i}: ${t}`, a && (h += encodeURI(i) + "=" + encodeURI(t));
        }), h += e;
      }
      R(e, "displayError", {
        chart: s,
        code: t,
        message: h,
        params: r
      }, function () {
        if (i) throw Error(h);
        n.console && -1 === o.messages.indexOf(h) && console.warn(h);
      }), o.messages.push(h);
    }
    function r(e, t) {
      return parseInt(e, t || 10);
    }
    function l(e) {
      return "string" == typeof e;
    }
    function a(e) {
      let t = Object.prototype.toString.call(e);
      return "[object Array]" === t || "[object Array Iterator]" === t;
    }
    function h(e, t) {
      return !!e && "object" == typeof e && (!t || !a(e));
    }
    function d(e) {
      return h(e) && "number" == typeof e.nodeType;
    }
    function u(e) {
      let t = e && e.constructor;
      return !!(h(e, !0) && !d(e) && t && t.name && "Object" !== t.name);
    }
    function c(e) {
      return "number" == typeof e && !isNaN(e) && e < 1 / 0 && e > -1 / 0;
    }
    function f(e) {
      return null != e;
    }
    function m(e, t, i) {
      let s;
      let n = l(t) && !f(i),
        o = (t, i) => {
          f(t) ? e.setAttribute(i, t) : n ? (s = e.getAttribute(i)) || "class" !== i || (s = e.getAttribute(i + "Name")) : e.removeAttribute(i);
        };
      return l(t) ? o(i, t) : T(t, o), s;
    }
    function p(e) {
      return a(e) ? e : [e];
    }
    function g(e, t) {
      let i;
      for (i in e || (e = {}), t) e[i] = t[i];
      return e;
    }
    function C() {
      let e = arguments,
        t = e.length;
      for (let i = 0; i < t; i++) {
        let t = e[i];
        if (null != t) return t;
      }
    }
    function y(e, t) {
      g(e.style, t);
    }
    function b(e) {
      return Math.pow(10, Math.floor(Math.log(e) / Math.LN10));
    }
    function w(e, t) {
      return e > 1e14 ? e : parseFloat(e.toPrecision(t || 14));
    }
    (o || (o = {})).messages = [], Math.easeInOutSine = function (e) {
      return -.5 * (Math.cos(Math.PI * e) - 1);
    };
    let v = Array.prototype.find ? function (e, t) {
      return e.find(t);
    } : function (e, t) {
      let i;
      let s = e.length;
      for (i = 0; i < s; i++) if (t(e[i], i)) return e[i];
    };
    function T(e, t, i) {
      for (let s in e) Object.hasOwnProperty.call(e, s) && t.call(i || e[s], e[s], s, e);
    }
    function D(e, t, i) {
      function s(t, i) {
        let s = e.removeEventListener;
        s && s.call(e, t, i, !1);
      }
      function n(i) {
        let n, o;
        e.nodeName && (t ? (n = {})[t] = !0 : n = i, T(n, function (e, t) {
          if (i[t]) for (o = i[t].length; o--;) s(t, i[t][o].fn);
        }));
      }
      let o = "function" == typeof e && e.prototype || e;
      if (Object.hasOwnProperty.call(o, "hcEvents")) {
        let e = o.hcEvents;
        if (t) {
          let o = e[t] || [];
          i ? (e[t] = o.filter(function (e) {
            return i !== e.fn;
          }), s(t, i)) : (n(e), e[t] = []);
        } else n(e), delete o.hcEvents;
      }
    }
    function R(t, i, n, o) {
      if (n = n || {}, s.createEvent && (t.dispatchEvent || t.fireEvent && t !== e)) {
        let e = s.createEvent("Events");
        e.initEvent(i, !0, !0), n = g(e, n), t.dispatchEvent ? t.dispatchEvent(n) : t.fireEvent(i, n);
      } else if (t.hcEvents) {
        n.target || g(n, {
          preventDefault: function () {
            n.defaultPrevented = !0;
          },
          target: t,
          type: i
        });
        let e = [],
          s = t,
          o = !1;
        for (; s.hcEvents;) Object.hasOwnProperty.call(s, "hcEvents") && s.hcEvents[i] && (e.length && (o = !0), e.unshift.apply(e, s.hcEvents[i])), s = Object.getPrototypeOf(s);
        o && e.sort((e, t) => e.order - t.order), e.forEach(e => {
          !1 === e.fn.call(t, n) && n.preventDefault();
        });
      }
      o && !n.defaultPrevented && o.call(t, n);
    }
    T({
      map: "map",
      each: "forEach",
      grep: "filter",
      reduce: "reduce",
      some: "some"
    }, function (t, i) {
      e[i] = function (e) {
        return o(32, !1, void 0, {
          [`Highcharts.${i}`]: `use Array.${t}`
        }), Array.prototype[t].apply(e, [].slice.call(arguments, 1));
      };
    });
    let x = function () {
      let e = Math.random().toString(36).substring(2, 9) + "-",
        i = 0;
      return function () {
        return "highcharts-" + (t ? "" : e) + i++;
      };
    }();
    return n.jQuery && (n.jQuery.fn.highcharts = function () {
      let t = [].slice.call(arguments);
      if (this[0]) return t[0] ? (new e[l(t[0]) ? t.shift() : "Chart"](this[0], t[0], t[1]), this) : i[m(this[0], "data-highcharts-chart")];
    }), {
      addEvent: function (t, i, s, n = {}) {
        let o = "function" == typeof t && t.prototype || t;
        Object.hasOwnProperty.call(o, "hcEvents") || (o.hcEvents = {});
        let r = o.hcEvents;
        e.Point && t instanceof e.Point && t.series && t.series.chart && (t.series.chart.runTrackerClick = !0);
        let l = t.addEventListener;
        l && l.call(t, i, s, !!e.supportsPassiveEvents && {
          passive: void 0 === n.passive ? -1 !== i.indexOf("touch") : n.passive,
          capture: !1
        }), r[i] || (r[i] = []);
        let a = {
          fn: s,
          order: "number" == typeof n.order ? n.order : 1 / 0
        };
        return r[i].push(a), r[i].sort((e, t) => e.order - t.order), function () {
          D(t, i, s);
        };
      },
      arrayMax: function (e) {
        let t = e.length,
          i = e[0];
        for (; t--;) e[t] > i && (i = e[t]);
        return i;
      },
      arrayMin: function (e) {
        let t = e.length,
          i = e[0];
        for (; t--;) e[t] < i && (i = e[t]);
        return i;
      },
      attr: m,
      clamp: function (e, t, i) {
        return e > t ? e < i ? e : i : t;
      },
      clearTimeout: function (e) {
        f(e) && clearTimeout(e);
      },
      correctFloat: w,
      createElement: function (e, t, i, n, o) {
        let r = s.createElement(e);
        return t && g(r, t), o && y(r, {
          padding: "0",
          border: "none",
          margin: "0"
        }), i && y(r, i), n && n.appendChild(r), r;
      },
      crisp: (e, t = 0, i) => {
        let s = t % 2 / 2,
          n = i ? -1 : 1;
        return (Math.round(e * n - s) + s) * n;
      },
      css: y,
      defined: f,
      destroyObjectProperties: function (e, t, i) {
        T(e, function (s, n) {
          s !== t && s?.destroy && s.destroy(), (s?.destroy || !i) && delete e[n];
        });
      },
      diffObjects: function (e, t, i, s) {
        let n = {};
        return function e(t, n, o, r) {
          let l = i ? n : t;
          T(t, function (i, d) {
            if (!r && s && s.indexOf(d) > -1 && n[d]) {
              i = p(i), o[d] = [];
              for (let t = 0; t < Math.max(i.length, n[d].length); t++) n[d][t] && (void 0 === i[t] ? o[d][t] = n[d][t] : (o[d][t] = {}, e(i[t], n[d][t], o[d][t], r + 1)));
            } else h(i, !0) && !i.nodeType ? (o[d] = a(i) ? [] : {}, e(i, n[d] || {}, o[d], r + 1), 0 !== Object.keys(o[d]).length || "colorAxis" === d && 0 === r || delete o[d]) : (t[d] !== n[d] || d in t && !(d in n)) && "__proto__" !== d && "constructor" !== d && (o[d] = l[d]);
          });
        }(e, t, n, 0), n;
      },
      discardElement: function (e) {
        e && e.parentElement && e.parentElement.removeChild(e);
      },
      erase: function (e, t) {
        let i = e.length;
        for (; i--;) if (e[i] === t) {
          e.splice(i, 1);
          break;
        }
      },
      error: o,
      extend: g,
      extendClass: function (e, t) {
        let i = function () {};
        return i.prototype = new e(), g(i.prototype, t), i;
      },
      find: v,
      fireEvent: R,
      getClosestDistance: function (e, t) {
        let i, s, n, o;
        let r = !t;
        return e.forEach(e => {
          if (e.length > 1) for (o = s = e.length - 1; o > 0; o--) (n = e[o] - e[o - 1]) < 0 && !r ? (t?.(), t = void 0) : n && (void 0 === i || n < i) && (i = n);
        }), i;
      },
      getMagnitude: b,
      getNestedProperty: function (e, t) {
        let i = e.split(".");
        for (; i.length && f(t);) {
          let e = i.shift();
          if (void 0 === e || "__proto__" === e) return;
          if ("this" === e) {
            let e;
            return h(t) && (e = t["@this"]), e ?? t;
          }
          let s = t[e];
          if (!f(s) || "function" == typeof s || "number" == typeof s.nodeType || s === n) return;
          t = s;
        }
        return t;
      },
      getStyle: function e(t, i, s) {
        let o;
        if ("width" === i) {
          let i = Math.min(t.offsetWidth, t.scrollWidth),
            s = t.getBoundingClientRect && t.getBoundingClientRect().width;
          return s < i && s >= i - 1 && (i = Math.floor(s)), Math.max(0, i - (e(t, "padding-left", !0) || 0) - (e(t, "padding-right", !0) || 0));
        }
        if ("height" === i) return Math.max(0, Math.min(t.offsetHeight, t.scrollHeight) - (e(t, "padding-top", !0) || 0) - (e(t, "padding-bottom", !0) || 0));
        let l = n.getComputedStyle(t, void 0);
        return l && (o = l.getPropertyValue(i), C(s, "opacity" !== i) && (o = r(o))), o;
      },
      inArray: function (e, t, i) {
        return o(32, !1, void 0, {
          "Highcharts.inArray": "use Array.indexOf"
        }), t.indexOf(e, i);
      },
      insertItem: function (e, t) {
        let i;
        let s = e.options.index,
          n = t.length;
        for (i = e.options.isInternal ? n : 0; i < n + 1; i++) if (!t[i] || c(s) && s < C(t[i].options.index, t[i]._i) || t[i].options.isInternal) {
          t.splice(i, 0, e);
          break;
        }
        return i;
      },
      isArray: a,
      isClass: u,
      isDOMElement: d,
      isFunction: function (e) {
        return "function" == typeof e;
      },
      isNumber: c,
      isObject: h,
      isString: l,
      keys: function (e) {
        return o(32, !1, void 0, {
          "Highcharts.keys": "use Object.keys"
        }), Object.keys(e);
      },
      merge: function () {
        let e,
          t = arguments,
          i = {},
          s = function (e, t) {
            return "object" != typeof e && (e = {}), T(t, function (i, n) {
              "__proto__" !== n && "constructor" !== n && (!h(i, !0) || u(i) || d(i) ? e[n] = t[n] : e[n] = s(e[n] || {}, i));
            }), e;
          };
        !0 === t[0] && (i = t[1], t = Array.prototype.slice.call(t, 2));
        let n = t.length;
        for (e = 0; e < n; e++) i = s(i, t[e]);
        return i;
      },
      normalizeTickInterval: function (e, t, i, s, n) {
        let o,
          r = e;
        i = C(i, b(e));
        let l = e / i;
        for (!t && (t = n ? [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10] : [1, 2, 2.5, 5, 10], !1 === s && (1 === i ? t = t.filter(function (e) {
          return e % 1 == 0;
        }) : i <= .1 && (t = [1 / i]))), o = 0; o < t.length && (r = t[o], (!n || !(r * i >= e)) && (n || !(l <= (t[o] + (t[o + 1] || t[o])) / 2))); o++);
        return w(r * i, -Math.round(Math.log(.001) / Math.LN10));
      },
      objectEach: T,
      offset: function (e) {
        let t = s.documentElement,
          i = e.parentElement || e.parentNode ? e.getBoundingClientRect() : {
            top: 0,
            left: 0,
            width: 0,
            height: 0
          };
        return {
          top: i.top + (n.pageYOffset || t.scrollTop) - (t.clientTop || 0),
          left: i.left + (n.pageXOffset || t.scrollLeft) - (t.clientLeft || 0),
          width: i.width,
          height: i.height
        };
      },
      pad: function (e, t, i) {
        return Array((t || 2) + 1 - String(e).replace("-", "").length).join(i || "0") + e;
      },
      pick: C,
      pInt: r,
      pushUnique: function (e, t) {
        return 0 > e.indexOf(t) && !!e.push(t);
      },
      relativeLength: function (e, t, i) {
        return /%$/.test(e) ? t * parseFloat(e) / 100 + (i || 0) : parseFloat(e);
      },
      removeEvent: D,
      replaceNested: function (e, ...t) {
        let i, s;
        do for (s of (i = e, t)) e = e.replace(s[0], s[1]); while (e !== i);
        return e;
      },
      splat: p,
      stableSort: function (e, t) {
        let i, s;
        let n = e.length;
        for (s = 0; s < n; s++) e[s].safeI = s;
        for (e.sort(function (e, s) {
          return 0 === (i = t(e, s)) ? e.safeI - s.safeI : i;
        }), s = 0; s < n; s++) delete e[s].safeI;
      },
      syncTimeout: function (e, t, i) {
        return t > 0 ? setTimeout(e, t, i) : (e.call(0, i), -1);
      },
      timeUnits: {
        millisecond: 1,
        second: 1e3,
        minute: 6e4,
        hour: 36e5,
        day: 864e5,
        week: 6048e5,
        month: 24192e5,
        year: 314496e5
      },
      uniqueKey: x,
      useSerialIds: function (e) {
        return t = C(e, t);
      },
      wrap: function (e, t, i) {
        let s = e[t];
        e[t] = function () {
          let e = arguments,
            t = this;
          return i.apply(this, [function () {
            return s.apply(t, arguments.length ? arguments : e);
          }].concat([].slice.call(arguments)));
        };
      }
    };
  }), i(t, "Core/Renderer/HTML/AST.js", [t["Core/Globals.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        SVG_NS: i,
        win: s
      } = e,
      {
        attr: n,
        createElement: o,
        css: r,
        error: l,
        isFunction: a,
        isString: h,
        objectEach: d,
        splat: u
      } = t,
      {
        trustedTypes: c
      } = s,
      f = c && a(c.createPolicy) && c.createPolicy("highcharts", {
        createHTML: e => e
      }),
      m = f ? f.createHTML("") : "",
      p = function () {
        try {
          return !!new DOMParser().parseFromString(m, "text/html");
        } catch (e) {
          return !1;
        }
      }();
    class g {
      static filterUserAttributes(e) {
        return d(e, (t, i) => {
          let s = !0;
          -1 === g.allowedAttributes.indexOf(i) && (s = !1), -1 !== ["background", "dynsrc", "href", "lowsrc", "src"].indexOf(i) && (s = h(t) && g.allowedReferences.some(e => 0 === t.indexOf(e))), s || (l(33, !1, void 0, {
            "Invalid attribute in config": `${i}`
          }), delete e[i]), h(t) && e[i] && (e[i] = t.replace(/</g, "&lt;"));
        }), e;
      }
      static parseStyle(e) {
        return e.split(";").reduce((e, t) => {
          let i = t.split(":").map(e => e.trim()),
            s = i.shift();
          return s && i.length && (e[s.replace(/-([a-z])/g, e => e[1].toUpperCase())] = i.join(":")), e;
        }, {});
      }
      static setElementHTML(e, t) {
        e.innerHTML = g.emptyHTML, t && new g(t).addToDOM(e);
      }
      constructor(e) {
        this.nodes = "string" == typeof e ? this.parseMarkup(e) : e;
      }
      addToDOM(t) {
        return function t(s, o) {
          let a;
          return u(s).forEach(function (s) {
            let h;
            let u = s.tagName,
              c = s.textContent ? e.doc.createTextNode(s.textContent) : void 0,
              f = g.bypassHTMLFiltering;
            if (u) {
              if ("#text" === u) h = c;else if (-1 !== g.allowedTags.indexOf(u) || f) {
                let l = "svg" === u ? i : o.namespaceURI || i,
                  a = e.doc.createElementNS(l, u),
                  m = s.attributes || {};
                d(s, function (e, t) {
                  "tagName" !== t && "attributes" !== t && "children" !== t && "style" !== t && "textContent" !== t && (m[t] = e);
                }), n(a, f ? m : g.filterUserAttributes(m)), s.style && r(a, s.style), c && a.appendChild(c), t(s.children || [], a), h = a;
              } else l(33, !1, void 0, {
                "Invalid tagName in config": u
              });
            }
            h && o.appendChild(h), a = h;
          }), a;
        }(this.nodes, t);
      }
      parseMarkup(e) {
        let t;
        let i = [];
        if (e = e.trim().replace(/ style=(["'])/g, " data-style=$1"), p) t = new DOMParser().parseFromString(f ? f.createHTML(e) : e, "text/html");else {
          let i = o("div");
          i.innerHTML = e, t = {
            body: i
          };
        }
        let s = (e, t) => {
          let i = e.nodeName.toLowerCase(),
            n = {
              tagName: i
            };
          "#text" === i && (n.textContent = e.textContent || "");
          let o = e.attributes;
          if (o) {
            let e = {};
            [].forEach.call(o, t => {
              "data-style" === t.name ? n.style = g.parseStyle(t.value) : e[t.name] = t.value;
            }), n.attributes = e;
          }
          if (e.childNodes.length) {
            let t = [];
            [].forEach.call(e.childNodes, e => {
              s(e, t);
            }), t.length && (n.children = t);
          }
          t.push(n);
        };
        return [].forEach.call(t.body.childNodes, e => s(e, i)), i;
      }
    }
    return g.allowedAttributes = ["alt", "aria-controls", "aria-describedby", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-label", "aria-labelledby", "aria-live", "aria-pressed", "aria-readonly", "aria-roledescription", "aria-selected", "class", "clip-path", "color", "colspan", "cx", "cy", "d", "dx", "dy", "disabled", "fill", "filterUnits", "flood-color", "flood-opacity", "height", "href", "id", "in", "in2", "markerHeight", "markerWidth", "offset", "opacity", "operator", "orient", "padding", "paddingLeft", "paddingRight", "patternUnits", "r", "radius", "refX", "refY", "role", "scope", "slope", "src", "startOffset", "stdDeviation", "stroke", "stroke-linecap", "stroke-width", "style", "tableValues", "result", "rowspan", "summary", "target", "tabindex", "text-align", "text-anchor", "textAnchor", "textLength", "title", "type", "valign", "width", "x", "x1", "x2", "xlink:href", "y", "y1", "y2", "zIndex"], g.allowedReferences = ["https://", "http://", "mailto:", "/", "../", "./", "#"], g.allowedTags = ["a", "abbr", "b", "br", "button", "caption", "circle", "clipPath", "code", "dd", "defs", "div", "dl", "dt", "em", "feComponentTransfer", "feComposite", "feDropShadow", "feFlood", "feFuncA", "feFuncB", "feFuncG", "feFuncR", "feGaussianBlur", "feMorphology", "feOffset", "feMerge", "feMergeNode", "filter", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "li", "linearGradient", "marker", "ol", "p", "path", "pattern", "pre", "rect", "small", "span", "stop", "strong", "style", "sub", "sup", "svg", "table", "text", "textPath", "thead", "title", "tbody", "tspan", "td", "th", "tr", "u", "ul", "#text"], g.emptyHTML = m, g.bypassHTMLFiltering = !1, g;
  }), i(t, "Data/Modifiers/DataModifier.js", [t["Core/Utilities.js"]], function (t) {
    var i;
    let {
      addEvent: s,
      fireEvent: n,
      merge: o
    } = t;
    class r {
      benchmark(t, i) {
        let s = [],
          n = this,
          r = () => {
            n.modifyTable(t), n.emit({
              type: "afterBenchmarkIteration"
            });
          },
          {
            iterations: l
          } = o({
            iterations: 1
          }, i);
        n.on("afterBenchmarkIteration", () => {
          if (s.length === l) {
            n.emit({
              type: "afterBenchmark",
              results: s
            });
            return;
          }
          r();
        });
        let a = {
          startTime: 0,
          endTime: 0
        };
        return n.on("modify", () => {
          a.startTime = e.performance.now();
        }), n.on("afterModify", () => {
          a.endTime = e.performance.now(), s.push(a.endTime - a.startTime);
        }), r(), s;
      }
      emit(e) {
        n(this, e.type, e);
      }
      modify(e, t) {
        let i = this;
        return new Promise((s, n) => {
          e.modified === e && (e.modified = e.clone(!1, t));
          try {
            s(i.modifyTable(e, t));
          } catch (s) {
            i.emit({
              type: "error",
              detail: t,
              table: e
            }), n(s);
          }
        });
      }
      modifyCell(e, t, i, s, n) {
        return this.modifyTable(e);
      }
      modifyColumns(e, t, i, s) {
        return this.modifyTable(e);
      }
      modifyRows(e, t, i, s) {
        return this.modifyTable(e);
      }
      on(e, t) {
        return s(this, e, t);
      }
    }
    return (i = r || (r = {})).types = {}, i.registerType = function (e, t) {
      return !!e && !i.types[e] && !!(i.types[e] = t);
    }, r;
  }), i(t, "Data/DataTable.js", [t["Core/Utilities.js"]], function (e) {
    let {
      addEvent: t,
      defined: i,
      fireEvent: s,
      uniqueKey: n
    } = e;
    class o {
      static isNull(e) {
        if (e === o.NULL) return !0;
        if (e instanceof Array) {
          if (!e.length) return !1;
          for (let t = 0, i = e.length; t < i; ++t) if (null !== e[t]) return !1;
        } else {
          let t = Object.keys(e);
          if (!t.length) return !1;
          for (let i = 0, s = t.length; i < s; ++i) if (null !== e[t[i]]) return !1;
        }
        return !0;
      }
      constructor(e = {}) {
        this.autoId = !e.id, this.columns = {}, this.id = e.id || n(), this.modified = this, this.rowCount = 0, this.versionTag = n();
        let t = e.columns || {},
          i = Object.keys(t),
          s = this.columns,
          o = 0;
        for (let e = 0, n = i.length, r, l; e < n; ++e) r = t[l = i[e]].slice(), s[l] = r, o = Math.max(o, r.length);
        for (let e = 0, t = i.length; e < t; ++e) s[i[e]].length = o;
        this.rowCount = o;
      }
      clone(e, t) {
        let i = {};
        this.emit({
          type: "cloneTable",
          detail: t
        }), e || (i.columns = this.columns), this.autoId || (i.id = this.id);
        let s = new o(i);
        return e || (s.versionTag = this.versionTag, s.originalRowIndexes = this.originalRowIndexes, s.localRowIndexes = this.localRowIndexes), this.emit({
          type: "afterCloneTable",
          detail: t,
          tableClone: s
        }), s;
      }
      deleteColumns(e, t) {
        let i = this.columns,
          s = {},
          n = {},
          o = this.modifier,
          r = this.rowCount;
        if ((e = e || Object.keys(i)).length) {
          this.emit({
            type: "deleteColumns",
            columnNames: e,
            detail: t
          });
          for (let t = 0, o = e.length, l, a; t < o; ++t) (l = i[a = e[t]]) && (s[a] = l, n[a] = Array(r)), delete i[a];
          return Object.keys(i).length || (this.rowCount = 0, this.deleteRowIndexReferences()), o && o.modifyColumns(this, n, 0, t), this.emit({
            type: "afterDeleteColumns",
            columns: s,
            columnNames: e,
            detail: t
          }), s;
        }
      }
      deleteRowIndexReferences() {
        delete this.originalRowIndexes, delete this.localRowIndexes;
      }
      deleteRows(e, t = 1, i) {
        let s = [],
          n = [],
          o = this.modifier;
        if (this.emit({
          type: "deleteRows",
          detail: i,
          rowCount: t,
          rowIndex: e || 0
        }), void 0 === e && (e = 0, t = this.rowCount), t > 0 && e < this.rowCount) {
          let i = this.columns,
            o = Object.keys(i);
          for (let r = 0, l = o.length, a, h; r < l; ++r) {
            h = (a = i[o[r]]).splice(e, t), r || (this.rowCount = a.length);
            for (let e = 0, t = h.length; e < t; ++e) s[e] = s[e] || [], s[e][r] = h[e];
            n.push(Array(l));
          }
        }
        return o && o.modifyRows(this, n, e || 0, i), this.emit({
          type: "afterDeleteRows",
          detail: i,
          rowCount: t,
          rowIndex: e || 0,
          rows: s
        }), s;
      }
      emit(e) {
        switch (e.type) {
          case "afterDeleteColumns":
          case "afterDeleteRows":
          case "afterSetCell":
          case "afterSetColumns":
          case "afterSetRows":
            this.versionTag = n();
        }
        s(this, e.type, e);
      }
      getCell(e, t) {
        let i = this.columns[e];
        if (i) return i[t];
      }
      getCellAsBoolean(e, t) {
        let i = this.columns[e];
        return !!(i && i[t]);
      }
      getCellAsNumber(e, t, i) {
        let s = this.columns[e],
          n = s && s[t];
        switch (typeof n) {
          case "boolean":
            return n ? 1 : 0;
          case "number":
            return isNaN(n) && !i ? null : n;
        }
        return isNaN(n = parseFloat(`${n ?? ""}`)) && !i ? null : n;
      }
      getCellAsString(e, t) {
        let i = this.columns[e];
        return `${i && i[t]}`;
      }
      getColumn(e, t) {
        return this.getColumns([e], t)[e];
      }
      getColumnAsNumbers(e, t) {
        let i = this.columns[e],
          s = [];
        if (i) {
          let n = i.length;
          if (t) for (let t = 0; t < n; ++t) s.push(this.getCellAsNumber(e, t, !0));else {
            for (let e = 0, t; e < n; ++e) {
              if ("number" == typeof (t = i[e])) return i.slice();
              if (null != t) break;
            }
            for (let t = 0; t < n; ++t) s.push(this.getCellAsNumber(e, t));
          }
        }
        return s;
      }
      getColumnNames() {
        return Object.keys(this.columns);
      }
      getColumns(e, t) {
        let i = this.columns,
          s = {};
        e = e || Object.keys(i);
        for (let n = 0, o = e.length, r, l; n < o; ++n) (r = i[l = e[n]]) && (s[l] = t ? r : r.slice());
        return s;
      }
      getLocalRowIndex(e) {
        let {
          localRowIndexes: t
        } = this;
        return t ? t[e] : e;
      }
      getModifier() {
        return this.modifier;
      }
      getOriginalRowIndex(e) {
        let {
          originalRowIndexes: t
        } = this;
        return t ? t[e] : e;
      }
      getRow(e, t) {
        return this.getRows(e, 1, t)[0];
      }
      getRowCount() {
        return this.rowCount;
      }
      getRowIndexBy(e, t, i) {
        let s = this.columns[e];
        if (s) {
          let e = s.indexOf(t, i);
          if (-1 !== e) return e;
        }
      }
      getRowObject(e, t) {
        return this.getRowObjects(e, 1, t)[0];
      }
      getRowObjects(e = 0, t = this.rowCount - e, i) {
        let s = this.columns,
          n = Array(t);
        i = i || Object.keys(s);
        for (let o = e, r = 0, l = Math.min(this.rowCount, e + t), a, h; o < l; ++o, ++r) for (let e of (h = n[r] = {}, i)) a = s[e], h[e] = a ? a[o] : void 0;
        return n;
      }
      getRows(e = 0, t = this.rowCount - e, i) {
        let s = this.columns,
          n = Array(t);
        i = i || Object.keys(s);
        for (let o = e, r = 0, l = Math.min(this.rowCount, e + t), a, h; o < l; ++o, ++r) for (let e of (h = n[r] = [], i)) a = s[e], h.push(a ? a[o] : void 0);
        return n;
      }
      getVersionTag() {
        return this.versionTag;
      }
      hasColumns(e) {
        let t = this.columns;
        for (let i = 0, s = e.length; i < s; ++i) if (!t[e[i]]) return !1;
        return !0;
      }
      hasRowWith(e, t) {
        let i = this.columns[e];
        return !!i && -1 !== i.indexOf(t);
      }
      on(e, i) {
        return t(this, e, i);
      }
      renameColumn(e, t) {
        let i = this.columns;
        return !!i[e] && (e !== t && (i[t] = i[e], delete i[e]), !0);
      }
      setCell(e, t, i, s) {
        let n = this.columns,
          o = this.modifier,
          r = n[e];
        r && r[t] === i || (this.emit({
          type: "setCell",
          cellValue: i,
          columnName: e,
          detail: s,
          rowIndex: t
        }), r || (r = n[e] = Array(this.rowCount)), t >= this.rowCount && (this.rowCount = t + 1), r[t] = i, o && o.modifyCell(this, e, t, i), this.emit({
          type: "afterSetCell",
          cellValue: i,
          columnName: e,
          detail: s,
          rowIndex: t
        }));
      }
      setColumn(e, t = [], i = 0, s) {
        this.setColumns({
          [e]: t
        }, i, s);
      }
      setColumns(e, t, i) {
        let s = this.columns,
          n = this.modifier,
          o = void 0 === t,
          r = Object.keys(e);
        this.emit({
          type: "setColumns",
          columns: e,
          columnNames: r,
          detail: i,
          rowIndex: t
        });
        for (let i = 0, n = r.length, l, a; i < n; ++i) if (l = e[a = r[i]], o) s[a] = l.slice(), this.rowCount = l.length;else {
          let e = s[a] ? s[a] : s[a] = Array(this.rowCount);
          for (let i = t || 0, s = l.length; i < s; ++i) e[i] = l[i];
          this.rowCount = Math.max(this.rowCount, e.length);
        }
        let l = Object.keys(s);
        for (let e = 0, t = l.length; e < t; ++e) s[l[e]].length = this.rowCount;
        n && n.modifyColumns(this, e, t || 0), this.emit({
          type: "afterSetColumns",
          columns: e,
          columnNames: r,
          detail: i,
          rowIndex: t
        });
      }
      setModifier(e, t) {
        let i = this;
        return i.emit({
          type: "setModifier",
          detail: t,
          modifier: e,
          modified: i.modified
        }), i.modified = i, i.modifier = e, (e ? e.modify(i) : Promise.resolve(i)).then(i => (i.emit({
          type: "afterSetModifier",
          detail: t,
          modifier: e,
          modified: i.modified
        }), i)).catch(t => {
          throw i.emit({
            type: "setModifierError",
            error: t,
            modifier: e,
            modified: i.modified
          }), t;
        });
      }
      setOriginalRowIndexes(e, t = !1) {
        if (this.originalRowIndexes = e, t) return;
        let s = this.localRowIndexes = [];
        for (let t = 0, n = e.length, o; t < n; ++t) i(o = e[t]) && (s[o] = t);
      }
      setRow(e, t, i) {
        this.setRows([e], t, i);
      }
      setRows(e, t = this.rowCount, i) {
        let s = this.columns,
          n = Object.keys(s),
          r = this.modifier,
          l = e.length;
        this.emit({
          type: "setRows",
          detail: i,
          rowCount: l,
          rowIndex: t,
          rows: e
        });
        for (let i = 0, r = t, a; i < l; ++i, ++r) if ((a = e[i]) === o.NULL) for (let e = 0, t = n.length; e < t; ++e) s[n[e]][r] = null;else if (a instanceof Array) for (let e = 0, t = n.length; e < t; ++e) s[n[e]][r] = a[e];else {
          let e = Object.keys(a);
          for (let t = 0, i = e.length, n; t < i; ++t) s[n = e[t]] || (s[n] = Array(r + 1)), s[n][r] = a[n];
        }
        let a = t + l;
        if (a > this.rowCount) {
          this.rowCount = a;
          for (let e = 0, t = n.length; e < t; ++e) s[n[e]].length = a;
        }
        r && r.modifyRows(this, e, t), this.emit({
          type: "afterSetRows",
          detail: i,
          rowCount: l,
          rowIndex: t,
          rows: e
        });
      }
    }
    return o.NULL = {}, o.version = "1.0.0", o;
  }), i(t, "Data/Connectors/DataConnector.js", [t["Data/Modifiers/DataModifier.js"], t["Data/DataTable.js"], t["Core/Utilities.js"]], function (t, i, s) {
    var n;
    let {
      addEvent: o,
      fireEvent: r,
      merge: l,
      pick: a
    } = s;
    class h {
      constructor(e = {}) {
        this.table = new i(e.dataTable), this.metadata = e.metadata || {
          columns: {}
        };
      }
      get polling() {
        return !!this.polling;
      }
      describeColumn(e, t) {
        let i = this.metadata.columns;
        i[e] = l(i[e] || {}, t);
      }
      describeColumns(e) {
        let t;
        let i = Object.keys(e);
        for (; "string" == typeof (t = i.pop());) this.describeColumn(t, e[t]);
      }
      emit(e) {
        r(this, e.type, e);
      }
      getColumnOrder(e) {
        let t = this.metadata.columns,
          i = Object.keys(t || {});
        if (i.length) return i.sort((e, i) => a(t[e].index, 0) - a(t[i].index, 0));
      }
      getSortedColumns(e) {
        return this.table.getColumns(this.getColumnOrder(e));
      }
      load() {
        return r(this, "afterLoad", {
          table: this.table
        }), Promise.resolve(this);
      }
      on(e, t) {
        return o(this, e, t);
      }
      save() {
        return r(this, "saveError", {
          table: this.table
        }), Promise.reject(Error("Not implemented"));
      }
      setColumnOrder(e) {
        for (let t = 0, i = e.length; t < i; ++t) this.describeColumn(e[t], {
          index: t
        });
      }
      setModifierOptions(e) {
        let i = e && t.types[e.type];
        return this.table.setModifier(i ? new i(e) : void 0).then(() => this);
      }
      startPolling(t = 1e3) {
        let i = this;
        e.clearTimeout(i._polling), i._polling = e.setTimeout(() => i.load().catch(e => i.emit({
          type: "loadError",
          error: e,
          table: i.table
        })).then(() => {
          i._polling && i.startPolling(t);
        }), t);
      }
      stopPolling() {
        e.clearTimeout(this._polling), delete this._polling;
      }
      whatIs(e) {
        return this.metadata.columns[e];
      }
    }
    return (n = h || (h = {})).types = {}, n.registerType = function (e, t) {
      return !!e && !n.types[e] && !!(n.types[e] = t);
    }, h;
  }), i(t, "Data/Converters/DataConverter.js", [t["Data/DataTable.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
      addEvent: i,
      fireEvent: s,
      isNumber: n,
      merge: o
    } = t;
    class r {
      constructor(e) {
        this.dateFormats = {
          "YYYY/mm/dd": {
            regex: /^(\d{4})([\-\.\/])(\d{1,2})\2(\d{1,2})$/,
            parser: function (e) {
              return e ? Date.UTC(+e[1], e[3] - 1, +e[4]) : NaN;
            }
          },
          "dd/mm/YYYY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{4})$/,
            parser: function (e) {
              return e ? Date.UTC(+e[4], e[3] - 1, +e[1]) : NaN;
            },
            alternative: "mm/dd/YYYY"
          },
          "mm/dd/YYYY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{4})$/,
            parser: function (e) {
              return e ? Date.UTC(+e[4], e[1] - 1, +e[3]) : NaN;
            }
          },
          "dd/mm/YY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{2})$/,
            parser: function (e) {
              let t = new Date();
              if (!e) return NaN;
              let i = +e[4];
              return i > t.getFullYear() - 2e3 ? i += 1900 : i += 2e3, Date.UTC(i, e[3] - 1, +e[1]);
            },
            alternative: "mm/dd/YY"
          },
          "mm/dd/YY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{2})$/,
            parser: function (e) {
              return e ? Date.UTC(+e[4] + 2e3, e[1] - 1, +e[3]) : NaN;
            }
          }
        };
        let t = o(r.defaultOptions, e),
          i = t.decimalPoint;
        ("." === i || "," === i) && (i = "." === i ? "\\." : ",", this.decimalRegExp = RegExp("^(-?[0-9]+)" + i + "([0-9]+)$")), this.options = t;
      }
      asBoolean(e) {
        return "boolean" == typeof e ? e : "string" == typeof e ? "" !== e && "0" !== e && "false" !== e : !!this.asNumber(e);
      }
      asDate(e) {
        let t;
        if ("string" == typeof e) t = this.parseDate(e);else if ("number" == typeof e) t = e;else {
          if (e instanceof Date) return e;
          t = this.parseDate(this.asString(e));
        }
        return new Date(t);
      }
      asGuessedType(e) {
        return {
          number: this.asNumber,
          Date: this.asDate,
          string: this.asString
        }[this.guessType(e)].call(this, e);
      }
      asNumber(e) {
        if ("number" == typeof e) return e;
        if ("boolean" == typeof e) return e ? 1 : 0;
        if ("string" == typeof e) {
          let t = this.decimalRegExp;
          if (e.indexOf(" ") > -1 && (e = e.replace(/\s+/g, "")), t) {
            if (!t.test(e)) return NaN;
            e = e.replace(t, "$1.$2");
          }
          return parseFloat(e);
        }
        return e instanceof Date ? e.getDate() : e ? e.getRowCount() : NaN;
      }
      asString(e) {
        return "" + e;
      }
      deduceDateFormat(e, t, i) {
        let s = [],
          n = [],
          o = "YYYY/mm/dd",
          r,
          l = [],
          a = 0,
          h = !1,
          d,
          u;
        for ((!t || t > e.length) && (t = e.length); a < t; a++) if (void 0 !== e[a] && e[a] && e[a].length) for (u = 0, r = e[a].trim().replace(/[\-\.\/]/g, " ").split(" "), l = ["", "", ""]; u < r.length; u++) u < l.length && (d = parseInt(r[u], 10)) && (n[u] = !n[u] || n[u] < d ? d : n[u], void 0 !== s[u] ? s[u] !== d && (s[u] = !1) : s[u] = d, d > 31 ? d < 100 ? l[u] = "YY" : l[u] = "YYYY" : d > 12 && d <= 31 ? (l[u] = "dd", h = !0) : l[u].length || (l[u] = "mm"));
        if (h) {
          for (u = 0; u < s.length; u++) !1 !== s[u] ? n[u] > 12 && "YY" !== l[u] && "YYYY" !== l[u] && (l[u] = "YY") : n[u] > 12 && "mm" === l[u] && (l[u] = "dd");
          3 === l.length && "dd" === l[1] && "dd" === l[2] && (l[2] = "YY"), o = l.join("/");
        }
        return i && (this.options.dateFormat = o), o;
      }
      emit(e) {
        s(this, e.type, e);
      }
      export(e, t) {
        throw this.emit({
          type: "exportError",
          columns: [],
          headers: []
        }), Error("Not implemented");
      }
      getTable() {
        throw Error("Not implemented");
      }
      guessType(e) {
        let t = "string";
        if ("string" == typeof e) {
          let i = this.trim(`${e}`),
            s = this.decimalRegExp,
            o = this.trim(i, !0);
          s && (o = s.test(o) ? o.replace(s, "$1.$2") : "");
          let r = parseFloat(o);
          +o === r ? e = r : t = n(this.parseDate(e)) ? "Date" : "string";
        }
        return "number" == typeof e && (t = e > 31536e6 ? "Date" : "number"), t;
      }
      on(e, t) {
        return i(this, e, t);
      }
      parse(e) {
        throw this.emit({
          type: "parseError",
          columns: [],
          headers: []
        }), Error("Not implemented");
      }
      parseDate(e, t) {
        let i = this.options,
          s = t || i.dateFormat,
          o = NaN,
          r,
          l,
          a;
        if (i.parseDate) o = i.parseDate(e);else {
          if (s) (l = this.dateFormats[s]) || (l = this.dateFormats["YYYY/mm/dd"]), (a = e.match(l.regex)) && (o = l.parser(a));else for (r in this.dateFormats) if (l = this.dateFormats[r], a = e.match(l.regex)) {
            s = r, o = l.parser(a);
            break;
          }
          !a && ("object" == typeof (a = Date.parse(e)) && null !== a && a.getTime ? o = a.getTime() - 6e4 * a.getTimezoneOffset() : n(a) && (o = a - 6e4 * new Date(a).getTimezoneOffset(), -1 === e.indexOf("2001") && 2001 === new Date(o).getFullYear() && (o = NaN)));
        }
        return o;
      }
      trim(e, t) {
        return "string" == typeof e && (e = e.replace(/^\s+|\s+$/g, ""), t && /^[\d\s]+$/.test(e) && (e = e.replace(/\s/g, ""))), e;
      }
    }
    return r.defaultOptions = {
      dateFormat: "",
      alternativeFormat: "",
      startColumn: 0,
      endColumn: Number.MAX_VALUE,
      startRow: 0,
      endRow: Number.MAX_VALUE,
      firstRowAsNames: !0,
      switchRowsAndColumns: !1
    }, (r || (r = {})).getTableFromColumns = function (t = [], i = []) {
      let s = new e();
      for (let e = 0, n = Math.max(i.length, t.length); e < n; ++e) s.setColumn(i[e] || `${e}`, t[e]);
      return s;
    }, r;
  }), i(t, "Data/DataCursor.js", [], function () {
    class e {
      constructor(e = {}) {
        this.emittingRegister = [], this.listenerMap = {}, this.stateMap = e;
      }
      addListener(e, t, i) {
        let s = this.listenerMap[e] = this.listenerMap[e] || {};
        return (s[t] = s[t] || []).push(i), this;
      }
      buildEmittingTag(e) {
        return ("position" === e.cursor.type ? [e.table.id, e.cursor.column, e.cursor.row, e.cursor.state, e.cursor.type] : [e.table.id, e.cursor.columns, e.cursor.firstRow, e.cursor.lastRow, e.cursor.state, e.cursor.type]).join("\0");
      }
      emitCursor(t, i, s, n) {
        let o = t.id,
          r = i.state,
          l = this.listenerMap[o] && this.listenerMap[o][r];
        if (l) {
          let r = this.stateMap[o] = this.stateMap[o] ?? {},
            a = r[i.state] || [];
          n && (a.length || (r[i.state] = a), -1 === e.getIndex(i, a) && a.push(i));
          let h = {
            cursor: i,
            cursors: a,
            table: t
          };
          s && (h.event = s);
          let d = this.emittingRegister,
            u = this.buildEmittingTag(h);
          if (d.indexOf(u) >= 0) return this;
          try {
            this.emittingRegister.push(u);
            for (let e = 0, t = l.length; e < t; ++e) l[e].call(this, h);
          } finally {
            let e = this.emittingRegister.indexOf(u);
            e >= 0 && this.emittingRegister.splice(e, 1);
          }
        }
        return this;
      }
      remitCursor(t, i) {
        let s = this.stateMap[t] && this.stateMap[t][i.state];
        if (s) {
          let t = e.getIndex(i, s);
          t >= 0 && s.splice(t, 1);
        }
        return this;
      }
      removeListener(e, t, i) {
        let s = this.listenerMap[e] && this.listenerMap[e][t];
        if (s) {
          let e = s.indexOf(i);
          e >= 0 && s.splice(e, 1);
        }
        return this;
      }
    }
    return e.version = "1.0.0", function (e) {
      function t(e, t) {
        if ("range" === e.type) return e;
        let i = {
          type: "range",
          firstRow: e.row ?? (t && t.firstRow) ?? 0,
          lastRow: e.row ?? (t && t.lastRow) ?? Number.MAX_VALUE,
          state: e.state
        };
        return void 0 !== e.column && (i.columns = [e.column]), i;
      }
      e.getIndex = function (e, t) {
        if ("position" === e.type) {
          for (let i, s = 0, n = t.length; s < n; ++s) if ("position" === (i = t[s]).type && i.state === e.state && i.column === e.column && i.row === e.row) return s;
        } else {
          let i = JSON.stringify(e.columns);
          for (let s, n = 0, o = t.length; n < o; ++n) if ("range" === (s = t[n]).type && s.state === e.state && s.firstRow === e.firstRow && s.lastRow === e.lastRow && JSON.stringify(s.columns) === i) return n;
        }
        return -1;
      }, e.isEqual = function (e, t) {
        return "position" === e.type && "position" === t.type ? e.column === t.column && e.row === t.row && e.state === t.state : "range" === e.type && "range" === t.type && e.firstRow === t.firstRow && e.lastRow === t.lastRow && JSON.stringify(e.columns) === JSON.stringify(t.columns);
      }, e.isInRange = function (e, i) {
        "position" === i.type && (i = t(i)), "position" === e.type && (e = t(e, i));
        let s = e.columns,
          n = i.columns;
        return e.firstRow >= i.firstRow && e.lastRow <= i.lastRow && (!s || !n || s.every(e => n.indexOf(e) >= 0));
      }, e.toPositions = function (e) {
        if ("position" === e.type) return [e];
        let t = e.columns || [],
          i = [],
          s = e.state;
        for (let n = e.firstRow, o = e.lastRow; n < o; ++n) {
          if (!t.length) {
            i.push({
              type: "position",
              row: n,
              state: s
            });
            continue;
          }
          for (let e = 0, o = t.length; e < o; ++e) i.push({
            type: "position",
            column: t[e],
            row: n,
            state: s
          });
        }
        return i;
      }, e.toRange = t;
    }(e || (e = {})), e;
  }), i(t, "DataGrid/DataGridUtils.js", [], function () {
    return {
      dataTableCellToString: e => "string" == typeof e || "number" == typeof e || "boolean" == typeof e ? "" + e : "",
      emptyHTMLElement(e) {
        for (; e.firstChild;) e.removeChild(e.firstChild);
      },
      makeDiv: (e, t) => {
        let i = document.createElement("div");
        return i.className = e, t && (i.id = t), i;
      }
    };
  }), i(t, "DataGrid/Globals.js", [], function () {
    var t, i;
    return (i = t || (t = {})).classNamePrefix = "highcharts-datagrid-", i.classNames = {
      gridContainer: i.classNamePrefix + "container",
      outerContainer: i.classNamePrefix + "outer-container",
      scrollContainer: i.classNamePrefix + "scroll-container",
      innerContainer: i.classNamePrefix + "inner-container",
      cell: i.classNamePrefix + "cell",
      cellInput: i.classNamePrefix + "cell-input",
      row: i.classNamePrefix + "row",
      columnHeader: i.classNamePrefix + "column-header"
    }, i.win = e, i.userAgent = i.win.navigator && i.win.navigator.userAgent || "", i.isChrome = i.win.chrome, i.isSafari = !i.isChrome && -1 !== i.userAgent.indexOf("Safari"), t;
  }), i(t, "Core/Chart/ChartDefaults.js", [], function () {
    return {
      alignThresholds: !1,
      panning: {
        enabled: !1,
        type: "x"
      },
      styledMode: !1,
      borderRadius: 0,
      colorCount: 10,
      allowMutatingData: !0,
      ignoreHiddenSeries: !0,
      spacing: [10, 10, 15, 10],
      resetZoomButton: {
        theme: {},
        position: {}
      },
      reflow: !0,
      type: "line",
      zooming: {
        singleTouch: !1,
        resetButton: {
          theme: {
            zIndex: 6
          },
          position: {
            align: "right",
            x: -10,
            y: 10
          }
        }
      },
      width: null,
      height: null,
      borderColor: "#334eff",
      backgroundColor: "#ffffff",
      plotBorderColor: "#cccccc"
    };
  }), i(t, "Core/Color/Palettes.js", [], function () {
    return {
      colors: ["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"]
    };
  }), i(t, "Core/Time.js", [t["Core/Globals.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        win: i
      } = e,
      {
        defined: s,
        error: n,
        extend: o,
        isNumber: r,
        isObject: l,
        merge: a,
        objectEach: h,
        pad: d,
        pick: u,
        splat: c,
        timeUnits: f
      } = t,
      m = e.isSafari && i.Intl && i.Intl.DateTimeFormat.prototype.formatRange,
      p = e.isSafari && i.Intl && !i.Intl.DateTimeFormat.prototype.formatRange;
    class g {
      constructor(e) {
        this.options = {}, this.useUTC = !1, this.variableTimezone = !1, this.Date = i.Date, this.getTimezoneOffset = this.timezoneOffsetFunction(), this.update(e);
      }
      get(e, t) {
        if (this.variableTimezone || this.timezoneOffset) {
          let i = t.getTime(),
            s = i - this.getTimezoneOffset(t);
          t.setTime(s);
          let n = t["getUTC" + e]();
          return t.setTime(i), n;
        }
        return this.useUTC ? t["getUTC" + e]() : t["get" + e]();
      }
      set(e, t, i) {
        if (this.variableTimezone || this.timezoneOffset) {
          if ("Milliseconds" === e || "Seconds" === e || "Minutes" === e && this.getTimezoneOffset(t) % 36e5 == 0) return t["setUTC" + e](i);
          let s = this.getTimezoneOffset(t),
            n = t.getTime() - s;
          t.setTime(n), t["setUTC" + e](i);
          let o = this.getTimezoneOffset(t);
          return n = t.getTime() + o, t.setTime(n);
        }
        return this.useUTC || m && "FullYear" === e ? t["setUTC" + e](i) : t["set" + e](i);
      }
      update(e = {}) {
        let t = u(e.useUTC, !0);
        this.options = e = a(!0, this.options, e), this.Date = e.Date || i.Date || Date, this.useUTC = t, this.timezoneOffset = t && e.timezoneOffset || void 0, this.getTimezoneOffset = this.timezoneOffsetFunction(), this.variableTimezone = t && !!(e.getTimezoneOffset || e.timezone);
      }
      makeTime(e, t, i, s, n, o) {
        let r, l, a;
        return this.useUTC ? (r = this.Date.UTC.apply(0, arguments), l = this.getTimezoneOffset(r), r += l, l !== (a = this.getTimezoneOffset(r)) ? r += a - l : l - 36e5 !== this.getTimezoneOffset(r - 36e5) || p || (r -= 36e5)) : r = new this.Date(e, t, u(i, 1), u(s, 0), u(n, 0), u(o, 0)).getTime(), r;
      }
      timezoneOffsetFunction() {
        let e = this,
          t = this.options,
          i = t.getTimezoneOffset;
        return this.useUTC ? t.timezone ? e => {
          try {
            let i = `shortOffset,${t.timezone || ""}`,
              [s, n, o, l, a = 0] = (g.formatCache[i] = g.formatCache[i] || Intl.DateTimeFormat("en", {
                timeZone: t.timezone,
                timeZoneName: "shortOffset"
              })).format(e).split(/(GMT|:)/).map(Number),
              h = -(36e5 * (o + a / 60));
            if (r(h)) return h;
          } catch (e) {
            n(34);
          }
          return 0;
        } : this.useUTC && i ? e => 6e4 * i(e.valueOf()) : () => 6e4 * (e.timezoneOffset || 0) : e => 6e4 * new Date(e.toString()).getTimezoneOffset();
      }
      dateFormat(t, i, n) {
        if (!s(i) || isNaN(i)) return e.defaultOptions.lang && e.defaultOptions.lang.invalidDate || "";
        t = u(t, "%Y-%m-%d %H:%M:%S");
        let r = this,
          l = new this.Date(i),
          a = this.get("Hours", l),
          c = this.get("Day", l),
          f = this.get("Date", l),
          m = this.get("Month", l),
          p = this.get("FullYear", l),
          g = e.defaultOptions.lang,
          C = g && g.weekdays,
          y = g && g.shortWeekdays;
        return h(o({
          a: y ? y[c] : C[c].substr(0, 3),
          A: C[c],
          d: d(f),
          e: d(f, 2, " "),
          w: c,
          b: g.shortMonths[m],
          B: g.months[m],
          m: d(m + 1),
          o: m + 1,
          y: p.toString().substr(2, 2),
          Y: p,
          H: d(a),
          k: a,
          I: d(a % 12 || 12),
          l: a % 12 || 12,
          M: d(this.get("Minutes", l)),
          p: a < 12 ? "AM" : "PM",
          P: a < 12 ? "am" : "pm",
          S: d(this.get("Seconds", l)),
          L: d(Math.floor(i % 1e3), 3)
        }, e.dateFormats), function (e, s) {
          for (; -1 !== t.indexOf("%" + s);) t = t.replace("%" + s, "function" == typeof e ? e.call(r, i) : e);
        }), n ? t.substr(0, 1).toUpperCase() + t.substr(1) : t;
      }
      resolveDTLFormat(e) {
        return l(e, !0) ? e : {
          main: (e = c(e))[0],
          from: e[1],
          to: e[2]
        };
      }
      getTimeTicks(e, t, i, n) {
        let r, l, a, h;
        let d = this,
          c = d.Date,
          m = [],
          p = {},
          g = new c(t),
          C = e.unitRange,
          y = e.count || 1;
        if (n = u(n, 1), s(t)) {
          d.set("Milliseconds", g, C >= f.second ? 0 : y * Math.floor(d.get("Milliseconds", g) / y)), C >= f.second && d.set("Seconds", g, C >= f.minute ? 0 : y * Math.floor(d.get("Seconds", g) / y)), C >= f.minute && d.set("Minutes", g, C >= f.hour ? 0 : y * Math.floor(d.get("Minutes", g) / y)), C >= f.hour && d.set("Hours", g, C >= f.day ? 0 : y * Math.floor(d.get("Hours", g) / y)), C >= f.day && d.set("Date", g, C >= f.month ? 1 : Math.max(1, y * Math.floor(d.get("Date", g) / y))), C >= f.month && (d.set("Month", g, C >= f.year ? 0 : y * Math.floor(d.get("Month", g) / y)), l = d.get("FullYear", g)), C >= f.year && (l -= l % y, d.set("FullYear", g, l)), C === f.week && (h = d.get("Day", g), d.set("Date", g, d.get("Date", g) - h + n + (h < n ? -7 : 0))), l = d.get("FullYear", g);
          let e = d.get("Month", g),
            o = d.get("Date", g),
            u = d.get("Hours", g);
          t = g.getTime(), (d.variableTimezone || !d.useUTC) && s(i) && (a = i - t > 4 * f.month || d.getTimezoneOffset(t) !== d.getTimezoneOffset(i));
          let c = g.getTime();
          for (r = 1; c < i;) m.push(c), C === f.year ? c = d.makeTime(l + r * y, 0) : C === f.month ? c = d.makeTime(l, e + r * y) : a && (C === f.day || C === f.week) ? c = d.makeTime(l, e, o + r * y * (C === f.day ? 1 : 7)) : a && C === f.hour && y > 1 ? c = d.makeTime(l, e, o, u + r * y) : c += C * y, r++;
          m.push(c), C <= f.hour && m.length < 1e4 && m.forEach(function (e) {
            e % 18e5 == 0 && "000000000" === d.dateFormat("%H%M%S%L", e) && (p[e] = "day");
          });
        }
        return m.info = o(e, {
          higherRanks: p,
          totalRange: C * y
        }), m;
      }
      getDateFormat(e, t, i, s) {
        let n = this.dateFormat("%m-%d %H:%M:%S.%L", t),
          o = "01-01 00:00:00.000",
          r = {
            millisecond: 15,
            second: 12,
            minute: 9,
            hour: 6,
            day: 3
          },
          l = "millisecond",
          a = l;
        for (l in f) {
          if (e === f.week && +this.dateFormat("%w", t) === i && n.substr(6) === o.substr(6)) {
            l = "week";
            break;
          }
          if (f[l] > e) {
            l = a;
            break;
          }
          if (r[l] && n.substr(r[l]) !== o.substr(r[l])) break;
          "week" !== l && (a = l);
        }
        return this.resolveDTLFormat(s[l]).main;
      }
    }
    return g.formatCache = {}, g;
  }), i(t, "Core/Defaults.js", [t["Core/Chart/ChartDefaults.js"], t["Core/Globals.js"], t["Core/Color/Palettes.js"], t["Core/Time.js"], t["Core/Utilities.js"]], function (e, t, i, s, n) {
    let {
        isTouchDevice: o
      } = t,
      {
        fireEvent: r,
        merge: l
      } = n,
      a = {
        colors: i.colors,
        symbols: ["circle", "diamond", "square", "triangle", "triangle-down"],
        lang: {
          loading: "Loading...",
          months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          decimalPoint: ".",
          numericSymbols: ["k", "M", "G", "T", "P", "E"],
          resetZoom: "Reset zoom",
          resetZoomTitle: "Reset zoom level 1:1",
          thousandsSep: " "
        },
        global: {
          buttonTheme: {
            fill: "#f7f7f7",
            padding: 8,
            r: 2,
            stroke: "#cccccc",
            "stroke-width": 1,
            style: {
              color: "#333333",
              cursor: "pointer",
              fontSize: "0.8em",
              fontWeight: "normal"
            },
            states: {
              hover: {
                fill: "#e6e6e6"
              },
              select: {
                fill: "#e6e9ff",
                style: {
                  color: "#000000",
                  fontWeight: "bold"
                }
              },
              disabled: {
                style: {
                  color: "#cccccc"
                }
              }
            }
          }
        },
        time: {
          Date: void 0,
          getTimezoneOffset: void 0,
          timezone: void 0,
          timezoneOffset: 0,
          useUTC: !0
        },
        chart: e,
        title: {
          style: {
            color: "#333333",
            fontWeight: "bold"
          },
          text: "Chart title",
          align: "center",
          margin: 15,
          widthAdjust: -44
        },
        subtitle: {
          style: {
            color: "#666666",
            fontSize: "0.8em"
          },
          text: "",
          align: "center",
          widthAdjust: -44
        },
        caption: {
          margin: 15,
          style: {
            color: "#666666",
            fontSize: "0.8em"
          },
          text: "",
          align: "left",
          verticalAlign: "bottom"
        },
        plotOptions: {},
        legend: {
          enabled: !0,
          align: "center",
          alignColumns: !0,
          className: "highcharts-no-tooltip",
          events: {},
          layout: "horizontal",
          itemMarginBottom: 2,
          itemMarginTop: 2,
          labelFormatter: function () {
            return this.name;
          },
          borderColor: "#999999",
          borderRadius: 0,
          navigation: {
            style: {
              fontSize: "0.8em"
            },
            activeColor: "#0022ff",
            inactiveColor: "#cccccc"
          },
          itemStyle: {
            color: "#333333",
            cursor: "pointer",
            fontSize: "0.8em",
            textDecoration: "none",
            textOverflow: "ellipsis"
          },
          itemHoverStyle: {
            color: "#000000"
          },
          itemHiddenStyle: {
            color: "#666666",
            textDecoration: "line-through"
          },
          shadow: !1,
          itemCheckboxStyle: {
            position: "absolute",
            width: "13px",
            height: "13px"
          },
          squareSymbol: !0,
          symbolPadding: 5,
          verticalAlign: "bottom",
          x: 0,
          y: 0,
          title: {
            style: {
              fontSize: "0.8em",
              fontWeight: "bold"
            }
          }
        },
        loading: {
          labelStyle: {
            fontWeight: "bold",
            position: "relative",
            top: "45%"
          },
          style: {
            position: "absolute",
            backgroundColor: "#ffffff",
            opacity: .5,
            textAlign: "center"
          }
        },
        tooltip: {
          enabled: !0,
          animation: {
            duration: 300,
            easing: e => Math.sqrt(1 - Math.pow(e - 1, 2))
          },
          borderRadius: 3,
          dateTimeLabelFormats: {
            millisecond: "%A, %e %b, %H:%M:%S.%L",
            second: "%A, %e %b, %H:%M:%S",
            minute: "%A, %e %b, %H:%M",
            hour: "%A, %e %b, %H:%M",
            day: "%A, %e %b %Y",
            week: "Week from %A, %e %b %Y",
            month: "%B %Y",
            year: "%Y"
          },
          footerFormat: "",
          headerShape: "callout",
          hideDelay: 500,
          padding: 8,
          shape: "callout",
          shared: !1,
          snap: o ? 25 : 10,
          headerFormat: '<span style="font-size: 0.8em">{point.key}</span><br/>',
          pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
          backgroundColor: "#ffffff",
          borderWidth: void 0,
          shadow: !0,
          stickOnContact: !1,
          style: {
            color: "#333333",
            cursor: "default",
            fontSize: "0.8em"
          },
          useHTML: !1
        },
        credits: {
          enabled: !0,
          href: "https://www.highcharts.com?credits",
          position: {
            align: "right",
            x: -10,
            verticalAlign: "bottom",
            y: -5
          },
          style: {
            cursor: "pointer",
            color: "#999999",
            fontSize: "0.6em"
          },
          text: "Highcharts.com"
        }
      };
    a.chart.styledMode = !1;
    let h = new s(a.time);
    return {
      defaultOptions: a,
      defaultTime: h,
      getOptions: function () {
        return a;
      },
      setOptions: function (e) {
        return r(t, "setOptions", {
          options: e
        }), l(!0, a, e), (e.time || e.global) && (t.time ? t.time.update(l(a.global, a.time, e.global, e.time)) : t.time = h), a;
      }
    };
  }), i(t, "Core/Templating.js", [t["Core/Defaults.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        defaultOptions: i,
        defaultTime: s
      } = e,
      {
        extend: n,
        getNestedProperty: o,
        isArray: r,
        isNumber: l,
        isObject: a,
        pick: h,
        pInt: d
      } = t,
      u = {
        add: (e, t) => e + t,
        divide: (e, t) => 0 !== t ? e / t : "",
        eq: (e, t) => e == t,
        each: function (e) {
          let t = arguments[arguments.length - 1];
          return !!r(e) && e.map((i, s) => c(t.body, n(a(i) ? i : {
            "@this": i
          }, {
            "@index": s,
            "@first": 0 === s,
            "@last": s === e.length - 1
          }))).join("");
        },
        ge: (e, t) => e >= t,
        gt: (e, t) => e > t,
        if: e => !!e,
        le: (e, t) => e <= t,
        lt: (e, t) => e < t,
        multiply: (e, t) => e * t,
        ne: (e, t) => e != t,
        subtract: (e, t) => e - t,
        unless: e => !e
      };
    function c(e = "", t, n) {
      let r = /\{([\w\:\.\,;\-\/<>%@"'’= #\(\)]+)\}/g,
        l = /\(([\w\:\.\,;\-\/<>%@"'= ]+)\)/g,
        a = [],
        d = /f$/,
        m = /\.(\d)/,
        p = i.lang,
        g = n && n.time || s,
        C = n && n.numberFormatter || f,
        y = (e = "") => {
          let i;
          return "true" === e || "false" !== e && ((i = Number(e)).toString() === e ? i : o(e, t));
        },
        b,
        w,
        v = 0,
        T;
      for (; null !== (b = r.exec(e));) {
        let i = l.exec(b[1]);
        i && (b = i, T = !0), w && w.isBlock || (w = {
          ctx: t,
          expression: b[1],
          find: b[0],
          isBlock: "#" === b[1].charAt(0),
          start: b.index,
          startInner: b.index + b[0].length,
          length: b[0].length
        });
        let s = b[1].split(" ")[0].replace("#", "");
        u[s] && (w.isBlock && s === w.fn && v++, w.fn || (w.fn = s));
        let n = "else" === b[1];
        if (w.isBlock && w.fn && (b[1] === `/${w.fn}` || n)) {
          if (v) !n && v--;else {
            let t = w.startInner,
              i = e.substr(t, b.index - t);
            void 0 === w.body ? (w.body = i, w.startInner = b.index + b[0].length) : w.elseBody = i, w.find += i + b[0], n || (a.push(w), w = void 0);
          }
        } else w.isBlock || a.push(w);
        if (i && !w?.isBlock) break;
      }
      return a.forEach(i => {
        let s, o;
        let {
          body: r,
          elseBody: l,
          expression: a,
          fn: f
        } = i;
        if (f) {
          let e = [i],
            h = a.split(" ");
          for (o = u[f].length; o--;) e.unshift(y(h[o + 1]));
          s = u[f].apply(t, e), i.isBlock && "boolean" == typeof s && (s = c(s ? r : l, t, n));
        } else {
          let e = a.split(":");
          if (s = y(e.shift() || ""), e.length && "number" == typeof s) {
            let t = e.join(":");
            if (d.test(t)) {
              let e = parseInt((t.match(m) || ["", "-1"])[1], 10);
              null !== s && (s = C(s, e, p.decimalPoint, t.indexOf(",") > -1 ? p.thousandsSep : ""));
            } else s = g.dateFormat(t, s);
          }
        }
        e = e.replace(i.find, h(s, ""));
      }), T ? c(e, t, n) : e;
    }
    function f(e, t, s, n) {
      let o, r;
      e = +e || 0, t = +t;
      let a = i.lang,
        u = (e.toString().split(".")[1] || "").split("e")[0].length,
        c = e.toString().split("e"),
        f = t;
      -1 === t ? t = Math.min(u, 20) : l(t) ? t && c[1] && c[1] < 0 && ((r = t + +c[1]) >= 0 ? (c[0] = (+c[0]).toExponential(r).split("e")[0], t = r) : (c[0] = c[0].split(".")[0] || 0, e = t < 20 ? (c[0] * Math.pow(10, c[1])).toFixed(t) : 0, c[1] = 0)) : t = 2;
      let m = (Math.abs(c[1] ? c[0] : e) + Math.pow(10, -Math.max(t, u) - 1)).toFixed(t),
        p = String(d(m)),
        g = p.length > 3 ? p.length % 3 : 0;
      return s = h(s, a.decimalPoint), n = h(n, a.thousandsSep), o = (e < 0 ? "-" : "") + (g ? p.substr(0, g) + n : ""), 0 > +c[1] && !f ? o = "0" : o += p.substr(g).replace(/(\d{3})(?=\d)/g, "$1" + n), t ? o += s + m.slice(-t) : 0 == +o && (o = "0"), c[1] && 0 != +o && (o += "e" + c[1]), o;
    }
    return {
      dateFormat: function (e, t, i) {
        return s.dateFormat(e, t, i);
      },
      format: c,
      helpers: u,
      numberFormat: f
    };
  }), i(t, "DataGrid/DataGridDefaults.js", [], function () {
    return {
      cellHeight: 49,
      columnHeaders: {
        enabled: !0
      },
      columns: {},
      defaultHeight: 400,
      editable: !0,
      resizableColumns: !0
    };
  }), i(t, "DataGrid/DataGrid.js", [t["Core/Renderer/HTML/AST.js"], t["Data/DataTable.js"], t["DataGrid/DataGridUtils.js"], t["DataGrid/Globals.js"], t["Core/Templating.js"], t["DataGrid/DataGridDefaults.js"], t["Core/Utilities.js"]], function (t, i, s, n, o, r, l) {
    let {
        emptyHTMLElement: a,
        makeDiv: h
      } = s,
      {
        isSafari: d,
        win: u
      } = n,
      {
        addEvent: c,
        clamp: f,
        defined: m,
        fireEvent: p,
        isNumber: g,
        merge: C,
        pick: y
      } = l;
    class b {
      static dataGrid(e, t) {
        return new b(e, t);
      }
      constructor(e, t) {
        if (this.columnNames = [], this.prevTop = -1, this.scrollEndRowCount = 0, this.scrollEndTop = 0, this.bottom = !1, this.overflowHeaderWidths = [], "string" == typeof e) {
          let t = u.document.getElementById(e);
          t ? this.container = t : this.container = h(n.classNames.gridContainer, e);
        } else this.container = e;
        this.gridContainer = h(n.classNames.gridContainer), this.outerContainer = h(n.classNames.outerContainer), this.scrollContainer = h(n.classNames.scrollContainer), this.innerContainer = h(n.classNames.innerContainer), this.outerContainer.appendChild(this.scrollContainer), this.gridContainer.appendChild(this.outerContainer), this.container.appendChild(this.gridContainer), this.options = C(b.defaultOptions, t), this.gridContainer.style.height = this.getDataGridSize() + "px", this.gridContainer.role = "figure", this.dataTable = this.initDataTable(), this.rowElements = [], this.draggedResizeHandle = null, this.draggedColumnRightIx = null, this.columnNames = this.getColumnsToDisplay(), this.render(), (this.containerResizeObserver = new ResizeObserver(() => {
          this.updateGridElements();
        })).observe(this.container);
      }
      update(e) {
        this.options = C(this.options, e), this.options.dataTable !== this.dataTable && (this.dataTable = this.initDataTable()), this.columnNames = this.getColumnsToDisplay(), this.scrollContainer.removeChild(this.innerContainer), this.render();
      }
      resizeColumn(e, t) {
        let i = this.columnHeadersContainer,
          s = "string" == typeof t ? this.columnNames.indexOf(t) : t,
          n = `${e}`;
        if (g(s)) {
          if (-1 !== s) {
            if (i) {
              let e = i.children[s];
              e && (e.style.flex = n);
            }
            for (let e = 0; e < this.rowElements.length; e++) {
              let t = this.rowElements[e].children[s];
              t && (t.style.flex = n);
            }
          }
        } else {
          if (i) for (let e = 0; e < i.children.length; e++) i.children[e].style.flex = n;
          for (let e = 0; e < this.rowElements.length; e++) {
            let t = this.rowElements[e];
            for (let e = 0; e < t.children.length; e++) t.children[e].style.flex = n;
          }
        }
        this.renderColumnDragHandles(), this.emit({
          type: "afterResizeColumn",
          width: e,
          index: s,
          name: g(s) ? this.columnNames[s] : void 0
        });
      }
      emit(e) {
        p(this, e.type, e);
      }
      toggleRowHighlight(e) {
        this.hoveredRow && this.hoveredRow.classList.contains("hovered") && this.hoveredRow.classList.remove("hovered"), e && (e.classList.contains("hovered") ? e.classList.remove("hovered") : e.classList.add("hovered"));
      }
      on(e, t) {
        return c(this, e, t);
      }
      scrollToRow(e) {
        this.outerContainer.scrollTop = e * this.options.cellHeight;
      }
      getColumnsToDisplay() {
        let e = this.options.columns,
          t = this.dataTable.modified.getColumnNames(),
          i = [];
        for (let s = 0; s < t.length; s++) {
          let n = t[s],
            o = e[n];
          o && m(o.show) ? e[n].show && i.push(n) : i.push(n);
        }
        return i;
      }
      isColumnEditable(e) {
        return y((this.options.columns[e] || {}).editable, this.options.editable);
      }
      initDataTable() {
        return this.options.dataTable ? this.options.dataTable : new i();
      }
      render() {
        let {
          options: e
        } = this;
        this.prevTop = -1, this.bottom = !1, a(this.innerContainer), e.columnHeaders.enabled ? this.renderColumnHeaders() : this.outerContainer.style.top = "0", this.renderInitialRows(), this.addEvents(), this.updateScrollingLength(), this.updateVisibleCells(), e.columnHeaders.enabled && e.resizableColumns && this.renderColumnDragHandles(), this.updateGridElements(), this.gridContainer.ariaLabel = `Grid with ${this.dataTable.getColumnNames().length} columns and ${this.dataTable.getRowCount()} rows.`;
      }
      addEvents() {
        this.outerContainer.addEventListener("scroll", e => {
          this.onScroll(e);
        }), document.addEventListener("click", e => {
          this.onDocumentClick(e);
        }), this.container.addEventListener("mouseover", e => {
          this.handleMouseOver(e);
        }), this.container.addEventListener("click", e => {
          this.handleRowClick(e);
        });
      }
      updateVisibleCells(e = !1) {
        let t = this.outerContainer.scrollTop;
        d && (t = f(t, 0, this.outerContainer.scrollHeight - this.outerContainer.clientHeight));
        let i = Math.floor(t / this.options.cellHeight);
        if (i === this.prevTop && !e) return;
        this.prevTop = i;
        let s = this.columnNames,
          n = this.dataTable.modified,
          o = n.modified.getRowCount();
        for (let e = 0; e < this.rowElements.length && i < o; e++, i++) {
          let t = this.rowElements[e];
          t.dataset.rowIndex = n.getOriginalRowIndex(i)?.toString();
          let o = t.childNodes;
          for (let e = 0, n = s.length; e < n; e++) {
            let n = o[e],
              r = s[e],
              l = this.dataTable.modified.getCell(s[e], i),
              a = this.formatCell(l, r);
            this.options.useHTML ? this.renderHTMLCellContent(a, n) : n.textContent = a, n.dataset.originalData = "" + l, n.dataset.columnName = s[e], n.dataset.dataType = typeof l, 0 === e && (t.dataset.rowXIndex = String(g(l) ? l : i));
          }
        }
        this.prevTop + this.scrollEndRowCount === o ? !this.bottom && this.scrollEndTop && (this.bottom = !0, this.innerContainer.scrollTop = this.scrollEndTop) : this.bottom && (this.bottom = !1, this.innerContainer.scrollTop = 0);
      }
      onScroll(t) {
        t.preventDefault(), e.requestAnimationFrame(this.updateVisibleCells.bind(this, !1));
      }
      onCellClick(e, t) {
        if (this.isColumnEditable(t)) {
          let t = e.querySelector("input"),
            i = e.getAttribute("data-original-data");
          if (!t) {
            this.removeCellInputElement();
            let s = e.clientHeight;
            e.textContent = "", (t = this.cellInputEl = document.createElement("input")).style.height = s + "px", t.className = n.classNames.cellInput, e.appendChild(t), t.focus(), t.value = i || "";
          }
          this.emit({
            type: "cellClick",
            input: t
          });
        }
      }
      onDocumentClick(e) {
        if (this.cellInputEl && e.target) {
          let t = this.cellInputEl.parentNode;
          t && t.contains(e.target) || this.removeCellInputElement();
        }
      }
      handleMouseOver(e) {
        let t = e.target;
        if (t && t.classList.contains(n.classNames.cell)) {
          let e = t.parentElement;
          this.toggleRowHighlight(e), this.hoveredRow = e, p(this.container, "dataGridHover", {
            row: e,
            columnName: t.dataset?.columnName
          });
        } else this.hoveredRow && (this.toggleRowHighlight(), this.hoveredRow = void 0);
      }
      handleRowClick(e) {
        let t = e.target,
          i = this.options.events?.row?.click;
        i && t?.classList.contains(n.classNames.cell) && i.call(t.parentElement, e);
      }
      removeCellInputElement() {
        let e = this.cellInputEl;
        if (e) {
          let t = e.parentNode;
          if (t) {
            let i = t.getAttribute("data-data-type"),
              s = t.getAttribute("data-column-name"),
              n = e.value;
            "number" === i && (n = parseFloat(n)), t.textContent = this.formatCell(n, s || "");
          }
          e.remove(), delete this.cellInputEl;
        }
      }
      updateScrollingLength() {
        let e = this.dataTable.modified.getRowCount() - 1,
          t = 0,
          i = e - this.getNumRowsToDraw(),
          s = this.outerContainer.clientHeight;
        this.innerContainer.style.height = s + "px", this.scrollContainer.appendChild(this.innerContainer);
        for (let n = 0; e > i; e--, n++) if ((t += this.rowElements[n].offsetHeight) > s) {
          e--;
          break;
        }
        let n = e - i;
        this.scrollEndRowCount = this.rowElements.length - n, this.scrollEndTop = t - s;
        let o = (this.dataTable.modified.getRowCount() + n) * this.options.cellHeight;
        this.scrollContainer.style.height = o + "px";
      }
      getNumRowsToDraw() {
        return Math.min(this.dataTable.modified.getRowCount(), Math.ceil((this.outerContainer.offsetHeight || this.options.defaultHeight) / this.options.cellHeight));
      }
      getDataGridSize() {
        let e = this.options,
          {
            height: t
          } = this.container.getBoundingClientRect();
        return t > 2 ? t : e.defaultHeight;
      }
      renderCell(e, t) {
        let i = n.classNames.cell;
        this.isColumnEditable(t) || (i += ` ${i}-readonly`);
        let s = h(i);
        s.style.minHeight = this.options.cellHeight + "px", s.addEventListener("click", () => this.onCellClick(s, t)), e.appendChild(s);
      }
      renderRow() {
        let e = h(n.classNames.row);
        for (let t = 0; t < this.columnNames.length; t++) this.renderCell(e, this.columnNames[t]);
        this.innerContainer.appendChild(e), this.rowElements.push(e);
      }
      formatHeaderCell(e) {
        let t = this.options.columns[e],
          i = t && t.headerFormat;
        return i ? o.format(i, {
          text: e
        }) : e;
      }
      formatCell(e, t) {
        let i = this.options.columns[t],
          s = i && i.cellFormat,
          n = i && i.cellFormatter,
          r = m(e) ? e : "";
        return (s && ("number" == typeof e && s.indexOf("value") > -1 ? r = o.format(s, {
          value: e
        }) : "string" == typeof e && s.indexOf("text") > -1 && (r = o.format(s, {
          text: e
        }))), n) ? n.call({
          value: e
        }) : r.toString();
      }
      renderHTMLCellContent(e, i) {
        let s = new t(e);
        i.innerHTML = "", s.addToDOM(i);
      }
      renderColumnHeader(e, t) {
        let i = n.classNames.columnHeader;
        this.isColumnEditable(t) || (i += ` ${i}-readonly`);
        let s = h(i);
        s.style.minHeight = this.options.cellHeight + "px", s.style.maxHeight = 2 * this.options.cellHeight + "px", s.textContent = this.formatHeaderCell(t), e.appendChild(s);
      }
      renderColumnHeaders() {
        let e = this.columnNames,
          t = this.columnHeadersContainer = this.columnHeadersContainer || h(`${n.classNamePrefix}column-headers`);
        a(t), e.forEach(this.renderColumnHeader.bind(this, t)), this.headerContainer || (this.headerContainer = h(`${n.classNamePrefix}header-container`), this.headerContainer.appendChild(t)), this.gridContainer.insertBefore(this.headerContainer, this.outerContainer), this.updateColumnHeaders();
      }
      updateGridElements() {
        this.updateColumnHeaders(), this.redrawRowElements(), this.updateDragHandlesPosition();
      }
      updateColumnHeaders() {
        let e = this.columnHeadersContainer;
        if (e) {
          for (let t = 0; t < this.columnNames.length; t++) {
            let i = this.columnNames[t],
              s = e.children[t],
              n = this.overflowHeaderWidths[t];
            s.scrollWidth > s.clientWidth ? (this.overflowHeaderWidths[t] = s.scrollWidth, s.textContent = this.formatHeaderCell(i).split(" ").map(e => e.length < 4 ? e : e.slice(0, 2) + "...").join(" ")) : g(n) && n <= s.clientWidth && (this.overflowHeaderWidths[t] = null, s.textContent = this.formatHeaderCell(i));
          }
          this.outerContainer.style.top = e.clientHeight + "px", e.lastChild && (e.lastChild.style.marginRight = this.outerContainer.offsetWidth - this.outerContainer.clientWidth + "px");
        }
      }
      redrawRowElements() {
        if (!this.rowElements.length) return;
        let e = [],
          t = this.rowElements[0].children;
        for (let i = 0; i < t.length; i++) e.push(t[i].style.flex);
        a(this.innerContainer), this.renderInitialRows(), this.updateScrollingLength(), this.updateVisibleCells(!0);
        for (let t = 0; t < this.rowElements.length; t++) {
          let i = this.rowElements[t];
          for (let t = 0; t < i.childElementCount; t++) i.children[t].style.flex = e[t];
        }
      }
      updateDragHandlesPosition() {
        let e = this.columnHeadersContainer,
          t = this.columnDragHandlesContainer;
        if (t && e) for (let i = 0; i < t.childElementCount - 1; i++) {
          let s = t.children[i],
            n = e.children[i + 1];
          s.style.height = e.clientHeight + "px", s.style.left = n.offsetLeft - 2 + "px";
        }
      }
      renderInitialRows() {
        this.rowElements = [];
        let e = this.getNumRowsToDraw();
        for (let t = 0; t < e; t++) this.renderRow();
      }
      renderColumnDragHandles() {
        if (!this.columnHeadersContainer) return;
        let e = this.columnDragHandlesContainer = this.columnDragHandlesContainer || h(`${n.classNamePrefix}col-resize-container`),
          t = this.columnHeadersContainer.children,
          i = this.options.cellHeight;
        a(e);
        for (let s = 1; s < t.length; ++s) {
          let o = t[s],
            r = h(`${n.classNamePrefix}col-resize-handle`);
          r.style.height = i + "px", r.style.left = o.offsetLeft - 2 + "px", r.addEventListener("mouseover", () => {
            this.draggedResizeHandle || (r.style.opacity = "1");
          }), r.addEventListener("mouseleave", () => {
            this.draggedResizeHandle || (r.style.opacity = "0");
          }), r.addEventListener("mousedown", this.onHandleMouseDown.bind(this, r, s)), e.appendChild(r);
        }
        this.renderColumnResizeCrosshair(e), document.addEventListener("mouseup", e => {
          this.draggedResizeHandle && this.stopColumnResize(this.draggedResizeHandle, e);
        }), document.addEventListener("mousemove", e => {
          this.draggedResizeHandle && this.updateColumnResizeDrag(e);
        }), this.headerContainer && this.headerContainer.appendChild(e);
      }
      renderColumnResizeCrosshair(e) {
        let t = this.columnResizeCrosshair = this.columnResizeCrosshair || h(`${n.classNamePrefix}col-resize-crosshair`),
          i = this.options.cellHeight;
        t.style.top = i + "px", t.style.height = this.innerContainer.offsetHeight + "px", e.appendChild(t);
      }
      onHandleMouseDown(e, t, i) {
        if (this.draggedResizeHandle) return;
        i.preventDefault(), this.draggedResizeHandle = e, this.draggedColumnRightIx = t, this.dragResizeStart = i.pageX;
        let s = this.columnResizeCrosshair;
        s && (s.style.left = e.offsetLeft + e.offsetWidth / 2 - s.offsetWidth / 2 + "px", s.style.opacity = "1");
      }
      updateColumnResizeDrag(e) {
        let t = this.draggedResizeHandle,
          i = this.columnResizeCrosshair,
          s = this.draggedColumnRightIx,
          n = this.columnHeadersContainer;
        if (!t || !i || null === s || !n || !this.dragResizeStart) return;
        let o = n.children[s],
          r = e.pageX - this.dragResizeStart,
          l = o.offsetLeft + r;
        t.style.left = l - t.offsetWidth / 2 + "px", i.style.left = l - i.offsetWidth / 2 + "px";
      }
      stopColumnResize(e, t) {
        let i = this.columnResizeCrosshair,
          s = this.draggedColumnRightIx,
          n = this.columnHeadersContainer;
        if (!i || !n || !this.dragResizeStart || null === s) return;
        e.style.opacity = "0", i.style.opacity = "0";
        let o = n.children[s - 1],
          r = n.children[s],
          l = t.pageX - this.dragResizeStart,
          a = o.offsetWidth + l,
          h = r.offsetWidth - l,
          d = a / o.offsetWidth,
          u = h / r.offsetWidth,
          c = (o.style.flex ? parseFloat(o.style.flex) : 1) * d,
          f = (r.style.flex ? parseFloat(r.style.flex) : 1) * u;
        this.resizeColumn(c, s - 1), this.resizeColumn(f, s), this.draggedResizeHandle = null, this.draggedColumnRightIx = null, this.updateGridElements();
      }
      setSize(e, t) {
        e && (this.innerContainer.style.width = e + "px"), t && (this.gridContainer.style.height = this.getDataGridSize() + "px", this.outerContainer.style.height = t - (this.options.cellHeight + this.getMarginHeight(t)) + "px"), this.render();
      }
      getMarginHeight(e) {
        return e - this.gridContainer.getBoundingClientRect().height;
      }
    }
    return b.defaultOptions = r, b;
  }), i(t, "Data/DataPoolDefaults.js", [], function () {
    return {
      connectors: []
    };
  }), i(t, "Data/DataPool.js", [t["Data/Connectors/DataConnector.js"], t["Data/DataPoolDefaults.js"], t["Core/Utilities.js"]], function (e, t, i) {
    class s {
      constructor(e = t) {
        e.connectors = e.connectors || [], this.connectors = {}, this.options = e, this.waiting = {};
      }
      emit(e) {
        i.fireEvent(this, e.type, e);
      }
      getConnector(e) {
        let t = this.connectors[e];
        if (t) return Promise.resolve(t);
        let i = this.waiting[e];
        if (!i) {
          i = this.waiting[e] = [];
          let t = this.getConnectorOptions(e);
          if (!t) throw Error(`Connector '${e}' not found.`);
          this.loadConnector(t).then(t => {
            delete this.waiting[e];
            for (let e = 0, s = i.length; e < s; ++e) i[e][0](t);
          }).catch(t => {
            delete this.waiting[e];
            for (let e = 0, s = i.length; e < s; ++e) i[e][1](t);
          });
        }
        return new Promise((e, t) => {
          i.push([e, t]);
        });
      }
      getConnectorIds() {
        let e = this.options.connectors,
          t = [];
        for (let i = 0, s = e.length; i < s; ++i) t.push(e[i].id);
        return t;
      }
      getConnectorOptions(e) {
        let t = this.options.connectors;
        for (let i = 0, s = t.length; i < s; ++i) if (t[i].id === e) return t[i];
      }
      getConnectorTable(e) {
        return this.getConnector(e).then(e => e.table);
      }
      isNewConnector(e) {
        return !this.connectors[e];
      }
      loadConnector(t) {
        return new Promise((i, s) => {
          this.emit({
            type: "load",
            options: t
          });
          let n = e.types[t.type];
          if (!n) throw Error(`Connector type not found. (${t.type})`);
          new n(t.options).load().then(e => {
            this.connectors[t.id] = e, this.emit({
              type: "afterLoad",
              options: t
            }), i(e);
          }).catch(s);
        });
      }
      on(e, t) {
        return i.addEvent(this, e, t);
      }
      setConnectorOptions(e) {
        let t = this.options.connectors,
          i = this.connectors;
        this.emit({
          type: "setConnectorOptions",
          options: e
        });
        for (let i = 0, s = t.length; i < s; ++i) if (t[i].id === e.id) {
          t.splice(i, 1);
          break;
        }
        i[e.id] && (i[e.id].stopPolling(), delete i[e.id]), t.push(e), this.emit({
          type: "afterSetConnectorOptions",
          options: e
        });
      }
    }
    return s.version = "1.0.0", s;
  }), i(t, "Data/Converters/CSVConverter.js", [t["Data/Converters/DataConverter.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
      merge: i
    } = t;
    class s extends e {
      constructor(e) {
        let t = i(s.defaultOptions, e);
        super(t), this.columns = [], this.headers = [], this.dataTypes = [], this.options = t;
      }
      export(e, t = this.options) {
        let {
            useLocalDecimalPoint: i,
            lineDelimiter: s
          } = t,
          n = !1 !== this.options.firstRowAsNames,
          {
            decimalPoint: o,
            itemDelimiter: r
          } = t;
        o || (o = "," !== r && i ? 1.1.toLocaleString()[1] : "."), r || (r = "," === o ? ";" : ",");
        let l = e.getSortedColumns(t.usePresentationOrder),
          a = Object.keys(l),
          h = [],
          d = a.length,
          u = [];
        n && h.push(a.map(e => `"${e}"`).join(r));
        for (let t = 0; t < d; t++) {
          let i;
          let s = a[t],
            n = l[s],
            c = n.length,
            f = e.whatIs(s);
          f && (i = f.dataType);
          for (let e = 0; e < c; e++) {
            let s = n[e];
            if (u[e] || (u[e] = []), "string" === i ? s = '"' + s + '"' : "number" == typeof s ? s = String(s).replace(".", o) : "string" == typeof s && (s = `"${s}"`), u[e][t] = s, t === d - 1) {
              let i = t;
              for (; u[e].length > 2 && void 0 === u[e][i];) u[e].pop(), i--;
              h.push(u[e].join(r));
            }
          }
        }
        return h.join(s);
      }
      parse(e, t) {
        let s = this.dataTypes,
          n = i(this.options, e),
          {
            beforeParse: o,
            lineDelimiter: r,
            firstRowAsNames: l,
            itemDelimiter: a
          } = n,
          h,
          d = 0,
          {
            csv: u,
            startRow: c,
            endRow: f
          } = n,
          m;
        if (this.columns = [], this.emit({
          type: "parse",
          columns: this.columns,
          detail: t,
          headers: this.headers
        }), u && o && (u = o(u)), u) {
          if (h = u.replace(/\r\n|\r/g, "\n").split(r || "\n"), (!c || c < 0) && (c = 0), (!f || f >= h.length) && (f = h.length - 1), a || (this.guessedItemDelimiter = this.guessDelimiter(h)), l) {
            let e = h[0].split(a || this.guessedItemDelimiter || ",");
            for (let t = 0; t < e.length; t++) e[t] = e[t].trim().replace(/^["']|["']$/g, "");
            this.headers = e, c++;
          }
          let e = 0;
          for (d = c; d <= f; d++) "#" === h[d][0] ? e++ : this.parseCSVRow(h[d], d - c - e);
          s.length && s[0].length && "date" === s[0][1] && !this.options.dateFormat && this.deduceDateFormat(this.columns[0], null, !0);
          for (let e = 0, t = this.columns.length; e < t; ++e) {
            m = this.columns[e];
            for (let t = 0, i = m.length; t < i; ++t) if (m[t] && "string" == typeof m[t]) {
              let i = this.asGuessedType(m[t]);
              i instanceof Date && (i = i.getTime()), this.columns[e][t] = i;
            }
          }
        }
        this.emit({
          type: "afterParse",
          columns: this.columns,
          detail: t,
          headers: this.headers
        });
      }
      parseCSVRow(e, t) {
        let i = this,
          s = i.columns || [],
          n = i.dataTypes,
          {
            startColumn: o,
            endColumn: r
          } = i.options,
          l = i.options.itemDelimiter || i.guessedItemDelimiter,
          {
            decimalPoint: a
          } = i.options;
        a && a !== l || (a = i.guessedDecimalPoint || ".");
        let h = 0,
          d = "",
          u = "",
          c = 0,
          f = 0,
          m = t => {
            d = e[t];
          },
          p = e => {
            n.length < f + 1 && n.push([e]), n[f][n[f].length - 1] !== e && n[f].push(e);
          },
          g = () => {
            if (o > c || c > r) {
              ++c, u = "";
              return;
            }
            if ("string" == typeof u ? !isNaN(parseFloat(u)) && isFinite(u) ? (u = parseFloat(u), p("number")) : isNaN(Date.parse(u)) ? p("string") : (u = u.replace(/\//g, "-"), p("date")) : p("number"), s.length < f + 1 && s.push([]), "number" != typeof u && "number" !== i.guessType(u) && a) {
              let e = u;
              u = u.replace(a, "."), "number" !== i.guessType(u) && (u = e);
            }
            s[f][t] = u, u = "", ++f, ++c;
          };
        if (e.trim().length && "#" !== e.trim()[0]) {
          for (; h < e.length; h++) {
            if (m(h), "#" === d && !/^#[A-F\d]{3,3}|[A-F\d]{6,6}/i.test(e.substring(h))) {
              g();
              return;
            }
            if ('"' === d) for (m(++h); h < e.length && '"' !== d;) u += d, m(++h);else d === l ? g() : u += d;
          }
          g();
        }
      }
      guessDelimiter(e) {
        let t = 0,
          i = 0,
          s,
          n = {
            ",": 0,
            ";": 0,
            "	": 0
          },
          o = e.length;
        for (let s = 0; s < o; s++) {
          let o = !1,
            r,
            l,
            a,
            h = "";
          if (s > 13) break;
          let d = e[s];
          for (let e = 0; e < d.length && (r = d[e], l = d[e + 1], a = d[e - 1], "#" !== r); e++) {
            if ('"' === r) {
              if (o) {
                if ('"' !== a && '"' !== l) {
                  for (; " " === l && e < d.length;) l = d[++e];
                  void 0 !== n[l] && n[l]++, o = !1;
                }
              } else o = !0;
            } else void 0 !== n[r] ? (isNaN(Date.parse(h = h.trim())) ? (isNaN(Number(h)) || !isFinite(Number(h))) && n[r]++ : n[r]++, h = "") : h += r;
            "," === r && i++, "." === r && t++;
          }
        }
        return n[";"] > n[","] ? s = ";" : (n[","], n[";"], s = ","), t > i ? this.guessedDecimalPoint = "." : this.guessedDecimalPoint = ",", s;
      }
      getTable() {
        return e.getTableFromColumns(this.columns, this.headers);
      }
    }
    return s.defaultOptions = {
      ...e.defaultOptions,
      lineDelimiter: "\n"
    }, s;
  }), i(t, "Data/Connectors/CSVConnector.js", [t["Data/Converters/CSVConverter.js"], t["Data/Connectors/DataConnector.js"], t["Core/Utilities.js"]], function (e, t, i) {
    let {
      merge: s
    } = i;
    class n extends t {
      constructor(t) {
        let i = s(n.defaultOptions, t);
        super(i), this.converter = new e(i), this.options = i, i.enablePolling && this.startPolling(1e3 * Math.max(i.dataRefreshRate || 0, 1));
      }
      load(e) {
        let t = this,
          i = t.converter,
          s = t.table,
          {
            csv: n,
            csvURL: o,
            dataModifier: r
          } = t.options;
        return t.emit({
          type: "load",
          csv: n,
          detail: e,
          table: s
        }), Promise.resolve(o ? fetch(o).then(e => e.text()) : n || "").then(e => (e && (s.deleteColumns(), i.parse({
          csv: e
        }), s.setColumns(i.getTable().getColumns())), t.setModifierOptions(r).then(() => e))).then(i => (t.emit({
          type: "afterLoad",
          csv: i,
          detail: e,
          table: s
        }), t)).catch(i => {
          throw t.emit({
            type: "loadError",
            detail: e,
            error: i,
            table: s
          }), i;
        });
      }
    }
    return n.defaultOptions = {
      csv: "",
      csvURL: "",
      enablePolling: !1,
      dataRefreshRate: 1,
      firstRowAsNames: !0
    }, t.registerType("CSV", n), n;
  }), i(t, "Data/Converters/GoogleSheetsConverter.js", [t["Data/Converters/DataConverter.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
      merge: i,
      uniqueKey: s
    } = t;
    class n extends e {
      constructor(e) {
        let t = i(n.defaultOptions, e);
        super(t), this.columns = [], this.header = [], this.options = t;
      }
      parse(e, t) {
        let n;
        let o = i(this.options, e),
          r = (o.json?.values || []).map(e => e.slice());
        if (0 === r.length) return !1;
        this.header = [], this.columns = [], this.emit({
          type: "parse",
          columns: this.columns,
          detail: t,
          headers: this.header
        });
        let {
          beforeParse: l,
          json: a
        } = o;
        l && a && (r = l(a.values)), this.columns = r;
        for (let e = 0, t = r.length; e < t; e++) {
          n = r[e], this.header[e] = o.firstRowAsNames ? `${n.shift()}` : s();
          for (let t = 0, i = n.length; t < i; ++t) if (n[t] && "string" == typeof n[t]) {
            let i = this.asGuessedType(n[t]);
            i instanceof Date && (i = i.getTime()), this.columns[e][t] = i;
          }
        }
        this.emit({
          type: "afterParse",
          columns: this.columns,
          detail: t,
          headers: this.header
        });
      }
      getTable() {
        return e.getTableFromColumns(this.columns, this.header);
      }
    }
    return n.defaultOptions = {
      ...e.defaultOptions
    }, n;
  }), i(t, "Data/Connectors/GoogleSheetsConnector.js", [t["Data/Connectors/DataConnector.js"], t["Data/Converters/GoogleSheetsConverter.js"], t["Core/Utilities.js"]], function (e, t, i) {
    let {
      merge: s,
      pick: n
    } = i;
    class o extends e {
      constructor(e) {
        let i = s(o.defaultOptions, e);
        super(i), this.converter = new t(i), this.options = i;
      }
      load(e) {
        let t = this,
          i = t.converter,
          s = t.table,
          {
            dataModifier: n,
            dataRefreshRate: r,
            enablePolling: l,
            firstRowAsNames: a,
            googleAPIKey: h,
            googleSpreadsheetKey: d
          } = t.options,
          u = o.buildFetchURL(h, d, t.options);
        if (t.emit({
          type: "load",
          detail: e,
          table: s,
          url: u
        }), !URL.canParse(u)) throw Error("Invalid URL: " + u);
        return fetch(u).then(e => e.json()).then(e => {
          if ("object" == typeof e && e && "object" == typeof e.error && e.error && "number" == typeof e.error.code && "string" == typeof e.error.message && "string" == typeof e.error.status) throw Error(e.error.message);
          return i.parse({
            firstRowAsNames: a,
            json: e
          }), s.deleteColumns(), s.setColumns(i.getTable().getColumns()), t.setModifierOptions(n);
        }).then(() => (t.emit({
          type: "afterLoad",
          detail: e,
          table: s,
          url: u
        }), l && setTimeout(() => t.load(), 1e3 * Math.max(r || 0, 1)), t)).catch(i => {
          throw t.emit({
            type: "loadError",
            detail: e,
            error: i,
            table: s
          }), i;
        });
      }
    }
    return o.defaultOptions = {
      googleAPIKey: "",
      googleSpreadsheetKey: "",
      enablePolling: !1,
      dataRefreshRate: 2,
      firstRowAsNames: !0
    }, function (e) {
      let t = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      function i(e = {}) {
        let {
          endColumn: s,
          endRow: o,
          googleSpreadsheetRange: r,
          startColumn: l,
          startRow: a
        } = e;
        return r || (t[l || 0] || "A") + (Math.max(a || 0, 0) + 1) + ":" + (t[n(s, 25)] || "Z") + (o ? Math.max(o, 0) : "Z");
      }
      e.buildFetchURL = function (e, t, s = {}) {
        let n = new URL(`https://sheets.googleapis.com/v4/spreadsheets/${t}/values/`),
          o = s.onlyColumnNames ? "A1:Z1" : i(s);
        n.pathname += o;
        let r = n.searchParams;
        return r.set("alt", "json"), s.onlyColumnNames || (r.set("dateTimeRenderOption", "FORMATTED_STRING"), r.set("majorDimension", "COLUMNS"), r.set("valueRenderOption", "UNFORMATTED_VALUE")), r.set("prettyPrint", "false"), r.set("key", e), n.href;
      }, e.buildQueryRange = i;
    }(o || (o = {})), e.registerType("GoogleSheets", o), o;
  }), i(t, "Data/Converters/HTMLTableConverter.js", [t["Data/Converters/DataConverter.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
      merge: i
    } = t;
    class s extends e {
      constructor(e) {
        let t = i(s.defaultOptions, e);
        super(t), this.columns = [], this.headers = [], this.options = t, t.tableElement && (this.tableElement = t.tableElement, this.tableElementID = t.tableElement.id);
      }
      export(e, t = this.options) {
        let i = !1 !== t.firstRowAsNames,
          s = t.useMultiLevelHeaders,
          n = e.getSortedColumns(t.usePresentationOrder),
          o = Object.keys(n),
          r = [],
          l = o.length,
          a = [],
          h = "";
        if (i) {
          let e = [];
          if (s) {
            for (let t of o) {
              let i = (n[t].shift() || "").toString();
              e.push(i);
            }
            h = this.getTableHeaderHTML(o, e, t);
          } else h = this.getTableHeaderHTML(void 0, o, t);
        }
        for (let e = 0; e < l; e++) {
          let t = n[o[e]],
            i = t.length;
          for (let s = 0; s < i; s++) {
            let i = t[s];
            a[s] || (a[s] = []), "string" == typeof i || "number" == typeof i || void 0 === i || (i = (i || "").toString()), a[s][e] = this.getCellHTMLFromValue(e ? "td" : "th", null, e ? "" : 'scope="row"', i), e === l - 1 && r.push("<tr>" + a[s].join("") + "</tr>");
          }
        }
        let d = "";
        return t.tableCaption && (d = '<caption class="highcharts-table-caption">' + t.tableCaption + "</caption>"), "<table>" + d + h + "<tbody>" + r.join("") + "</tbody></table>";
      }
      getCellHTMLFromValue(e, t, i, s, n) {
        let o = s,
          r = "text" + (t ? " " + t : "");
        return "number" == typeof o ? (o = o.toString(), "," === n && (o = o.replace(".", n)), r = "number") : s || (o = "", r = "empty"), "<" + e + (i ? " " + i : "") + ' class="' + r + '">' + o + "</" + e + ">";
      }
      getTableHeaderHTML(e = [], t = [], i = this.options) {
        let {
            useMultiLevelHeaders: s,
            useRowspanHeaders: n
          } = i,
          o = "<thead>",
          r = 0,
          l = t && t.length,
          a,
          h = 0,
          d;
        if (s && e && t && !function (e, t) {
          let i = e.length;
          if (t.length !== i) return !1;
          for (; --i;) if (e[i] !== t[i]) return !1;
          return !0;
        }(e, t)) {
          for (o += "<tr>"; r < l; ++r) (a = e[r]) === e[r + 1] ? ++h : h ? (o += this.getCellHTMLFromValue("th", "highcharts-table-topheading", 'scope="col" colspan="' + (h + 1) + '"', a), h = 0) : (a === t[r] ? n ? (d = 2, delete t[r]) : (d = 1, t[r] = "") : d = 1, o += this.getCellHTMLFromValue("th", "highcharts-table-topheading", 'scope="col"' + (d > 1 ? ' valign="top" rowspan="' + d + '"' : ""), a));
          o += "</tr>";
        }
        if (t) {
          for (o += "<tr>", r = 0, l = t.length; r < l; ++r) void 0 !== t[r] && (o += this.getCellHTMLFromValue("th", null, 'scope="col"', t[r]));
          o += "</tr>";
        }
        return o + "</thead>";
      }
      parse(e, t) {
        let s = [],
          n = [],
          o = i(this.options, e),
          {
            endRow: r,
            startColumn: l,
            endColumn: a,
            firstRowAsNames: h
          } = o,
          d = o.tableElement || this.tableElement;
        if (!(d instanceof HTMLElement)) {
          this.emit({
            type: "parseError",
            columns: s,
            detail: t,
            headers: n,
            error: "Not a valid HTML Table"
          });
          return;
        }
        this.tableElement = d, this.tableElementID = d.id, this.emit({
          type: "parse",
          columns: this.columns,
          detail: t,
          headers: this.headers
        });
        let u = d.getElementsByTagName("tr"),
          c = u.length,
          f = 0,
          m,
          {
            startRow: p
          } = o;
        if (h && c) {
          let e = u[0].children,
            t = e.length;
          for (let i = l; i < t && !(i > a); i++) ("TD" === (m = e[i]).tagName || "TH" === m.tagName) && n.push(m.innerHTML);
          p++;
        }
        for (; f < c;) {
          if (f >= p && f <= r) {
            let e = u[f].children,
              t = e.length,
              i = 0;
            for (; i < t;) {
              let t = i - l,
                n = s[t];
              if (("TD" === (m = e[i]).tagName || "TH" === m.tagName) && i >= l && i <= a) {
                s[t] || (s[t] = []);
                let e = this.asGuessedType(m.innerHTML);
                e instanceof Date && (e = e.getTime()), s[t][f - p] = e;
                let i = 1;
                for (; f - p >= i && void 0 === n[f - p - i];) n[f - p - i] = null, i++;
              }
              i++;
            }
          }
          f++;
        }
        this.columns = s, this.headers = n, this.emit({
          type: "afterParse",
          columns: s,
          detail: t,
          headers: n
        });
      }
      getTable() {
        return e.getTableFromColumns(this.columns, this.headers);
      }
    }
    return s.defaultOptions = {
      ...e.defaultOptions,
      useRowspanHeaders: !0,
      useMultiLevelHeaders: !0
    }, s;
  }), i(t, "Data/Connectors/HTMLTableConnector.js", [t["Data/Connectors/DataConnector.js"], t["Core/Globals.js"], t["Data/Converters/HTMLTableConverter.js"], t["Core/Utilities.js"]], function (e, t, i, s) {
    let {
        win: n
      } = t,
      {
        merge: o
      } = s;
    class r extends e {
      constructor(e) {
        let t = o(r.defaultOptions, e);
        super(t), this.converter = new i(t), this.options = t;
      }
      load(e) {
        let t;
        let i = this,
          s = i.converter,
          r = i.table,
          {
            dataModifier: l,
            table: a
          } = i.options;
        if (i.emit({
          type: "load",
          detail: e,
          table: r,
          tableElement: i.tableElement
        }), "string" == typeof a ? (i.tableID = a, t = n.document.getElementById(a)) : (t = a, i.tableID = t.id), i.tableElement = t || void 0, !i.tableElement) {
          let t = "HTML table not provided, or element with ID not found";
          return i.emit({
            type: "loadError",
            detail: e,
            error: t,
            table: r
          }), Promise.reject(Error(t));
        }
        return s.parse(o({
          tableElement: i.tableElement
        }, i.options), e), r.deleteColumns(), r.setColumns(s.getTable().getColumns()), i.setModifierOptions(l).then(() => (i.emit({
          type: "afterLoad",
          detail: e,
          table: r,
          tableElement: i.tableElement
        }), i));
      }
    }
    return r.defaultOptions = {
      table: ""
    }, e.registerType("HTMLTable", r), r;
  }), i(t, "Data/Converters/JSONConverter.js", [t["Data/Converters/DataConverter.js"], t["Data/DataTable.js"], t["Core/Utilities.js"]], function (e, t, i) {
    let {
      error: s,
      isArray: n,
      merge: o,
      objectEach: r
    } = i;
    class l extends e {
      constructor(e) {
        let i = o(l.defaultOptions, e);
        super(i), this.columns = [], this.headers = [], this.options = i, this.table = new t();
      }
      parse(e, t) {
        let {
            beforeParse: i,
            orientation: l,
            firstRowAsNames: a,
            columnNames: h
          } = e = o(this.options, e),
          d = e.data;
        if (d) {
          if (this.columns = [], this.emit({
            type: "parse",
            columns: this.columns,
            detail: t,
            headers: this.headers
          }), i && (d = i(d)), d = d.slice(), "columns" === l) for (let e = 0, t = d.length; e < t; e++) {
            let t = d[e];
            if (!(t instanceof Array)) return;
            this.headers instanceof Array ? (a ? this.headers.push(`${t.shift()}`) : h && h instanceof Array && this.headers.push(h[e]), this.table.setColumn(this.headers[e] || e.toString(), t)) : s("JSONConverter: Invalid `columnNames` option.", !1);
          } else if ("rows" === l) {
            a ? this.headers = d.shift() : h && (this.headers = h);
            for (let e = 0, t = d.length; e < t; e++) {
              let t = d[e];
              if (n(t)) for (let e = 0, i = t.length; e < i; e++) this.columns.length < e + 1 && this.columns.push([]), this.columns[e].push(t[e]), this.headers instanceof Array ? this.table.setColumn(this.headers[e] || e.toString(), this.columns[e]) : s("JSONConverter: Invalid `columnNames` option.", !1);else {
                let i = this.headers;
                if (i && !(i instanceof Array)) {
                  let e = {};
                  r(i, (i, s) => {
                    e[s] = i.reduce((e, t) => e[t], t);
                  }), t = e;
                }
                this.table.setRows([t], e);
              }
            }
          }
          this.emit({
            type: "afterParse",
            columns: this.columns,
            detail: t,
            headers: this.headers
          });
        }
      }
      getTable() {
        return this.table;
      }
    }
    return l.defaultOptions = {
      ...e.defaultOptions,
      data: [],
      orientation: "rows"
    }, l;
  }), i(t, "Data/Connectors/JSONConnector.js", [t["Data/Connectors/DataConnector.js"], t["Core/Utilities.js"], t["Data/Converters/JSONConverter.js"]], function (e, t, i) {
    let {
      merge: s
    } = t;
    class n extends e {
      constructor(e) {
        let t = s(n.defaultOptions, e);
        super(t), this.converter = new i(t), this.options = t, t.enablePolling && this.startPolling(1e3 * Math.max(t.dataRefreshRate || 0, 1));
      }
      load(e) {
        let t = this,
          i = t.converter,
          s = t.table,
          {
            data: n,
            dataUrl: o,
            dataModifier: r
          } = t.options;
        return t.emit({
          type: "load",
          data: n,
          detail: e,
          table: s
        }), Promise.resolve(o ? fetch(o).then(e => e.json()) : n || []).then(e => (e && (s.deleteColumns(), i.parse({
          data: e
        }), s.setColumns(i.getTable().getColumns())), t.setModifierOptions(r).then(() => e))).then(i => (t.emit({
          type: "afterLoad",
          data: i,
          detail: e,
          table: s
        }), t)).catch(i => {
          throw t.emit({
            type: "loadError",
            detail: e,
            error: i,
            table: s
          }), i;
        });
      }
    }
    return n.defaultOptions = {
      data: [],
      enablePolling: !1,
      dataRefreshRate: 0,
      firstRowAsNames: !0,
      orientation: "rows"
    }, e.registerType("JSON", n), n;
  }), i(t, "Data/Modifiers/ChainModifier.js", [t["Data/Modifiers/DataModifier.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
      merge: i
    } = t;
    class s extends e {
      constructor(t, ...n) {
        super(), this.chain = n, this.options = i(s.defaultOptions, t);
        let o = this.options.chain || [];
        for (let t = 0, i = o.length, s, r; t < i; ++t) (s = o[t]).type && (r = e.types[s.type]) && n.push(new r(s));
      }
      add(e, t) {
        this.emit({
          type: "addModifier",
          detail: t,
          modifier: e
        }), this.chain.push(e), this.emit({
          type: "addModifier",
          detail: t,
          modifier: e
        });
      }
      clear(e) {
        this.emit({
          type: "clearChain",
          detail: e
        }), this.chain.length = 0, this.emit({
          type: "afterClearChain",
          detail: e
        });
      }
      async modify(e, t) {
        let i = this.options.reverse ? this.chain.slice().reverse() : this.chain.slice();
        e.modified === e && (e.modified = e.clone(!1, t));
        let s = e;
        for (let n = 0, o = i.length; n < o; ++n) {
          try {
            await i[n].modify(s, t);
          } catch (i) {
            throw this.emit({
              type: "error",
              detail: t,
              table: e
            }), i;
          }
          s = s.modified;
        }
        return e.modified = s, e;
      }
      modifyCell(e, t, i, s, n) {
        let o = this.options.reverse ? this.chain.reverse() : this.chain;
        if (o.length) {
          let r = e.clone();
          for (let e = 0, l = o.length; e < l; ++e) o[e].modifyCell(r, t, i, s, n), r = r.modified;
          e.modified = r;
        }
        return e;
      }
      modifyColumns(e, t, i, s) {
        let n = this.options.reverse ? this.chain.reverse() : this.chain.slice();
        if (n.length) {
          let o = e.clone();
          for (let e = 0, r = n.length; e < r; ++e) n[e].modifyColumns(o, t, i, s), o = o.modified;
          e.modified = o;
        }
        return e;
      }
      modifyRows(e, t, i, s) {
        let n = this.options.reverse ? this.chain.reverse() : this.chain.slice();
        if (n.length) {
          let o = e.clone();
          for (let e = 0, r = n.length; e < r; ++e) n[e].modifyRows(o, t, i, s), o = o.modified;
          e.modified = o;
        }
        return e;
      }
      modifyTable(e, t) {
        this.emit({
          type: "modify",
          detail: t,
          table: e
        });
        let i = this.options.reverse ? this.chain.reverse() : this.chain.slice(),
          s = e.modified;
        for (let e = 0, n = i.length; e < n; ++e) s = i[e].modifyTable(s, t).modified;
        return e.modified = s, this.emit({
          type: "afterModify",
          detail: t,
          table: e
        }), e;
      }
      remove(e, t) {
        let i = this.chain;
        this.emit({
          type: "removeModifier",
          detail: t,
          modifier: e
        }), i.splice(i.indexOf(e), 1), this.emit({
          type: "afterRemoveModifier",
          detail: t,
          modifier: e
        });
      }
    }
    return s.defaultOptions = {
      type: "Chain"
    }, e.registerType("Chain", s), s;
  }), i(t, "Data/Modifiers/InvertModifier.js", [t["Data/Modifiers/DataModifier.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
      merge: i
    } = t;
    class s extends e {
      constructor(e) {
        super(), this.options = i(s.defaultOptions, e);
      }
      modifyCell(e, t, i, s, n) {
        let o = e.modified,
          r = o.getRowIndexBy("columnNames", t);
        return void 0 === r ? o.setColumns(this.modifyTable(e.clone()).getColumns(), void 0, n) : o.setCell(`${i}`, r, s, n), e;
      }
      modifyColumns(e, t, i, s) {
        let n = e.modified,
          o = n.getColumn("columnNames") || [],
          r = e.getColumnNames(),
          l = e.getRowCount() !== o.length;
        if (!l) {
          for (let e = 0, t = r.length; e < t; ++e) if (r[e] !== o[e]) {
            l = !0;
            break;
          }
        }
        if (l) return this.modifyTable(e, s);
        r = Object.keys(t);
        for (let e = 0, o = r.length, l, a, h; e < o; ++e) {
          l = t[a = r[e]], h = n.getRowIndexBy("columnNames", a) || n.getRowCount();
          for (let e = 0, t = i, o = l.length; e < o; ++e, ++t) n.setCell(`${t}`, h, l[e], s);
        }
        return e;
      }
      modifyRows(e, t, i, s) {
        let n = e.getColumnNames(),
          o = e.modified,
          r = o.getColumn("columnNames") || [],
          l = e.getRowCount() !== r.length;
        if (!l) {
          for (let e = 0, t = n.length; e < t; ++e) if (n[e] !== r[e]) {
            l = !0;
            break;
          }
        }
        if (l) return this.modifyTable(e, s);
        for (let e = 0, r = i, l = t.length, a; e < l; ++e, ++r) if ((a = t[e]) instanceof Array) o.setColumn(`${r}`, a);else for (let e = 0, t = n.length; e < t; ++e) o.setCell(`${r}`, e, a[n[e]], s);
        return e;
      }
      modifyTable(e, t) {
        this.emit({
          type: "modify",
          detail: t,
          table: e
        });
        let i = e.modified;
        if (e.hasColumns(["columnNames"])) {
          let t = ((e.deleteColumns(["columnNames"]) || {}).columnNames || []).map(e => `${e}`),
            s = {};
          for (let i = 0, n = e.getRowCount(), o; i < n; ++i) (o = e.getRow(i)) && (s[t[i]] = o);
          i.deleteColumns(), i.setColumns(s);
        } else {
          let t = {};
          for (let i = 0, s = e.getRowCount(), n; i < s; ++i) (n = e.getRow(i)) && (t[`${i}`] = n);
          t.columnNames = e.getColumnNames(), i.deleteColumns(), i.setColumns(t);
        }
        return this.emit({
          type: "afterModify",
          detail: t,
          table: e
        }), e;
      }
    }
    return s.defaultOptions = {
      type: "Invert"
    }, e.registerType("Invert", s), s;
  }), i(t, "Data/Modifiers/RangeModifier.js", [t["Data/Modifiers/DataModifier.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
      merge: i
    } = t;
    class s extends e {
      constructor(e) {
        super(), this.options = i(s.defaultOptions, e);
      }
      modifyTable(e, t) {
        this.emit({
          type: "modify",
          detail: t,
          table: e
        });
        let i = [],
          {
            additive: s,
            ranges: n,
            strict: o
          } = this.options;
        if (n.length) {
          let t = e.modified,
            r = e.getColumns(),
            l = [];
          for (let a = 0, h = n.length, d, u; a < h; ++a) if (d = n[a], !o || typeof d.minValue == typeof d.maxValue) {
            a > 0 && !s && (t.deleteRows(), t.setRows(l), t.setOriginalRowIndexes(i, !0), r = t.getColumns(), l = [], i = []), u = r[d.column] || [];
            for (let n = 0, r = u.length, a, h, c; n < r; ++n) {
              switch (typeof (a = u[n])) {
                default:
                  continue;
                case "boolean":
                case "number":
                case "string":
              }
              (!o || typeof a == typeof d.minValue) && a >= d.minValue && a <= d.maxValue && (s ? (h = e.getRow(n), c = e.getOriginalRowIndex(n)) : (h = t.getRow(n), c = t.getOriginalRowIndex(n)), h && (l.push(h), i.push(c)));
            }
          }
          t.deleteRows(), t.setRows(l), t.setOriginalRowIndexes(i);
        }
        return this.emit({
          type: "afterModify",
          detail: t,
          table: e
        }), e;
      }
    }
    return s.defaultOptions = {
      type: "Range",
      ranges: []
    }, e.registerType("Range", s), s;
  }), i(t, "Data/Modifiers/SortModifier.js", [t["Data/Modifiers/DataModifier.js"], t["Data/DataTable.js"], t["Core/Utilities.js"]], function (e, t, i) {
    let {
      merge: s
    } = i;
    class n extends e {
      static ascending(e, t) {
        return (e || 0) < (t || 0) ? -1 : (e || 0) > (t || 0) ? 1 : 0;
      }
      static descending(e, t) {
        return (t || 0) < (e || 0) ? -1 : (t || 0) > (e || 0) ? 1 : 0;
      }
      constructor(e) {
        super(), this.options = s(n.defaultOptions, e);
      }
      getRowReferences(e) {
        let t = e.getRows(),
          i = [];
        for (let e = 0, s = t.length; e < s; ++e) i.push({
          index: e,
          row: t[e]
        });
        return i;
      }
      modifyCell(e, i, s, n, o) {
        let {
          orderByColumn: r,
          orderInColumn: l
        } = this.options;
        return i === r && (l ? (e.modified.setCell(i, s, n), e.modified.setColumn(l, this.modifyTable(new t({
          columns: e.getColumns([r, l])
        })).modified.getColumn(l))) : this.modifyTable(e, o)), e;
      }
      modifyColumns(e, i, s, n) {
        let {
            orderByColumn: o,
            orderInColumn: r
          } = this.options,
          l = Object.keys(i);
        return l.indexOf(o) > -1 && (r && i[l[0]].length ? (e.modified.setColumns(i, s), e.modified.setColumn(r, this.modifyTable(new t({
          columns: e.getColumns([o, r])
        })).modified.getColumn(r))) : this.modifyTable(e, n)), e;
      }
      modifyRows(e, i, s, n) {
        let {
          orderByColumn: o,
          orderInColumn: r
        } = this.options;
        return r && i.length ? (e.modified.setRows(i, s), e.modified.setColumn(r, this.modifyTable(new t({
          columns: e.getColumns([o, r])
        })).modified.getColumn(r))) : this.modifyTable(e, n), e;
      }
      modifyTable(e, t) {
        this.emit({
          type: "modify",
          detail: t,
          table: e
        });
        let i = e.getColumnNames(),
          s = e.getRowCount(),
          o = this.getRowReferences(e),
          {
            direction: r,
            orderByColumn: l,
            orderInColumn: a
          } = this.options,
          h = "asc" === r ? n.ascending : n.descending,
          d = i.indexOf(l),
          u = e.modified;
        if (-1 !== d && o.sort((e, t) => h(e.row[d], t.row[d])), a) {
          let e = [];
          for (let t = 0; t < s; ++t) e[o[t].index] = t;
          u.setColumns({
            [a]: e
          });
        } else {
          let e;
          let t = [],
            i = [];
          for (let n = 0; n < s; ++n) e = o[n], t.push(u.getOriginalRowIndex(e.index)), i.push(e.row);
          u.setRows(i, 0), u.setOriginalRowIndexes(t);
        }
        return this.emit({
          type: "afterModify",
          detail: t,
          table: e
        }), e;
      }
    }
    return n.defaultOptions = {
      type: "Sort",
      direction: "desc",
      orderByColumn: "y"
    }, e.registerType("Sort", n), n;
  }), i(t, "masters/datagrid.src.js", [t["Core/Renderer/HTML/AST.js"], t["Data/Connectors/DataConnector.js"], t["Data/Converters/DataConverter.js"], t["Data/DataCursor.js"], t["DataGrid/DataGrid.js"], t["Data/Modifiers/DataModifier.js"], t["Data/DataPool.js"], t["Data/DataTable.js"], t["DataGrid/Globals.js"]], function (e, t, i, s, n, o, r, l, a) {
    return a.AST = e, a.DataConnector = t, a.DataCursor = s, a.DataConverter = i, a.DataGrid = n, a.dataGrid = n.dataGrid, a.DataModifier = o, a.DataPool = r, a.DataTable = l, a.win.DataGrid || (a.win.DataGrid = a), a;
  }), t["masters/datagrid.src.js"]._modules = t, t["masters/datagrid.src.js"];
});