import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { UsersService } from '../services/users/users.service';
import { map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

export const adminGuard: CanActivateFn = (route, state) => {
  const usersService = inject(UsersService);
  const toastr = inject(ToastrService);

  if (!usersService.isLoggedIn) return false;
  return usersService.currentUser$.pipe(
    map(user => {
      if (!user) return false;
      if (user.userRoles.some(role => role.role.name === 'Appraiser Administrator' || role.role.name ==='Data Administrator') 
            && (user.currentUserRole.role.name === 'Appraiser Administrator' || user.currentUserRole.role.name ==='Data Administrator')) {
        return true;
      } else {
        toastr.error('You cannot enter this area.');
        return false;
      }
    })
  )
};
