import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-view-data',
    templateUrl: './view-data.component.html',
    styleUrls: ['./view-data.component.scss'],
    standalone: false
})

export class ViewDataComponent implements OnInit {

    constructor() {
        console.log("constructor not implemented.");
    }

    ngOnInit() {
        console.log("ngOnInit not implemented.");
    }

    
}


