import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { AppraisalGroup } from '../../models/appraisals/appraisal-group';
import { getList, getListHeaders } from '../pagination/listHelper';

@Injectable({
  providedIn: 'root'
})

export class AppraisalGroupsService {
    baseUrl = environment.apiUrl;
    appraisalGroupsCache = new Map();
    private appraisalGroupsSource = new BehaviorSubject<AppraisalGroup | null>(null);
    appraisalGroups$ = this.appraisalGroupsSource.asObservable();

  constructor(private http: HttpClient) { 
  }

  setAppraisalGroups(appraisalGroups: AppraisalGroup[]) {
    this.appraisalGroupsCache.set('appraisalGroups', appraisalGroups);
  }

  getAppraisalGroups() {
    const appraisalGroups = this.appraisalGroupsCache.get('appraisalGroups') as AppraisalGroup[];

    if(appraisalGroups != null && appraisalGroups.length != 0) return of(appraisalGroups);

    let params = getListHeaders();

    return getList<AppraisalGroup[]>(this.baseUrl + 'appraisal-groups/get-all', params, this.http).pipe(
      map(response => {
        this.setAppraisalGroups(response as AppraisalGroup[]);
        return response;
      })
    )
  }

  getAppraisalGroupByZone(treeProductId: number, treeSpeciesId: number, treeCondition: string, uomId: number | null, appraisalZoneId: number) {
    if(treeProductId == 0 || treeSpeciesId == 0 || uomId == 0 || appraisalZoneId == 0)
      throw new Error("Arguments for Appraisal Group missing.");
    
    let params = new HttpParams();
    params = params.append('treeProductId', treeProductId);
    params = params.append('treeSpeciesId', treeSpeciesId);    
    params = params.append('treeCondition', treeCondition);
    params = params.append('unitsOfMeasureId', uomId ?? '');
    params = params.append('appraisalZoneId', appraisalZoneId);

    return this.http.get<AppraisalGroup>(this.baseUrl + 'appraisal-groups', { observe: 'response', params }).pipe(
      map(response => {
        console.log(response);
        console.log("======================================================");
        return response.body;
      })
    );

  }

  getAppraisalGroupByAppraisalZoneId(appraisalZoneId: number) {
    if(appraisalZoneId == 0)
      throw new Error("Arguments for Appraisal Zone Id missing.");

    return this.http.get<AppraisalGroup[]>(this.baseUrl + 'appraisal-groups/appraisal-zone/' + appraisalZoneId).pipe(
      map(response => {
        console.log(response);
        console.log("======================================================");
        return response;
      })
    );

  }

  updateAppraisalGroup(appraisalGroup: AppraisalGroup) {
    
    return this.http.put(this.baseUrl + 'appraisal-groups', appraisalGroup).pipe(
      map(() => {
        // appraisalGroup = appraisalGroup;
        // this.setAppraisalGroup(appraisalGroup);
      })
    )
  }


  
}