!
/**
* Highcharts Dashboards v2.3.0 (2024-08-26)
*
* (c) 2009-2024 Highsoft AS
*
* License: www.highcharts.com/license
*/
function (t, e) {
  "object" == typeof module && module.exports ? (e.default = e, module.exports = t && t.document ? e(t) : e) : "function" == typeof define && define.amd ? define("dashboards/dashboards", function () {
    return e(t);
  }) : (t.Dashboards && t.Dashboards.error(16, !0), t.Dashboards = e(t));
}("undefined" != typeof window ? window : this, function (t) {
  "use strict";

  var e = {};
  function s(e, s, i, n) {
    !e.hasOwnProperty(s) && (e[s] = n.apply(null, i), t && "function" == typeof CustomEvent && t.dispatchEvent(new CustomEvent("DashboardsModuleLoaded", {
      detail: {
        path: s,
        module: e[s]
      }
    })));
  }
  return s(e, "Core/Globals.js", [], function () {
    var e, s;
    return (s = e || (e = {})).SVG_NS = "http://www.w3.org/2000/svg", s.product = "Highcharts", s.version = "2.3.0", s.win = void 0 !== t ? t : {}, s.doc = s.win.document, s.svg = s.doc && s.doc.createElementNS && !!s.doc.createElementNS(s.SVG_NS, "svg").createSVGRect, s.userAgent = s.win.navigator && s.win.navigator.userAgent || "", s.isChrome = s.win.chrome, s.isFirefox = -1 !== s.userAgent.indexOf("Firefox"), s.isMS = /(edge|msie|trident)/i.test(s.userAgent) && !s.win.opera, s.isSafari = !s.isChrome && -1 !== s.userAgent.indexOf("Safari"), s.isTouchDevice = /(Mobile|Android|Windows Phone)/.test(s.userAgent), s.isWebKit = -1 !== s.userAgent.indexOf("AppleWebKit"), s.deg2rad = 2 * Math.PI / 360, s.hasBidiBug = s.isFirefox && 4 > parseInt(s.userAgent.split("Firefox/")[1], 10), s.marginNames = ["plotTop", "marginRight", "marginBottom", "plotLeft"], s.noop = function () {}, s.supportsPassiveEvents = function () {
      let t = !1;
      if (!s.isMS) {
        let e = Object.defineProperty({}, "passive", {
          get: function () {
            t = !0;
          }
        });
        s.win.addEventListener && s.win.removeEventListener && (s.win.addEventListener("testPassive", s.noop, e), s.win.removeEventListener("testPassive", s.noop, e));
      }
      return t;
    }(), s.charts = [], s.composed = [], s.dateFormats = {}, s.seriesTypes = {}, s.symbolSizes = {}, s.chartCount = 0, e;
  }), s(e, "Core/Utilities.js", [e["Core/Globals.js"]], function (t) {
    let e;
    let {
      charts: s,
      doc: i,
      win: n
    } = t;
    function o(e, s, i, r) {
      let a = s ? "Highcharts error" : "Highcharts warning";
      32 === e && (e = `${a}: Deprecated member`);
      let l = u(e),
        h = l ? `${a} #${e}: www.highcharts.com/errors/${e}/` : e.toString();
      if (void 0 !== r) {
        let t = "";
        l && (h += "?"), O(r, function (e, s) {
          t += `
 - ${s}: ${e}`, l && (h += encodeURI(s) + "=" + encodeURI(e));
        }), h += t;
      }
      x(t, "displayError", {
        chart: i,
        code: e,
        message: h,
        params: r
      }, function () {
        if (s) throw Error(h);
        n.console && -1 === o.messages.indexOf(h) && console.warn(h);
      }), o.messages.push(h);
    }
    function r(t, e) {
      return parseInt(t, e || 10);
    }
    function a(t) {
      return "string" == typeof t;
    }
    function l(t) {
      let e = Object.prototype.toString.call(t);
      return "[object Array]" === e || "[object Array Iterator]" === e;
    }
    function h(t, e) {
      return !!t && "object" == typeof t && (!e || !l(t));
    }
    function c(t) {
      return h(t) && "number" == typeof t.nodeType;
    }
    function d(t) {
      let e = t && t.constructor;
      return !!(h(t, !0) && !c(t) && e && e.name && "Object" !== e.name);
    }
    function u(t) {
      return "number" == typeof t && !isNaN(t) && t < 1 / 0 && t > -1 / 0;
    }
    function p(t) {
      return null != t;
    }
    function m(t, e, s) {
      let i;
      let n = a(e) && !p(s),
        o = (e, s) => {
          p(e) ? t.setAttribute(s, e) : n ? (i = t.getAttribute(s)) || "class" !== s || (i = t.getAttribute(s + "Name")) : t.removeAttribute(s);
        };
      return a(e) ? o(s, e) : O(e, o), i;
    }
    function f(t) {
      return l(t) ? t : [t];
    }
    function g(t, e) {
      let s;
      for (s in t || (t = {}), e) t[s] = e[s];
      return t;
    }
    function y() {
      let t = arguments,
        e = t.length;
      for (let s = 0; s < e; s++) {
        let e = t[s];
        if (null != e) return e;
      }
    }
    function b(t, e) {
      g(t.style, e);
    }
    function C(t) {
      return Math.pow(10, Math.floor(Math.log(t) / Math.LN10));
    }
    function w(t, e) {
      return t > 1e14 ? t : parseFloat(t.toPrecision(e || 14));
    }
    (o || (o = {})).messages = [], Math.easeInOutSine = function (t) {
      return -.5 * (Math.cos(Math.PI * t) - 1);
    };
    let v = Array.prototype.find ? function (t, e) {
      return t.find(e);
    } : function (t, e) {
      let s;
      let i = t.length;
      for (s = 0; s < i; s++) if (e(t[s], s)) return t[s];
    };
    function O(t, e, s) {
      for (let i in t) Object.hasOwnProperty.call(t, i) && e.call(s || t[i], t[i], i, t);
    }
    function D(t, e, s) {
      function i(e, s) {
        let i = t.removeEventListener;
        i && i.call(t, e, s, !1);
      }
      function n(s) {
        let n, o;
        t.nodeName && (e ? (n = {})[e] = !0 : n = s, O(n, function (t, e) {
          if (s[e]) for (o = s[e].length; o--;) i(e, s[e][o].fn);
        }));
      }
      let o = "function" == typeof t && t.prototype || t;
      if (Object.hasOwnProperty.call(o, "hcEvents")) {
        let t = o.hcEvents;
        if (e) {
          let o = t[e] || [];
          s ? (t[e] = o.filter(function (t) {
            return s !== t.fn;
          }), i(e, s)) : (n(t), t[e] = []);
        } else n(t), delete o.hcEvents;
      }
    }
    function x(e, s, n, o) {
      if (n = n || {}, i.createEvent && (e.dispatchEvent || e.fireEvent && e !== t)) {
        let t = i.createEvent("Events");
        t.initEvent(s, !0, !0), n = g(t, n), e.dispatchEvent ? e.dispatchEvent(n) : e.fireEvent(s, n);
      } else if (e.hcEvents) {
        n.target || g(n, {
          preventDefault: function () {
            n.defaultPrevented = !0;
          },
          target: e,
          type: s
        });
        let t = [],
          i = e,
          o = !1;
        for (; i.hcEvents;) Object.hasOwnProperty.call(i, "hcEvents") && i.hcEvents[s] && (t.length && (o = !0), t.unshift.apply(t, i.hcEvents[s])), i = Object.getPrototypeOf(i);
        o && t.sort((t, e) => t.order - e.order), t.forEach(t => {
          !1 === t.fn.call(e, n) && n.preventDefault();
        });
      }
      o && !n.defaultPrevented && o.call(e, n);
    }
    O({
      map: "map",
      each: "forEach",
      grep: "filter",
      reduce: "reduce",
      some: "some"
    }, function (e, s) {
      t[s] = function (t) {
        return o(32, !1, void 0, {
          [`Highcharts.${s}`]: `use Array.${e}`
        }), Array.prototype[e].apply(t, [].slice.call(arguments, 1));
      };
    });
    let S = function () {
      let t = Math.random().toString(36).substring(2, 9) + "-",
        s = 0;
      return function () {
        return "highcharts-" + (e ? "" : t) + s++;
      };
    }();
    return n.jQuery && (n.jQuery.fn.highcharts = function () {
      let e = [].slice.call(arguments);
      if (this[0]) return e[0] ? (new t[a(e[0]) ? e.shift() : "Chart"](this[0], e[0], e[1]), this) : s[m(this[0], "data-highcharts-chart")];
    }), {
      addEvent: function (e, s, i, n = {}) {
        let o = "function" == typeof e && e.prototype || e;
        Object.hasOwnProperty.call(o, "hcEvents") || (o.hcEvents = {});
        let r = o.hcEvents;
        t.Point && e instanceof t.Point && e.series && e.series.chart && (e.series.chart.runTrackerClick = !0);
        let a = e.addEventListener;
        a && a.call(e, s, i, !!t.supportsPassiveEvents && {
          passive: void 0 === n.passive ? -1 !== s.indexOf("touch") : n.passive,
          capture: !1
        }), r[s] || (r[s] = []);
        let l = {
          fn: i,
          order: "number" == typeof n.order ? n.order : 1 / 0
        };
        return r[s].push(l), r[s].sort((t, e) => t.order - e.order), function () {
          D(e, s, i);
        };
      },
      arrayMax: function (t) {
        let e = t.length,
          s = t[0];
        for (; e--;) t[e] > s && (s = t[e]);
        return s;
      },
      arrayMin: function (t) {
        let e = t.length,
          s = t[0];
        for (; e--;) t[e] < s && (s = t[e]);
        return s;
      },
      attr: m,
      clamp: function (t, e, s) {
        return t > e ? t < s ? t : s : e;
      },
      clearTimeout: function (t) {
        p(t) && clearTimeout(t);
      },
      correctFloat: w,
      createElement: function (t, e, s, n, o) {
        let r = i.createElement(t);
        return e && g(r, e), o && b(r, {
          padding: "0",
          border: "none",
          margin: "0"
        }), s && b(r, s), n && n.appendChild(r), r;
      },
      crisp: (t, e = 0, s) => {
        let i = e % 2 / 2,
          n = s ? -1 : 1;
        return (Math.round(t * n - i) + i) * n;
      },
      css: b,
      defined: p,
      destroyObjectProperties: function (t, e, s) {
        O(t, function (i, n) {
          i !== e && i?.destroy && i.destroy(), (i?.destroy || !s) && delete t[n];
        });
      },
      diffObjects: function (t, e, s, i) {
        let n = {};
        return function t(e, n, o, r) {
          let a = s ? n : e;
          O(e, function (s, c) {
            if (!r && i && i.indexOf(c) > -1 && n[c]) {
              s = f(s), o[c] = [];
              for (let e = 0; e < Math.max(s.length, n[c].length); e++) n[c][e] && (void 0 === s[e] ? o[c][e] = n[c][e] : (o[c][e] = {}, t(s[e], n[c][e], o[c][e], r + 1)));
            } else h(s, !0) && !s.nodeType ? (o[c] = l(s) ? [] : {}, t(s, n[c] || {}, o[c], r + 1), 0 !== Object.keys(o[c]).length || "colorAxis" === c && 0 === r || delete o[c]) : (e[c] !== n[c] || c in e && !(c in n)) && "__proto__" !== c && "constructor" !== c && (o[c] = a[c]);
          });
        }(t, e, n, 0), n;
      },
      discardElement: function (t) {
        t && t.parentElement && t.parentElement.removeChild(t);
      },
      erase: function (t, e) {
        let s = t.length;
        for (; s--;) if (t[s] === e) {
          t.splice(s, 1);
          break;
        }
      },
      error: o,
      extend: g,
      extendClass: function (t, e) {
        let s = function () {};
        return s.prototype = new t(), g(s.prototype, e), s;
      },
      find: v,
      fireEvent: x,
      getClosestDistance: function (t, e) {
        let s, i, n, o;
        let r = !e;
        return t.forEach(t => {
          if (t.length > 1) for (o = i = t.length - 1; o > 0; o--) (n = t[o] - t[o - 1]) < 0 && !r ? (e?.(), e = void 0) : n && (void 0 === s || n < s) && (s = n);
        }), s;
      },
      getMagnitude: C,
      getNestedProperty: function (t, e) {
        let s = t.split(".");
        for (; s.length && p(e);) {
          let t = s.shift();
          if (void 0 === t || "__proto__" === t) return;
          if ("this" === t) {
            let t;
            return h(e) && (t = e["@this"]), t ?? e;
          }
          let i = e[t];
          if (!p(i) || "function" == typeof i || "number" == typeof i.nodeType || i === n) return;
          e = i;
        }
        return e;
      },
      getStyle: function t(e, s, i) {
        let o;
        if ("width" === s) {
          let s = Math.min(e.offsetWidth, e.scrollWidth),
            i = e.getBoundingClientRect && e.getBoundingClientRect().width;
          return i < s && i >= s - 1 && (s = Math.floor(i)), Math.max(0, s - (t(e, "padding-left", !0) || 0) - (t(e, "padding-right", !0) || 0));
        }
        if ("height" === s) return Math.max(0, Math.min(e.offsetHeight, e.scrollHeight) - (t(e, "padding-top", !0) || 0) - (t(e, "padding-bottom", !0) || 0));
        let a = n.getComputedStyle(e, void 0);
        return a && (o = a.getPropertyValue(s), y(i, "opacity" !== s) && (o = r(o))), o;
      },
      inArray: function (t, e, s) {
        return o(32, !1, void 0, {
          "Highcharts.inArray": "use Array.indexOf"
        }), e.indexOf(t, s);
      },
      insertItem: function (t, e) {
        let s;
        let i = t.options.index,
          n = e.length;
        for (s = t.options.isInternal ? n : 0; s < n + 1; s++) if (!e[s] || u(i) && i < y(e[s].options.index, e[s]._i) || e[s].options.isInternal) {
          e.splice(s, 0, t);
          break;
        }
        return s;
      },
      isArray: l,
      isClass: d,
      isDOMElement: c,
      isFunction: function (t) {
        return "function" == typeof t;
      },
      isNumber: u,
      isObject: h,
      isString: a,
      keys: function (t) {
        return o(32, !1, void 0, {
          "Highcharts.keys": "use Object.keys"
        }), Object.keys(t);
      },
      merge: function () {
        let t,
          e = arguments,
          s = {},
          i = function (t, e) {
            return "object" != typeof t && (t = {}), O(e, function (s, n) {
              "__proto__" !== n && "constructor" !== n && (!h(s, !0) || d(s) || c(s) ? t[n] = e[n] : t[n] = i(t[n] || {}, s));
            }), t;
          };
        !0 === e[0] && (s = e[1], e = Array.prototype.slice.call(e, 2));
        let n = e.length;
        for (t = 0; t < n; t++) s = i(s, e[t]);
        return s;
      },
      normalizeTickInterval: function (t, e, s, i, n) {
        let o,
          r = t;
        s = y(s, C(t));
        let a = t / s;
        for (!e && (e = n ? [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10] : [1, 2, 2.5, 5, 10], !1 === i && (1 === s ? e = e.filter(function (t) {
          return t % 1 == 0;
        }) : s <= .1 && (e = [1 / s]))), o = 0; o < e.length && (r = e[o], (!n || !(r * s >= t)) && (n || !(a <= (e[o] + (e[o + 1] || e[o])) / 2))); o++);
        return w(r * s, -Math.round(Math.log(.001) / Math.LN10));
      },
      objectEach: O,
      offset: function (t) {
        let e = i.documentElement,
          s = t.parentElement || t.parentNode ? t.getBoundingClientRect() : {
            top: 0,
            left: 0,
            width: 0,
            height: 0
          };
        return {
          top: s.top + (n.pageYOffset || e.scrollTop) - (e.clientTop || 0),
          left: s.left + (n.pageXOffset || e.scrollLeft) - (e.clientLeft || 0),
          width: s.width,
          height: s.height
        };
      },
      pad: function (t, e, s) {
        return Array((e || 2) + 1 - String(t).replace("-", "").length).join(s || "0") + t;
      },
      pick: y,
      pInt: r,
      pushUnique: function (t, e) {
        return 0 > t.indexOf(e) && !!t.push(e);
      },
      relativeLength: function (t, e, s) {
        return /%$/.test(t) ? e * parseFloat(t) / 100 + (s || 0) : parseFloat(t);
      },
      removeEvent: D,
      replaceNested: function (t, ...e) {
        let s, i;
        do for (i of (s = t, e)) t = t.replace(i[0], i[1]); while (t !== s);
        return t;
      },
      splat: f,
      stableSort: function (t, e) {
        let s, i;
        let n = t.length;
        for (i = 0; i < n; i++) t[i].safeI = i;
        for (t.sort(function (t, i) {
          return 0 === (s = e(t, i)) ? t.safeI - i.safeI : s;
        }), i = 0; i < n; i++) delete t[i].safeI;
      },
      syncTimeout: function (t, e, s) {
        return e > 0 ? setTimeout(t, e, s) : (t.call(0, s), -1);
      },
      timeUnits: {
        millisecond: 1,
        second: 1e3,
        minute: 6e4,
        hour: 36e5,
        day: 864e5,
        week: 6048e5,
        month: 24192e5,
        year: 314496e5
      },
      uniqueKey: S,
      useSerialIds: function (t) {
        return e = y(t, e);
      },
      wrap: function (t, e, s) {
        let i = t[e];
        t[e] = function () {
          let t = arguments,
            e = this;
          return s.apply(this, [function () {
            return i.apply(e, arguments.length ? arguments : t);
          }].concat([].slice.call(arguments)));
        };
      }
    };
  }), s(e, "Core/Renderer/HTML/AST.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        SVG_NS: s,
        win: i
      } = t,
      {
        attr: n,
        createElement: o,
        css: r,
        error: a,
        isFunction: l,
        isString: h,
        objectEach: c,
        splat: d
      } = e,
      {
        trustedTypes: u
      } = i,
      p = u && l(u.createPolicy) && u.createPolicy("highcharts", {
        createHTML: t => t
      }),
      m = p ? p.createHTML("") : "",
      f = function () {
        try {
          return !!new DOMParser().parseFromString(m, "text/html");
        } catch (t) {
          return !1;
        }
      }();
    class g {
      static filterUserAttributes(t) {
        return c(t, (e, s) => {
          let i = !0;
          -1 === g.allowedAttributes.indexOf(s) && (i = !1), -1 !== ["background", "dynsrc", "href", "lowsrc", "src"].indexOf(s) && (i = h(e) && g.allowedReferences.some(t => 0 === e.indexOf(t))), i || (a(33, !1, void 0, {
            "Invalid attribute in config": `${s}`
          }), delete t[s]), h(e) && t[s] && (t[s] = e.replace(/</g, "&lt;"));
        }), t;
      }
      static parseStyle(t) {
        return t.split(";").reduce((t, e) => {
          let s = e.split(":").map(t => t.trim()),
            i = s.shift();
          return i && s.length && (t[i.replace(/-([a-z])/g, t => t[1].toUpperCase())] = s.join(":")), t;
        }, {});
      }
      static setElementHTML(t, e) {
        t.innerHTML = g.emptyHTML, e && new g(e).addToDOM(t);
      }
      constructor(t) {
        this.nodes = "string" == typeof t ? this.parseMarkup(t) : t;
      }
      addToDOM(e) {
        return function e(i, o) {
          let l;
          return d(i).forEach(function (i) {
            let h;
            let d = i.tagName,
              u = i.textContent ? t.doc.createTextNode(i.textContent) : void 0,
              p = g.bypassHTMLFiltering;
            if (d) {
              if ("#text" === d) h = u;else if (-1 !== g.allowedTags.indexOf(d) || p) {
                let a = "svg" === d ? s : o.namespaceURI || s,
                  l = t.doc.createElementNS(a, d),
                  m = i.attributes || {};
                c(i, function (t, e) {
                  "tagName" !== e && "attributes" !== e && "children" !== e && "style" !== e && "textContent" !== e && (m[e] = t);
                }), n(l, p ? m : g.filterUserAttributes(m)), i.style && r(l, i.style), u && l.appendChild(u), e(i.children || [], l), h = l;
              } else a(33, !1, void 0, {
                "Invalid tagName in config": d
              });
            }
            h && o.appendChild(h), l = h;
          }), l;
        }(this.nodes, e);
      }
      parseMarkup(t) {
        let e;
        let s = [];
        if (t = t.trim().replace(/ style=(["'])/g, " data-style=$1"), f) e = new DOMParser().parseFromString(p ? p.createHTML(t) : t, "text/html");else {
          let s = o("div");
          s.innerHTML = t, e = {
            body: s
          };
        }
        let i = (t, e) => {
          let s = t.nodeName.toLowerCase(),
            n = {
              tagName: s
            };
          "#text" === s && (n.textContent = t.textContent || "");
          let o = t.attributes;
          if (o) {
            let t = {};
            [].forEach.call(o, e => {
              "data-style" === e.name ? n.style = g.parseStyle(e.value) : t[e.name] = e.value;
            }), n.attributes = t;
          }
          if (t.childNodes.length) {
            let e = [];
            [].forEach.call(t.childNodes, t => {
              i(t, e);
            }), e.length && (n.children = e);
          }
          e.push(n);
        };
        return [].forEach.call(e.body.childNodes, t => i(t, s)), s;
      }
    }
    return g.allowedAttributes = ["alt", "aria-controls", "aria-describedby", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-label", "aria-labelledby", "aria-live", "aria-pressed", "aria-readonly", "aria-roledescription", "aria-selected", "class", "clip-path", "color", "colspan", "cx", "cy", "d", "dx", "dy", "disabled", "fill", "filterUnits", "flood-color", "flood-opacity", "height", "href", "id", "in", "in2", "markerHeight", "markerWidth", "offset", "opacity", "operator", "orient", "padding", "paddingLeft", "paddingRight", "patternUnits", "r", "radius", "refX", "refY", "role", "scope", "slope", "src", "startOffset", "stdDeviation", "stroke", "stroke-linecap", "stroke-width", "style", "tableValues", "result", "rowspan", "summary", "target", "tabindex", "text-align", "text-anchor", "textAnchor", "textLength", "title", "type", "valign", "width", "x", "x1", "x2", "xlink:href", "y", "y1", "y2", "zIndex"], g.allowedReferences = ["https://", "http://", "mailto:", "/", "../", "./", "#"], g.allowedTags = ["a", "abbr", "b", "br", "button", "caption", "circle", "clipPath", "code", "dd", "defs", "div", "dl", "dt", "em", "feComponentTransfer", "feComposite", "feDropShadow", "feFlood", "feFuncA", "feFuncB", "feFuncG", "feFuncR", "feGaussianBlur", "feMorphology", "feOffset", "feMerge", "feMergeNode", "filter", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "li", "linearGradient", "marker", "ol", "p", "path", "pattern", "pre", "rect", "small", "span", "stop", "strong", "style", "sub", "sup", "svg", "table", "text", "textPath", "thead", "title", "tbody", "tspan", "td", "th", "tr", "u", "ul", "#text"], g.emptyHTML = m, g.bypassHTMLFiltering = !1, g;
  }), s(e, "Dashboards/Components/ComponentRegistry.js", [], function () {
    var t, e;
    return (e = t || (t = {})).types = {}, e.registerComponent = function (t, s) {
      return !!t && !e.types[t] && !!(e.types[t] = s);
    }, t;
  }), s(e, "Dashboards/Globals.js", [], function () {
    var e, s;
    return (s = e || (e = {})).classNamePrefix = "highcharts-dashboards-", s.classNames = {
      layout: s.classNamePrefix + "layout",
      cell: s.classNamePrefix + "cell",
      cellHover: s.classNamePrefix + "cell-state-hover",
      cellActive: s.classNamePrefix + "cell-state-active",
      cellLoading: s.classNamePrefix + "cell-state-loading",
      row: s.classNamePrefix + "row",
      layoutsWrapper: s.classNamePrefix + "layouts-wrapper",
      boardContainer: s.classNamePrefix + "wrapper"
    }, s.guiElementType = {
      row: "row",
      cell: "cell",
      layout: "layout"
    }, s.boards = [], s.win = t, s.doc = document, s.noop = function () {}, s.isMS = /(edge|msie|trident)/i.test(s.win.navigator && s.win.navigator.userAgent || "") && !s.win.opera, s.supportsPassiveEvents = function () {
      let t = !1;
      if (!s.isMS) {
        let e = Object.defineProperty({}, "passive", {
          get: function () {
            t = !0;
          }
        });
        s.win.addEventListener && s.win.removeEventListener && (s.win.addEventListener("testPassive", s.noop, e), s.win.removeEventListener("testPassive", s.noop, e));
      }
      return t;
    }(), e;
  }), s(e, "Dashboards/EditMode/EditGlobals.js", [e["Dashboards/Globals.js"]], function (t) {
    let e = t.classNamePrefix + "edit-";
    return {
      classNames: {
        resizeSnap: e + "resize-snap",
        resizeSnapX: e + "resize-snap-x",
        resizeSnapY: e + "resize-snap-y",
        separator: e + "separator",
        contextMenuBtn: e + "context-menu-btn",
        contextMenuBtnText: e + "context-menu-btn-text",
        contextMenu: e + "context-menu",
        contextMenuItem: e + "context-menu-item",
        editModeEnabled: e + "enabled",
        editToolbar: e + "toolbar",
        editToolbarCellOutline: e + "toolbar-cell-outline",
        editToolbarRowOutline: e + "toolbar-row-outline",
        editToolbarItem: e + "toolbar-item",
        editToolbarRow: e + "toolbar-row",
        editToolbarCell: e + "toolbar-cell",
        editSidebar: e + "sidebar",
        editSidebarShow: e + "sidebar-show",
        editSidebarHide: e + "sidebar-hide",
        editSidebarTitle: e + "sidebar-title",
        editSidebarMenuItem: e + "sidebar-item",
        rowContextHighlight: e + "row-context-highlight",
        cellEditHighlight: e + "cell-highlight",
        dashboardCellEditHighlightActive: e + "cell-highlight-active",
        dragMock: e + "drag-mock",
        dropPointer: e + "drop-pointer",
        contextDetectionPointer: e + "ctx-detection-pointer",
        resizePointer: e + "resize-pointer",
        currentEditedElement: e + "unmask",
        maskElement: e + "mask",
        menuItem: e + "menu-item",
        menu: e + "menu",
        menuVerticalSeparator: e + "menu-vertical-separator",
        menuHorizontalSeparator: e + "menu-horizontal-separator",
        menuDestroy: e + "menu-destroy",
        editSidebarWrapper: e + "sidebar-wrapper",
        customSelect: e + "custom-select",
        customSelectButton: e + "custom-option-button",
        toggleContainer: e + "toggle-container",
        toggleWrapper: e + "toggle-wrapper",
        toggleSlider: e + "toggle-slider",
        toggleWrapperColored: e + "toggle-wrapper-colored",
        toggleLabels: e + "toggle-labels",
        button: e + "button",
        sidebarNavButton: e + "sidebar-button-nav",
        labelText: e + "label-text",
        editSidebarTabBtn: e + "sidebar-tab-btn",
        editToolsBtn: e + "tools-btn",
        editTools: e + "tools",
        editGridItems: e + "grid-items",
        confirmationPopup: e + "confirmation-popup",
        popupButtonContainer: e + "confirmation-popup-button-container",
        popupContentContainer: e + "confirmation-popup-content",
        popupCancelBtn: e + "confirmation-popup-cancel-btn",
        popupConfirmBtn: e + "confirmation-popup-confirm-btn",
        popupCloseButton: e + "popup-close",
        editOverlay: e + "overlay",
        editOverlayActive: e + "overlay-active",
        resizerMenuBtnActive: e + "resizer-menu-btn-active",
        sidebarCloseButton: e + "close-btn",
        editSidebarTabBtnWrapper: e + "tabs-buttons-wrapper",
        editSidebarRight: e + "sidebar-right",
        editSidebarRightShow: e + "sidebar-right-show",
        viewFullscreen: e + "view-fullscreen",
        accordionMenu: e + "accordion-menu",
        accordionContainer: e + "accordion",
        accordionHeader: e + "accordion-header",
        accordionHeaderBtn: e + "accordion-header-btn",
        accordionHeaderIcon: e + "accordion-header-icon",
        accordionContent: e + "accordion-content",
        accordionNestedWrapper: e + "accordion-nested",
        accordionMenuButtonsContainer: e + "accordion-menu-buttons-container",
        accordionMenuButton: e + "accordion-menu-button",
        hiddenElement: e + "hidden-element",
        collapsableContentHeader: e + "collapsable-content-header",
        standaloneElement: e + "standalone-element",
        collapsedElement: e + "collapsed-element",
        dropdown: e + "dropdown",
        dropdownContent: e + "dropdown-content",
        dropdownButton: e + "dropdown-button",
        dropdownButtonContent: e + "dropdown-button-content",
        dropdownIcon: e + "pointer",
        icon: e + "icon"
      },
      lang: {
        accessibility: {
          contextMenu: {
            button: "Context menu"
          },
          editMode: {
            editMode: "Edit mode toggle button"
          }
        },
        addComponent: "Add component",
        cancelButton: "Cancel",
        caption: "Caption",
        chartClassName: "Chart class name",
        chartConfig: "Chart configuration",
        chartID: "Chart ID",
        chartOptions: "Chart options",
        chartType: "Chart type",
        connectorName: "Connector name",
        confirmButton: "Confirm",
        confirmDestroyCell: "Do you really want to destroy the cell?",
        confirmDestroyRow: "Do you really want to destroy the row?",
        confirmDiscardChanges: "Do you really want to discard the changes?",
        dataLabels: "Data labels",
        editMode: "Edit mode",
        errorMessage: "Something went wrong",
        exitFullscreen: "Exit full screen",
        htmlInput: "HTML",
        id: "Id",
        off: "off",
        on: "on",
        pointFormat: "Point format",
        settings: "Settings",
        style: "Styles",
        title: "Title",
        viewFullscreen: "View in full screen",
        sidebar: {
          HTML: "HTML",
          row: "Row",
          Highcharts: "Highcharts",
          DataGrid: "DataGrid",
          KPI: "KPI"
        }
      }
    };
  }), s(e, "Dashboards/Layout/GUIElement.js", [e["Dashboards/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      addEvent: s,
      createElement: i,
      uniqueKey: n,
      objectEach: o,
      error: r
    } = e;
    return class {
      static getOffsets(t, e) {
        let s = {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        };
        if (!t.container) return s;
        let i = t.container.getBoundingClientRect(),
          n = e ? e.getBoundingClientRect() : {
            left: 0,
            top: 0
          };
        return s.left = i.left - n.left, s.top = i.top - n.top, s.right = i.right - n.left, s.bottom = i.bottom - n.top, s;
      }
      static getDimFromOffsets(t) {
        return {
          width: t.right - t.left,
          height: t.bottom - t.top
        };
      }
      static getElementId(e) {
        return t.classNamePrefix + e + "-" + n().slice(11);
      }
      static getPercentageWidth(t) {
        let e;
        let s = /^(\d{1})[\-\/\.](\d{1,2})$/;
        if (s.test(t)) {
          let i = t.match(s) || [];
          e = ((e = 100 * +i[1] / +i[2]) <= 100 ? e : 100) + "%";
        } else if (-1 !== t.indexOf("%")) {
          let s = parseFloat(t);
          e = (s <= 100 ? s >= 0 ? s : 0 : 100) + "%";
        }
        return e;
      }
      getElementContainer(t) {
        let e;
        let n = this;
        if (t.render) t.attribs && !t.attribs.id && delete t.attribs.id;else if ("string" == typeof t.elementId) {
          let e = document.getElementById(t.elementId);
          e ? n.container = e : r("Element " + t.elementId + " does not exist");
        }
        return t.element instanceof HTMLElement ? e = t.element : e = i("div", t.attribs || {}, t.style || {}, t.parentContainer), n.removeBindedEventFn = s(e, "bindedGUIElement", function (t) {
          t.guiElement = n, t.stopImmediatePropagation();
        }), e;
      }
      destroy() {
        let t = this;
        t.removeBindedEventFn && t.removeBindedEventFn(), t.container && t.container.parentNode && t.container.parentNode.removeChild(t.container), o(t, function (e, s) {
          delete t[s];
        });
      }
      getType() {
        return this.type;
      }
      changeVisibility(t = !0, e) {
        let s = this.isVisible && !t || !this.isVisible && t;
        this.container && s && (this.container.style.display = t ? e || "block" : "none", this.isVisible = t);
      }
      hide() {
        this.changeVisibility(!1);
      }
      show() {
        this.changeVisibility();
      }
    };
  }), s(e, "Dashboards/Layout/CellHTML.js", [e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/Globals.js"], e["Dashboards/Layout/GUIElement.js"]], function (t, e, s) {
    return class extends s {
      constructor(t) {
        super(), this.type = "cell-html", this.options = t, this.id = t.id, this.container = t.container, this.mountedComponent = t.mountedComponent;
      }
      destroy() {
        this.mountedComponent?.destroy(), super.destroy();
      }
      setHighlight() {
        this.container.classList.toggle(t.classNames.cellEditHighlight), this.mountedComponent?.board.container.classList.toggle(t.classNames.dashboardCellEditHighlightActive);
      }
      setActiveState() {
        this.container && this.container.classList.add(e.classNames.cellActive);
      }
    };
  }), s(e, "Dashboards/Actions/Bindings.js", [e["Dashboards/Components/ComponentRegistry.js"], e["Dashboards/Layout/CellHTML.js"], e["Dashboards/Globals.js"], e["Core/Utilities.js"]], function (t, e, s, i) {
    var n;
    let {
      addEvent: o,
      fireEvent: r
    } = i;
    return function (i) {
      function n(t, e) {
        let s;
        "string" == typeof t && document.querySelectorAll("#" + t).length > 1 && console.warn(`Multiple cells have identical ID %c${t}%c, potentially leading to unexpected behavior. 
Ensure that each cell has a unique ID on the page.`, "font-weight: bold", "");
        let i = e ? e.querySelector("#" + t) : document.getElementById(t);
        return null !== i && r(i, "bindedGUIElement", {}, function (t) {
          s = t.guiElement;
        }), s;
      }
      async function a(n, a, l) {
        let h = n.states,
          c = n.events,
          d = n.renderTo || n.cell;
        if (!d) {
          console.error("The%c renderTo%c option is required to render the component.", "font-weight: bold", "");
          return;
        }
        if (a.mountedComponents.filter(t => (t.options.renderTo || t.options.cell) === d).length > 0) {
          console.error(`A component has already been declared in the cell %c${d}%c use a different cell.`, "font-weight: bold", "");
          return;
        }
        l = l || i.getCell(d, a.container);
        let u = l?.container || document.querySelector("#" + d);
        if (!u || !n.type) {
          console.error(`The component is unable to find the HTML cell element %c${d}%c to render the content.`, "font-weight: bold", "");
          return;
        }
        let p = t.types[n.type];
        !p && (console.error(`The component's type %c${n.type}%c does not exist.`, "font-weight: bold", ""), l && (p = t.types.HTML, n.title = {
          text: a.editMode?.lang.errorMessage || "Something went wrong",
          className: s.classNamePrefix + "component-title-error " + s.classNamePrefix + "component-title"
        }));
        let m = new p(l, n, a),
          f = m.load().catch(t => {
            console.error(t), m.update({
              connector: {
                id: ""
              },
              title: {
                text: a.editMode?.lang.errorMessage || "Something went wrong",
                className: s.classNamePrefix + "component-title-error " + s.classNamePrefix + "component-title"
              }
            });
          });
        return l && (m.setCell(l), l.mountedComponent = m), a.mountedComponents.push({
          options: n,
          component: m,
          cell: l || new e({
            id: d,
            container: u,
            mountedComponent: m
          })
        }), l && h?.active?.enabled && h?.active?.isActive && (l.setActiveState(), m.isActive = !0), r(m, "mount"), o(u, "click", () => {
          c && c.click && c.click.call(m), l && m && u && h?.active?.enabled && (l.setActiveState(), m.isActive = !0);
        }), h?.hover?.enabled && u.classList.add(s.classNames.cellHover), r(m, "afterLoad"), f;
      }
      i.addComponent = a, i.componentFromJSON = function (e) {
        let s = t.types[e.$class];
        if (!s) return;
        let n = i.getCell(e.options.renderTo || "");
        if (!n) return;
        let o = s.fromJSON(e, n);
        return o && o.render(), o;
      }, i.getCell = function (t, e) {
        let s = n(t, e);
        if (s && "cell" === s.getType()) return s;
      }, i.getRow = function (t, e) {
        let s = n(t, e);
        if (s && "row" === s.getType()) return s;
      }, i.getLayout = function (t, e) {
        let s = n(t, e);
        if (s && "layout" === s.getType()) return s;
      };
    }(n || (n = {})), n;
  }), s(e, "Dashboards/Layout/Cell.js", [e["Dashboards/Actions/Bindings.js"], e["Dashboards/EditMode/EditGlobals.js"], e["Dashboards/Globals.js"], e["Dashboards/Layout/GUIElement.js"], e["Core/Utilities.js"]], function (t, e, s, i, n) {
    let {
        componentFromJSON: o
      } = t,
      {
        merge: r,
        fireEvent: a
      } = n;
    class l extends i {
      static fromJSON(t, e) {
        if (e) {
          let s = t.options,
            i = s.containerId;
          return e.layout.copyId && (i = i + "_" + e.layout.copyId), new l(e, {
            id: i,
            parentContainerId: e.container && e.container.id || s.parentContainerId,
            mountedComponentJSON: s.mountedComponentJSON,
            style: s.style,
            layoutJSON: s.layoutJSON,
            width: s.width,
            height: s.height
          });
        }
      }
      constructor(t, e, i) {
        let n;
        super(), this.type = s.guiElementType.cell, this.id = e.id, this.options = e, this.row = t, this.isVisible = !0;
        let o = document.getElementById(e.parentContainerId || "") || t.container,
          a = t.layout.options || {},
          l = t.options || {},
          h = a.cellClassName || "";
        if (e.height && (n = "number" == typeof e.height ? e.height + "px" : e.height), this.container = this.getElementContainer({
          render: t.layout.board.guiEnabled,
          parentContainer: o,
          attribs: {
            id: e.id,
            className: s.classNames.cell + " " + h
          },
          element: i,
          elementId: e.id,
          style: r(a.style, l.style, e.style, {
            height: n
          })
        }), this.options.mountedComponentJSON && this.mountComponentFromJSON(this.options.mountedComponentJSON), this.options.layout && this.setNestedLayout(), this.options.layoutJSON) {
          let t = this.row.layout,
            e = t.board,
            s = t.constructor.fromJSON;
          this.nestedLayout = s(r(this.options.layoutJSON, {
            parentContainerId: this.options.id
          }), e, this);
        }
      }
      setNestedLayout() {
        let t = this.row.layout.board,
          e = this.row.layout.constructor,
          s = t.options.gui;
        this.nestedLayout = new e(t, r({}, s && s.layoutOptions, this.options.layout, {
          parentContainerId: this.options.id
        }), this);
      }
      mountComponentFromJSON(t) {
        this.id !== t.options.parentElement && (t.options.parentElement = this.id);
        let e = o(t);
        return !!e && (this.mountedComponent = e, !0);
      }
      destroy() {
        let {
          row: t
        } = this;
        this.mountedComponent?.destroy(), this.nestedLayout?.destroy(), t.unmountCell(this);
        let e = t.cells?.length === 0;
        super.destroy(), e && t.destroy();
      }
      toJSON() {
        let t = (this.row.container || {}).id || "";
        return {
          $class: "Dashboards.Layout.Cell",
          options: {
            containerId: this.container.id,
            parentContainerId: t,
            width: this.options.width,
            height: this.options.height,
            mountedComponentJSON: this.mountedComponent && this.mountedComponent.toJSON(),
            style: this.options.style,
            layoutJSON: this.nestedLayout && this.nestedLayout.toJSON()
          }
        };
      }
      getOptions() {
        return this.options;
      }
      changeVisibility(t = !0) {
        super.changeVisibility(t);
        let e = this,
          s = e.row;
        e.row.getVisibleCells().length ? e.isVisible && !s.isVisible && e.row.show() : e.row.hide(), setTimeout(() => {
          a(s, "cellChange", {
            row: s,
            cell: e
          });
        }, 0);
      }
      getParentCell(t) {
        let e;
        if (t <= this.row.layout.level) {
          if (this.row.layout.level === t) return this;
          if (this.row.layout.level - 1 >= 0 && (e = this.row.layout.parentCell)) return e.getParentCell(t);
        }
      }
      getOverlappingLevels(t, e, s) {
        let n = this.row.layout.parentCell,
          o = [this.row.layout.level];
        if (n) {
          let r = s || i.getOffsets(this)[t],
            a = i.getOffsets(n)[t];
          Math.abs(r - a) < e && (o = [...o, ...n.getOverlappingLevels(t, e, a)]);
        }
        return o;
      }
      setSize(t, e) {
        let s = this.row.layout.board.editMode;
        if (this.container) {
          if (t) {
            if ("auto" === t && "1 1 0%" !== this.container.style.flex) this.container.style.flex = "1 1 0%";else {
              let e = this.convertWidthToValue(t);
              e && this.container.style.flex !== "0 0 " + e && (this.container.style.flex = "0 0 " + e), this.options.width = e;
            }
          }
          e && (this.options.height = this.container.style.height = e + "px"), s && (s.hideContextPointer(), s.cellToolbar && s.cellToolbar.isVisible && (s.cellToolbar.cell === this ? s.cellToolbar.showToolbar(this) : s.cellToolbar.hide())), a(this.row.layout.board, "cellResize", {
            cell: this
          }), a(this.row, "cellChange", {
            cell: this,
            row: this.row
          });
        }
      }
      setHighlight(t) {
        let s = this.row.layout.board.editMode;
        if (this.container && s) {
          let s = this.container,
            i = s.classList.contains(e.classNames.cellEditHighlight);
          t || i ? t && i && (s.classList.remove(e.classNames.cellEditHighlight), this.row.layout.board.container.classList.remove(e.classNames.dashboardCellEditHighlightActive), this.isHighlighted = !1) : (s.classList.add(e.classNames.cellEditHighlight), this.row.layout.board.container.classList.add(e.classNames.dashboardCellEditHighlightActive), this.isHighlighted = !0);
        }
      }
      setActiveState() {
        this.row.layout.board.mountedComponents.forEach(t => {
          t.cell.container && t.cell.container.classList.remove(s.classNames.cellActive), t.component.isActive = !1;
        }), this.container && this.container.classList.add(s.classNames.cellActive);
      }
      setLoadingState(t = !0) {
        this.container?.classList?.toggle(s.classNames.cellLoading, t);
      }
      convertWidthToValue(t) {
        return "number" == typeof t ? t + "px" : /px/.test(t) ? t : i.getPercentageWidth(t) || "";
      }
    }
    return l;
  }), s(e, "Dashboards/CallbackRegistry.js", [], function () {
    return class {
      constructor() {
        this.registry = {};
      }
      addCallback(t, e) {
        this.registry[t] = e;
      }
      getCallback(t) {
        return this.registry[t];
      }
      toJSON() {
        let t = {};
        return Object.keys(this.registry).forEach(e => {
          let {
            func: s,
            type: i
          } = this.getCallback(e);
          t[e] = {
            func: s.toString(),
            type: i
          };
        }), t;
      }
    };
  }), s(e, "Dashboards/Components/ConnectorHandler.js", [e["Dashboards/Layout/Cell.js"], e["Dashboards/Globals.js"]], function (t, e) {
    return class {
      constructor(t, e) {
        this.tableEvents = [], this.component = t, this.options = e;
      }
      async initConnector() {
        let e = this.component,
          s = this.options.id,
          i = this.component.board.dataPool;
        if (s && (this.connectorId !== s || i.isNewConnector(s))) {
          e.cell instanceof t && e.cell.setLoadingState();
          let n = await i.getConnector(s);
          this.setConnector(n);
        }
        return e;
      }
      setConnector(t) {
        for (; this.tableEvents.length;) {
          let t = this.tableEvents.pop();
          "function" == typeof t && t();
        }
        return this.connector = t, t && (this.clearTableListeners(), this.setupTableListeners(t.table), t.table.on("setModifier", () => this.clearTableListeners()), t.table.on("afterSetModifier", e => {
          "afterSetModifier" === e.type && e.modified && (this.setupTableListeners(e.modified), this.component.emit({
            type: "tableChanged",
            connector: t
          }));
        }), t.table && (this.presentationModifier ? this.presentationTable = this.presentationModifier.modifyTable(t.table.modified.clone()).modified : this.presentationTable = t.table)), this.component;
      }
      setupTableListeners(t) {
        let s = this.connector;
        s && t && ["afterDeleteRows", "afterSetCell", "afterSetColumns", "afterSetRows"].forEach(i => {
          this.tableEvents.push(t.on(i, t => {
            clearTimeout(this.tableEventTimeout), this.tableEventTimeout = e.win.setTimeout(() => {
              this.component.emit({
                ...t,
                type: "tableChanged",
                targetConnector: s
              }), this.tableEventTimeout = void 0;
            });
          }));
        });
      }
      clearTableListeners() {
        let t = this.connector,
          s = this.tableEvents;
        this.destroy(), t && s.push(t.table.on("afterSetModifier", s => {
          "afterSetModifier" === s.type && (clearTimeout(this.tableEventTimeout), this.tableEventTimeout = e.win.setTimeout(() => {
            t.emit({
              ...s,
              type: "tableChanged",
              targetConnector: t
            }), this.tableEventTimeout = void 0;
          }));
        }));
      }
      updateOptions(t) {
        this.options = t;
      }
      destroy() {
        this.tableEvents.forEach(t => t()), this.tableEvents.length = 0;
      }
    };
  }), s(e, "Dashboards/Components/EditableOptions.js", [], function () {
    class t {
      constructor(e, s = t.defaultBindings) {
        this.component = e, this.bindings = s;
      }
      getOptions() {
        let t = this.component.options.editableOptions;
        if (!t) return [];
        for (let e = 0, s = t.length; e < s; e++) {
          let s = t[e];
          if ("connectorName" === s.name) {
            let t = this.component.board,
              e = t ? t.dataPool.getConnectorIds().map(t => ({
                name: t
              })) : [];
            s.selectOptions = e;
          }
        }
        return t;
      }
    }
    return t.defaultBindings = {
      keyMap: {
        color: "colorPicker",
        title: "text",
        caption: "text",
        style: "textarea"
      },
      typeMap: {
        string: "text",
        number: "input",
        boolean: "toggle"
      },
      skipRedraw: []
    }, t.defaultTypeMap = {
      string: "text",
      number: "input",
      boolean: "toggle"
    }, t;
  }), s(e, "Dashboards/Components/Sync/Emitter.js", [], function () {
    class t {
      static register(t) {
        let {
          id: e
        } = t;
        this.registry[e] = t;
      }
      static get(t) {
        return this.registry[t];
      }
      constructor(e, s) {
        this.id = e, this.func = s, t.register(this);
      }
      create(t) {
        this.callback = this.func.call(t);
      }
      remove() {
        this.callback && this.callback();
      }
    }
    return t.registry = {}, t;
  }), s(e, "Dashboards/Components/Sync/Handler.js", [], function () {
    class t {
      static register(t) {
        let {
          id: e
        } = t;
        this.registry[e] = t;
      }
      static get(t) {
        return this.registry[t];
      }
      constructor(e, s) {
        this.id = e, this.func = s, t.register(this);
      }
      register(t) {
        let {
          func: e
        } = this;
        this.callback = e.call(t);
      }
      remove() {
        this.callback && this.callback();
      }
    }
    return t.registry = {}, t;
  }), s(e, "Dashboards/Components/Sync/Sync.js", [e["Dashboards/Components/Sync/Emitter.js"], e["Dashboards/Components/Sync/Handler.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
      merge: i,
      isObject: n
    } = s;
    class o {
      constructor(t, e) {
        this.component = t, this.predefinedSyncConfig = e, this.syncConfig = o.prepareSyncConfig(e, t.options.sync), this.registeredSyncHandlers = {}, this.registeredSyncEmitters = {}, this.isSyncing = !1, this.listeners = [];
      }
      static prepareSyncConfig(t, e = {}) {
        let {
          defaultSyncPairs: s,
          defaultSyncOptions: o
        } = t;
        return Object.keys(e).reduce((t, r) => {
          if (r) {
            let a = s[r],
              l = o[r],
              h = e[r],
              c = i(l || {}, {
                enabled: n(h) ? h.enabled : h
              }, n(h) ? h : {});
            if (a && c.enabled) for (let t of ["emitter", "handler"]) (!0 === c[t] || void 0 === c[t]) && (c[t] = a[t]);
            t[r] = c;
          }
          return t;
        }, {});
      }
      registerSyncEmitter(t) {
        let {
          id: e
        } = t;
        this.registeredSyncEmitters[e] = t;
      }
      isRegisteredEmitter(t) {
        return !!this.registeredSyncEmitters[t];
      }
      registerSyncHandler(t) {
        let {
          id: e
        } = t;
        this.registeredSyncHandlers[e] = t;
      }
      isRegisteredHandler(t) {
        return !!this.registeredSyncHandlers[t];
      }
      start() {
        let {
          component: s
        } = this;
        for (let i of (this.syncConfig = o.prepareSyncConfig(this.predefinedSyncConfig, s.options.sync), Object.keys(this.syncConfig))) {
          let n = this.syncConfig[i];
          if (!n) continue;
          let {
            emitter: r,
            handler: a
          } = n;
          if (a) {
            !0 === a && (a = o.defaultHandlers[i].handler);
            let t = new e(i, a);
            this.isRegisteredHandler(t.id) || (this.registerSyncHandler(t), t.register(s));
          }
          if (r) {
            !0 === r && (r = o.defaultHandlers[i].emitter);
            let e = new t(i, r);
            this.isRegisteredEmitter(e.id) || (this.registerSyncEmitter(e), e.create(s));
          }
        }
        this.isSyncing = !0, this.listeners.push(s.on("update", () => this.stop()));
      }
      stop() {
        let {
          component: t,
          listeners: e,
          registeredSyncHandlers: s,
          registeredSyncEmitters: i
        } = this;
        Object.keys(s).forEach(t => {
          s[t].remove(), delete s[t];
        }), Object.keys(i).forEach(t => {
          i[t].remove(), delete i[t];
        }), this.isSyncing = !1;
        for (let t = 0, s = e.length; t < s; ++t) e[t]();
        this.listeners.length = 0, this.listeners.push(t.on("afterUpdate", () => {
          this.start();
        }));
      }
    }
    return o.defaultHandlers = {}, o;
  }), s(e, "Dashboards/Components/ComponentUtilities.js", [], function () {
    var e;
    return function (e) {
      function s(e, s) {
        let i = t.getComputedStyle(e);
        return s.map(t => i[t]);
      }
      function i(t, e) {
        return e && (t += "number" == typeof e ? e : parseFloat(e)), t;
      }
      e.getMargins = function (t, e = !0) {
        let n = {
          x: ["borderLeft", "borderRight"],
          y: ["borderTop", "borderBottom"]
        };
        return {
          y: s(t, ["marginTop", "marginBottom", ...(e ? n.y : [])]).reduce(i, 0),
          x: s(t, ["marginLeft", "marginTop", ...(e ? n.x : [])]).reduce(i, 0)
        };
      }, e.getPaddings = function (t) {
        return {
          x: s(t, ["paddingLeft", "paddingRight"]).reduce(i, 0),
          y: s(t, ["paddingTop", "paddingBottom"]).reduce(i, 0)
        };
      }, e.getStyles = s, e.sumPixels = i;
    }(e || (e = {})), e;
  }), s(e, "Dashboards/Utilities.js", [e["Dashboards/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        doc: s,
        supportsPassiveEvents: i
      } = t,
      {
        error: n,
        isClass: o,
        isDOMElement: r,
        isObject: a,
        objectEach: l,
        uniqueKey: h
      } = e;
    function c(t, e) {
      let s;
      for (s in t || (t = {}), e) t[s] = e[s];
      return t;
    }
    function d(t, e, s) {
      function i(e, s) {
        let i = t.removeEventListener;
        i && i.call(t, e, s, !1);
      }
      function n(s) {
        let n, o;
        t.nodeName && (e ? (n = {})[e] = !0 : n = s, l(n, function (t, e) {
          if (s[e]) for (o = s[e].length; o--;) i(e, s[e][o].fn);
        }));
      }
      let o = "function" == typeof t && t.prototype || t;
      if (Object.hasOwnProperty.call(o, "hcEvents")) {
        let t = o.hcEvents;
        if (e) {
          let o = t[e] || [];
          s ? (t[e] = o.filter(function (t) {
            return s !== t.fn;
          }), i(e, s)) : (n(t), t[e] = []);
        } else n(t), delete o.hcEvents;
      }
    }
    return {
      addEvent: function (t, e, s, n = {}) {
        let o = "function" == typeof t && t.prototype || t;
        Object.hasOwnProperty.call(o, "hcEvents") || (o.hcEvents = {});
        let r = o.hcEvents,
          a = t.addEventListener;
        a && a.call(t, e, s, !!i && {
          passive: void 0 === n.passive ? -1 !== e.indexOf("touch") : n.passive,
          capture: !1
        }), r[e] || (r[e] = []);
        let l = {
          fn: s,
          order: "number" == typeof n.order ? n.order : 1 / 0
        };
        return r[e].push(l), r[e].sort((t, e) => t.order - e.order), function () {
          d(t, e, s);
        };
      },
      error: function (t, e) {
        if (16 === t) {
          console.warn("Dashboard error: Dashboards library loaded more than once.This may cause undefined behavior.");
          return;
        }
        n(t, e);
      },
      fireEvent: function (e, i, n, o) {
        if (n = n || {}, s.createEvent && (e.dispatchEvent || e.fireEvent && e !== t)) {
          let t = s.createEvent("Events");
          t.initEvent(i, !0, !0), n = c(t, n), e.dispatchEvent ? e.dispatchEvent(n) : e.fireEvent(i, n);
        } else if (e.hcEvents) {
          n.target || c(n, {
            preventDefault: function () {
              n.defaultPrevented = !0;
            },
            target: e,
            type: i
          });
          let t = [],
            s = e,
            o = !1;
          for (; s.hcEvents;) Object.hasOwnProperty.call(s, "hcEvents") && s.hcEvents[i] && (t.length && (o = !0), t.unshift.apply(t, s.hcEvents[i])), s = Object.getPrototypeOf(s);
          o && t.sort((t, e) => t.order - e.order), t.forEach(t => {
            !1 === t.fn.call(e, n) && n.preventDefault();
          });
        }
        o && !n.defaultPrevented && o.call(e, n);
      },
      merge: function (t, ...e) {
        let s = 0,
          i = {},
          n = Error("Recursive copy depth > 100"),
          h = (t, e) => {
            if ("object" != typeof t && (t = {}), ++s > 100) throw n;
            return l(e, (s, i) => {
              "__proto__" !== i && "constructor" !== i && (!a(s, !0) || o(s) || r(s) ? t[i] = e[i] : t[i] = h(t[i] || {}, s));
            }), --s, t;
          };
        !0 === t ? i = e.shift() : e.unshift(t);
        for (let t = 0, s = e.length; t < s; ++t) i = h(i, e[t]);
        return i;
      },
      removeEvent: d,
      uniqueKey: function () {
        return `dashboard-${h().replace("highcharts-", "")}`;
      }
    };
  }), s(e, "Dashboards/Components/Component.js", [e["Dashboards/Layout/Cell.js"], e["Dashboards/CallbackRegistry.js"], e["Dashboards/Components/ConnectorHandler.js"], e["Dashboards/Components/EditableOptions.js"], e["Dashboards/Components/Sync/Sync.js"], e["Dashboards/Globals.js"], e["Core/Utilities.js"], e["Dashboards/Components/ComponentUtilities.js"], e["Dashboards/Utilities.js"]], function (e, s, i, n, o, r, a, l, h) {
    let {
        classNamePrefix: c
      } = r,
      {
        createElement: d,
        isArray: u,
        merge: p,
        fireEvent: m,
        addEvent: f,
        objectEach: g,
        isFunction: y,
        getStyle: b,
        diffObjects: C
      } = a,
      {
        getMargins: w,
        getPaddings: v
      } = l,
      {
        uniqueKey: O
      } = h;
    class D {
      static createTextElement(t, e, s) {
        if ("object" == typeof s) {
          let {
            className: i,
            text: n,
            style: o
          } = s;
          return d(t, {
            className: i || `${c}component-${e}`,
            textContent: n
          }, o);
        }
        if ("string" == typeof s) return d(t, {
          className: `${c}component-${e}`,
          textContent: s
        }, {});
      }
      constructor(t, r, a) {
        this.connectorHandlers = [], this.callbackRegistry = new s(), this.cellListeners = [], this.resizeTimeouts = [], this.innerResizeTimeouts = [];
        let l = r.renderTo || r.cell;
        if (this.board = a || t?.row?.layout?.board || {}, this.parentElement = t?.container || document.querySelector("#" + l), this.cell = t, this.options = p(D.defaultOptions, r), this.id = this.options.id && this.options.id.length ? this.options.id : O(), this.options.connector) for (let t of u(this.options.connector) ? this.options.connector : [this.options.connector]) this.connectorHandlers.push(new i(this, t));
        this.editableOptions = new n(this, r.editableOptionsBindings), this.dimensions = {
          width: null,
          height: null
        }, this.element = d("div", {
          className: this.options.className
        }, {}, this.parentElement), Number(b(this.element, "padding")) || (this.element.style.padding = "0.1px"), this.contentElement = d("div", {
          className: `${this.options.className}-content`
        }, {}, this.element, !0), this.sync = new o(this, this.constructor.predefinedSyncConfig), this.setupEventListeners(), t && (this.attachCellListeners(), this.on("update", () => {
          this.cell instanceof e && this.cell.setLoadingState();
        }), this.on("afterRender", () => {
          this.cell instanceof e && this.cell.setLoadingState(!1);
        })), this.on("tableChanged", () => {
          this.onTableChanged();
        });
      }
      getOptionsOnDrop(t) {
        return {};
      }
      getFirstConnector() {
        return this.connectorHandlers[0]?.connector;
      }
      attachCellListeners() {
        for (; this.cellListeners.length;) {
          let t = this.cellListeners.pop();
          t && t();
        }
        if (this.cell && this.cell instanceof e && Object.keys(this.cell).length) {
          let t = this.cell.row.layout.board;
          this.cellListeners.push(f(t, "cellResize", () => {
            this.resizeTo(this.parentElement);
          }), f(this.cell.row, "cellChange", t => {
            let {
              row: e
            } = t;
            e && this.cell && void 0 === e.getCellIndex(this.cell) && this.cell && this.setCell(this.cell);
          }));
        }
      }
      setCell(t, e = !1) {
        this.cell = t, t.container && (this.parentElement = t.container), this.attachCellListeners(), e && this.resizeTo(this.parentElement);
      }
      async initConnectors() {
        for (let t of (m(this, "setConnectors", {
          connectorHandlers: this.connectorHandlers
        }), this.connectorHandlers)) await t.initConnector();
        return m(this, "afterSetConnectors", {
          connectorHandlers: this.connectorHandlers
        }), this;
      }
      getContentHeight() {
        return (this.titleElement ? this.titleElement.clientHeight + w(this.titleElement).y : 0) + (this.captionElement ? this.captionElement.clientHeight + w(this.captionElement).y : 0);
      }
      resize(t, e) {
        if (e) {
          let t = v(this.element).y + w(this.element).y;
          this.element.style.height = "calc(100% - " + t + "px)", this.contentElement.style.height = "calc(100% - " + this.getContentHeight() + "px)";
        } else null === e && (this.dimensions.height = null, this.element.style.removeProperty("height"));
        m(this, "resize", {
          width: t,
          height: e
        });
      }
      resizeTo(t) {
        for (; this.resizeTimeouts.length;) {
          let t = this.resizeTimeouts.pop();
          t && cancelAnimationFrame(t);
        }
        let e = requestAnimationFrame(() => {
          let {
              width: e,
              height: s
            } = t.getBoundingClientRect(),
            i = v(t),
            n = w(t);
          this.resize(e - i.x - n.x, s - i.y - n.y);
        });
        this.resizeTimeouts.push(e);
      }
      async update(t, e = !0) {
        let s = {
          options: t,
          shouldForceRerender: !1
        };
        m(this, "update", s), t.connector && Array.isArray(this.options.connector) && (this.options.connector = void 0), this.options = p(this.options, t);
        let n = this.options.connector ? u(this.options.connector) ? this.options.connector : [this.options.connector] : [],
          o = n.length !== this.connectorHandlers.length;
        if (!o) for (let t = 0, e = n.length; t < e; t++) {
          if (this.connectorHandlers[t]?.options.id !== n[t]?.id) {
            o = !0;
            break;
          }
          this.connectorHandlers[t].updateOptions(n[t]);
        }
        if (o) {
          for (let t of this.connectorHandlers) t.destroy();
          for (let t of (this.connectorHandlers.length = 0, n)) this.connectorHandlers.push(new i(this, t));
          await this.initConnectors();
        }
        (e || s.shouldForceRerender) && this.render();
      }
      setupEventListeners() {
        let e = this.options.events;
        e && (Object.keys(e).forEach(t => {
          let s = e[t];
          s && this.callbackRegistry.addCallback(t, {
            type: "component",
            func: s
          });
        }), g(e, (t, e) => {
          y(t) && this.on(e, t);
        }));
        let s = () => {
          this.resizeTo(this.parentElement);
        };
        if ("function" == typeof ResizeObserver) this.resizeObserver = new ResizeObserver(s), this.resizeObserver.observe(this.element);else {
          let e = f(t, "resize", s);
          f(this, "destroy", e);
        }
      }
      setTitle(t) {
        let e = this.titleElement;
        if (t && ("string" == typeof t || t.text)) {
          let s = D.createTextElement("h2", "title", t);
          s && (e ? e.replaceWith(s) : this.element.insertBefore(s, this.element.firstChild), this.titleElement = s);
        } else if (e) {
          e.remove(), delete this.titleElement;
          return;
        }
      }
      setCaption(t) {
        let e = this.captionElement;
        if (t && ("string" == typeof t || t.text)) {
          let s = D.createTextElement("div", "caption", t);
          s && (e ? e.replaceWith(s) : this.element.appendChild(s), this.captionElement = s);
        } else if (e) {
          e.remove(), delete this.captionElement;
          return;
        }
      }
      async load() {
        return await this.initConnectors(), this.render(), this;
      }
      render() {
        return this.emit({
          type: "render"
        }), this.setTitle(this.options.title), this.setCaption(this.options.caption), this.resizeTo(this.parentElement), this;
      }
      destroy() {
        for (this.sync.stop(); this.element.firstChild;) this.element.firstChild.remove();
        for (let t of (m(this, "unmount"), this.connectorHandlers)) t.destroy();
        this.element.remove();
      }
      on(t, e) {
        return f(this, t, e);
      }
      emit(t) {
        t.target || (t.target = this), m(this, t.type, t);
      }
      toJSON() {
        let t = {
          width: 0,
          height: 0
        };
        return g(this.dimensions, function (e, s) {
          null !== e && (t[s] = e);
        }), {
          $class: this.options.type,
          options: {
            renderTo: this.options.renderTo,
            parentElement: this.parentElement.id,
            dimensions: t,
            id: this.id,
            type: this.type
          }
        };
      }
      getOptions() {
        return C(this.options, D.defaultOptions);
      }
      getEditableOptions() {
        return p(this.options);
      }
      getEditableOptionValue(t) {
        if (!t) return;
        let e = this.getEditableOptions();
        for (let s = 0, i = t.length; s < i; s++) {
          if (u(e)) {
            if ("connector" === t[0] && e.length > 1) return "multiple connectors";
            e = e[0];
          }
          if (!e) return;
          !1 === (e = e[t[s]]) && (t.indexOf("title") >= 0 || t.indexOf("subtitle") >= 0 || t.indexOf("caption") >= 0) && (e = "");
        }
        return e;
      }
    }
    return D.Sync = o, D.predefinedSyncConfig = {
      defaultSyncOptions: {},
      defaultSyncPairs: {}
    }, D.defaultOptions = {
      className: `${c}component`,
      id: "",
      title: !1,
      caption: !1,
      sync: o.defaultHandlers,
      editableOptions: [{
        name: "title",
        propertyPath: ["title"],
        type: "input"
      }, {
        name: "caption",
        propertyPath: ["caption"],
        type: "input"
      }]
    }, D;
  }), s(e, "Dashboards/Components/HTMLComponent/HTMLComponentDefaults.js", [e["Dashboards/Components/Component.js"]], function (t) {
    return {
      type: "HTML",
      className: [t.defaultOptions.className, `${t.defaultOptions.className}-html`].join(" "),
      elements: [],
      editableOptions: [...(t.defaultOptions.editableOptions || []), {
        name: "htmlInput",
        propertyPath: ["html"],
        type: "textarea"
      }]
    };
  }), s(e, "Dashboards/Components/HTMLComponent/HTMLSyncs/HTMLSyncs.js", [], function () {
    return {
      defaultSyncPairs: {},
      defaultSyncOptions: {}
    };
  }), s(e, "Dashboards/Components/HTMLComponent/HTMLComponent.js", [e["Core/Renderer/HTML/AST.js"], e["Dashboards/Components/Component.js"], e["Dashboards/Components/HTMLComponent/HTMLComponentDefaults.js"], e["Dashboards/Components/HTMLComponent/HTMLSyncs/HTMLSyncs.js"], e["Core/Utilities.js"]], function (t, e, s, i, n) {
    let {
      merge: o,
      diffObjects: r
    } = n;
    t.allowedTags = [...t.allowedTags, "option", "select", "label", "input", "textarea"], t.allowedAttributes = [...t.allowedAttributes, "for", "value", "checked", "src", "name", "selected"], t.allowedReferences = [...t.allowedReferences, "data:image/"];
    class a extends e {
      static fromJSON(t, e) {
        let s = new a(e, o(t.options, {
          elements: t.elements ? t.elements.map(t => JSON.parse(t)) : []
        }));
        return s.emit({
          type: "fromJSON",
          json: t
        }), s;
      }
      constructor(t, e) {
        e.className && (e.className = `${a.defaultOptions.className} ${e.className}`), super(t, e = o(a.defaultOptions, e)), this.options = e, this.type = "HTML", this.elements = [];
      }
      async load() {
        this.emit({
          type: "load"
        }), await super.load();
        let e = this.options,
          s = !1;
        if (e.elements?.length ? this.elements = e.elements.map(function (e) {
          return "string" == typeof e ? new t(e).nodes[0] : (e.textContent || e.tagName || !e.attributes || (s = !0), e);
        }) : e.html && (this.elements = this.getElementsFromString(e.html), this.options.elements = this.elements), this.constructTree(), this.emit({
          type: "afterLoad"
        }), s) throw Error(`Missing tagName param in component: ${e.renderTo}`);
        return this;
      }
      render() {
        return super.render(), this.constructTree(), this.sync.start(), this.emit({
          type: "afterRender"
        }), this;
      }
      resize(t, e) {
        return super.resize(t, e), this;
      }
      async update(t, e = !0) {
        t.html && (this.elements = this.getElementsFromString(t.html), this.options.elements = this.elements, this.constructTree()), await super.update(t, e), this.emit({
          type: "afterUpdate"
        });
      }
      getOptionsOnDrop() {
        return {
          cell: "",
          type: "HTML",
          elements: [{
            tagName: "span",
            textContent: "[Your custom HTML here- edit the component]"
          }]
        };
      }
      constructTree() {
        for (; this.contentElement.firstChild;) this.contentElement.firstChild.remove();
        new t(this.options.elements || []).addToDOM(this.contentElement);
      }
      getElementsFromString(e) {
        return new t(e).nodes;
      }
      toJSON() {
        let t = (this.options.elements || []).map(t => JSON.stringify(t)),
          e = o(super.toJSON(), {
            elements: t,
            options: this.options
          });
        return this.emit({
          type: "toJSON",
          json: e
        }), e;
      }
      getOptions() {
        return {
          ...r(this.options, a.defaultOptions),
          type: "HTML"
        };
      }
      getEditableOptions() {
        return this.elements.length ? o(this.options, {
          elements: this.elements
        }) : this.options;
      }
      getEditableOptionValue(t) {
        if (t) {
          if ("html" === t[0]) {
            let e = this.getEditableOptions();
            return !e.html && e.elements ? this.getStringFromElements(e.elements) : e[t[0]];
          }
          return super.getEditableOptionValue(t);
        }
      }
      getStringFromElements(t) {
        let e = "";
        for (let s of t) e += this.serializeNode(s);
        return e;
      }
      serializeNode(t) {
        if (!t.tagName || "#text" === t.tagName) return t.textContent || "";
        let e = t.attributes,
          s = `<${t.tagName}`;
        if (e) {
          for (let t in e) if (Object.prototype.hasOwnProperty.call(e, t)) {
            let i = e[t];
            void 0 !== i && (s += ` ${t}="${i}"`);
          }
        }
        return s += ">" + (t.textContent || ""), (t.children || []).forEach(t => {
          s += this.serializeNode(t);
        }), s += `</${t.tagName}>`;
      }
      onTableChanged(t) {
        t.detail?.sender !== this.id && this.render();
      }
    }
    return a.defaultOptions = o(e.defaultOptions, s), a.predefinedSyncConfig = i, a;
  }), s(e, "Data/DataTable.js", [e["Core/Utilities.js"]], function (t) {
    let {
      addEvent: e,
      defined: s,
      fireEvent: i,
      uniqueKey: n
    } = t;
    class o {
      static isNull(t) {
        if (t === o.NULL) return !0;
        if (t instanceof Array) {
          if (!t.length) return !1;
          for (let e = 0, s = t.length; e < s; ++e) if (null !== t[e]) return !1;
        } else {
          let e = Object.keys(t);
          if (!e.length) return !1;
          for (let s = 0, i = e.length; s < i; ++s) if (null !== t[e[s]]) return !1;
        }
        return !0;
      }
      constructor(t = {}) {
        this.autoId = !t.id, this.columns = {}, this.id = t.id || n(), this.modified = this, this.rowCount = 0, this.versionTag = n();
        let e = t.columns || {},
          s = Object.keys(e),
          i = this.columns,
          o = 0;
        for (let t = 0, n = s.length, r, a; t < n; ++t) r = e[a = s[t]].slice(), i[a] = r, o = Math.max(o, r.length);
        for (let t = 0, e = s.length; t < e; ++t) i[s[t]].length = o;
        this.rowCount = o;
      }
      clone(t, e) {
        let s = {};
        this.emit({
          type: "cloneTable",
          detail: e
        }), t || (s.columns = this.columns), this.autoId || (s.id = this.id);
        let i = new o(s);
        return t || (i.versionTag = this.versionTag, i.originalRowIndexes = this.originalRowIndexes, i.localRowIndexes = this.localRowIndexes), this.emit({
          type: "afterCloneTable",
          detail: e,
          tableClone: i
        }), i;
      }
      deleteColumns(t, e) {
        let s = this.columns,
          i = {},
          n = {},
          o = this.modifier,
          r = this.rowCount;
        if ((t = t || Object.keys(s)).length) {
          this.emit({
            type: "deleteColumns",
            columnNames: t,
            detail: e
          });
          for (let e = 0, o = t.length, a, l; e < o; ++e) (a = s[l = t[e]]) && (i[l] = a, n[l] = Array(r)), delete s[l];
          return Object.keys(s).length || (this.rowCount = 0, this.deleteRowIndexReferences()), o && o.modifyColumns(this, n, 0, e), this.emit({
            type: "afterDeleteColumns",
            columns: i,
            columnNames: t,
            detail: e
          }), i;
        }
      }
      deleteRowIndexReferences() {
        delete this.originalRowIndexes, delete this.localRowIndexes;
      }
      deleteRows(t, e = 1, s) {
        let i = [],
          n = [],
          o = this.modifier;
        if (this.emit({
          type: "deleteRows",
          detail: s,
          rowCount: e,
          rowIndex: t || 0
        }), void 0 === t && (t = 0, e = this.rowCount), e > 0 && t < this.rowCount) {
          let s = this.columns,
            o = Object.keys(s);
          for (let r = 0, a = o.length, l, h; r < a; ++r) {
            h = (l = s[o[r]]).splice(t, e), r || (this.rowCount = l.length);
            for (let t = 0, e = h.length; t < e; ++t) i[t] = i[t] || [], i[t][r] = h[t];
            n.push(Array(a));
          }
        }
        return o && o.modifyRows(this, n, t || 0, s), this.emit({
          type: "afterDeleteRows",
          detail: s,
          rowCount: e,
          rowIndex: t || 0,
          rows: i
        }), i;
      }
      emit(t) {
        switch (t.type) {
          case "afterDeleteColumns":
          case "afterDeleteRows":
          case "afterSetCell":
          case "afterSetColumns":
          case "afterSetRows":
            this.versionTag = n();
        }
        i(this, t.type, t);
      }
      getCell(t, e) {
        let s = this.columns[t];
        if (s) return s[e];
      }
      getCellAsBoolean(t, e) {
        let s = this.columns[t];
        return !!(s && s[e]);
      }
      getCellAsNumber(t, e, s) {
        let i = this.columns[t],
          n = i && i[e];
        switch (typeof n) {
          case "boolean":
            return n ? 1 : 0;
          case "number":
            return isNaN(n) && !s ? null : n;
        }
        return isNaN(n = parseFloat(`${n ?? ""}`)) && !s ? null : n;
      }
      getCellAsString(t, e) {
        let s = this.columns[t];
        return `${s && s[e]}`;
      }
      getColumn(t, e) {
        return this.getColumns([t], e)[t];
      }
      getColumnAsNumbers(t, e) {
        let s = this.columns[t],
          i = [];
        if (s) {
          let n = s.length;
          if (e) for (let e = 0; e < n; ++e) i.push(this.getCellAsNumber(t, e, !0));else {
            for (let t = 0, e; t < n; ++t) {
              if ("number" == typeof (e = s[t])) return s.slice();
              if (null != e) break;
            }
            for (let e = 0; e < n; ++e) i.push(this.getCellAsNumber(t, e));
          }
        }
        return i;
      }
      getColumnNames() {
        return Object.keys(this.columns);
      }
      getColumns(t, e) {
        let s = this.columns,
          i = {};
        t = t || Object.keys(s);
        for (let n = 0, o = t.length, r, a; n < o; ++n) (r = s[a = t[n]]) && (i[a] = e ? r : r.slice());
        return i;
      }
      getLocalRowIndex(t) {
        let {
          localRowIndexes: e
        } = this;
        return e ? e[t] : t;
      }
      getModifier() {
        return this.modifier;
      }
      getOriginalRowIndex(t) {
        let {
          originalRowIndexes: e
        } = this;
        return e ? e[t] : t;
      }
      getRow(t, e) {
        return this.getRows(t, 1, e)[0];
      }
      getRowCount() {
        return this.rowCount;
      }
      getRowIndexBy(t, e, s) {
        let i = this.columns[t];
        if (i) {
          let t = i.indexOf(e, s);
          if (-1 !== t) return t;
        }
      }
      getRowObject(t, e) {
        return this.getRowObjects(t, 1, e)[0];
      }
      getRowObjects(t = 0, e = this.rowCount - t, s) {
        let i = this.columns,
          n = Array(e);
        s = s || Object.keys(i);
        for (let o = t, r = 0, a = Math.min(this.rowCount, t + e), l, h; o < a; ++o, ++r) for (let t of (h = n[r] = {}, s)) l = i[t], h[t] = l ? l[o] : void 0;
        return n;
      }
      getRows(t = 0, e = this.rowCount - t, s) {
        let i = this.columns,
          n = Array(e);
        s = s || Object.keys(i);
        for (let o = t, r = 0, a = Math.min(this.rowCount, t + e), l, h; o < a; ++o, ++r) for (let t of (h = n[r] = [], s)) l = i[t], h.push(l ? l[o] : void 0);
        return n;
      }
      getVersionTag() {
        return this.versionTag;
      }
      hasColumns(t) {
        let e = this.columns;
        for (let s = 0, i = t.length; s < i; ++s) if (!e[t[s]]) return !1;
        return !0;
      }
      hasRowWith(t, e) {
        let s = this.columns[t];
        return !!s && -1 !== s.indexOf(e);
      }
      on(t, s) {
        return e(this, t, s);
      }
      renameColumn(t, e) {
        let s = this.columns;
        return !!s[t] && (t !== e && (s[e] = s[t], delete s[t]), !0);
      }
      setCell(t, e, s, i) {
        let n = this.columns,
          o = this.modifier,
          r = n[t];
        r && r[e] === s || (this.emit({
          type: "setCell",
          cellValue: s,
          columnName: t,
          detail: i,
          rowIndex: e
        }), r || (r = n[t] = Array(this.rowCount)), e >= this.rowCount && (this.rowCount = e + 1), r[e] = s, o && o.modifyCell(this, t, e, s), this.emit({
          type: "afterSetCell",
          cellValue: s,
          columnName: t,
          detail: i,
          rowIndex: e
        }));
      }
      setColumn(t, e = [], s = 0, i) {
        this.setColumns({
          [t]: e
        }, s, i);
      }
      setColumns(t, e, s) {
        let i = this.columns,
          n = this.modifier,
          o = void 0 === e,
          r = Object.keys(t);
        this.emit({
          type: "setColumns",
          columns: t,
          columnNames: r,
          detail: s,
          rowIndex: e
        });
        for (let s = 0, n = r.length, a, l; s < n; ++s) if (a = t[l = r[s]], o) i[l] = a.slice(), this.rowCount = a.length;else {
          let t = i[l] ? i[l] : i[l] = Array(this.rowCount);
          for (let s = e || 0, i = a.length; s < i; ++s) t[s] = a[s];
          this.rowCount = Math.max(this.rowCount, t.length);
        }
        let a = Object.keys(i);
        for (let t = 0, e = a.length; t < e; ++t) i[a[t]].length = this.rowCount;
        n && n.modifyColumns(this, t, e || 0), this.emit({
          type: "afterSetColumns",
          columns: t,
          columnNames: r,
          detail: s,
          rowIndex: e
        });
      }
      setModifier(t, e) {
        let s = this;
        return s.emit({
          type: "setModifier",
          detail: e,
          modifier: t,
          modified: s.modified
        }), s.modified = s, s.modifier = t, (t ? t.modify(s) : Promise.resolve(s)).then(s => (s.emit({
          type: "afterSetModifier",
          detail: e,
          modifier: t,
          modified: s.modified
        }), s)).catch(e => {
          throw s.emit({
            type: "setModifierError",
            error: e,
            modifier: t,
            modified: s.modified
          }), e;
        });
      }
      setOriginalRowIndexes(t, e = !1) {
        if (this.originalRowIndexes = t, e) return;
        let i = this.localRowIndexes = [];
        for (let e = 0, n = t.length, o; e < n; ++e) s(o = t[e]) && (i[o] = e);
      }
      setRow(t, e, s) {
        this.setRows([t], e, s);
      }
      setRows(t, e = this.rowCount, s) {
        let i = this.columns,
          n = Object.keys(i),
          r = this.modifier,
          a = t.length;
        this.emit({
          type: "setRows",
          detail: s,
          rowCount: a,
          rowIndex: e,
          rows: t
        });
        for (let s = 0, r = e, l; s < a; ++s, ++r) if ((l = t[s]) === o.NULL) for (let t = 0, e = n.length; t < e; ++t) i[n[t]][r] = null;else if (l instanceof Array) for (let t = 0, e = n.length; t < e; ++t) i[n[t]][r] = l[t];else {
          let t = Object.keys(l);
          for (let e = 0, s = t.length, n; e < s; ++e) i[n = t[e]] || (i[n] = Array(r + 1)), i[n][r] = l[n];
        }
        let l = e + a;
        if (l > this.rowCount) {
          this.rowCount = l;
          for (let t = 0, e = n.length; t < e; ++t) i[n[t]].length = l;
        }
        r && r.modifyRows(this, t, e), this.emit({
          type: "afterSetRows",
          detail: s,
          rowCount: a,
          rowIndex: e,
          rows: t
        });
      }
    }
    return o.NULL = {}, o.version = "1.0.0", o;
  }), s(e, "Data/Converters/DataConverter.js", [e["Data/DataTable.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      addEvent: s,
      fireEvent: i,
      isNumber: n,
      merge: o
    } = e;
    class r {
      constructor(t) {
        this.dateFormats = {
          "YYYY/mm/dd": {
            regex: /^(\d{4})([\-\.\/])(\d{1,2})\2(\d{1,2})$/,
            parser: function (t) {
              return t ? Date.UTC(+t[1], t[3] - 1, +t[4]) : NaN;
            }
          },
          "dd/mm/YYYY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{4})$/,
            parser: function (t) {
              return t ? Date.UTC(+t[4], t[3] - 1, +t[1]) : NaN;
            },
            alternative: "mm/dd/YYYY"
          },
          "mm/dd/YYYY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{4})$/,
            parser: function (t) {
              return t ? Date.UTC(+t[4], t[1] - 1, +t[3]) : NaN;
            }
          },
          "dd/mm/YY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{2})$/,
            parser: function (t) {
              let e = new Date();
              if (!t) return NaN;
              let s = +t[4];
              return s > e.getFullYear() - 2e3 ? s += 1900 : s += 2e3, Date.UTC(s, t[3] - 1, +t[1]);
            },
            alternative: "mm/dd/YY"
          },
          "mm/dd/YY": {
            regex: /^(\d{1,2})([\-\.\/])(\d{1,2})\2(\d{2})$/,
            parser: function (t) {
              return t ? Date.UTC(+t[4] + 2e3, t[1] - 1, +t[3]) : NaN;
            }
          }
        };
        let e = o(r.defaultOptions, t),
          s = e.decimalPoint;
        ("." === s || "," === s) && (s = "." === s ? "\\." : ",", this.decimalRegExp = RegExp("^(-?[0-9]+)" + s + "([0-9]+)$")), this.options = e;
      }
      asBoolean(t) {
        return "boolean" == typeof t ? t : "string" == typeof t ? "" !== t && "0" !== t && "false" !== t : !!this.asNumber(t);
      }
      asDate(t) {
        let e;
        if ("string" == typeof t) e = this.parseDate(t);else if ("number" == typeof t) e = t;else {
          if (t instanceof Date) return t;
          e = this.parseDate(this.asString(t));
        }
        return new Date(e);
      }
      asGuessedType(t) {
        return {
          number: this.asNumber,
          Date: this.asDate,
          string: this.asString
        }[this.guessType(t)].call(this, t);
      }
      asNumber(t) {
        if ("number" == typeof t) return t;
        if ("boolean" == typeof t) return t ? 1 : 0;
        if ("string" == typeof t) {
          let e = this.decimalRegExp;
          if (t.indexOf(" ") > -1 && (t = t.replace(/\s+/g, "")), e) {
            if (!e.test(t)) return NaN;
            t = t.replace(e, "$1.$2");
          }
          return parseFloat(t);
        }
        return t instanceof Date ? t.getDate() : t ? t.getRowCount() : NaN;
      }
      asString(t) {
        return "" + t;
      }
      deduceDateFormat(t, e, s) {
        let i = [],
          n = [],
          o = "YYYY/mm/dd",
          r,
          a = [],
          l = 0,
          h = !1,
          c,
          d;
        for ((!e || e > t.length) && (e = t.length); l < e; l++) if (void 0 !== t[l] && t[l] && t[l].length) for (d = 0, r = t[l].trim().replace(/[\-\.\/]/g, " ").split(" "), a = ["", "", ""]; d < r.length; d++) d < a.length && (c = parseInt(r[d], 10)) && (n[d] = !n[d] || n[d] < c ? c : n[d], void 0 !== i[d] ? i[d] !== c && (i[d] = !1) : i[d] = c, c > 31 ? c < 100 ? a[d] = "YY" : a[d] = "YYYY" : c > 12 && c <= 31 ? (a[d] = "dd", h = !0) : a[d].length || (a[d] = "mm"));
        if (h) {
          for (d = 0; d < i.length; d++) !1 !== i[d] ? n[d] > 12 && "YY" !== a[d] && "YYYY" !== a[d] && (a[d] = "YY") : n[d] > 12 && "mm" === a[d] && (a[d] = "dd");
          3 === a.length && "dd" === a[1] && "dd" === a[2] && (a[2] = "YY"), o = a.join("/");
        }
        return s && (this.options.dateFormat = o), o;
      }
      emit(t) {
        i(this, t.type, t);
      }
      export(t, e) {
        throw this.emit({
          type: "exportError",
          columns: [],
          headers: []
        }), Error("Not implemented");
      }
      getTable() {
        throw Error("Not implemented");
      }
      guessType(t) {
        let e = "string";
        if ("string" == typeof t) {
          let s = this.trim(`${t}`),
            i = this.decimalRegExp,
            o = this.trim(s, !0);
          i && (o = i.test(o) ? o.replace(i, "$1.$2") : "");
          let r = parseFloat(o);
          +o === r ? t = r : e = n(this.parseDate(t)) ? "Date" : "string";
        }
        return "number" == typeof t && (e = t > 31536e6 ? "Date" : "number"), e;
      }
      on(t, e) {
        return s(this, t, e);
      }
      parse(t) {
        throw this.emit({
          type: "parseError",
          columns: [],
          headers: []
        }), Error("Not implemented");
      }
      parseDate(t, e) {
        let s = this.options,
          i = e || s.dateFormat,
          o = NaN,
          r,
          a,
          l;
        if (s.parseDate) o = s.parseDate(t);else {
          if (i) (a = this.dateFormats[i]) || (a = this.dateFormats["YYYY/mm/dd"]), (l = t.match(a.regex)) && (o = a.parser(l));else for (r in this.dateFormats) if (a = this.dateFormats[r], l = t.match(a.regex)) {
            i = r, o = a.parser(l);
            break;
          }
          !l && ("object" == typeof (l = Date.parse(t)) && null !== l && l.getTime ? o = l.getTime() - 6e4 * l.getTimezoneOffset() : n(l) && (o = l - 6e4 * new Date(l).getTimezoneOffset(), -1 === t.indexOf("2001") && 2001 === new Date(o).getFullYear() && (o = NaN)));
        }
        return o;
      }
      trim(t, e) {
        return "string" == typeof t && (t = t.replace(/^\s+|\s+$/g, ""), e && /^[\d\s]+$/.test(t) && (t = t.replace(/\s/g, ""))), t;
      }
    }
    return r.defaultOptions = {
      dateFormat: "",
      alternativeFormat: "",
      startColumn: 0,
      endColumn: Number.MAX_VALUE,
      startRow: 0,
      endRow: Number.MAX_VALUE,
      firstRowAsNames: !0,
      switchRowsAndColumns: !1
    }, (r || (r = {})).getTableFromColumns = function (e = [], s = []) {
      let i = new t();
      for (let t = 0, n = Math.max(s.length, e.length); t < n; ++t) i.setColumn(s[t] || `${t}`, e[t]);
      return i;
    }, r;
  }), s(e, "Data/Converters/CSVConverter.js", [e["Data/Converters/DataConverter.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s
    } = e;
    class i extends t {
      constructor(t) {
        let e = s(i.defaultOptions, t);
        super(e), this.columns = [], this.headers = [], this.dataTypes = [], this.options = e;
      }
      export(t, e = this.options) {
        let {
            useLocalDecimalPoint: s,
            lineDelimiter: i
          } = e,
          n = !1 !== this.options.firstRowAsNames,
          {
            decimalPoint: o,
            itemDelimiter: r
          } = e;
        o || (o = "," !== r && s ? 1.1.toLocaleString()[1] : "."), r || (r = "," === o ? ";" : ",");
        let a = t.getSortedColumns(e.usePresentationOrder),
          l = Object.keys(a),
          h = [],
          c = l.length,
          d = [];
        n && h.push(l.map(t => `"${t}"`).join(r));
        for (let e = 0; e < c; e++) {
          let s;
          let i = l[e],
            n = a[i],
            u = n.length,
            p = t.whatIs(i);
          p && (s = p.dataType);
          for (let t = 0; t < u; t++) {
            let i = n[t];
            if (d[t] || (d[t] = []), "string" === s ? i = '"' + i + '"' : "number" == typeof i ? i = String(i).replace(".", o) : "string" == typeof i && (i = `"${i}"`), d[t][e] = i, e === c - 1) {
              let s = e;
              for (; d[t].length > 2 && void 0 === d[t][s];) d[t].pop(), s--;
              h.push(d[t].join(r));
            }
          }
        }
        return h.join(i);
      }
      parse(t, e) {
        let i = this.dataTypes,
          n = s(this.options, t),
          {
            beforeParse: o,
            lineDelimiter: r,
            firstRowAsNames: a,
            itemDelimiter: l
          } = n,
          h,
          c = 0,
          {
            csv: d,
            startRow: u,
            endRow: p
          } = n,
          m;
        if (this.columns = [], this.emit({
          type: "parse",
          columns: this.columns,
          detail: e,
          headers: this.headers
        }), d && o && (d = o(d)), d) {
          if (h = d.replace(/\r\n|\r/g, "\n").split(r || "\n"), (!u || u < 0) && (u = 0), (!p || p >= h.length) && (p = h.length - 1), l || (this.guessedItemDelimiter = this.guessDelimiter(h)), a) {
            let t = h[0].split(l || this.guessedItemDelimiter || ",");
            for (let e = 0; e < t.length; e++) t[e] = t[e].trim().replace(/^["']|["']$/g, "");
            this.headers = t, u++;
          }
          let t = 0;
          for (c = u; c <= p; c++) "#" === h[c][0] ? t++ : this.parseCSVRow(h[c], c - u - t);
          i.length && i[0].length && "date" === i[0][1] && !this.options.dateFormat && this.deduceDateFormat(this.columns[0], null, !0);
          for (let t = 0, e = this.columns.length; t < e; ++t) {
            m = this.columns[t];
            for (let e = 0, s = m.length; e < s; ++e) if (m[e] && "string" == typeof m[e]) {
              let s = this.asGuessedType(m[e]);
              s instanceof Date && (s = s.getTime()), this.columns[t][e] = s;
            }
          }
        }
        this.emit({
          type: "afterParse",
          columns: this.columns,
          detail: e,
          headers: this.headers
        });
      }
      parseCSVRow(t, e) {
        let s = this,
          i = s.columns || [],
          n = s.dataTypes,
          {
            startColumn: o,
            endColumn: r
          } = s.options,
          a = s.options.itemDelimiter || s.guessedItemDelimiter,
          {
            decimalPoint: l
          } = s.options;
        l && l !== a || (l = s.guessedDecimalPoint || ".");
        let h = 0,
          c = "",
          d = "",
          u = 0,
          p = 0,
          m = e => {
            c = t[e];
          },
          f = t => {
            n.length < p + 1 && n.push([t]), n[p][n[p].length - 1] !== t && n[p].push(t);
          },
          g = () => {
            if (o > u || u > r) {
              ++u, d = "";
              return;
            }
            if ("string" == typeof d ? !isNaN(parseFloat(d)) && isFinite(d) ? (d = parseFloat(d), f("number")) : isNaN(Date.parse(d)) ? f("string") : (d = d.replace(/\//g, "-"), f("date")) : f("number"), i.length < p + 1 && i.push([]), "number" != typeof d && "number" !== s.guessType(d) && l) {
              let t = d;
              d = d.replace(l, "."), "number" !== s.guessType(d) && (d = t);
            }
            i[p][e] = d, d = "", ++p, ++u;
          };
        if (t.trim().length && "#" !== t.trim()[0]) {
          for (; h < t.length; h++) {
            if (m(h), "#" === c && !/^#[A-F\d]{3,3}|[A-F\d]{6,6}/i.test(t.substring(h))) {
              g();
              return;
            }
            if ('"' === c) for (m(++h); h < t.length && '"' !== c;) d += c, m(++h);else c === a ? g() : d += c;
          }
          g();
        }
      }
      guessDelimiter(t) {
        let e = 0,
          s = 0,
          i,
          n = {
            ",": 0,
            ";": 0,
            "	": 0
          },
          o = t.length;
        for (let i = 0; i < o; i++) {
          let o = !1,
            r,
            a,
            l,
            h = "";
          if (i > 13) break;
          let c = t[i];
          for (let t = 0; t < c.length && (r = c[t], a = c[t + 1], l = c[t - 1], "#" !== r); t++) {
            if ('"' === r) {
              if (o) {
                if ('"' !== l && '"' !== a) {
                  for (; " " === a && t < c.length;) a = c[++t];
                  void 0 !== n[a] && n[a]++, o = !1;
                }
              } else o = !0;
            } else void 0 !== n[r] ? (isNaN(Date.parse(h = h.trim())) ? (isNaN(Number(h)) || !isFinite(Number(h))) && n[r]++ : n[r]++, h = "") : h += r;
            "," === r && s++, "." === r && e++;
          }
        }
        return n[";"] > n[","] ? i = ";" : (n[","], n[";"], i = ","), e > s ? this.guessedDecimalPoint = "." : this.guessedDecimalPoint = ",", i;
      }
      getTable() {
        return t.getTableFromColumns(this.columns, this.headers);
      }
    }
    return i.defaultOptions = {
      ...t.defaultOptions,
      lineDelimiter: "\n"
    }, i;
  }), s(e, "Data/Modifiers/DataModifier.js", [e["Core/Utilities.js"]], function (e) {
    var s;
    let {
      addEvent: i,
      fireEvent: n,
      merge: o
    } = e;
    class r {
      benchmark(e, s) {
        let i = [],
          n = this,
          r = () => {
            n.modifyTable(e), n.emit({
              type: "afterBenchmarkIteration"
            });
          },
          {
            iterations: a
          } = o({
            iterations: 1
          }, s);
        n.on("afterBenchmarkIteration", () => {
          if (i.length === a) {
            n.emit({
              type: "afterBenchmark",
              results: i
            });
            return;
          }
          r();
        });
        let l = {
          startTime: 0,
          endTime: 0
        };
        return n.on("modify", () => {
          l.startTime = t.performance.now();
        }), n.on("afterModify", () => {
          l.endTime = t.performance.now(), i.push(l.endTime - l.startTime);
        }), r(), i;
      }
      emit(t) {
        n(this, t.type, t);
      }
      modify(t, e) {
        let s = this;
        return new Promise((i, n) => {
          t.modified === t && (t.modified = t.clone(!1, e));
          try {
            i(s.modifyTable(t, e));
          } catch (i) {
            s.emit({
              type: "error",
              detail: e,
              table: t
            }), n(i);
          }
        });
      }
      modifyCell(t, e, s, i, n) {
        return this.modifyTable(t);
      }
      modifyColumns(t, e, s, i) {
        return this.modifyTable(t);
      }
      modifyRows(t, e, s, i) {
        return this.modifyTable(t);
      }
      on(t, e) {
        return i(this, t, e);
      }
    }
    return (s = r || (r = {})).types = {}, s.registerType = function (t, e) {
      return !!t && !s.types[t] && !!(s.types[t] = e);
    }, r;
  }), s(e, "Data/Connectors/DataConnector.js", [e["Data/Modifiers/DataModifier.js"], e["Data/DataTable.js"], e["Core/Utilities.js"]], function (e, s, i) {
    var n;
    let {
      addEvent: o,
      fireEvent: r,
      merge: a,
      pick: l
    } = i;
    class h {
      constructor(t = {}) {
        this.table = new s(t.dataTable), this.metadata = t.metadata || {
          columns: {}
        };
      }
      get polling() {
        return !!this.polling;
      }
      describeColumn(t, e) {
        let s = this.metadata.columns;
        s[t] = a(s[t] || {}, e);
      }
      describeColumns(t) {
        let e;
        let s = Object.keys(t);
        for (; "string" == typeof (e = s.pop());) this.describeColumn(e, t[e]);
      }
      emit(t) {
        r(this, t.type, t);
      }
      getColumnOrder(t) {
        let e = this.metadata.columns,
          s = Object.keys(e || {});
        if (s.length) return s.sort((t, s) => l(e[t].index, 0) - l(e[s].index, 0));
      }
      getSortedColumns(t) {
        return this.table.getColumns(this.getColumnOrder(t));
      }
      load() {
        return r(this, "afterLoad", {
          table: this.table
        }), Promise.resolve(this);
      }
      on(t, e) {
        return o(this, t, e);
      }
      save() {
        return r(this, "saveError", {
          table: this.table
        }), Promise.reject(Error("Not implemented"));
      }
      setColumnOrder(t) {
        for (let e = 0, s = t.length; e < s; ++e) this.describeColumn(t[e], {
          index: e
        });
      }
      setModifierOptions(t) {
        let s = t && e.types[t.type];
        return this.table.setModifier(s ? new s(t) : void 0).then(() => this);
      }
      startPolling(e = 1e3) {
        let s = this;
        t.clearTimeout(s._polling), s._polling = t.setTimeout(() => s.load().catch(t => s.emit({
          type: "loadError",
          error: t,
          table: s.table
        })).then(() => {
          s._polling && s.startPolling(e);
        }), e);
      }
      stopPolling() {
        t.clearTimeout(this._polling), delete this._polling;
      }
      whatIs(t) {
        return this.metadata.columns[t];
      }
    }
    return (n = h || (h = {})).types = {}, n.registerType = function (t, e) {
      return !!t && !n.types[t] && !!(n.types[t] = e);
    }, h;
  }), s(e, "Data/Connectors/CSVConnector.js", [e["Data/Converters/CSVConverter.js"], e["Data/Connectors/DataConnector.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
      merge: i
    } = s;
    class n extends e {
      constructor(e) {
        let s = i(n.defaultOptions, e);
        super(s), this.converter = new t(s), this.options = s, s.enablePolling && this.startPolling(1e3 * Math.max(s.dataRefreshRate || 0, 1));
      }
      load(t) {
        let e = this,
          s = e.converter,
          i = e.table,
          {
            csv: n,
            csvURL: o,
            dataModifier: r
          } = e.options;
        return e.emit({
          type: "load",
          csv: n,
          detail: t,
          table: i
        }), Promise.resolve(o ? fetch(o).then(t => t.text()) : n || "").then(t => (t && (i.deleteColumns(), s.parse({
          csv: t
        }), i.setColumns(s.getTable().getColumns())), e.setModifierOptions(r).then(() => t))).then(s => (e.emit({
          type: "afterLoad",
          csv: s,
          detail: t,
          table: i
        }), e)).catch(s => {
          throw e.emit({
            type: "loadError",
            detail: t,
            error: s,
            table: i
          }), s;
        });
      }
    }
    return n.defaultOptions = {
      csv: "",
      csvURL: "",
      enablePolling: !1,
      dataRefreshRate: 1,
      firstRowAsNames: !0
    }, e.registerType("CSV", n), n;
  }), s(e, "Data/Converters/GoogleSheetsConverter.js", [e["Data/Converters/DataConverter.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s,
      uniqueKey: i
    } = e;
    class n extends t {
      constructor(t) {
        let e = s(n.defaultOptions, t);
        super(e), this.columns = [], this.header = [], this.options = e;
      }
      parse(t, e) {
        let n;
        let o = s(this.options, t),
          r = (o.json?.values || []).map(t => t.slice());
        if (0 === r.length) return !1;
        this.header = [], this.columns = [], this.emit({
          type: "parse",
          columns: this.columns,
          detail: e,
          headers: this.header
        });
        let {
          beforeParse: a,
          json: l
        } = o;
        a && l && (r = a(l.values)), this.columns = r;
        for (let t = 0, e = r.length; t < e; t++) {
          n = r[t], this.header[t] = o.firstRowAsNames ? `${n.shift()}` : i();
          for (let e = 0, s = n.length; e < s; ++e) if (n[e] && "string" == typeof n[e]) {
            let s = this.asGuessedType(n[e]);
            s instanceof Date && (s = s.getTime()), this.columns[t][e] = s;
          }
        }
        this.emit({
          type: "afterParse",
          columns: this.columns,
          detail: e,
          headers: this.header
        });
      }
      getTable() {
        return t.getTableFromColumns(this.columns, this.header);
      }
    }
    return n.defaultOptions = {
      ...t.defaultOptions
    }, n;
  }), s(e, "Data/Connectors/GoogleSheetsConnector.js", [e["Data/Connectors/DataConnector.js"], e["Data/Converters/GoogleSheetsConverter.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
      merge: i,
      pick: n
    } = s;
    class o extends t {
      constructor(t) {
        let s = i(o.defaultOptions, t);
        super(s), this.converter = new e(s), this.options = s;
      }
      load(t) {
        let e = this,
          s = e.converter,
          i = e.table,
          {
            dataModifier: n,
            dataRefreshRate: r,
            enablePolling: a,
            firstRowAsNames: l,
            googleAPIKey: h,
            googleSpreadsheetKey: c
          } = e.options,
          d = o.buildFetchURL(h, c, e.options);
        if (e.emit({
          type: "load",
          detail: t,
          table: i,
          url: d
        }), !URL.canParse(d)) throw Error("Invalid URL: " + d);
        return fetch(d).then(t => t.json()).then(t => {
          if ("object" == typeof t && t && "object" == typeof t.error && t.error && "number" == typeof t.error.code && "string" == typeof t.error.message && "string" == typeof t.error.status) throw Error(t.error.message);
          return s.parse({
            firstRowAsNames: l,
            json: t
          }), i.deleteColumns(), i.setColumns(s.getTable().getColumns()), e.setModifierOptions(n);
        }).then(() => (e.emit({
          type: "afterLoad",
          detail: t,
          table: i,
          url: d
        }), a && setTimeout(() => e.load(), 1e3 * Math.max(r || 0, 1)), e)).catch(s => {
          throw e.emit({
            type: "loadError",
            detail: t,
            error: s,
            table: i
          }), s;
        });
      }
    }
    return o.defaultOptions = {
      googleAPIKey: "",
      googleSpreadsheetKey: "",
      enablePolling: !1,
      dataRefreshRate: 2,
      firstRowAsNames: !0
    }, function (t) {
      let e = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      function s(t = {}) {
        let {
          endColumn: i,
          endRow: o,
          googleSpreadsheetRange: r,
          startColumn: a,
          startRow: l
        } = t;
        return r || (e[a || 0] || "A") + (Math.max(l || 0, 0) + 1) + ":" + (e[n(i, 25)] || "Z") + (o ? Math.max(o, 0) : "Z");
      }
      t.buildFetchURL = function (t, e, i = {}) {
        let n = new URL(`https://sheets.googleapis.com/v4/spreadsheets/${e}/values/`),
          o = i.onlyColumnNames ? "A1:Z1" : s(i);
        n.pathname += o;
        let r = n.searchParams;
        return r.set("alt", "json"), i.onlyColumnNames || (r.set("dateTimeRenderOption", "FORMATTED_STRING"), r.set("majorDimension", "COLUMNS"), r.set("valueRenderOption", "UNFORMATTED_VALUE")), r.set("prettyPrint", "false"), r.set("key", t), n.href;
      }, t.buildQueryRange = s;
    }(o || (o = {})), t.registerType("GoogleSheets", o), o;
  }), s(e, "Data/Converters/HTMLTableConverter.js", [e["Data/Converters/DataConverter.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s
    } = e;
    class i extends t {
      constructor(t) {
        let e = s(i.defaultOptions, t);
        super(e), this.columns = [], this.headers = [], this.options = e, e.tableElement && (this.tableElement = e.tableElement, this.tableElementID = e.tableElement.id);
      }
      export(t, e = this.options) {
        let s = !1 !== e.firstRowAsNames,
          i = e.useMultiLevelHeaders,
          n = t.getSortedColumns(e.usePresentationOrder),
          o = Object.keys(n),
          r = [],
          a = o.length,
          l = [],
          h = "";
        if (s) {
          let t = [];
          if (i) {
            for (let e of o) {
              let s = (n[e].shift() || "").toString();
              t.push(s);
            }
            h = this.getTableHeaderHTML(o, t, e);
          } else h = this.getTableHeaderHTML(void 0, o, e);
        }
        for (let t = 0; t < a; t++) {
          let e = n[o[t]],
            s = e.length;
          for (let i = 0; i < s; i++) {
            let s = e[i];
            l[i] || (l[i] = []), "string" == typeof s || "number" == typeof s || void 0 === s || (s = (s || "").toString()), l[i][t] = this.getCellHTMLFromValue(t ? "td" : "th", null, t ? "" : 'scope="row"', s), t === a - 1 && r.push("<tr>" + l[i].join("") + "</tr>");
          }
        }
        let c = "";
        return e.tableCaption && (c = '<caption class="highcharts-table-caption">' + e.tableCaption + "</caption>"), "<table>" + c + h + "<tbody>" + r.join("") + "</tbody></table>";
      }
      getCellHTMLFromValue(t, e, s, i, n) {
        let o = i,
          r = "text" + (e ? " " + e : "");
        return "number" == typeof o ? (o = o.toString(), "," === n && (o = o.replace(".", n)), r = "number") : i || (o = "", r = "empty"), "<" + t + (s ? " " + s : "") + ' class="' + r + '">' + o + "</" + t + ">";
      }
      getTableHeaderHTML(t = [], e = [], s = this.options) {
        let {
            useMultiLevelHeaders: i,
            useRowspanHeaders: n
          } = s,
          o = "<thead>",
          r = 0,
          a = e && e.length,
          l,
          h = 0,
          c;
        if (i && t && e && !function (t, e) {
          let s = t.length;
          if (e.length !== s) return !1;
          for (; --s;) if (t[s] !== e[s]) return !1;
          return !0;
        }(t, e)) {
          for (o += "<tr>"; r < a; ++r) (l = t[r]) === t[r + 1] ? ++h : h ? (o += this.getCellHTMLFromValue("th", "highcharts-table-topheading", 'scope="col" colspan="' + (h + 1) + '"', l), h = 0) : (l === e[r] ? n ? (c = 2, delete e[r]) : (c = 1, e[r] = "") : c = 1, o += this.getCellHTMLFromValue("th", "highcharts-table-topheading", 'scope="col"' + (c > 1 ? ' valign="top" rowspan="' + c + '"' : ""), l));
          o += "</tr>";
        }
        if (e) {
          for (o += "<tr>", r = 0, a = e.length; r < a; ++r) void 0 !== e[r] && (o += this.getCellHTMLFromValue("th", null, 'scope="col"', e[r]));
          o += "</tr>";
        }
        return o + "</thead>";
      }
      parse(t, e) {
        let i = [],
          n = [],
          o = s(this.options, t),
          {
            endRow: r,
            startColumn: a,
            endColumn: l,
            firstRowAsNames: h
          } = o,
          c = o.tableElement || this.tableElement;
        if (!(c instanceof HTMLElement)) {
          this.emit({
            type: "parseError",
            columns: i,
            detail: e,
            headers: n,
            error: "Not a valid HTML Table"
          });
          return;
        }
        this.tableElement = c, this.tableElementID = c.id, this.emit({
          type: "parse",
          columns: this.columns,
          detail: e,
          headers: this.headers
        });
        let d = c.getElementsByTagName("tr"),
          u = d.length,
          p = 0,
          m,
          {
            startRow: f
          } = o;
        if (h && u) {
          let t = d[0].children,
            e = t.length;
          for (let s = a; s < e && !(s > l); s++) ("TD" === (m = t[s]).tagName || "TH" === m.tagName) && n.push(m.innerHTML);
          f++;
        }
        for (; p < u;) {
          if (p >= f && p <= r) {
            let t = d[p].children,
              e = t.length,
              s = 0;
            for (; s < e;) {
              let e = s - a,
                n = i[e];
              if (("TD" === (m = t[s]).tagName || "TH" === m.tagName) && s >= a && s <= l) {
                i[e] || (i[e] = []);
                let t = this.asGuessedType(m.innerHTML);
                t instanceof Date && (t = t.getTime()), i[e][p - f] = t;
                let s = 1;
                for (; p - f >= s && void 0 === n[p - f - s];) n[p - f - s] = null, s++;
              }
              s++;
            }
          }
          p++;
        }
        this.columns = i, this.headers = n, this.emit({
          type: "afterParse",
          columns: i,
          detail: e,
          headers: n
        });
      }
      getTable() {
        return t.getTableFromColumns(this.columns, this.headers);
      }
    }
    return i.defaultOptions = {
      ...t.defaultOptions,
      useRowspanHeaders: !0,
      useMultiLevelHeaders: !0
    }, i;
  }), s(e, "Data/Connectors/HTMLTableConnector.js", [e["Data/Connectors/DataConnector.js"], e["Core/Globals.js"], e["Data/Converters/HTMLTableConverter.js"], e["Core/Utilities.js"]], function (t, e, s, i) {
    let {
        win: n
      } = e,
      {
        merge: o
      } = i;
    class r extends t {
      constructor(t) {
        let e = o(r.defaultOptions, t);
        super(e), this.converter = new s(e), this.options = e;
      }
      load(t) {
        let e;
        let s = this,
          i = s.converter,
          r = s.table,
          {
            dataModifier: a,
            table: l
          } = s.options;
        if (s.emit({
          type: "load",
          detail: t,
          table: r,
          tableElement: s.tableElement
        }), "string" == typeof l ? (s.tableID = l, e = n.document.getElementById(l)) : (e = l, s.tableID = e.id), s.tableElement = e || void 0, !s.tableElement) {
          let e = "HTML table not provided, or element with ID not found";
          return s.emit({
            type: "loadError",
            detail: t,
            error: e,
            table: r
          }), Promise.reject(Error(e));
        }
        return i.parse(o({
          tableElement: s.tableElement
        }, s.options), t), r.deleteColumns(), r.setColumns(i.getTable().getColumns()), s.setModifierOptions(a).then(() => (s.emit({
          type: "afterLoad",
          detail: t,
          table: r,
          tableElement: s.tableElement
        }), s));
      }
    }
    return r.defaultOptions = {
      table: ""
    }, t.registerType("HTMLTable", r), r;
  }), s(e, "Data/Converters/JSONConverter.js", [e["Data/Converters/DataConverter.js"], e["Data/DataTable.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
      error: i,
      isArray: n,
      merge: o,
      objectEach: r
    } = s;
    class a extends t {
      constructor(t) {
        let s = o(a.defaultOptions, t);
        super(s), this.columns = [], this.headers = [], this.options = s, this.table = new e();
      }
      parse(t, e) {
        let {
            beforeParse: s,
            orientation: a,
            firstRowAsNames: l,
            columnNames: h
          } = t = o(this.options, t),
          c = t.data;
        if (c) {
          if (this.columns = [], this.emit({
            type: "parse",
            columns: this.columns,
            detail: e,
            headers: this.headers
          }), s && (c = s(c)), c = c.slice(), "columns" === a) for (let t = 0, e = c.length; t < e; t++) {
            let e = c[t];
            if (!(e instanceof Array)) return;
            this.headers instanceof Array ? (l ? this.headers.push(`${e.shift()}`) : h && h instanceof Array && this.headers.push(h[t]), this.table.setColumn(this.headers[t] || t.toString(), e)) : i("JSONConverter: Invalid `columnNames` option.", !1);
          } else if ("rows" === a) {
            l ? this.headers = c.shift() : h && (this.headers = h);
            for (let t = 0, e = c.length; t < e; t++) {
              let e = c[t];
              if (n(e)) for (let t = 0, s = e.length; t < s; t++) this.columns.length < t + 1 && this.columns.push([]), this.columns[t].push(e[t]), this.headers instanceof Array ? this.table.setColumn(this.headers[t] || t.toString(), this.columns[t]) : i("JSONConverter: Invalid `columnNames` option.", !1);else {
                let s = this.headers;
                if (s && !(s instanceof Array)) {
                  let t = {};
                  r(s, (s, i) => {
                    t[i] = s.reduce((t, e) => t[e], e);
                  }), e = t;
                }
                this.table.setRows([e], t);
              }
            }
          }
          this.emit({
            type: "afterParse",
            columns: this.columns,
            detail: e,
            headers: this.headers
          });
        }
      }
      getTable() {
        return this.table;
      }
    }
    return a.defaultOptions = {
      ...t.defaultOptions,
      data: [],
      orientation: "rows"
    }, a;
  }), s(e, "Data/Connectors/JSONConnector.js", [e["Data/Connectors/DataConnector.js"], e["Core/Utilities.js"], e["Data/Converters/JSONConverter.js"]], function (t, e, s) {
    let {
      merge: i
    } = e;
    class n extends t {
      constructor(t) {
        let e = i(n.defaultOptions, t);
        super(e), this.converter = new s(e), this.options = e, e.enablePolling && this.startPolling(1e3 * Math.max(e.dataRefreshRate || 0, 1));
      }
      load(t) {
        let e = this,
          s = e.converter,
          i = e.table,
          {
            data: n,
            dataUrl: o,
            dataModifier: r
          } = e.options;
        return e.emit({
          type: "load",
          data: n,
          detail: t,
          table: i
        }), Promise.resolve(o ? fetch(o).then(t => t.json()) : n || []).then(t => (t && (i.deleteColumns(), s.parse({
          data: t
        }), i.setColumns(s.getTable().getColumns())), e.setModifierOptions(r).then(() => t))).then(s => (e.emit({
          type: "afterLoad",
          data: s,
          detail: t,
          table: i
        }), e)).catch(s => {
          throw e.emit({
            type: "loadError",
            detail: t,
            error: s,
            table: i
          }), s;
        });
      }
    }
    return n.defaultOptions = {
      data: [],
      enablePolling: !1,
      dataRefreshRate: 0,
      firstRowAsNames: !0,
      orientation: "rows"
    }, t.registerType("JSON", n), n;
  }), s(e, "Data/Modifiers/ChainModifier.js", [e["Data/Modifiers/DataModifier.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s
    } = e;
    class i extends t {
      constructor(e, ...n) {
        super(), this.chain = n, this.options = s(i.defaultOptions, e);
        let o = this.options.chain || [];
        for (let e = 0, s = o.length, i, r; e < s; ++e) (i = o[e]).type && (r = t.types[i.type]) && n.push(new r(i));
      }
      add(t, e) {
        this.emit({
          type: "addModifier",
          detail: e,
          modifier: t
        }), this.chain.push(t), this.emit({
          type: "addModifier",
          detail: e,
          modifier: t
        });
      }
      clear(t) {
        this.emit({
          type: "clearChain",
          detail: t
        }), this.chain.length = 0, this.emit({
          type: "afterClearChain",
          detail: t
        });
      }
      async modify(t, e) {
        let s = this.options.reverse ? this.chain.slice().reverse() : this.chain.slice();
        t.modified === t && (t.modified = t.clone(!1, e));
        let i = t;
        for (let n = 0, o = s.length; n < o; ++n) {
          try {
            await s[n].modify(i, e);
          } catch (s) {
            throw this.emit({
              type: "error",
              detail: e,
              table: t
            }), s;
          }
          i = i.modified;
        }
        return t.modified = i, t;
      }
      modifyCell(t, e, s, i, n) {
        let o = this.options.reverse ? this.chain.reverse() : this.chain;
        if (o.length) {
          let r = t.clone();
          for (let t = 0, a = o.length; t < a; ++t) o[t].modifyCell(r, e, s, i, n), r = r.modified;
          t.modified = r;
        }
        return t;
      }
      modifyColumns(t, e, s, i) {
        let n = this.options.reverse ? this.chain.reverse() : this.chain.slice();
        if (n.length) {
          let o = t.clone();
          for (let t = 0, r = n.length; t < r; ++t) n[t].modifyColumns(o, e, s, i), o = o.modified;
          t.modified = o;
        }
        return t;
      }
      modifyRows(t, e, s, i) {
        let n = this.options.reverse ? this.chain.reverse() : this.chain.slice();
        if (n.length) {
          let o = t.clone();
          for (let t = 0, r = n.length; t < r; ++t) n[t].modifyRows(o, e, s, i), o = o.modified;
          t.modified = o;
        }
        return t;
      }
      modifyTable(t, e) {
        this.emit({
          type: "modify",
          detail: e,
          table: t
        });
        let s = this.options.reverse ? this.chain.reverse() : this.chain.slice(),
          i = t.modified;
        for (let t = 0, n = s.length; t < n; ++t) i = s[t].modifyTable(i, e).modified;
        return t.modified = i, this.emit({
          type: "afterModify",
          detail: e,
          table: t
        }), t;
      }
      remove(t, e) {
        let s = this.chain;
        this.emit({
          type: "removeModifier",
          detail: e,
          modifier: t
        }), s.splice(s.indexOf(t), 1), this.emit({
          type: "afterRemoveModifier",
          detail: e,
          modifier: t
        });
      }
    }
    return i.defaultOptions = {
      type: "Chain"
    }, t.registerType("Chain", i), i;
  }), s(e, "Data/Modifiers/InvertModifier.js", [e["Data/Modifiers/DataModifier.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s
    } = e;
    class i extends t {
      constructor(t) {
        super(), this.options = s(i.defaultOptions, t);
      }
      modifyCell(t, e, s, i, n) {
        let o = t.modified,
          r = o.getRowIndexBy("columnNames", e);
        return void 0 === r ? o.setColumns(this.modifyTable(t.clone()).getColumns(), void 0, n) : o.setCell(`${s}`, r, i, n), t;
      }
      modifyColumns(t, e, s, i) {
        let n = t.modified,
          o = n.getColumn("columnNames") || [],
          r = t.getColumnNames(),
          a = t.getRowCount() !== o.length;
        if (!a) {
          for (let t = 0, e = r.length; t < e; ++t) if (r[t] !== o[t]) {
            a = !0;
            break;
          }
        }
        if (a) return this.modifyTable(t, i);
        r = Object.keys(e);
        for (let t = 0, o = r.length, a, l, h; t < o; ++t) {
          a = e[l = r[t]], h = n.getRowIndexBy("columnNames", l) || n.getRowCount();
          for (let t = 0, e = s, o = a.length; t < o; ++t, ++e) n.setCell(`${e}`, h, a[t], i);
        }
        return t;
      }
      modifyRows(t, e, s, i) {
        let n = t.getColumnNames(),
          o = t.modified,
          r = o.getColumn("columnNames") || [],
          a = t.getRowCount() !== r.length;
        if (!a) {
          for (let t = 0, e = n.length; t < e; ++t) if (n[t] !== r[t]) {
            a = !0;
            break;
          }
        }
        if (a) return this.modifyTable(t, i);
        for (let t = 0, r = s, a = e.length, l; t < a; ++t, ++r) if ((l = e[t]) instanceof Array) o.setColumn(`${r}`, l);else for (let t = 0, e = n.length; t < e; ++t) o.setCell(`${r}`, t, l[n[t]], i);
        return t;
      }
      modifyTable(t, e) {
        this.emit({
          type: "modify",
          detail: e,
          table: t
        });
        let s = t.modified;
        if (t.hasColumns(["columnNames"])) {
          let e = ((t.deleteColumns(["columnNames"]) || {}).columnNames || []).map(t => `${t}`),
            i = {};
          for (let s = 0, n = t.getRowCount(), o; s < n; ++s) (o = t.getRow(s)) && (i[e[s]] = o);
          s.deleteColumns(), s.setColumns(i);
        } else {
          let e = {};
          for (let s = 0, i = t.getRowCount(), n; s < i; ++s) (n = t.getRow(s)) && (e[`${s}`] = n);
          e.columnNames = t.getColumnNames(), s.deleteColumns(), s.setColumns(e);
        }
        return this.emit({
          type: "afterModify",
          detail: e,
          table: t
        }), t;
      }
    }
    return i.defaultOptions = {
      type: "Invert"
    }, t.registerType("Invert", i), i;
  }), s(e, "Data/Modifiers/RangeModifier.js", [e["Data/Modifiers/DataModifier.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s
    } = e;
    class i extends t {
      constructor(t) {
        super(), this.options = s(i.defaultOptions, t);
      }
      modifyTable(t, e) {
        this.emit({
          type: "modify",
          detail: e,
          table: t
        });
        let s = [],
          {
            additive: i,
            ranges: n,
            strict: o
          } = this.options;
        if (n.length) {
          let e = t.modified,
            r = t.getColumns(),
            a = [];
          for (let l = 0, h = n.length, c, d; l < h; ++l) if (c = n[l], !o || typeof c.minValue == typeof c.maxValue) {
            l > 0 && !i && (e.deleteRows(), e.setRows(a), e.setOriginalRowIndexes(s, !0), r = e.getColumns(), a = [], s = []), d = r[c.column] || [];
            for (let n = 0, r = d.length, l, h, u; n < r; ++n) {
              switch (typeof (l = d[n])) {
                default:
                  continue;
                case "boolean":
                case "number":
                case "string":
              }
              (!o || typeof l == typeof c.minValue) && l >= c.minValue && l <= c.maxValue && (i ? (h = t.getRow(n), u = t.getOriginalRowIndex(n)) : (h = e.getRow(n), u = e.getOriginalRowIndex(n)), h && (a.push(h), s.push(u)));
            }
          }
          e.deleteRows(), e.setRows(a), e.setOriginalRowIndexes(s);
        }
        return this.emit({
          type: "afterModify",
          detail: e,
          table: t
        }), t;
      }
    }
    return i.defaultOptions = {
      type: "Range",
      ranges: []
    }, t.registerType("Range", i), i;
  }), s(e, "Data/Modifiers/SortModifier.js", [e["Data/Modifiers/DataModifier.js"], e["Data/DataTable.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
      merge: i
    } = s;
    class n extends t {
      static ascending(t, e) {
        return (t || 0) < (e || 0) ? -1 : (t || 0) > (e || 0) ? 1 : 0;
      }
      static descending(t, e) {
        return (e || 0) < (t || 0) ? -1 : (e || 0) > (t || 0) ? 1 : 0;
      }
      constructor(t) {
        super(), this.options = i(n.defaultOptions, t);
      }
      getRowReferences(t) {
        let e = t.getRows(),
          s = [];
        for (let t = 0, i = e.length; t < i; ++t) s.push({
          index: t,
          row: e[t]
        });
        return s;
      }
      modifyCell(t, s, i, n, o) {
        let {
          orderByColumn: r,
          orderInColumn: a
        } = this.options;
        return s === r && (a ? (t.modified.setCell(s, i, n), t.modified.setColumn(a, this.modifyTable(new e({
          columns: t.getColumns([r, a])
        })).modified.getColumn(a))) : this.modifyTable(t, o)), t;
      }
      modifyColumns(t, s, i, n) {
        let {
            orderByColumn: o,
            orderInColumn: r
          } = this.options,
          a = Object.keys(s);
        return a.indexOf(o) > -1 && (r && s[a[0]].length ? (t.modified.setColumns(s, i), t.modified.setColumn(r, this.modifyTable(new e({
          columns: t.getColumns([o, r])
        })).modified.getColumn(r))) : this.modifyTable(t, n)), t;
      }
      modifyRows(t, s, i, n) {
        let {
          orderByColumn: o,
          orderInColumn: r
        } = this.options;
        return r && s.length ? (t.modified.setRows(s, i), t.modified.setColumn(r, this.modifyTable(new e({
          columns: t.getColumns([o, r])
        })).modified.getColumn(r))) : this.modifyTable(t, n), t;
      }
      modifyTable(t, e) {
        this.emit({
          type: "modify",
          detail: e,
          table: t
        });
        let s = t.getColumnNames(),
          i = t.getRowCount(),
          o = this.getRowReferences(t),
          {
            direction: r,
            orderByColumn: a,
            orderInColumn: l
          } = this.options,
          h = "asc" === r ? n.ascending : n.descending,
          c = s.indexOf(a),
          d = t.modified;
        if (-1 !== c && o.sort((t, e) => h(t.row[c], e.row[c])), l) {
          let t = [];
          for (let e = 0; e < i; ++e) t[o[e].index] = e;
          d.setColumns({
            [l]: t
          });
        } else {
          let t;
          let e = [],
            s = [];
          for (let n = 0; n < i; ++n) t = o[n], e.push(d.getOriginalRowIndex(t.index)), s.push(t.row);
          d.setRows(s, 0), d.setOriginalRowIndexes(e);
        }
        return this.emit({
          type: "afterModify",
          detail: e,
          table: t
        }), t;
      }
    }
    return n.defaultOptions = {
      type: "Sort",
      direction: "desc",
      orderByColumn: "y"
    }, t.registerType("Sort", n), n;
  }), s(e, "Dashboards/Accessibility/DashboardsAccessibility.js", [], function () {
    return class {
      constructor(t) {
        this.board = t, this.addTabIndexToCells();
      }
      addTabIndexToCells() {
        let t;
        let e = this.board.mountedComponents;
        for (let s = 0, i = e.length; s < i; ++s) (t = e[s].cell) && t.container && t.container.setAttribute("tabindex", -1);
      }
    };
  }), s(e, "Data/DataCursor.js", [], function () {
    class t {
      constructor(t = {}) {
        this.emittingRegister = [], this.listenerMap = {}, this.stateMap = t;
      }
      addListener(t, e, s) {
        let i = this.listenerMap[t] = this.listenerMap[t] || {};
        return (i[e] = i[e] || []).push(s), this;
      }
      buildEmittingTag(t) {
        return ("position" === t.cursor.type ? [t.table.id, t.cursor.column, t.cursor.row, t.cursor.state, t.cursor.type] : [t.table.id, t.cursor.columns, t.cursor.firstRow, t.cursor.lastRow, t.cursor.state, t.cursor.type]).join("\0");
      }
      emitCursor(e, s, i, n) {
        let o = e.id,
          r = s.state,
          a = this.listenerMap[o] && this.listenerMap[o][r];
        if (a) {
          let r = this.stateMap[o] = this.stateMap[o] ?? {},
            l = r[s.state] || [];
          n && (l.length || (r[s.state] = l), -1 === t.getIndex(s, l) && l.push(s));
          let h = {
            cursor: s,
            cursors: l,
            table: e
          };
          i && (h.event = i);
          let c = this.emittingRegister,
            d = this.buildEmittingTag(h);
          if (c.indexOf(d) >= 0) return this;
          try {
            this.emittingRegister.push(d);
            for (let t = 0, e = a.length; t < e; ++t) a[t].call(this, h);
          } finally {
            let t = this.emittingRegister.indexOf(d);
            t >= 0 && this.emittingRegister.splice(t, 1);
          }
        }
        return this;
      }
      remitCursor(e, s) {
        let i = this.stateMap[e] && this.stateMap[e][s.state];
        if (i) {
          let e = t.getIndex(s, i);
          e >= 0 && i.splice(e, 1);
        }
        return this;
      }
      removeListener(t, e, s) {
        let i = this.listenerMap[t] && this.listenerMap[t][e];
        if (i) {
          let t = i.indexOf(s);
          t >= 0 && i.splice(t, 1);
        }
        return this;
      }
    }
    return t.version = "1.0.0", function (t) {
      function e(t, e) {
        if ("range" === t.type) return t;
        let s = {
          type: "range",
          firstRow: t.row ?? (e && e.firstRow) ?? 0,
          lastRow: t.row ?? (e && e.lastRow) ?? Number.MAX_VALUE,
          state: t.state
        };
        return void 0 !== t.column && (s.columns = [t.column]), s;
      }
      t.getIndex = function (t, e) {
        if ("position" === t.type) {
          for (let s, i = 0, n = e.length; i < n; ++i) if ("position" === (s = e[i]).type && s.state === t.state && s.column === t.column && s.row === t.row) return i;
        } else {
          let s = JSON.stringify(t.columns);
          for (let i, n = 0, o = e.length; n < o; ++n) if ("range" === (i = e[n]).type && i.state === t.state && i.firstRow === t.firstRow && i.lastRow === t.lastRow && JSON.stringify(i.columns) === s) return n;
        }
        return -1;
      }, t.isEqual = function (t, e) {
        return "position" === t.type && "position" === e.type ? t.column === e.column && t.row === e.row && t.state === e.state : "range" === t.type && "range" === e.type && t.firstRow === e.firstRow && t.lastRow === e.lastRow && JSON.stringify(t.columns) === JSON.stringify(e.columns);
      }, t.isInRange = function (t, s) {
        "position" === s.type && (s = e(s)), "position" === t.type && (t = e(t, s));
        let i = t.columns,
          n = s.columns;
        return t.firstRow >= s.firstRow && t.lastRow <= s.lastRow && (!i || !n || i.every(t => n.indexOf(t) >= 0));
      }, t.toPositions = function (t) {
        if ("position" === t.type) return [t];
        let e = t.columns || [],
          s = [],
          i = t.state;
        for (let n = t.firstRow, o = t.lastRow; n < o; ++n) {
          if (!e.length) {
            s.push({
              type: "position",
              row: n,
              state: i
            });
            continue;
          }
          for (let t = 0, o = e.length; t < o; ++t) s.push({
            type: "position",
            column: e[t],
            row: n,
            state: i
          });
        }
        return s;
      }, t.toRange = e;
    }(t || (t = {})), t;
  }), s(e, "Dashboards/Serializable.js", [], function () {
    var t;
    return function (t) {
      let e = {},
        s = {};
      t.fromJSON = function (t) {
        let i = t.$class;
        if ("string" != typeof i) throw Error("JSON has no $class property.");
        let n = e[i];
        if (n) return n.fromJSON(t);
        let o = s[i];
        if (o) return o.fromJSON(t);
        throw Error(`'${i}' unknown.`);
      }, t.registerClassPrototype = function (t, s) {
        if (e[t]) throw Error("A serializer for '" + t + "' is already registered.");
        e[t] = s;
      }, t.registerHelper = function (t) {
        if (s[t.$class]) throw Error("A serializer for '" + t.$class + "' is already registered.");
        s[t.$class] = t;
      }, t.toJSON = function (t) {
        let e;
        if ("function" == typeof t.fromJSON && "function" == typeof t.toJSON) return t.toJSON();
        let i = Object.keys(s),
          n = i.length;
        for (let o = 0; o < n; ++o) if ((e = s[i[o]]).jsonSupportFor(t)) return e.toJSON(t);
        throw Error("Object is not supported.");
      };
    }(t || (t = {})), t;
  }), s(e, "Dashboards/SerializeHelper/DataCursorHelper.js", [e["Data/DataCursor.js"], e["Dashboards/Serializable.js"]], function (t, e) {
    let s = {
      $class: "Data.DataCursor",
      fromJSON: function (e) {
        return new t(e.stateMap);
      },
      jsonSupportFor: function (e) {
        return e instanceof t;
      },
      toJSON: function (t) {
        let e, s, i, n, o;
        let r = t.stateMap,
          a = {},
          l = Object.keys(t.stateMap);
        for (let t = 0, h = l.length; t < h; ++t) {
          o = Object.keys(r[i = l[t]]), a[i] = {};
          for (let t = 0, l = o.length; t < l; ++t) {
            n = o[t], e = r[i][n], a[i][n] = s = [];
            for (let t = 0, i = e.length; t < i; ++t) s.push({
              ...e[t]
            });
          }
        }
        return {
          $class: "Data.DataCursor",
          stateMap: a
        };
      }
    };
    return e.registerHelper(s), s;
  }), s(e, "Data/DataPoolDefaults.js", [], function () {
    return {
      connectors: []
    };
  }), s(e, "Data/DataPool.js", [e["Data/Connectors/DataConnector.js"], e["Data/DataPoolDefaults.js"], e["Core/Utilities.js"]], function (t, e, s) {
    class i {
      constructor(t = e) {
        t.connectors = t.connectors || [], this.connectors = {}, this.options = t, this.waiting = {};
      }
      emit(t) {
        s.fireEvent(this, t.type, t);
      }
      getConnector(t) {
        let e = this.connectors[t];
        if (e) return Promise.resolve(e);
        let s = this.waiting[t];
        if (!s) {
          s = this.waiting[t] = [];
          let e = this.getConnectorOptions(t);
          if (!e) throw Error(`Connector '${t}' not found.`);
          this.loadConnector(e).then(e => {
            delete this.waiting[t];
            for (let t = 0, i = s.length; t < i; ++t) s[t][0](e);
          }).catch(e => {
            delete this.waiting[t];
            for (let t = 0, i = s.length; t < i; ++t) s[t][1](e);
          });
        }
        return new Promise((t, e) => {
          s.push([t, e]);
        });
      }
      getConnectorIds() {
        let t = this.options.connectors,
          e = [];
        for (let s = 0, i = t.length; s < i; ++s) e.push(t[s].id);
        return e;
      }
      getConnectorOptions(t) {
        let e = this.options.connectors;
        for (let s = 0, i = e.length; s < i; ++s) if (e[s].id === t) return e[s];
      }
      getConnectorTable(t) {
        return this.getConnector(t).then(t => t.table);
      }
      isNewConnector(t) {
        return !this.connectors[t];
      }
      loadConnector(e) {
        return new Promise((s, i) => {
          this.emit({
            type: "load",
            options: e
          });
          let n = t.types[e.type];
          if (!n) throw Error(`Connector type not found. (${e.type})`);
          new n(e.options).load().then(t => {
            this.connectors[e.id] = t, this.emit({
              type: "afterLoad",
              options: e
            }), s(t);
          }).catch(i);
        });
      }
      on(t, e) {
        return s.addEvent(this, t, e);
      }
      setConnectorOptions(t) {
        let e = this.options.connectors,
          s = this.connectors;
        this.emit({
          type: "setConnectorOptions",
          options: t
        });
        for (let s = 0, i = e.length; s < i; ++s) if (e[s].id === t.id) {
          e.splice(s, 1);
          break;
        }
        s[t.id] && (s[t.id].stopPolling(), delete s[t.id]), e.push(t), this.emit({
          type: "afterSetConnectorOptions",
          options: t
        });
      }
    }
    return i.version = "1.0.0", i;
  }), s(e, "Dashboards/Layout/Row.js", [e["Dashboards/Globals.js"], e["Dashboards/Layout/Cell.js"], e["Dashboards/Layout/GUIElement.js"], e["Core/Utilities.js"], e["Dashboards/EditMode/EditGlobals.js"]], function (t, e, s, i, n) {
    let {
      pick: o,
      defined: r,
      merge: a,
      objectEach: l,
      fireEvent: h
    } = i;
    class c extends s {
      static fromJSON(t, e) {
        if (e) {
          let s = t.options,
            i = s.containerId || "";
          return i && e.copyId && (i = i + "_" + e.copyId), new c(e, {
            id: i,
            parentContainerId: e.container && e.container.id || s.parentContainerId,
            cellsJSON: s.cells,
            style: s.style
          });
        }
      }
      static setContainerHeight(t, e) {
        e && (t.style.height = e + "px");
      }
      constructor(e, s, i) {
        super(), this.type = t.guiElementType.row, this.layout = e, this.cells = [], this.options = s, this.isVisible = !0;
        let n = document.getElementById(s.parentContainerId || "") || e.container,
          o = e.options || {},
          r = o.rowClassName || "";
        this.container = this.getElementContainer({
          render: e.board.guiEnabled,
          parentContainer: n,
          attribs: {
            id: s.id,
            className: t.classNames.row + " " + r
          },
          element: i,
          elementId: s.id,
          style: a(o.style, s.style)
        }), this.options.cells && this.setCells(), s.cellsJSON && !this.cells.length && this.setCellsFromJSON(s.cellsJSON);
      }
      setCells() {
        let t, e, s;
        let i = (this.layout.options || {}).cellClassName || "",
          n = o(this.options.cells, this.container && this.container.getElementsByClassName(i)) || [];
        for (e = 0, s = n.length; e < s; ++e) t = n[e], this.addCell(this.layout.board.guiEnabled ? t : {
          id: ""
        }, t instanceof HTMLElement ? t : void 0);
      }
      setCellsFromJSON(t) {
        let s, i;
        let n = [];
        for (let o = 0, r = t.length; o < r; ++o) i = t[o], (s = e.fromJSON({
          $class: i.$class,
          options: {
            containerId: i.options.containerId,
            parentContainerId: i.options.parentContainerId,
            width: i.options.width,
            height: i.options.height,
            style: i.options.style,
            layoutJSON: i.options.layoutJSON,
            mountedComponentJSON: void 0
          }
        }, this)) && (this.cells.push(s), i.options.mountedComponentJSON && n.push({
          cell: s,
          mountedComponentJSON: i.options.mountedComponentJSON
        }));
        for (let t = 0, e = n.length; t < e; ++t) n[t].cell.mountComponentFromJSON(n[t].mountedComponentJSON);
      }
      addCell(t, s, i) {
        let n = new e(this, t, s);
        return r(i) ? this.mountCell(n, i) : this.cells.push(n), this.layout.board.editMode && this.layout.board.editMode.setCellEvents(n), n;
      }
      destroy() {
        let {
            layout: t
          } = this,
          e = [...this.cells];
        for (let t = 0, s = e?.length; t < s; ++t) e[t] && e[t].destroy();
        this.layout && (this.layout.unmountRow(this), super.destroy(), t.rows?.length === 0 && t.destroy());
      }
      toJSON() {
        let t = (this.layout.container || {}).id || "",
          e = [];
        for (let t = 0, s = this.cells.length; t < s; ++t) e.push(this.cells[t].toJSON());
        return {
          $class: "Dashboards.Layout.Row",
          options: {
            containerId: this.container.id,
            parentContainerId: t,
            cells: e,
            style: this.options.style
          }
        };
      }
      getOptions() {
        let t = [];
        for (let e = 0, s = this.cells.length; e < s; ++e) t.push(this.cells[e].getOptions());
        return {
          id: this.options.id,
          style: this.options.style,
          cells: t
        };
      }
      setSize(t) {
        c.setContainerHeight(this.container, t);
      }
      getCellIndex(t) {
        for (let e = 0, s = this.cells?.length; e < s; ++e) if (this.cells[e].id === t.id) return e;
      }
      mountCell(t, e = 0) {
        let s = this,
          i = s.cells[e],
          n = s.cells[e - 1];
        t.container && (i && i.container ? i.container.parentNode.insertBefore(t.container, i.container) : n && n.container ? n.container.parentNode.insertBefore(t.container, n.container.nextSibling) : n || i || !s.container || s.container.appendChild(t.container), s.cells.splice(e, 0, t), t.row = s, setTimeout(() => {
          h(s, "cellChange", {
            row: s,
            cell: t
          });
        }, 0));
      }
      unmountCell(t) {
        let e = this.getCellIndex(t);
        r(e) && this.cells.splice(e, 1), setTimeout(() => {
          h(this, "cellChange", {
            row: this,
            cell: t
          });
        }, 0);
      }
      getVisibleCells() {
        let t = [];
        for (let e = 0, s = this.cells.length; e < s; ++e) this.cells[e].isVisible && t.push(this.cells[e]);
        return t;
      }
      changeVisibility(t = !0, e) {
        super.changeVisibility(t, e), this.layout.getVisibleRows().length ? this.isVisible && !this.layout.isVisible && this.layout.show() : this.layout.hide();
      }
      show() {
        this.changeVisibility(!0, "flex");
      }
      setHighlight() {
        this.container.classList.toggle(n.classNames.rowContextHighlight);
      }
      getRowLevels() {
        let t, e;
        let i = {},
          n = [];
        for (let n = 0, o = this.cells.length; n < o; ++n) (t = this.cells[n]).isVisible && (i[(e = s.getOffsets(t)).top] || (i[e.top] = {
          top: e.top,
          bottom: e.bottom,
          cells: []
        }), i[e.top].bottom < e.bottom && (i[e.top].bottom = e.bottom), i[e.top].cells.push(t));
        return l(i, t => {
          n.push(t);
        }), n;
      }
      getRowLevelInfo(t) {
        let e;
        let s = this.getRowLevels();
        for (let i = 0, n = s.length; i < n; ++i) s[i].top <= t && s[i].bottom > t && (e = {
          index: i,
          rowLevels: s,
          rowLevel: s[i]
        });
        return e;
      }
    }
    return c;
  }), s(e, "Dashboards/Layout/Layout.js", [e["Dashboards/Utilities.js"], e["Core/Utilities.js"], e["Dashboards/Layout/Row.js"], e["Dashboards/Layout/GUIElement.js"], e["Dashboards/Globals.js"]], function (t, e, s, i, n) {
    let {
        uniqueKey: o
      } = t,
      {
        pick: r,
        defined: a
      } = e;
    class l extends i {
      static fromJSON(t, e, s) {
        let i = t.options,
          n = document.getElementById(t.options.containerId),
          r = new l(e, {
            id: i.containerId,
            copyId: n ? o() : "",
            parentContainerId: i.parentContainerId || e.container.id,
            rowsJSON: i.rows,
            style: i.style
          }, s);
        return r && !s && e.layouts.push(r), r;
      }
      static importLocal(t, e) {
        let s;
        let i = localStorage.getItem(n.classNamePrefix + t);
        return i && (s = l.fromJSON(JSON.parse(i), e)), s;
      }
      constructor(t, e, s) {
        super(), this.type = n.guiElementType.layout, this.board = t, this.rows = [], this.options = e, this.isVisible = !0;
        let i = s ? s.container : document.getElementById(e.parentContainerId || "") || t.layoutsWrapper;
        s ? (this.parentCell = s, this.level = s.row.layout.level + 1) : this.level = 0, e.copyId && (this.copyId = e.copyId);
        let o = (this.options || {}).rowClassName || "";
        this.container = this.getElementContainer({
          render: t.guiEnabled,
          parentContainer: i,
          attribs: {
            id: (e.id || "") + (this.copyId ? "_" + this.copyId : ""),
            className: n.classNames.layout + " " + o
          },
          elementId: e.id,
          style: this.options.style
        }), this.options.rows && this.setRows(), e.rowsJSON && !this.rows.length && this.setRowsFromJSON(e.rowsJSON);
      }
      setRows() {
        let t, e, s;
        let i = r(this.options.rows, this.container && this.container.getElementsByClassName(this.options.rowClassName || "")) || [];
        for (e = 0, s = i.length; e < s; ++e) t = i[e], this.addRow(this.board.guiEnabled ? t : {}, t instanceof HTMLElement ? t : void 0);
      }
      setRowsFromJSON(t) {
        let e;
        for (let i = 0, n = t.length; i < n; ++i) (e = s.fromJSON(t[i], this)) && this.rows.push(e);
      }
      addRow(t, e, i) {
        let n = new s(this, t, e);
        return a(i) ? this.mountRow(n, i) : this.rows.push(n), this.board.editMode && this.board.editMode.setRowEvents(n), n;
      }
      destroy() {
        for (let t = this.board.layouts.length - 1; t >= 0; t--) this.board.layouts[t] === this && this.board.layouts.splice(t, 1);
        this.parentCell && delete this.parentCell.nestedLayout;
        for (let t = this.rows.length - 1; t >= 0; t--) this.rows[t].destroy();
        this.parentCell && this.parentCell.destroy(), super.destroy();
      }
      exportLocal() {
        localStorage.setItem(n.classNamePrefix + this.options.id, JSON.stringify(this.toJSON()));
      }
      getRowIndex(t) {
        for (let e = 0, s = this.rows.length; e < s; ++e) if (this.rows[e] === t) return e;
      }
      mountRow(t, e) {
        let s = this.rows[e],
          i = this.rows[e - 1];
        t.container && (s && s.container ? s.container.parentNode.insertBefore(t.container, s.container) : i && i.container && i.container.parentNode.insertBefore(t.container, i.container.nextSibling), this.rows.splice(e, 0, t), t.layout = this);
      }
      unmountRow(t) {
        let e = this.getRowIndex(t);
        a(e) && this.rows.splice(e, 1);
      }
      getVisibleRows() {
        let t = [];
        for (let e = 0, s = this.rows.length; e < s; ++e) this.rows[e].isVisible && t.push(this.rows[e]);
        return t;
      }
      changeVisibility(t = !0) {
        super.changeVisibility(t), this.parentCell && (this.isVisible && !this.parentCell.isVisible ? this.parentCell.show() : !this.isVisible && this.parentCell.isVisible && this.parentCell.hide());
      }
      toJSON() {
        let t = (this.board.container || {}).id || "",
          e = [];
        for (let t = 0, s = this.rows.length; t < s; ++t) e.push(this.rows[t].toJSON());
        return {
          $class: "Dashboards.Layout",
          options: {
            containerId: this.container.id,
            parentContainerId: t,
            rows: e,
            style: this.options.style
          }
        };
      }
      getOptions() {
        let t = [];
        for (let e = 0, s = this.rows.length; e < s; ++e) t.push(this.rows[e].getOptions());
        return {
          id: this.options.id,
          layoutClassName: this.options.layoutClassName,
          rowClassName: this.options.rowClassName,
          cellClassName: this.options.cellClassName,
          style: this.options.style,
          rows: t
        };
      }
    }
    return l;
  }), s(e, "Dashboards/Board.js", [e["Dashboards/Actions/Bindings.js"], e["Dashboards/Components/ComponentRegistry.js"], e["Dashboards/Accessibility/DashboardsAccessibility.js"], e["Data/DataCursor.js"], e["Dashboards/SerializeHelper/DataCursorHelper.js"], e["Data/DataPool.js"], e["Dashboards/Globals.js"], e["Dashboards/Layout/Layout.js"], e["Dashboards/Serializable.js"], e["Dashboards/Components/HTMLComponent/HTMLComponent.js"], e["Core/Utilities.js"]], function (e, s, i, n, o, r, a, l, h, c, d) {
    var u;
    let {
      merge: p,
      addEvent: m,
      error: f,
      objectEach: g,
      uniqueKey: y
    } = d;
    class b {
      static board(t, e, s) {
        return new b(t, e).init(s);
      }
      constructor(t, e) {
        this.componentTypes = s.types, this.options = p(b.defaultOptions, e), this.dataPool = new r(e.dataPool), this.id = y(), this.guiEnabled = !!e.gui && this.options?.gui?.enabled, this.editModeEnabled = !!e.editMode && this.options?.editMode?.enabled, this.layouts = [], this.mountedComponents = [], this.initContainer(t), this.initEditMode(), this.dataCursor = new n(), this.index = a.boards.length, a.boards.push(this), this.a11y = new i(this);
      }
      init(t) {
        let e = this.options,
          s = e.components ? this.setComponents(e.components) : [];
        return (this.initEvents(), t) ? Promise.all(s).then(() => this) : this;
      }
      initEvents() {
        let e = this,
          s = () => {
            e.reflow();
          };
        if ("function" == typeof ResizeObserver) this.resizeObserver = new ResizeObserver(s), this.resizeObserver.observe(e.container);else {
          let e = m(t, "resize", s);
          m(this, "destroy", e);
        }
      }
      initContainer(e) {
        "string" == typeof e && (e = t.document.getElementById(e)), e || f(13, !0), this.container = e;
      }
      initEditMode() {
        if (Dashboards.EditMode) this.editMode = new Dashboards.EditMode(this, this.options.editMode);else if (this.editModeEnabled) throw Error("Missing layout.js module");
      }
      setComponents(t) {
        let s = [];
        for (let i = 0, n = t.length; i < n; ++i) s.push(e.addComponent(t[i], this));
        return s;
      }
      destroy() {
        let t = this;
        for (let e = 0, s = t.layouts?.length; e < s; ++e) t.layouts[e].destroy();
        this.resizeObserver?.unobserve(t.container), t.container?.remove(), g(t, function (e, s) {
          delete t[s];
        }), a.boards[this.index] = void 0;
      }
      exportLocal() {
        localStorage.setItem(a.classNamePrefix + "1", JSON.stringify(this.toJSON()));
      }
      importLayoutLocal(t) {
        return l.importLocal(t, this);
      }
      reflow() {
        if (this.editMode) {
          let t = this.editMode.tools;
          this.editMode.hideToolbars(["cell", "row"]), this.editMode.hideContextPointer(), t.contextMenu && t.contextMenu.updatePosition(t.contextButtonElement);
        }
      }
      fromJSON(t) {
        let e = t.options,
          s = new b(e.containerId, {
            componentOptions: e.componentOptions,
            dataPool: e.dataPool,
            layoutsJSON: e.layouts
          });
        return s.dataCursor = o.fromJSON(t.dataCursor), s;
      }
      toJSON() {
        let t = [];
        for (let e = 0, s = this.layouts.length; e < s; ++e) t.push(this.layouts[e].toJSON());
        return {
          $class: "Board",
          dataCursor: o.toJSON(this.dataCursor),
          options: {
            containerId: this.container.id,
            dataPool: this.options.dataPool,
            guiEnabled: this.guiEnabled,
            layouts: t,
            componentOptions: this.options.componentOptions
          }
        };
      }
      getOptions() {
        let t = {
          ...this.options,
          components: []
        };
        for (let e = 0, s = this.mountedComponents.length; e < s; ++e) this.mountedComponents[e].cell && this.mountedComponents[e].cell.mountedComponent && t.components?.push(this.mountedComponents[e].component.getOptions());
        if (this.guiEnabled) {
          t.gui = {
            layouts: []
          };
          for (let e = 0, s = this.layouts.length; e < s; ++e) t.gui.layouts?.push(this.layouts[e].getOptions());
        } else delete t.gui;
        return t;
      }
      getComponentById(t) {
        return this.mountedComponents.find(e => e.component.id === t)?.component;
      }
      getComponentByCellId(t) {
        return this.mountedComponents.find(e => e.cell.id === t)?.component;
      }
    }
    return (u = b || (b = {})).defaultOptions = {
      gui: {
        enabled: !0,
        layoutOptions: {
          rowClassName: void 0,
          cellClassName: void 0
        },
        layouts: []
      },
      components: []
    }, u.importLocal = function () {
      let t = localStorage.getItem(a.classNamePrefix + "1");
      if (t) try {
        return h.fromJSON(JSON.parse(t));
      } catch (t) {
        throw Error("" + t);
      }
    }, h.registerClassPrototype("Board", b.prototype), s.registerComponent("HTML", c), b;
  }), s(e, "Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridExtremesSync.js", [], function () {
    return {
      defaultOptions: {},
      syncPair: {
        emitter: void 0,
        handler: function () {
          if ("DataGrid" !== this.type) return;
          let t = this,
            e = this.sync.syncConfig.extremes,
            s = e.group ? ":" + e.group : "",
            {
              board: i
            } = t,
            n = e => {
              let s = e.cursor;
              if ("position" === s.type && t.dataGrid && "number" == typeof s?.row) {
                let {
                  row: e
                } = s;
                t.dataGrid.scrollToRow(e);
              }
            };
          if (i) return (() => {
            let {
              dataCursor: e
            } = i;
            if (!e) return;
            let o = t.connectorHandlers?.[0]?.connector?.table;
            o && e.addListener(o.id, "xAxis.extremes.min" + s, n);
          })(), () => {
            let e = t.connectorHandlers?.[0]?.connector?.table,
              {
                dataCursor: o
              } = i;
            e && o.removeListener(e.id, "xAxis.extremes.min" + s, n);
          };
        }
      }
    };
  }), s(e, "Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridHighlightSync.js", [e["Core/Utilities.js"]], function (t) {
    let {
      addEvent: e,
      removeEvent: s
    } = t;
    return {
      defaultOptions: {
        autoScroll: !1
      },
      syncPair: {
        emitter: function () {
          if ("DataGrid" !== this.type) return;
          let {
              dataGrid: t,
              board: i
            } = this,
            n = this.sync.syncConfig.highlight,
            o = n.group ? ":" + n.group : "";
          if (!i || !t || !n?.enabled) return;
          let {
              dataCursor: r
            } = i,
            a = t => {
              let e = this.getFirstConnector()?.table;
              if (e) {
                let s = t.row;
                r.emitCursor(e, {
                  type: "position",
                  row: parseInt(s.dataset.rowIndex, 10),
                  column: t.columnName,
                  state: "dataGrid.hoverRow" + o
                });
              }
            },
            l = () => {
              let t = this.getFirstConnector()?.table;
              t && r.emitCursor(t, {
                type: "position",
                state: "dataGrid.hoverOut" + o
              });
            };
          return e(t.container, "dataGridHover", a), e(t.container, "mouseout", l), function () {
            s(t.container, "dataGridHover", a), s(t.container, "mouseout", l);
          };
        },
        handler: function () {
          let t;
          if ("DataGrid" !== this.type) return;
          let e = this,
            {
              board: s
            } = e,
            i = e.sync.syncConfig.highlight,
            n = i.group ? ":" + i.group : "";
          if (!i?.enabled) return;
          let o = s => {
              let n = s.cursor;
              if ("position" !== n.type) return;
              let {
                  row: o
                } = n,
                {
                  dataGrid: r
                } = e;
              void 0 !== o && r && (i.autoScroll && r.scrollToRow(o - Math.round(r.rowElements.length / 2) + 1), t && clearTimeout(t), t = setTimeout(() => {
                let t = r.container.querySelector(`.highcharts-datagrid-row[data-row-index="${o}"]`);
                t && (r.toggleRowHighlight(t), r.hoveredRow = t);
              }, i.autoScroll ? 10 : 0));
            },
            r = () => {
              let {
                dataGrid: t
              } = e;
              t && t.toggleRowHighlight(void 0);
            };
          if (s) return (() => {
            let {
              dataCursor: t
            } = s;
            if (!t) return;
            let i = e.connectorHandlers?.[0]?.connector?.table;
            i && (t.addListener(i.id, "point.mouseOver" + n, o), t.addListener(i.id, "point.mouseOut" + n, r));
          })(), () => {
            let t = s.dataCursor,
              i = e.connectorHandlers?.[0]?.connector?.table;
            i && (t.removeListener(i.id, "point.mouseOver" + n, o), t.removeListener(i.id, "point.mouseOut" + n, r));
          };
        }
      }
    };
  }), s(e, "Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridVisibilitySync.js", [], function () {
    return {
      defaultOptions: {},
      syncPair: {
        emitter: void 0,
        handler: function () {
          if ("DataGrid" !== this.type) return;
          let t = this,
            e = this.sync.syncConfig.visibility,
            s = e.group ? ":" + e.group : "",
            {
              board: i
            } = t,
            n = e => {
              let i = e.cursor,
                n = t.dataGrid;
              if (!(n && "position" === i.type && i.column)) return;
              let o = i.column;
              n.update({
                columns: {
                  [o]: {
                    show: i.state !== "series.hide" + s
                  }
                }
              });
            };
          if (i) return (() => {
            let {
              dataCursor: e
            } = i;
            if (!e) return;
            let o = t.connectorHandlers?.[0]?.connector?.table;
            o && (e.addListener(o.id, "series.show" + s, n), e.addListener(o.id, "series.hide" + s, n));
          })(), () => {
            let e = t.connectorHandlers?.[0]?.connector?.table,
              {
                dataCursor: o
              } = i;
            e && (o.removeListener(e.id, "series.show" + s, n), o.removeListener(e.id, "series.hide" + s, n));
          };
        }
      }
    };
  }), s(e, "Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridSyncs.js", [e["Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridExtremesSync.js"], e["Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridHighlightSync.js"], e["Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridVisibilitySync.js"]], function (t, e, s) {
    return {
      defaultSyncPairs: {
        extremes: t.syncPair,
        highlight: e.syncPair,
        visibility: s.syncPair
      },
      defaultSyncOptions: {
        extremes: t.defaultOptions,
        highlight: e.defaultOptions,
        visibility: s.defaultOptions
      }
    };
  }), s(e, "Dashboards/Components/DataGridComponent/DataGridComponentDefaults.js", [e["Data/Converters/DataConverter.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      uniqueKey: s
    } = e;
    return {
      dataGridClassName: "dataGrid-container",
      dataGridID: "dataGrid-" + s(),
      dataGridOptions: {},
      editableOptions: [{
        name: "connectorName",
        propertyPath: ["connector", "id"],
        type: "select"
      }],
      onUpdate: (e, s) => {
        let i = e.target;
        if (i) {
          let e = i.closest(".highcharts-datagrid-row"),
            n = i.closest(".highcharts-datagrid-cell");
          if (e && e instanceof HTMLElement && n && n instanceof HTMLElement) {
            let o = e.dataset.rowIndex,
              {
                columnName: r
              } = n.dataset;
            if (void 0 !== o && void 0 !== r) {
              let e = s.table;
              if (e) {
                let s = new t().asGuessedType(i.value);
                s instanceof Date && (s = s.toString()), e.setCell(r, Number(o), s);
              }
            }
          }
        }
      }
    };
  }), s(e, "Dashboards/Components/DataGridComponent/DataGridComponent.js", [e["Dashboards/Components/Component.js"], e["Dashboards/Components/DataGridComponent/DataGridSyncs/DataGridSyncs.js"], e["Dashboards/Components/DataGridComponent/DataGridComponentDefaults.js"], e["Core/Utilities.js"]], function (t, e, s, i) {
    let {
      diffObjects: n,
      merge: o
    } = i;
    class r extends t {
      static fromJSON(t, e) {
        let s = new r(e, o(t.options, {
          dataGridOptions: JSON.parse(t.options.dataGridOptions || "")
        }));
        return s.emit({
          type: "fromJSON",
          json: t
        }), s;
      }
      constructor(t, e, s) {
        super(t, e = o(r.defaultOptions, e), s), this.connectorListeners = [], this.options = e, this.type = "DataGrid", this.options.dataGridClassName && this.contentElement.classList.add(this.options.dataGridClassName), this.options.dataGridID && (this.contentElement.id = this.options.dataGridID), this.dataGridOptions = this.options.dataGridOptions || {}, this.innerResizeTimeouts = [], this.on("afterSetConnectors", t => {
          let e = t.connectorHandlers?.[0]?.connector;
          e && this.disableEditingModifiedColumns(e);
        });
      }
      onTableChanged() {
        this.dataGrid && !this.dataGrid?.cellInputEl && this.dataGrid.update({
          dataTable: this.filterColumns()
        });
      }
      disableEditingModifiedColumns(t) {
        let e = this.getColumnOptions(t);
        this.dataGrid?.update({
          columns: e
        });
      }
      getColumnOptions(t) {
        let e = t.options.dataModifier;
        if (!e || "Math" !== e.type) return {};
        let s = e.columnFormulas;
        if (!s) return {};
        let i = {};
        for (let t = 0, e = s.length; t < e; ++t) i[s[t].column] = {
          editable: !1
        };
        return i;
      }
      async load() {
        this.emit({
          type: "load"
        }), await super.load();
        let t = this.getFirstConnector();
        if (t && !this.connectorListeners.length) {
          let e = this.connectorListeners;
          e.push(t.on("afterLoad", e => {
            e.table && t && t.table.setColumns(e.table.getColumns());
          })), e.push(t.table.on("afterSetCell", t => {
            let e = this.dataGrid,
              s = !0;
            if (e) {
              let i = e.rowElements[t.rowIndex],
                n = [];
              i && (n = Array.prototype.slice.call(i.childNodes)), n.forEach(e => {
                if (e.childElementCount > 0) {
                  let i = e.childNodes[0],
                    n = "string" == typeof t.cellValue ? i.value : +i.value;
                  e.dataset.columnName === t.columnName && n === t.cellValue && (s = !1);
                }
              });
            }
            s && this.update({});
          }));
        }
        return this.emit({
          type: "afterLoad"
        }), this;
      }
      render() {
        super.render(), this.dataGrid || (this.dataGrid = this.constructDataGrid());
        let t = this.getFirstConnector();
        return t && this.dataGrid && this.dataGrid.dataTable.modified !== t.table.modified && this.dataGrid.update({
          dataTable: this.filterColumns()
        }), this.sync.start(), this.emit({
          type: "afterRender"
        }), this.setupConnectorUpdate(), this;
      }
      resize(t, e) {
        this.dataGrid && super.resize(t, e);
      }
      async update(t) {
        let e = Array.isArray(t.connector) ? t.connector[0] : t.connector;
        if (this.connectorHandlers[0] && e?.id !== this.connectorHandlers[0]?.connectorId) {
          let t = this.connectorListeners;
          for (let e = 0, s = t.length; e < s; ++e) t[e]();
          t.length = 0;
        }
        await super.update(t), this.dataGrid && this.dataGrid.update(this.options.dataGridOptions || {}), this.emit({
          type: "afterUpdate"
        });
      }
      constructDataGrid() {
        if (r.DataGridNamespace) {
          let t = r.DataGridNamespace.DataGrid,
            e = this.getFirstConnector(),
            s = e ? this.getColumnOptions(e) : {};
          return this.dataGrid = new t(this.contentElement, {
            ...this.options.dataGridOptions,
            dataTable: this.options.dataGridOptions?.dataTable || this.filterColumns(),
            columns: o(s, this.options.dataGridOptions?.columns)
          }), this.dataGrid;
        }
        throw Error("DataGrid not connected.");
      }
      setupConnectorUpdate() {
        let {
            dataGrid: t
          } = this,
          e = this.getFirstConnector();
        e && t && t.on("cellClick", t => {
          "input" in t && t.input.addEventListener("keyup", t => this.options.onUpdate(t, e));
        });
      }
      filterColumns() {
        let t = this.getFirstConnector()?.table.modified,
          e = this.options.visibleColumns;
        if (t) {
          if (!e?.length) return t;
          let s = t.getColumnNames().filter(t => e?.length > 0 && !e.includes(t)),
            i = t.clone();
          return i.deleteColumns(s), i;
        }
      }
      getOptionsOnDrop(t) {
        let e = t.editMode.board.dataPool.getConnectorIds(),
          s = {
            cell: "",
            type: "DataGrid"
          };
        return e.length && (s = {
          ...s,
          connector: {
            id: e[0]
          }
        }), s;
      }
      toJSON() {
        let t = JSON.stringify(this.options.dataGridOptions),
          e = super.toJSON(),
          s = {
            ...e,
            options: {
              ...e.options,
              dataGridOptions: t
            }
          };
        return this.emit({
          type: "toJSON",
          json: s
        }), s;
      }
      getOptions() {
        return {
          ...n(this.options, r.defaultOptions),
          type: "DataGrid"
        };
      }
      destroy() {
        this.dataGrid?.containerResizeObserver.disconnect(), super.destroy();
      }
    }
    return r.predefinedSyncConfig = e, r.defaultOptions = o(t.defaultOptions, s), r;
  }), s(e, "Dashboards/Plugins/DataGridPlugin.js", [e["Dashboards/Components/DataGridComponent/DataGridComponent.js"]], function (t) {
    return {
      custom: {
        connectDataGrid: function (e) {
          t.DataGridNamespace = e;
        }
      },
      name: "DataGrid.DashboardsPlugin",
      onRegister: function (e) {
        let {
          ComponentRegistry: s
        } = e;
        s.registerComponent("DataGrid", t);
      },
      onUnregister: function (t) {}
    };
  }), s(e, "Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsExtremesSync.js", [e["Core/Utilities.js"]], function (t) {
    let {
      addEvent: e,
      isString: s
    } = t;
    return {
      defaultOptions: {},
      syncPair: {
        emitter: function () {
          if ("Highcharts" !== this.type) return;
          let t = this,
            i = [],
            {
              chart: n,
              board: o
            } = t,
            r = t.connectorHandlers?.[0]?.connector,
            a = r && r.table,
            l = this.sync.syncConfig.extremes,
            h = l.group ? ":" + l.group : "",
            {
              dataCursor: c
            } = o;
          if (a && n) {
            let o = e => {
                let i = !!e.resetSelection;
                if ((!e.trigger || e.trigger && "dashboards-sync" !== e.trigger) && !i) {
                  let i = e.target,
                    o = Object.keys(t.seriesFromConnector),
                    r = o.length > 0 ? n.get(o[0]) : i.series[0];
                  if (r) {
                    let n = r.points.filter(t => t.isInside || !1),
                      l = {
                        type: "position",
                        state: `${i.coll}.extremes.min${h}`
                      },
                      d = {
                        type: "position",
                        state: `${i.coll}.extremes.max${h}`
                      };
                    if (o.length > 0 && "xAxis" === i.coll && n.length) {
                      let e;
                      let o = (t.connectorHandlers[0]?.options).columnAssignment;
                      if (o) {
                        let t = o.find(t => t.seriesId === r.options.id);
                        if (t) {
                          let i = t.data;
                          e = s(i) ? i : Array.isArray(i) ? i[i.length - 1] : i.y;
                        }
                      }
                      e || (e = i.dateTime && (a.hasColumns(["x"]) ? "x" : r.options.id ?? r.name)), l.row = n[0].index, l.column = e, d.row = n[n.length - 1].index, d.column = e;
                    }
                    c.emitCursor(a, l, e, !0).emitCursor(a, d, e, !0);
                  }
                }
              },
              r = () => n.axes.map(t => e(t, "afterSetExtremes", o)),
              l = r(),
              d = () => {
                l.forEach(t => {
                  t();
                }), l = [];
              };
            i.push(e(n, "selection", t => {
              t.resetSelection && (d(), c.emitCursor(a, {
                type: "position",
                state: "chart.zoomOut" + h
              }, t), l.push(...r()));
            })), i.push(() => {
              c.remitCursor(a.id, {
                type: "position",
                state: "xAxis.extremes.min" + h
              }), c.remitCursor(a.id, {
                type: "position",
                state: "xAxis.extremes.max" + h
              }), d();
            });
          }
          return function () {
            i.forEach(t => {
              t();
            });
          };
        },
        handler: function () {
          if ("Highcharts" !== this.type) return;
          let t = this,
            e = this.sync.syncConfig.extremes,
            s = e.group ? ":" + e.group : "",
            {
              chart: i,
              board: n
            } = t;
          if (i && n && i.zooming?.type) {
            let e = i.zooming.type.split("").map(t => t + "Axis"),
              o = [];
            return e.forEach(e => {
              let r = t => {
                let {
                  cursor: s,
                  event: n
                } = t;
                if ("position" === s.type) {
                  let t = n?.target;
                  if (t && i) {
                    let s = i[e],
                      n = !1;
                    s.forEach(e => {
                      t.coll === e.coll && t !== e && null !== t.min && null !== t.max && (e.max !== t.max || e.min !== t.min) && (e.setExtremes(t.min, t.max, !1, void 0, {
                        trigger: "dashboards-sync"
                      }), n = !0);
                    }), n && !i.resetZoomButton && i.showResetZoom(), i.redraw();
                  }
                }
              };
              n && (() => {
                let {
                    dataCursor: a
                  } = n,
                  l = t.connectorHandlers?.[0]?.connector;
                if (l) {
                  let {
                    table: n
                  } = l;
                  a.addListener(n.id, `${e}.extremes.min${s}`, r), a.addListener(n.id, `${e}.extremes.max${s}`, r);
                  let h = () => {
                    i.zoomOut(), setTimeout(() => {
                      t.element.querySelectorAll(".highcharts-reset-zoom").forEach(t => {
                        t.remove();
                      });
                    });
                  };
                  a.addListener(n.id, "chart.zoomOut", h), o.push(() => {
                    a.removeListener(n.id, `${e}.extremes.min${s}`, r), a.removeListener(n.id, `${e}.extremes.max${s}`, r), a.removeListener(n.id, "chart.zoomOut" + s, h);
                  });
                }
              })();
            }), function () {
              o.forEach(t => {
                t();
              });
            };
          }
        }
      }
    };
  }), s(e, "Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsHighlightSync.js", [e["Dashboards/Utilities.js"]], function (t) {
    let {
      error: e
    } = t;
    function s(t, e) {
      let {
        ranges: s
      } = e;
      if (s) {
        let e = s.reduce((t, e) => (e.minValue > t.minValue && (t = e), t), s[0]),
          i = t.getRowIndexBy(e.column, e.minValue);
        if (i) return i;
      }
      return 0;
    }
    return {
      defaultOptions: {
        affectedSeriesId: null,
        highlightPoint: !0,
        showTooltip: !0,
        showCrosshair: !0
      },
      syncPair: {
        emitter: function () {
          if ("Highcharts" !== this.type) return;
          let {
              chart: t,
              board: e
            } = this,
            i = this.sync.syncConfig.highlight,
            n = i.group ? ":" + i.group : "";
          if (!i.enabled || !t) return;
          let {
            dataCursor: o
          } = e;
          for (let e = 0, i = t.series?.length ?? 0; e < i; ++e) {
            let i;
            let r = t.series[e],
              a = r.options.id ?? "",
              l = this.seriesFromConnector[a],
              h = l?.connector?.table;
            if (!h) continue;
            let c = l.columnAssignment?.find(t => t.seriesId === a);
            if (c) {
              let {
                data: t
              } = c;
              i = "string" == typeof t ? t : Array.isArray(t) ? t[1] : t.y ?? t.value;
            }
            i || (i = r.name), r.update({
              point: {
                events: {
                  mouseOver: function () {
                    let t = 0,
                      e = h.getModifier();
                    e?.options.type === "Range" && (t = s(h, e.options)), o.emitCursor(h, {
                      type: "position",
                      row: t + this.index,
                      column: i,
                      state: "point.mouseOver" + n
                    });
                  },
                  mouseOut: function () {
                    let t = 0,
                      e = h.getModifier();
                    e?.options.type === "Range" && (t = s(h, e.options)), o.emitCursor(h, {
                      type: "position",
                      row: t + this.index,
                      column: i,
                      state: "point.mouseOut" + n
                    });
                  }
                }
              }
            }, !1);
          }
          return t.redraw(), function () {
            t && t.series && (t.series.forEach(t => {
              t.update({
                point: {
                  events: {
                    mouseOver: void 0,
                    mouseOut: void 0
                  }
                }
              }, !1);
            }), t.redraw());
          };
        },
        handler: function () {
          if ("Highcharts" !== this.type) return;
          let t = this,
            i = this.sync.syncConfig.highlight.group ? ":" + this.sync.syncConfig.highlight.group : "",
            {
              chart: n,
              board: o
            } = t,
            r = i => {
              let {
                  table: o,
                  cursor: r
                } = i,
                a = this.sync.syncConfig.highlight,
                l = o.getModifier(),
                h = 0;
              if (l && "Range" === l.options.type && (h = s(o, l.options)), n && n.series?.length && "position" === r.type) {
                let s;
                let i = a.affectedSeriesId;
                if (a.affectedSeriesId) {
                  let t = n.get(a.affectedSeriesId);
                  t?.points ? s = t : e("No series with ID '" + i + "' found in the chart. Affected series will be selected according to the column assignment.");
                }
                if (!s) {
                  let e = Object.keys(t.seriesFromConnector);
                  for (let i = 0, a = e.length; i < a; ++i) {
                    let a = e[i],
                      l = t.seriesFromConnector[a];
                    if (l?.connector?.table !== o) continue;
                    let h = l.columnAssignment;
                    if (s = n.get(a), !h) break;
                    let {
                      data: c
                    } = h.find(t => t.seriesId === a) ?? {};
                    if (!c || !r.column) break;
                    if ("string" == typeof c) {
                      if (c === r.column) break;
                    } else if (Array.isArray(c)) {
                      if (c.includes(r.column)) break;
                    } else if (Object.keys(c).map(t => c[t]).includes(r.column)) break;
                  }
                }
                if (s?.visible && void 0 !== r.row) {
                  let t = s.data[r.row - h];
                  if (t?.visible) return t;
                }
              }
            },
            a = t => {
              let e = this.sync.syncConfig.highlight;
              if (!e.enabled) return;
              let s = r(t);
              if (!s || !n || !s.isInside && s.series.isCartesian || s === n.hoverPoint) return;
              let i = n.tooltip;
              if (i && e.showTooltip) {
                let t = i.shared,
                  e = n.hoverPoint,
                  o = e?.series || n.hoverSeries,
                  r = n.pointer?.getHoverData(s, o, n.series, !0, !0);
                n.tooltip && r?.hoverPoints.length && i.refresh(t ? r.hoverPoints : s);
              }
              e.highlightPoint && (!i?.shared && e.showTooltip || !e.showTooltip) && s.setState("hover"), e.showCrosshair && (s.series.xAxis?.drawCrosshair(void 0, s), s.series.yAxis?.drawCrosshair(void 0, s));
            },
            l = t => {
              let e = this.sync.syncConfig.highlight;
              if (!n || !n.series.length || !e.enabled) return;
              let s = r(t);
              if (s && (!s.isInside && s.series.isCartesian || s === n.hoverPoint)) return;
              let i = !1,
                o = () => {
                  let t = n.series.length;
                  for (let e = 0; e < t; e++) {
                    let t = n.series[e].points,
                      s = t.length;
                    for (let e = 0; e < s; e++) t[e].setState();
                  }
                },
                a = n.tooltip;
              if (a && e.showTooltip && (a.hide(), a.shared && (o(), i = !0)), e.highlightPoint && !i && (s ? s.setState() : o()), e.showCrosshair) {
                if (s) s.series.xAxis?.drawCrosshair(), s.series.yAxis?.drawCrosshair();else {
                  let t = n.xAxis,
                    e = n.yAxis;
                  for (let e = 0, s = t.length; e < s; e++) t[e].drawCrosshair();
                  for (let t = 0, s = e.length; t < s; t++) e[t].drawCrosshair();
                }
              }
            };
          if (o) return (() => {
            let {
                dataCursor: t
              } = o,
              {
                connectorHandlers: e
              } = this;
            if (t) for (let s = 0, n = e.length; s < n; ++s) {
              let n = e[s]?.connector?.table;
              n && (t.addListener(n.id, "point.mouseOver" + i, a), t.addListener(n.id, "dataGrid.hoverRow" + i, a), t.addListener(n.id, "point.mouseOut" + i, l), t.addListener(n.id, "dataGrid.hoverOut" + i, l));
            }
          })(), () => {
            let {
                dataCursor: t
              } = o,
              {
                connectorHandlers: e
              } = this;
            if (t) for (let s = 0, n = e.length; s < n; ++s) {
              let n = e[s]?.connector?.table;
              n && (t.removeListener(n.id, "point.mouseOver" + i, a), t.removeListener(n.id, "dataGrid.hoverRow" + i, a), t.removeListener(n.id, "point.mouseOut" + i, l), t.removeListener(n.id, "dataGrid.hoverOut" + i, l));
            }
          };
        }
      }
    };
  }), s(e, "Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsVisibilitySync.js", [], function () {
    return {
      defaultOptions: {},
      syncPair: {
        emitter: function () {
          if ("Highcharts" !== this.type) return;
          let t = this.sync.syncConfig.visibility,
            e = t.group ? ":" + t.group : "",
            {
              chart: s,
              board: i
            } = this,
            n = this.getFirstConnector();
          if (!i || !s) return;
          let o = n?.table;
          if (o) {
            let {
                dataCursor: t
              } = i,
              {
                series: n
              } = s;
            n.forEach(s => {
              s.update({
                events: {
                  show: function () {
                    t.emitCursor(o, {
                      type: "position",
                      state: "series.show" + e,
                      column: this.name
                    });
                  },
                  hide: function () {
                    t.emitCursor(o, {
                      type: "position",
                      state: "series.hide" + e,
                      column: this.name
                    });
                  }
                }
              }, !1);
            }), s.redraw();
          }
          return function () {
            s && s.series?.length && (s.series.forEach(t => {
              t.update({
                events: {
                  show: void 0,
                  hide: void 0
                }
              }, !1);
            }), s.redraw());
          };
        },
        handler: function () {
          if ("Highcharts" !== this.type) return;
          let t = this,
            e = this.sync.syncConfig.visibility,
            s = e.group ? ":" + e.group : "",
            {
              board: i
            } = t,
            n = (t, e) => {
              for (let s of t) if (s.name === e) return s;
            },
            o = e => {
              let s = t.chart;
              if (s && s.series?.length && "position" === e.cursor.type && void 0 !== e.cursor.column) {
                let t = n(s.series, e.cursor.column);
                t && t.setVisible(!0, !0);
              }
            },
            r = e => {
              let s = t.chart;
              if (s && s.series?.length && "position" === e.cursor.type && void 0 !== e.cursor.column) {
                let t = n(s.series, e.cursor.column);
                t && t.setVisible(!1, !0);
              }
            };
          if (i) return (() => {
            let {
              dataCursor: e
            } = i;
            if (!e) return;
            let n = t.connectorHandlers?.[0]?.connector?.table;
            n && (e.addListener(n.id, "series.show" + s, o), e.addListener(n.id, "series.hide" + s, r));
          })(), () => {
            let e = t.connectorHandlers?.[0]?.connector?.table;
            e && (i.dataCursor.removeListener(e.id, "series.show" + s, o), i.dataCursor.removeListener(e.id, "series.hide" + s, r));
          };
        }
      }
    };
  }), s(e, "Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsSyncs.js", [e["Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsExtremesSync.js"], e["Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsHighlightSync.js"], e["Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsVisibilitySync.js"]], function (t, e, s) {
    return {
      defaultSyncPairs: {
        extremes: t.syncPair,
        highlight: e.syncPair,
        visibility: s.syncPair
      },
      defaultSyncOptions: {
        extremes: t.defaultOptions,
        highlight: e.defaultOptions,
        visibility: s.defaultOptions
      }
    };
  }), s(e, "Dashboards/Components/HighchartsComponent/HighchartsComponentDefaults.js", [e["Dashboards/Components/Component.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      merge: s,
      uniqueKey: i
    } = e;
    return {
      allowConnectorUpdate: !0,
      className: [t.defaultOptions.className, `${t.defaultOptions.className}-highcharts`].join(" "),
      chartClassName: "chart-container",
      chartID: "chart-" + i(),
      chartOptions: {
        chart: {
          styledMode: !0
        },
        series: []
      },
      chartConstructor: "chart",
      editableOptions: [{
        name: "connectorName",
        propertyPath: ["connector", "id"],
        type: "select"
      }, ...(t.defaultOptions.editableOptions || []), {
        name: "chartOptions",
        type: "nested",
        nestedOptions: [{
          name: "chart",
          options: [{
            name: "title",
            propertyPath: ["chartOptions", "title", "text"],
            type: "input"
          }, {
            name: "subtitle",
            propertyPath: ["chartOptions", "subtitle", "text"],
            type: "input"
          }, {
            name: "type",
            propertyPath: ["chartOptions", "chart", "type"],
            type: "select",
            selectOptions: [{
              name: "column",
              iconURL: "series-types/icon-column.svg"
            }, {
              name: "line",
              iconURL: "series-types/icon-line.svg"
            }, {
              name: "scatter",
              iconURL: "series-types/icon-scatter.svg"
            }, {
              name: "pie",
              iconURL: "series-types/icon-pie.svg"
            }]
          }]
        }, {
          name: "xAxis",
          options: [{
            name: "title",
            propertyPath: ["chartOptions", "xAxis", "title", "text"],
            type: "input"
          }, {
            name: "type",
            propertyPath: ["chartOptions", "xAxis", "type"],
            type: "select",
            selectOptions: [{
              name: "linear"
            }, {
              name: "datetime"
            }, {
              name: "logarithmic"
            }]
          }]
        }, {
          name: "yAxis",
          options: [{
            name: "title",
            propertyPath: ["chartOptions", "yAxis", "title", "text"],
            type: "input"
          }, {
            name: "type",
            propertyPath: ["chartOptions", "yAxis", "type"],
            type: "select",
            selectOptions: [{
              name: "linear"
            }, {
              name: "datetime"
            }, {
              name: "logarithmic"
            }]
          }]
        }, {
          name: "legend",
          showToggle: !0,
          propertyPath: ["chartOptions", "legend", "enabled"],
          options: [{
            name: "align",
            propertyPath: ["chartOptions", "legend", "align"],
            type: "select",
            selectOptions: [{
              name: "left"
            }, {
              name: "center"
            }, {
              name: "right"
            }]
          }]
        }, {
          name: "tooltip",
          showToggle: !0,
          propertyPath: ["chartOptions", "tooltip", "enabled"],
          options: [{
            name: "split",
            propertyPath: ["chartOptions", "tooltip", "split"],
            type: "toggle"
          }]
        }, {
          name: "dataLabels",
          propertyPath: ["chartOptions", "plotOptions", "series", "dataLabels", "enabled"],
          showToggle: !0,
          options: [{
            name: "align",
            propertyPath: ["chartOptions", "plotOptions", "series", "dataLabels", "align"],
            type: "select",
            selectOptions: [{
              name: "left"
            }, {
              name: "center"
            }, {
              name: "right"
            }]
          }]
        }, {
          name: "credits",
          showToggle: !0,
          propertyPath: ["chartOptions", "credits", "enabled"],
          options: [{
            name: "name",
            propertyPath: ["chartOptions", "credits", "text"],
            type: "input"
          }, {
            name: "url",
            propertyPath: ["chartOptions", "credits", "href"],
            type: "input"
          }]
        }]
      }, {
        name: "chartConfig",
        propertyPath: ["chartOptions"],
        type: "textarea"
      }, {
        name: "chartClassName",
        propertyPath: ["chartClassName"],
        type: "input"
      }, {
        name: "chartID",
        propertyPath: ["chartID"],
        type: "input"
      }],
      editableOptionsBindings: s(t.defaultOptions.editableOptionsBindings, {
        skipRedraw: ["chartOptions", "chartConfig"]
      })
    };
  }), s(e, "Dashboards/Components/HighchartsComponent/HighchartsComponent.js", [e["Dashboards/Components/Component.js"], e["Data/Converters/DataConverter.js"], e["Data/DataTable.js"], e["Dashboards/Globals.js"], e["Dashboards/Components/HighchartsComponent/HighchartsSyncs/HighchartsSyncs.js"], e["Dashboards/Components/HighchartsComponent/HighchartsComponentDefaults.js"], e["Core/Utilities.js"]], function (t, e, s, i, n, o, r) {
    let {
      createElement: a,
      diffObjects: l,
      isString: h,
      merge: c,
      splat: d
    } = r;
    class u extends t {
      static fromJSON(t, e) {
        let s = new u(e, c(t.options, {
          chartOptions: JSON.parse(t.options.chartOptions || "{}")
        }));
        return s.emit({
          type: "fromJSON",
          json: t
        }), s;
      }
      constructor(t, e, s) {
        for (let i of (super(t, e = c(u.defaultOptions, e), s), this.seriesFromConnector = {}, this.options = e, this.chartConstructor = this.options.chartConstructor || "chart", this.type = "Highcharts", this.chartContainer = a("figure", void 0, void 0, this.contentElement, !0), this.setOptions(), this.chartOptions = c(this.options.chartOptions || {
          chart: {}
        }, {
          tooltip: {}
        }), this.connectorHandlers)) {
          let t = i.connector;
          t && t.on("afterLoad", e => {
            e.table && t.table.setColumns(e.table.getColumns());
          });
        }
        this.innerResizeTimeouts = [];
      }
      onTableChanged() {
        this.updateSeries();
      }
      async load() {
        return this.emit({
          type: "load"
        }), await super.load(), this.emit({
          type: "afterLoad"
        }), this;
      }
      render() {
        return super.render(), this.chart = this.getChart(), this.updateSeries(), this.emit({
          type: "afterRender"
        }), this.setupConnectorUpdate(), this.sync.start(), this;
      }
      resize(t, e) {
        for (super.resize(t, e); this.innerResizeTimeouts.length;) {
          let t = this.innerResizeTimeouts.pop();
          t && clearTimeout(t);
        }
        return this.innerResizeTimeouts.push(setTimeout(() => {
          if (this.chart && this.chart.container) {
            let t = this.contentElement.offsetHeight - this.chart?.container.offsetHeight;
            this.chart.setSize(null, Math.abs(t) > 1 ? this.contentElement.offsetHeight : null, !1);
          }
        }, 33)), this;
      }
      setupConnectorUpdate() {
        let {
          connectorHandlers: t,
          chart: e
        } = this;
        if (!e || !this.options.allowConnectorUpdate) return;
        let s = e.series.length;
        for (let i = 0, n = t.length; i < n; i++) {
          let n = t[i];
          for (let t = 0; t < s; t++) e.series[t].update({
            point: {
              events: {
                update: t => {
                  this.onChartUpdate(t.target, n);
                }
              }
            }
          }, !1);
        }
      }
      onChartUpdate(t, s) {
        let i;
        let n = s.connector?.table,
          o = s.columnAssignment,
          r = t.series.options.id,
          a = new e().asNumber(t.y);
        if (n) {
          if (o && r) {
            let t = o.find(t => t.seriesId === r)?.data;
            h(t) ? i = t : Array.isArray(t) ? i = t[1] : t && (i = t.y ?? t.value);
          }
          i || (i = r ?? t.series.name), n.setCell(i, t.index, a);
        }
      }
      setOptions() {
        this.options.chartClassName && this.chartContainer.classList.add(this.options.chartClassName), this.options.chartID && (this.chartContainer.id = this.options.chartID);
      }
      async update(t, e = !0) {
        await super.update(t, !1), this.setOptions(), this.chart && this.chart.update(c(this.options.chartOptions) || {}), this.emit({
          type: "afterUpdate"
        }), e && this.render();
      }
      updateSeries() {
        let {
            chart: t
          } = this,
          e = this.connectorHandlers;
        if (!t) return;
        let s = [];
        for (let t of e) {
          let e = t.options.columnAssignment;
          if (!e && t.presentationTable && (e = this.getDefaultColumnAssignment(t.presentationTable.getColumnNames(), t.presentationTable)), e) for (let {
            seriesId: i
          } of (t.columnAssignment = e, e)) i && s.push(i);
        }
        let i = Object.keys(this.seriesFromConnector);
        for (let e = 0, n = i.length; e < n; ++e) {
          let n = i[e];
          if (s.some(t => t === n)) continue;
          let o = t.get(n);
          o && o.destroy();
        }
        for (let t of (this.seriesFromConnector = {}, e)) this.updateSeriesFromConnector(t);
        t.redraw();
      }
      updateSeriesFromConnector(t) {
        let e = this.chart;
        if (!t.connector || !e || !t.presentationTable) return;
        let i = t.presentationTable.modified,
          n = t.presentationTable.getModifier()?.options,
          o = t.columnAssignment ?? [];
        for (let r = 0, a = o.length; r < a; ++r) {
          let a = o[r],
            l = a.data,
            c = e.get(a.seriesId),
            d = {},
            u = t => {
              n?.type === "Math" && n.columnFormulas?.some(e => t(e.column)) && (d.dragDrop = {
                draggableY: !1
              });
            };
          if (h(l)) {
            let t = i.getColumn(l);
            t && (d.data = t.slice()), u(t => t === l);
          } else if (Array.isArray(l)) {
            let t = new s({
              columns: i.getColumns(l)
            });
            d.data = t.getRows(), u(t => l.some(e => e === t));
          } else {
            let t = Object.keys(l),
              e = [];
            for (let s = 0, i = t.length; s < i; ++s) e.push(l[t[s]]);
            let n = new s({
              columns: i.getColumns(e)
            });
            d.keys = t, d.data = n.getRows(), u(t => e.some(e => e === t));
          }
          c ? c.update(d, !1) : e.addSeries({
            name: a.seriesId,
            id: a.seriesId,
            ...d
          }, !1), this.seriesFromConnector[a.seriesId] = t;
        }
      }
      getChart() {
        return this.chart || this.createChart();
      }
      destroy() {
        this.chart?.destroy(), super.destroy();
      }
      getDefaultColumnAssignment(t = [], e) {
        let s = [],
          i = e.getColumn(t[0]);
        if (i && h(i[0])) {
          for (let e = 1, i = t.length; e < i; ++e) s.push({
            seriesId: t[e],
            data: [t[0], t[e]]
          });
          return s;
        }
        for (let e = 0, i = t.length; e < i; ++e) s.push({
          seriesId: t[e],
          data: t[e]
        });
        return s;
      }
      createChart() {
        let t = u.charter || i.win.Highcharts;
        this.chartConstructor || (this.chartConstructor = "chart");
        let e = t[this.chartConstructor];
        if (e) try {
          if ("chart" === this.chartConstructor) return t.Chart.chart(this.chartContainer, this.chartOptions);
          return new e(this.chartContainer, this.chartOptions);
        } catch (t) {
          throw Error(`The Highcharts component in cell '${this.cell.id}' is misconfigured. 
____________
${t}`);
        }
        if ("function" != typeof t.chart) throw Error("Chart constructor not found");
        return this.chart;
      }
      registerChartEvents() {
        if (this.chart && this.chart.options) {
          let t = this.chart.options;
          ["chart", "series", "yAxis", "xAxis", "colorAxis", "annotations", "navigation"].map(e => {
            let s = t[e] || {};
            return !Array.isArray(s) && s.events && (s = [s]), s && "object" == typeof s && Array.isArray(s) && s.reduce((t, s, i) => (s && s.events && (t[s.id || `${e}-${i}`] = s.events), t), {}) || {};
          }).forEach(t => {
            Object.keys(t).forEach(e => {
              let s = t[e];
              Object.keys(s).forEach(t => {
                this.callbackRegistry.addCallback(`${e}-${t}`, {
                  type: "seriesEvent",
                  func: s[t]
                });
              });
            });
          });
        }
      }
      getOptionsOnDrop(t) {
        let e = t.editMode.board.dataPool.getConnectorIds(),
          s = {
            cell: "",
            type: "Highcharts",
            chartOptions: {
              chart: {
                animation: !1,
                type: "column",
                zooming: {}
              }
            }
          };
        return e.length && (s = {
          ...s,
          connector: {
            id: e[0]
          }
        }), s;
      }
      toJSON() {
        let t = JSON.stringify(this.options.chartOptions),
          e = this.options.chartConstructor || "chart";
        this.registerChartEvents();
        let s = super.toJSON(),
          i = {
            ...s,
            type: "Highcharts",
            options: {
              ...s.options,
              chartOptions: t,
              chartConstructor: e,
              type: "Highcharts",
              sync: {}
            }
          };
        return this.emit({
          type: "toJSON",
          json: i
        }), i;
      }
      getOptions() {
        return {
          ...l(this.options, u.defaultOptions),
          type: "Highcharts"
        };
      }
      getEditableOptions() {
        let t = this.options,
          e = this.chart,
          s = e && e.options,
          i = s?.chart?.type || "line";
        return c({
          chartOptions: s
        }, {
          chartOptions: {
            yAxis: d(e && e.yAxis[0].options),
            xAxis: d(e && e.xAxis[0].options),
            plotOptions: {
              series: (s && s.plotOptions || {})[i]
            }
          }
        }, t);
      }
      getEditableOptionValue(t) {
        return t ? 1 === t.length && "chartOptions" === t[0] ? JSON.stringify(this.options.chartOptions, null, 2) : super.getEditableOptionValue.call(this, t) : void 0;
      }
    }
    return u.predefinedSyncConfig = n, u.defaultOptions = c(t.defaultOptions, o), u;
  }), s(e, "Dashboards/Components/KPIComponent/KPISyncs/KPIExtremesSync.js", [e["Core/Utilities.js"]], function (t) {
    let {
      defined: e
    } = t;
    return {
      defaultOptions: {},
      syncPair: {
        emitter: void 0,
        handler: function () {
          if ("KPI" !== this.type) return;
          let t = this,
            s = this.sync.syncConfig.extremes,
            i = s.group ? ":" + s.group : "",
            {
              board: n
            } = this,
            o = s => {
              let i = s.cursor;
              if ("position" === i.type && "number" == typeof i?.row && e(i.column) && t.connectorHandlers?.[0]?.connector && !e(t.options.value)) {
                let e = t.connectorHandlers[0].connector.table.modified.getCellAsString(i.column, i.row);
                t.setValue(e);
              }
            };
          if (n) return (() => {
            let {
              dataCursor: t
            } = n;
            if (!t) return;
            let e = this.getFirstConnector()?.table;
            e && t.addListener(e.id, "xAxis.extremes.max" + i, o);
          })(), () => {
            let t = this.getFirstConnector()?.table,
              {
                dataCursor: e
              } = n;
            t && e.removeListener(t.id, "xAxis.extremes.max" + i, o);
          };
        }
      }
    };
  }), s(e, "Dashboards/Components/KPIComponent/KPISyncs/KPISyncs.js", [e["Dashboards/Components/KPIComponent/KPISyncs/KPIExtremesSync.js"]], function (t) {
    return {
      defaultSyncPairs: {
        extremes: t.syncPair
      },
      defaultSyncOptions: {
        extremes: t.defaultOptions
      }
    };
  }), s(e, "Dashboards/Components/KPIComponent/KPIComponentDefaults.js", [e["Dashboards/Components/Component.js"]], function (t) {
    return {
      type: "KPI",
      className: [t.defaultOptions.className, `${t.defaultOptions.className}-kpi`].join(" "),
      minFontSize: 20,
      thresholdColors: ["#f45b5b", "#90ed7d"],
      editableOptions: [{
        name: "connectorName",
        propertyPath: ["connector", "id"],
        type: "select"
      }, ...(t.defaultOptions.editableOptions || []), {
        name: "Value",
        type: "input",
        propertyPath: ["value"]
      }, {
        name: "Column name",
        type: "input",
        propertyPath: ["columnName"]
      }, {
        name: "Value format",
        type: "input",
        propertyPath: ["valueFormat"]
      }],
      linkedValueTo: {
        enabled: !0,
        seriesIndex: 0,
        pointIndex: 0
      }
    };
  }), s(e, "Core/Chart/ChartDefaults.js", [], function () {
    return {
      alignThresholds: !1,
      panning: {
        enabled: !1,
        type: "x"
      },
      styledMode: !1,
      borderRadius: 0,
      colorCount: 10,
      allowMutatingData: !0,
      ignoreHiddenSeries: !0,
      spacing: [10, 10, 15, 10],
      resetZoomButton: {
        theme: {},
        position: {}
      },
      reflow: !0,
      type: "line",
      zooming: {
        singleTouch: !1,
        resetButton: {
          theme: {
            zIndex: 6
          },
          position: {
            align: "right",
            x: -10,
            y: 10
          }
        }
      },
      width: null,
      height: null,
      borderColor: "#334eff",
      backgroundColor: "#ffffff",
      plotBorderColor: "#cccccc"
    };
  }), s(e, "Core/Color/Palettes.js", [], function () {
    return {
      colors: ["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"]
    };
  }), s(e, "Core/Time.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        win: s
      } = t,
      {
        defined: i,
        error: n,
        extend: o,
        isNumber: r,
        isObject: a,
        merge: l,
        objectEach: h,
        pad: c,
        pick: d,
        splat: u,
        timeUnits: p
      } = e,
      m = t.isSafari && s.Intl && s.Intl.DateTimeFormat.prototype.formatRange,
      f = t.isSafari && s.Intl && !s.Intl.DateTimeFormat.prototype.formatRange;
    class g {
      constructor(t) {
        this.options = {}, this.useUTC = !1, this.variableTimezone = !1, this.Date = s.Date, this.getTimezoneOffset = this.timezoneOffsetFunction(), this.update(t);
      }
      get(t, e) {
        if (this.variableTimezone || this.timezoneOffset) {
          let s = e.getTime(),
            i = s - this.getTimezoneOffset(e);
          e.setTime(i);
          let n = e["getUTC" + t]();
          return e.setTime(s), n;
        }
        return this.useUTC ? e["getUTC" + t]() : e["get" + t]();
      }
      set(t, e, s) {
        if (this.variableTimezone || this.timezoneOffset) {
          if ("Milliseconds" === t || "Seconds" === t || "Minutes" === t && this.getTimezoneOffset(e) % 36e5 == 0) return e["setUTC" + t](s);
          let i = this.getTimezoneOffset(e),
            n = e.getTime() - i;
          e.setTime(n), e["setUTC" + t](s);
          let o = this.getTimezoneOffset(e);
          return n = e.getTime() + o, e.setTime(n);
        }
        return this.useUTC || m && "FullYear" === t ? e["setUTC" + t](s) : e["set" + t](s);
      }
      update(t = {}) {
        let e = d(t.useUTC, !0);
        this.options = t = l(!0, this.options, t), this.Date = t.Date || s.Date || Date, this.useUTC = e, this.timezoneOffset = e && t.timezoneOffset || void 0, this.getTimezoneOffset = this.timezoneOffsetFunction(), this.variableTimezone = e && !!(t.getTimezoneOffset || t.timezone);
      }
      makeTime(t, e, s, i, n, o) {
        let r, a, l;
        return this.useUTC ? (r = this.Date.UTC.apply(0, arguments), a = this.getTimezoneOffset(r), r += a, a !== (l = this.getTimezoneOffset(r)) ? r += l - a : a - 36e5 !== this.getTimezoneOffset(r - 36e5) || f || (r -= 36e5)) : r = new this.Date(t, e, d(s, 1), d(i, 0), d(n, 0), d(o, 0)).getTime(), r;
      }
      timezoneOffsetFunction() {
        let t = this,
          e = this.options,
          s = e.getTimezoneOffset;
        return this.useUTC ? e.timezone ? t => {
          try {
            let s = `shortOffset,${e.timezone || ""}`,
              [i, n, o, a, l = 0] = (g.formatCache[s] = g.formatCache[s] || Intl.DateTimeFormat("en", {
                timeZone: e.timezone,
                timeZoneName: "shortOffset"
              })).format(t).split(/(GMT|:)/).map(Number),
              h = -(36e5 * (o + l / 60));
            if (r(h)) return h;
          } catch (t) {
            n(34);
          }
          return 0;
        } : this.useUTC && s ? t => 6e4 * s(t.valueOf()) : () => 6e4 * (t.timezoneOffset || 0) : t => 6e4 * new Date(t.toString()).getTimezoneOffset();
      }
      dateFormat(e, s, n) {
        if (!i(s) || isNaN(s)) return t.defaultOptions.lang && t.defaultOptions.lang.invalidDate || "";
        e = d(e, "%Y-%m-%d %H:%M:%S");
        let r = this,
          a = new this.Date(s),
          l = this.get("Hours", a),
          u = this.get("Day", a),
          p = this.get("Date", a),
          m = this.get("Month", a),
          f = this.get("FullYear", a),
          g = t.defaultOptions.lang,
          y = g && g.weekdays,
          b = g && g.shortWeekdays;
        return h(o({
          a: b ? b[u] : y[u].substr(0, 3),
          A: y[u],
          d: c(p),
          e: c(p, 2, " "),
          w: u,
          b: g.shortMonths[m],
          B: g.months[m],
          m: c(m + 1),
          o: m + 1,
          y: f.toString().substr(2, 2),
          Y: f,
          H: c(l),
          k: l,
          I: c(l % 12 || 12),
          l: l % 12 || 12,
          M: c(this.get("Minutes", a)),
          p: l < 12 ? "AM" : "PM",
          P: l < 12 ? "am" : "pm",
          S: c(this.get("Seconds", a)),
          L: c(Math.floor(s % 1e3), 3)
        }, t.dateFormats), function (t, i) {
          for (; -1 !== e.indexOf("%" + i);) e = e.replace("%" + i, "function" == typeof t ? t.call(r, s) : t);
        }), n ? e.substr(0, 1).toUpperCase() + e.substr(1) : e;
      }
      resolveDTLFormat(t) {
        return a(t, !0) ? t : {
          main: (t = u(t))[0],
          from: t[1],
          to: t[2]
        };
      }
      getTimeTicks(t, e, s, n) {
        let r, a, l, h;
        let c = this,
          u = c.Date,
          m = [],
          f = {},
          g = new u(e),
          y = t.unitRange,
          b = t.count || 1;
        if (n = d(n, 1), i(e)) {
          c.set("Milliseconds", g, y >= p.second ? 0 : b * Math.floor(c.get("Milliseconds", g) / b)), y >= p.second && c.set("Seconds", g, y >= p.minute ? 0 : b * Math.floor(c.get("Seconds", g) / b)), y >= p.minute && c.set("Minutes", g, y >= p.hour ? 0 : b * Math.floor(c.get("Minutes", g) / b)), y >= p.hour && c.set("Hours", g, y >= p.day ? 0 : b * Math.floor(c.get("Hours", g) / b)), y >= p.day && c.set("Date", g, y >= p.month ? 1 : Math.max(1, b * Math.floor(c.get("Date", g) / b))), y >= p.month && (c.set("Month", g, y >= p.year ? 0 : b * Math.floor(c.get("Month", g) / b)), a = c.get("FullYear", g)), y >= p.year && (a -= a % b, c.set("FullYear", g, a)), y === p.week && (h = c.get("Day", g), c.set("Date", g, c.get("Date", g) - h + n + (h < n ? -7 : 0))), a = c.get("FullYear", g);
          let t = c.get("Month", g),
            o = c.get("Date", g),
            d = c.get("Hours", g);
          e = g.getTime(), (c.variableTimezone || !c.useUTC) && i(s) && (l = s - e > 4 * p.month || c.getTimezoneOffset(e) !== c.getTimezoneOffset(s));
          let u = g.getTime();
          for (r = 1; u < s;) m.push(u), y === p.year ? u = c.makeTime(a + r * b, 0) : y === p.month ? u = c.makeTime(a, t + r * b) : l && (y === p.day || y === p.week) ? u = c.makeTime(a, t, o + r * b * (y === p.day ? 1 : 7)) : l && y === p.hour && b > 1 ? u = c.makeTime(a, t, o, d + r * b) : u += y * b, r++;
          m.push(u), y <= p.hour && m.length < 1e4 && m.forEach(function (t) {
            t % 18e5 == 0 && "000000000" === c.dateFormat("%H%M%S%L", t) && (f[t] = "day");
          });
        }
        return m.info = o(t, {
          higherRanks: f,
          totalRange: y * b
        }), m;
      }
      getDateFormat(t, e, s, i) {
        let n = this.dateFormat("%m-%d %H:%M:%S.%L", e),
          o = "01-01 00:00:00.000",
          r = {
            millisecond: 15,
            second: 12,
            minute: 9,
            hour: 6,
            day: 3
          },
          a = "millisecond",
          l = a;
        for (a in p) {
          if (t === p.week && +this.dateFormat("%w", e) === s && n.substr(6) === o.substr(6)) {
            a = "week";
            break;
          }
          if (p[a] > t) {
            a = l;
            break;
          }
          if (r[a] && n.substr(r[a]) !== o.substr(r[a])) break;
          "week" !== a && (l = a);
        }
        return this.resolveDTLFormat(i[a]).main;
      }
    }
    return g.formatCache = {}, g;
  }), s(e, "Core/Defaults.js", [e["Core/Chart/ChartDefaults.js"], e["Core/Globals.js"], e["Core/Color/Palettes.js"], e["Core/Time.js"], e["Core/Utilities.js"]], function (t, e, s, i, n) {
    let {
        isTouchDevice: o
      } = e,
      {
        fireEvent: r,
        merge: a
      } = n,
      l = {
        colors: s.colors,
        symbols: ["circle", "diamond", "square", "triangle", "triangle-down"],
        lang: {
          loading: "Loading...",
          months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          decimalPoint: ".",
          numericSymbols: ["k", "M", "G", "T", "P", "E"],
          resetZoom: "Reset zoom",
          resetZoomTitle: "Reset zoom level 1:1",
          thousandsSep: " "
        },
        global: {
          buttonTheme: {
            fill: "#f7f7f7",
            padding: 8,
            r: 2,
            stroke: "#cccccc",
            "stroke-width": 1,
            style: {
              color: "#333333",
              cursor: "pointer",
              fontSize: "0.8em",
              fontWeight: "normal"
            },
            states: {
              hover: {
                fill: "#e6e6e6"
              },
              select: {
                fill: "#e6e9ff",
                style: {
                  color: "#000000",
                  fontWeight: "bold"
                }
              },
              disabled: {
                style: {
                  color: "#cccccc"
                }
              }
            }
          }
        },
        time: {
          Date: void 0,
          getTimezoneOffset: void 0,
          timezone: void 0,
          timezoneOffset: 0,
          useUTC: !0
        },
        chart: t,
        title: {
          style: {
            color: "#333333",
            fontWeight: "bold"
          },
          text: "Chart title",
          align: "center",
          margin: 15,
          widthAdjust: -44
        },
        subtitle: {
          style: {
            color: "#666666",
            fontSize: "0.8em"
          },
          text: "",
          align: "center",
          widthAdjust: -44
        },
        caption: {
          margin: 15,
          style: {
            color: "#666666",
            fontSize: "0.8em"
          },
          text: "",
          align: "left",
          verticalAlign: "bottom"
        },
        plotOptions: {},
        legend: {
          enabled: !0,
          align: "center",
          alignColumns: !0,
          className: "highcharts-no-tooltip",
          events: {},
          layout: "horizontal",
          itemMarginBottom: 2,
          itemMarginTop: 2,
          labelFormatter: function () {
            return this.name;
          },
          borderColor: "#999999",
          borderRadius: 0,
          navigation: {
            style: {
              fontSize: "0.8em"
            },
            activeColor: "#0022ff",
            inactiveColor: "#cccccc"
          },
          itemStyle: {
            color: "#333333",
            cursor: "pointer",
            fontSize: "0.8em",
            textDecoration: "none",
            textOverflow: "ellipsis"
          },
          itemHoverStyle: {
            color: "#000000"
          },
          itemHiddenStyle: {
            color: "#666666",
            textDecoration: "line-through"
          },
          shadow: !1,
          itemCheckboxStyle: {
            position: "absolute",
            width: "13px",
            height: "13px"
          },
          squareSymbol: !0,
          symbolPadding: 5,
          verticalAlign: "bottom",
          x: 0,
          y: 0,
          title: {
            style: {
              fontSize: "0.8em",
              fontWeight: "bold"
            }
          }
        },
        loading: {
          labelStyle: {
            fontWeight: "bold",
            position: "relative",
            top: "45%"
          },
          style: {
            position: "absolute",
            backgroundColor: "#ffffff",
            opacity: .5,
            textAlign: "center"
          }
        },
        tooltip: {
          enabled: !0,
          animation: {
            duration: 300,
            easing: t => Math.sqrt(1 - Math.pow(t - 1, 2))
          },
          borderRadius: 3,
          dateTimeLabelFormats: {
            millisecond: "%A, %e %b, %H:%M:%S.%L",
            second: "%A, %e %b, %H:%M:%S",
            minute: "%A, %e %b, %H:%M",
            hour: "%A, %e %b, %H:%M",
            day: "%A, %e %b %Y",
            week: "Week from %A, %e %b %Y",
            month: "%B %Y",
            year: "%Y"
          },
          footerFormat: "",
          headerShape: "callout",
          hideDelay: 500,
          padding: 8,
          shape: "callout",
          shared: !1,
          snap: o ? 25 : 10,
          headerFormat: '<span style="font-size: 0.8em">{point.key}</span><br/>',
          pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
          backgroundColor: "#ffffff",
          borderWidth: void 0,
          shadow: !0,
          stickOnContact: !1,
          style: {
            color: "#333333",
            cursor: "default",
            fontSize: "0.8em"
          },
          useHTML: !1
        },
        credits: {
          enabled: !0,
          href: "https://www.highcharts.com?credits",
          position: {
            align: "right",
            x: -10,
            verticalAlign: "bottom",
            y: -5
          },
          style: {
            cursor: "pointer",
            color: "#999999",
            fontSize: "0.6em"
          },
          text: "Highcharts.com"
        }
      };
    l.chart.styledMode = !1;
    let h = new i(l.time);
    return {
      defaultOptions: l,
      defaultTime: h,
      getOptions: function () {
        return l;
      },
      setOptions: function (t) {
        return r(e, "setOptions", {
          options: t
        }), a(!0, l, t), (t.time || t.global) && (e.time ? e.time.update(a(l.global, l.time, t.global, t.time)) : e.time = h), l;
      }
    };
  }), s(e, "Core/Templating.js", [e["Core/Defaults.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        defaultOptions: s,
        defaultTime: i
      } = t,
      {
        extend: n,
        getNestedProperty: o,
        isArray: r,
        isNumber: a,
        isObject: l,
        pick: h,
        pInt: c
      } = e,
      d = {
        add: (t, e) => t + e,
        divide: (t, e) => 0 !== e ? t / e : "",
        eq: (t, e) => t == e,
        each: function (t) {
          let e = arguments[arguments.length - 1];
          return !!r(t) && t.map((s, i) => u(e.body, n(l(s) ? s : {
            "@this": s
          }, {
            "@index": i,
            "@first": 0 === i,
            "@last": i === t.length - 1
          }))).join("");
        },
        ge: (t, e) => t >= e,
        gt: (t, e) => t > e,
        if: t => !!t,
        le: (t, e) => t <= e,
        lt: (t, e) => t < e,
        multiply: (t, e) => t * e,
        ne: (t, e) => t != e,
        subtract: (t, e) => t - e,
        unless: t => !t
      };
    function u(t = "", e, n) {
      let r = /\{([\w\:\.\,;\-\/<>%@"'’= #\(\)]+)\}/g,
        a = /\(([\w\:\.\,;\-\/<>%@"'= ]+)\)/g,
        l = [],
        c = /f$/,
        m = /\.(\d)/,
        f = s.lang,
        g = n && n.time || i,
        y = n && n.numberFormatter || p,
        b = (t = "") => {
          let s;
          return "true" === t || "false" !== t && ((s = Number(t)).toString() === t ? s : o(t, e));
        },
        C,
        w,
        v = 0,
        O;
      for (; null !== (C = r.exec(t));) {
        let s = a.exec(C[1]);
        s && (C = s, O = !0), w && w.isBlock || (w = {
          ctx: e,
          expression: C[1],
          find: C[0],
          isBlock: "#" === C[1].charAt(0),
          start: C.index,
          startInner: C.index + C[0].length,
          length: C[0].length
        });
        let i = C[1].split(" ")[0].replace("#", "");
        d[i] && (w.isBlock && i === w.fn && v++, w.fn || (w.fn = i));
        let n = "else" === C[1];
        if (w.isBlock && w.fn && (C[1] === `/${w.fn}` || n)) {
          if (v) !n && v--;else {
            let e = w.startInner,
              s = t.substr(e, C.index - e);
            void 0 === w.body ? (w.body = s, w.startInner = C.index + C[0].length) : w.elseBody = s, w.find += s + C[0], n || (l.push(w), w = void 0);
          }
        } else w.isBlock || l.push(w);
        if (s && !w?.isBlock) break;
      }
      return l.forEach(s => {
        let i, o;
        let {
          body: r,
          elseBody: a,
          expression: l,
          fn: p
        } = s;
        if (p) {
          let t = [s],
            h = l.split(" ");
          for (o = d[p].length; o--;) t.unshift(b(h[o + 1]));
          i = d[p].apply(e, t), s.isBlock && "boolean" == typeof i && (i = u(i ? r : a, e, n));
        } else {
          let t = l.split(":");
          if (i = b(t.shift() || ""), t.length && "number" == typeof i) {
            let e = t.join(":");
            if (c.test(e)) {
              let t = parseInt((e.match(m) || ["", "-1"])[1], 10);
              null !== i && (i = y(i, t, f.decimalPoint, e.indexOf(",") > -1 ? f.thousandsSep : ""));
            } else i = g.dateFormat(e, i);
          }
        }
        t = t.replace(s.find, h(i, ""));
      }), O ? u(t, e, n) : t;
    }
    function p(t, e, i, n) {
      let o, r;
      t = +t || 0, e = +e;
      let l = s.lang,
        d = (t.toString().split(".")[1] || "").split("e")[0].length,
        u = t.toString().split("e"),
        p = e;
      -1 === e ? e = Math.min(d, 20) : a(e) ? e && u[1] && u[1] < 0 && ((r = e + +u[1]) >= 0 ? (u[0] = (+u[0]).toExponential(r).split("e")[0], e = r) : (u[0] = u[0].split(".")[0] || 0, t = e < 20 ? (u[0] * Math.pow(10, u[1])).toFixed(e) : 0, u[1] = 0)) : e = 2;
      let m = (Math.abs(u[1] ? u[0] : t) + Math.pow(10, -Math.max(e, d) - 1)).toFixed(e),
        f = String(c(m)),
        g = f.length > 3 ? f.length % 3 : 0;
      return i = h(i, l.decimalPoint), n = h(n, l.thousandsSep), o = (t < 0 ? "-" : "") + (g ? f.substr(0, g) + n : ""), 0 > +u[1] && !p ? o = "0" : o += f.substr(g).replace(/(\d{3})(?=\d)/g, "$1" + n), e ? o += i + m.slice(-e) : 0 == +o && (o = "0"), u[1] && 0 != +o && (o += "e" + u[1]), o;
    }
    return {
      dateFormat: function (t, e, s) {
        return i.dateFormat(t, e, s);
      },
      format: u,
      helpers: d,
      numberFormat: p
    };
  }), s(e, "Dashboards/Components/KPIComponent/KPIComponent.js", [e["Core/Renderer/HTML/AST.js"], e["Dashboards/Components/Component.js"], e["Dashboards/Components/KPIComponent/KPISyncs/KPISyncs.js"], e["Dashboards/Components/KPIComponent/KPIComponentDefaults.js"], e["Core/Templating.js"], e["Core/Utilities.js"]], function (t, e, s, i, n, o) {
    let {
        format: r
      } = n,
      {
        createElement: a,
        css: l,
        defined: h,
        diffObjects: c,
        isArray: d,
        isNumber: u,
        merge: p
      } = o;
    class m extends e {
      static fromJSON(t, e) {
        let s = t.options,
          i = s.chartOptions && JSON.parse(s.chartOptions),
          n = JSON.parse(s.subtitle || "{}"),
          o = s.title && JSON.parse(s.title);
        return new m(e, p(s, {
          chartOptions: i,
          title: o,
          subtitle: n
        }));
      }
      constructor(t, e, s) {
        super(t, e = p(m.defaultOptions, e), s), this.options = e, this.type = "KPI", this.value = a("span", {
          className: `${e.className}-value`
        }, {}, this.contentElement), this.subtitle = a("span", {
          className: this.getSubtitleClassName()
        }, {}, this.contentElement);
      }
      async load() {
        return await super.load(), this.linkValueToChart(), this;
      }
      resize(t, e) {
        return super.resize(t, e), this.chart && this.chart.container && this.chart.reflow(), this;
      }
      render() {
        super.render(), this.updateElements();
        let t = m.charter?.Chart;
        return t && this.options.chartOptions && !this.chart ? (this.chartContainer || (this.chartContainer = a("div", {
          className: `${this.options.className}-chart-container`
        }, {
          padding: "0.1px"
        }, this.contentElement)), this.chart = t.chart(this.chartContainer, p(m.defaultChartOptions, this.options.chartOptions))) : this.chart && !this.options.chartOptions && "chartOptions" in this.options && (this.chart.destroy(), this.chart = void 0), this.sync.start(), this.emit({
          type: "afterRender"
        }), this;
      }
      async update(t, e = !0) {
        await super.update(t), t.chartOptions && this.chart && this.chart.update(t.chartOptions), e && this.render();
      }
      onTableChanged() {
        this.setValue();
      }
      destroy() {
        this.chart?.destroy(), super.destroy();
      }
      getValue() {
        if (h(this.options.value)) return this.options.value;
        let t = this.getFirstConnector();
        if (t && this.options.columnName) {
          let e = t.table.modified,
            s = e.getColumn(this.options.columnName),
            i = s?.length || 0;
          return e.getCellAsString(this.options.columnName, i - 1);
        }
      }
      setValue(e = this.getValue()) {
        let {
          valueFormat: s,
          valueFormatter: i
        } = this.options;
        if (h(e)) {
          let n;
          u(+e) && (n = +e), i ? e = i.call(this, e) : s ? e = r(s, {
            value: e
          }) : u(e) && (e = e.toLocaleString()), t.setElementHTML(this.value, "" + e), this.linkValueToChart(n), this.prevValue = n;
        }
      }
      linkValueToChart(t = this.getValue()) {
        let e = this.chart,
          s = this.options.linkedValueTo;
        if (!e || !s.enabled || !h(t) || !u(+t)) return;
        t = +t;
        let i = e.series[s.seriesIndex ?? 0],
          n = i?.points[s.pointIndex ?? 0];
        if (i) {
          if (n) {
            n.update({
              y: t
            });
            return;
          }
          i.addPoint({
            y: t
          });
          return;
        }
        e.addSeries({
          data: [{
            y: t
          }]
        });
      }
      updateElements() {
        let {
          style: e,
          subtitle: s
        } = this.options;
        this.setValue(), t.setElementHTML(this.subtitle, this.getSubtitle()), e && l(this.element, e), "object" == typeof s && (s.style && l(this.subtitle, s.style), this.subtitle.className = this.getSubtitleClassName()), this.chartContainer && (this.chartContainer.style.flex = this.options.chartOptions ? "1" : "0"), this.chart && this.chart.reflow(), this.value.style.color = this.getValueColor();
      }
      getSubtitle() {
        let {
          subtitle: t,
          value: e
        } = this.options;
        if ("string" == typeof t) return t;
        if (t) {
          if (u(this.prevValue) && u(e)) {
            let s = e - this.prevValue,
              i = "";
            if (s > 0) i = '<span style="color:green">&#9650;</span> +';else {
              if (!(s < 0)) return this.subtitle.innerHTML;
              i = '<span style="color:red">&#9660;</span> ';
            }
            if ("diff" === t.type) return i + s.toLocaleString();
            if ("diffpercent" === t.type) return i + r("{v:,.2f}%", {
              v: s / this.prevValue * 100
            });
          }
          return t.text || "";
        }
        return "";
      }
      getSubtitleClassName() {
        let {
          subtitle: t
        } = this.options;
        return `${e.defaultOptions.className}-subtitle` + ("object" == typeof t && t.className || "");
      }
      getValueColor() {
        let {
          threshold: t,
          thresholdColors: e,
          value: s
        } = this.options;
        if (e && t && u(s)) {
          if (d(t)) {
            for (let i = t.length - 1; i >= 0; i--) if (s >= t[i]) {
              if (i + 1 < e.length) return e[i + 1];
              return e[e.length - 1];
            }
          } else if (s >= t) return e[1];
          return e[0];
        }
        return "";
      }
      getOptionsOnDrop(t) {
        let e = t.editMode.board.dataPool.getConnectorIds(),
          s = {
            cell: "",
            type: "KPI"
          };
        return e.length && (s = {
          ...s,
          connector: {
            id: e[0]
          }
        }), s;
      }
      toJSON() {
        let t = super.toJSON(),
          e = {
            ...t,
            type: "KPI",
            options: {
              ...t.options,
              type: "KPI",
              value: this.options.value,
              subtitle: JSON.stringify(this.options.subtitle),
              title: JSON.stringify(this.options.title),
              threshold: this.options.threshold,
              thresholdColors: this.options.thresholdColors,
              chartOptions: JSON.stringify(this.options.chartOptions),
              valueFormat: this.options.valueFormat
            }
          };
        return this.emit({
          type: "toJSON",
          json: t
        }), e;
      }
      getOptions() {
        return {
          ...c(this.options, m.defaultOptions),
          type: "KPI"
        };
      }
    }
    return m.defaultOptions = p(e.defaultOptions, i), m.predefinedSyncConfig = s, m.defaultChartOptions = {
      chart: {
        type: "spline",
        styledMode: !0,
        zooming: {
          mouseWheel: {
            enabled: !1
          }
        }
      },
      title: {
        text: void 0
      },
      xAxis: {
        visible: !1
      },
      yAxis: {
        visible: !1,
        title: {
          text: null
        }
      },
      legend: {
        enabled: !1
      },
      credits: {
        enabled: !1
      },
      tooltip: {
        outside: !0
      },
      plotOptions: {
        series: {
          marker: {
            enabled: !1
          }
        }
      }
    }, m;
  }), s(e, "Dashboards/Components/NavigatorComponent/NavigatorComponentDefaults.js", [e["Dashboards/Components/Component.js"]], function (t) {
    return {
      type: "Navigator",
      className: [t.defaultOptions.className, `${t.defaultOptions.className}-navigator`].join(" "),
      chartOptions: {
        chart: {
          animation: !1,
          height: 200,
          styledMode: !0,
          type: "column",
          zooming: {
            mouseWheel: {
              enabled: !1
            }
          }
        },
        credits: {
          enabled: !1
        },
        legend: {
          enabled: !1
        },
        navigator: {
          enabled: !0,
          outlineWidth: 0,
          series: {
            animation: !1,
            lineWidth: 0,
            colorIndex: 0
          },
          xAxis: {
            endOnTick: !0,
            gridZIndex: 4,
            labels: {
              x: 1,
              y: 22
            },
            opposite: !0,
            showFirstLabel: !0,
            showLastLabel: !0,
            startOnTick: !0,
            tickPosition: "inside"
          },
          yAxis: {
            maxPadding: .5
          }
        },
        plotOptions: {
          series: {
            borderRadius: 0,
            marker: {
              enabled: !1
            },
            states: {
              hover: {
                enabled: !1
              }
            }
          }
        },
        scrollbar: {
          enabled: !0
        },
        title: {
          text: ""
        },
        tooltip: {
          enabled: !1
        },
        xAxis: {
          visible: !1,
          minRange: Number.MIN_VALUE
        },
        yAxis: {
          visible: !1
        }
      },
      editableOptions: (t.defaultOptions.editableOptions || []).concat()
    };
  }), s(e, "Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorSyncUtils.js", [], function () {
    var t, e;
    return (e = t || (t = {})).setRangeOptions = function (t, e, s, i) {
      let n = !1;
      for (let o = 0, r = t.length; o < r; ++o) if (t[o].column === e) {
        t[o].maxValue = i, t[o].minValue = s, n = !0;
        break;
      }
      n || t.push({
        column: e,
        maxValue: i,
        minValue: s
      });
    }, e.unsetRangeOptions = function (t, e) {
      for (let s = 0, i = t.length; s < i; ++s) if (t[s].column === e) return t.splice(s, 1)[0];
    }, t;
  }), s(e, "Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorCrossfilterSync.js", [e["Data/Modifiers/DataModifier.js"], e["Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorSyncUtils.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
        Range: i
      } = t.types,
      {
        addEvent: n
      } = s;
    return {
      defaultOptions: {
        affectNavigator: !1
      },
      syncPair: {
        emitter: function () {
          let t;
          if ("Navigator" !== this.type) return;
          let s = this,
            o = this.sync.syncConfig.crossfilter,
            r = o.group ? ":" + o.group : "",
            a = async t => {
              if (s.connectorHandlers?.[0]?.connector) {
                let n = s.connectorHandlers[0].connector.table,
                  o = s.board.dataCursor,
                  a = s.getColumnAssignment()[0],
                  [l, h] = s.getAxisExtremes(),
                  c = n.getModifier();
                c instanceof i ? e.setRangeOptions(c.options.ranges, a, l, h) : c = new i({
                  ranges: [{
                    column: a,
                    maxValue: h,
                    minValue: l
                  }]
                }), await n.setModifier(c), o.emitCursor(n, {
                  type: "position",
                  column: a,
                  row: n.getRowIndexBy(a, l),
                  state: "crossfilter" + r
                }, t), o.emitCursor(n, {
                  type: "position",
                  column: a,
                  row: n.getRowIndexBy(a, h),
                  state: "crossfilter" + r
                }, t);
              }
            };
          return n(s.chart.xAxis[0], "afterSetExtremes", function (e) {
            clearTimeout(t), t = setTimeout(a, 50, this, e);
          });
        },
        handler: void 0
      }
    };
  }), s(e, "Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorExtremesSync.js", [e["Data/Modifiers/DataModifier.js"], e["Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorSyncUtils.js"], e["Core/Utilities.js"]], function (t, e, s) {
    let {
        Range: i
      } = t.types,
      {
        addEvent: n,
        pick: o
      } = s;
    return {
      defaultOptions: {},
      syncPair: {
        emitter: function () {
          let t;
          if ("Navigator" !== this.type) return;
          let e = this,
            s = this.sync.syncConfig.extremes,
            i = s.group ? ":" + s.group : "",
            o = t => {
              if (e.connectorHandlers?.[0]?.connector) {
                let s = e.connectorHandlers[0].connector.table,
                  n = e.board.dataCursor,
                  o = e.getColumnAssignment()[0],
                  [r, a] = e.getAxisExtremes();
                n.emitCursor(s, {
                  type: "position",
                  column: o,
                  row: s.getRowIndexBy(o, r),
                  state: "xAxis.extremes.min" + i
                }, t), n.emitCursor(s, {
                  type: "position",
                  column: o,
                  row: s.getRowIndexBy(o, a),
                  state: "xAxis.extremes.max" + i
                }, t);
              }
            };
          return n(e.chart.xAxis[0], "afterSetExtremes", function (e) {
            clearTimeout(t), t = setTimeout(o, 50, this, e);
          });
        },
        handler: function () {
          if ("Navigator" !== this.type) return;
          let t = this,
            s = this.sync.syncConfig.extremes,
            n = s.group ? ":" + s.group : "",
            r = t.board.dataCursor,
            a = s => {
              let r = s.cursor;
              if (!t.connectorHandlers?.[0]?.connector) return;
              let a = t.connectorHandlers[0].connector.table,
                l = a.getColumnNames()[0],
                h = a.getRowCount(),
                c = 0;
              "range" === r.type ? (h = r.lastRow, c = r.firstRow, r.columns && (l = o(r.columns[0], l))) : r.state === "xAxis.extremes.max" + n ? (l = o(r.column, l), h = o(r.row, h)) : (l = o(r.column, l), c = o(r.row, c));
              let d = a.getModifier();
              if ("string" == typeof l && d instanceof i) {
                let t = d.options.ranges,
                  s = a.getCell(l, c),
                  i = a.getCell(l, h);
                null != i && null != s && (e.unsetRangeOptions(t, l), t.unshift({
                  column: l,
                  maxValue: i,
                  minValue: s
                }), a.setModifier(d));
              }
            };
          return (() => {
            let e = t.connectorHandlers?.[0]?.connector?.table;
            e && (r.addListener(e.id, "xAxis.extremes" + n, a), r.addListener(e.id, "xAxis.extremes.max" + n, a), r.addListener(e.id, "xAxis.extremes.min" + n, a));
          })(), () => {
            let e = t.connectorHandlers?.[0]?.connector?.table;
            e && (r.removeListener(e.id, "xAxis.extremes" + n, a), r.removeListener(e.id, "xAxis.extremes.max" + n, a), r.removeListener(e.id, "xAxis.extremes.min" + n, a));
          };
        }
      }
    };
  }), s(e, "Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorSyncs.js", [e["Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorCrossfilterSync.js"], e["Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorExtremesSync.js"]], function (t, e) {
    return {
      defaultSyncPairs: {
        crossfilter: t.syncPair,
        extremes: e.syncPair
      },
      defaultSyncOptions: {
        crossfilter: t.defaultOptions,
        extremes: e.defaultOptions
      }
    };
  }), s(e, "Dashboards/Components/NavigatorComponent/NavigatorComponent.js", [e["Dashboards/Components/Component.js"], e["Dashboards/Globals.js"], e["Dashboards/Components/NavigatorComponent/NavigatorComponentDefaults.js"], e["Dashboards/Components/NavigatorComponent/NavigatorSyncs/NavigatorSyncs.js"], e["Core/Utilities.js"]], function (t, e, s, i, n) {
    let {
      diffObjects: o,
      isNumber: r,
      isString: a,
      merge: l,
      pick: h
    } = n;
    class c extends t {
      static fromJSON(t, e) {
        let s = new c(e, t.options);
        return s.emit({
          type: "fromJSON",
          json: t
        }), s;
      }
      constructor(t, s) {
        super(t, s), this.type = "Navigator", this.options = l(c.defaultOptions, s);
        let i = c.charter.Chart || e.win.Highcharts;
        this.chartContainer = e.win.document.createElement("div"), this.chart = i.chart(this.chartContainer, this.options.chartOptions || {}), this.chartContainer.classList.add(e.classNamePrefix + "navigator"), this.sync.syncConfig.crossfilter?.enabled && this.chart.update(l({
          navigator: {
            xAxis: {
              labels: {
                format: "{value}"
              }
            }
          }
        }, this.options.chartOptions || {}), !1);
      }
      adjustNavigator() {
        let t = this.chart,
          e = h(t.chartHeight, this.contentElement.clientHeight),
          s = this.contentElement.clientWidth,
          i = {};
        if ((t.chartHeight !== e || t.chartWidth !== s) && (i.chart = {
          height: e,
          width: s
        }), t.navigator) {
          let s = t.navigator,
            n = s.top - t.plotTop + s.height;
          s.height !== n && (i.navigator = {
            handles: {
              height: Math.round(e / 4)
            },
            height: n
          }), Object.keys(i).length && t.update(i, !1), s.series && s.series[0] && s.series[0].update({
            type: t.series[0].type
          }, !1);
        } else Object.keys(i).length && t.update(i, !1);
      }
      getColumnAssignment() {
        let t;
        let e = this.options.columnAssignment ?? this.options.columnAssignments ?? {};
        for (let s of Object.keys(e)) if (null !== (t = e[s])) return [s, t];
        let s = this.getFirstConnector();
        if (s) {
          let t = s.table.getColumnNames();
          if (t.length) return [t[0], "y"];
        }
        return ["", "y"];
      }
      getOptions() {
        return {
          ...o(this.options, s),
          type: "Navigator"
        };
      }
      getAxisExtremes() {
        let t = this.chart.xAxis[0],
          e = t.getExtremes(),
          s = r(e.min) ? e.min : e.dataMin,
          i = r(e.max) ? e.max : e.dataMax;
        return this.categories ? [this.categories[Math.max(0, Math.ceil(s))], this.categories[Math.min(this.categories.length - 1, Math.floor(i))]] : t.hasNames ? [t.names[Math.ceil(s)], t.names[Math.floor(i)]] : [s, i];
      }
      async load() {
        return await super.load(), this.contentElement.appendChild(this.chartContainer), this.parentElement.appendChild(this.element), this.adjustNavigator(), this.emit({
          type: "afterLoad"
        }), this;
      }
      onTableChanged() {
        this.renderNavigator();
      }
      redrawNavigator() {
        let t = this.resizeTimeouts;
        for (let e = 0, s = t.length; e < s; ++e) clearTimeout(t[e]);
        t.length = 0, t.push(setTimeout(() => {
          this.adjustNavigator(), this.chart.redraw();
        }, 33));
      }
      render() {
        return super.render(), this.renderNavigator(), this.sync.start(), this.emit({
          type: "afterRender"
        }), this;
      }
      renderNavigator() {
        let t = this.chart,
          e = this.getFirstConnector();
        if (e) {
          let s;
          let i = e.table,
            n = this.getColumnAssignment(),
            o = i.getColumn(n[0], !0) || [];
          s = this.sync.syncConfig.crossfilter?.enabled ? this.generateCrossfilterData() : o.slice(), t.series[0] ? t.series[0].setData(s, !1) : t.addSeries({
            id: i.id,
            data: s
          }, !1);
        }
        this.redrawNavigator();
      }
      generateCrossfilterData() {
        let t;
        let e = this.sync.syncConfig.crossfilter,
          s = this.getFirstConnector()?.table,
          i = s?.getColumn(this.getColumnAssignment()[0], !0) || [];
        if (!s || i.length < 1 || !e) return [];
        let n = [],
          o = [];
        for (let t = 0, e = i.length; t < e; t++) {
          let e = i[t];
          if (null !== e) {
            if (r(e) || (e = `${e}`), void 0 === this.stringData) this.stringData = a(e);else if (this.stringData !== a(e)) throw Error("Mixed data types in crossfilter navigator are not supported.");
            n.push(e), -1 === o.indexOf(e) && o.push(e);
          }
        }
        o.sort((t, e) => h(t, NaN) < h(e, NaN) ? -1 : t === e ? 0 : 1);
        let l = s.getModifier()?.options;
        if (e.affectNavigator && l) {
          let e = [],
            i = [],
            {
              ranges: o
            } = l;
          for (let t = 0, n = o.length; t < n; t++) o[t].column !== this.getColumnAssignment()[0] && (e.push(o[t]), i.push(s.getColumn(o[t].column, !0) || []));
          t = [];
          let r = e.length;
          for (let s = 0, o = n.length; s < o; s++) {
            let o = n[s],
              a = !0;
            for (let t = 0; t < r; t++) {
              let n = e[t];
              if (!(i[t][s] >= (n.minValue ?? -1 / 0) && i[t][s] <= (n.maxValue ?? 1 / 0))) {
                a = !1;
                break;
              }
            }
            a && t.push(o);
          }
        } else t = n;
        let c = [];
        if (this.stringData) {
          this.categories = o;
          for (let t = 0, e = o.length; t < e; t++) c.push([t, null]);
        } else for (let t = 0, e = o.length; t < e; t++) c.push([o[t], null]);
        for (let e = 0, s = t.length; e < s; e++) {
          let s = o.indexOf(t[e]);
          c[s][1] = (c[s][1] || 0) + 1;
        }
        return c;
      }
      resize(t, e) {
        return super.resize(t, e), this.redrawNavigator(), this;
      }
      async update(t, e = !0) {
        let s = this.chart;
        await super.update(t, !1), t.chartOptions && s.update(l(this.sync.syncConfig.crossfilter?.enabled ? {
          navigator: {
            xAxis: {
              labels: {
                format: "{value}"
              }
            }
          }
        } : {}, t.chartOptions), !1), this.emit({
          type: "afterUpdate"
        }), e && this.render();
      }
      getOptionsOnDrop() {
        return {};
      }
    }
    return c.defaultOptions = l(t.defaultOptions, s), c.predefinedSyncConfig = i, c;
  }), s(e, "Dashboards/Plugins/HighchartsPlugin.js", [e["Dashboards/Components/HighchartsComponent/HighchartsComponent.js"], e["Dashboards/Components/KPIComponent/KPIComponent.js"], e["Dashboards/Components/NavigatorComponent/NavigatorComponent.js"]], function (t, e, s) {
    return {
      custom: {
        connectHighcharts: function (i) {
          t.charter = i, e.charter = i, s.charter = i;
        }
      },
      name: "Highcharts.DashboardsPlugin",
      onRegister: function (i) {
        let {
          ComponentRegistry: n
        } = i;
        n.registerComponent("Highcharts", t), n.registerComponent("KPI", e), n.registerComponent("Navigator", s);
      },
      onUnregister: function (t) {}
    };
  }), s(e, "Dashboards/PluginHandler.js", [e["Dashboards/Board.js"], e["Dashboards/Components/Sync/Sync.js"], e["Dashboards/Components/ComponentRegistry.js"]], function (t, e, s) {
    var i, n;
    return (n = i || (i = {})).registry = {}, n.revision = 0, n.addPlugin = function (i, o = i.name) {
      let {
        maxRevision: r,
        minRevision: a,
        onRegister: l
      } = i;
      if (n.registry[o]) {
        if (o !== i.name) throw Error(`Plugin '${o}' already registered.`);
        return;
      }
      if ("number" == typeof a && a > n.revision || "number" == typeof r && r < n.revision) throw Error(`Plugin '${o}' does not support revision ${n.revision}.`);
      l({
        Board: t,
        ComponentRegistry: s,
        Sync: e,
        revision: n.revision
      }), n.registry[o] = i;
    }, n.removePlugin = function (i) {
      n.registry[i] && (n.registry[i].onUnregister({
        ComponentRegistry: s,
        Board: t,
        Sync: e,
        revision: n.revision
      }), delete n.registry[i]);
    }, i;
  }), s(e, "masters/dashboards.src.js", [e["Core/Renderer/HTML/AST.js"], e["Data/Connectors/DataConnector.js"], e["Dashboards/Board.js"], e["Dashboards/Components/Component.js"], e["Dashboards/Components/ComponentRegistry.js"], e["Data/DataPool.js"], e["Data/DataCursor.js"], e["Data/Converters/DataConverter.js"], e["Data/Modifiers/DataModifier.js"], e["Data/DataTable.js"], e["Dashboards/Globals.js"], e["Dashboards/Plugins/DataGridPlugin.js"], e["Dashboards/Plugins/HighchartsPlugin.js"], e["Dashboards/PluginHandler.js"], e["Dashboards/Components/Sync/Sync.js"], e["Dashboards/Utilities.js"]], function (t, e, s, i, n, o, r, a, l, h, c, d, u, p, m, f) {
    return c.board = s.board, c.addEvent = f.addEvent, c.error = f.error, c.merge = f.merge, c.removeEvent = f.removeEvent, c.uniqueKey = f.uniqueKey, c.AST = t, c.Board = s, c.Component = i, c.ComponentRegistry = n, c.DataConnector = e, c.DataConverter = a, c.DataCursor = r, c.DataModifier = l, c.DataPool = o, c.DataTable = h, c.DataGridPlugin = d, c.HighchartsPlugin = u, c.PluginHandler = p, c.Sync = m, c.win.Dashboards || (c.win.Dashboards = c), c.win.DataGrid && (d.custom.connectDataGrid(c.win.DataGrid), c.PluginHandler.addPlugin(d)), c.win.Highcharts && (u.custom.connectHighcharts(c.win.Highcharts), c.PluginHandler.addPlugin(u)), c;
  }), e["masters/dashboards.src.js"]._modules = e, e["masters/dashboards.src.js"];
});