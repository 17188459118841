import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-home-guest',
    templateUrl: './home-guest.component.html',
    styleUrls: ['./home-guest.component.scss'],
    standalone: false
})

export class HomeGuestComponent implements OnInit {

    constructor(private router: Router, 
        private route: ActivatedRoute,) {
    }


    ngOnInit() {
        console.log("ngOnInit not implemented.");
    }

    requestPermissions(){
        this.router.navigate(['../request-permissions'], { relativeTo: this.route });  
    }
    
}


