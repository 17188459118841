import { Component } from '@angular/core';

@Component({
  selector: 'app-standard-rates-in-effect',
  standalone: true,
  imports: [],
  templateUrl: './standard-rates-in-effect.component.html',
  styleUrl: './standard-rates-in-effect.component.scss'
})
export class StandardRatesInEffectComponent {

}
