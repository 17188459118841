import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs";

export function getList<T>(url: string, params: HttpParams, http: HttpClient) {
    return http.get<T>(url, { observe: 'response', params }).pipe(
      map(response => {
        return response.body;
      })
    );
  }

  export function getListHeaders() {
    let params = new HttpParams();
    return params;
  }

