import { Component } from '@angular/core';

@Component({
  selector: 'app-display-product',
  standalone: true,
  imports: [],
  templateUrl: './display-product.component.html',
  styleUrl: './display-product.component.scss'
})
export class DisplayProductComponent {

}
