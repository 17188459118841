import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { of, BehaviorSubject } from 'rxjs';
import { Region } from '../../models/forest-service/region';
import { getList, getListHeaders } from '../pagination/listHelper';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  baseUrl = environment.apiUrl;

  private regionsSource = new BehaviorSubject<Region[] | null>(null);
  regions$ = this.regionsSource.asObservable();

  private allRegionsSource = new BehaviorSubject<Region[] | null>(null);
  allRegions$ = this.allRegionsSource.asObservable();

  constructor(private http: HttpClient) { 
    this.getRegions();
    this.getAllRegions();
  }

  setRegions(regions: Region[]) {
    this.regionsSource.next(regions);
  }

  setAllRegions(regions: Region[]) {
    this.allRegionsSource.next(regions);
  }

  private getRegions() {
    let params = getListHeaders();
      console.log("sending region api call");
      getList<Region[]>(this.baseUrl + 'regions', params, this.http).subscribe({
        next: (response => {
          console.log("received regions api response");
          console.log(response);
          this.setRegions(response as Region[]);
        })
      })
  }

  private getAllRegions() {
    let params = getListHeaders();
      console.log("sending region api call");
      getList<Region[]>(this.baseUrl + 'regions/getall', params, this.http).subscribe({
        next: (response => {
          console.log("received regions api response");
          console.log(response);
          this.setAllRegions(response as Region[]);
        })
      })
  }

  getRegion(regionCode: string) {
    const region = this.regionsSource.value?.filter(r => r.regionCode == regionCode)[0];
    if (region) return of(region);
    return this.http.get<Region>(this.baseUrl + 'regions/' + regionCode);
  }

  
}