import {Component, ElementRef, Renderer2, ViewChild} from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FocusService } from "../../../services/focus.service";

@Component({
    selector: 'app-usa-banner',
    templateUrl: "./usa-banner.component.html",
    styleUrls: ["./usa-banner.component.scss"]
})
export class UsaBannerComponent {
    currentUrl: any ='';
    @ViewChild('skip')  skip!:ElementRef;
    private _showDisclosure: boolean;

    constructor(private router: Router, private renderer: Renderer2, public focusService:FocusService) {
        this._showDisclosure = false;

        router.events.subscribe((event) => {
            if(event instanceof NavigationEnd){
              if(event.url != ''){
                this.currentUrl = event.url;
              }else{
               this.currentUrl = ''
              }
           }
        })
    }

    toggleDisclosure() {
        this._showDisclosure = !this._showDisclosure;
    }

    get showDisclosure() {
        return this._showDisclosure; 
    }

    onKeydown(event:any){
       this.renderer.addClass(this.skip.nativeElement, "slideup");
       //this.focusService.onFocus.next('');

   }

}
