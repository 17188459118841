
<div *ngIf="appraiserRoleId == user_Roles.Appraiser || appraiserRoleId == user_Roles.AppraiserAdministrator || appraiserRoleId == user_Roles.DataAdministrator">
    <form [formGroup]="form">
    <div class="appraiserHeaderFixed">
        <div class="usa-accordion">
          <h4 class="usa-accordion__heading">
            <button type="button" tabindex="0" id="user-portfolio-btn" class="usa-accordion__button" aria-expanded="true" aria-controls="a1" (click)="toggleservice.toggle()">
                <div class="grid-container flex-content">
                    User Portfolio 
                    <p class="appraiseHeader_text">Sale Name: {{ saleName }}</p>
                    <p class="appraiseHeader_text">Appraisal Zone: {{ appraisalZoneCode }}</p>
                </div>
            </button>
          </h4>
          
        </div>
      
    </div>
    <div class="appraisal-grid-container">
       
        <div id="a1" class="usa-accordion__content usa-prose">
            <div class="flex_buttons">
                <button type="button" tabindex="0" class="btn btn-sm btn-outline-success" (click)="modalService.open('modal-6')">Import Cruise Sale</button>
                <button type="button" tabindex="0" class="btn btn-sm btn-outline-success" (click)="addNewSale()">Add New Sale</button>
            </div>
            <table class="usa-table" style="width:100% !important; margin: -2.75rem 0 1.25rem 0 !important;">
                <caption>
                    Appraisal Sales: {{ appraisalSales.length }}
                  </caption>
                <thead>
                <tr>
                    <th scope="col">Modify Date</th>
                    <th scope="col">Sale Number</th>
                    <th scope="col">Sale Name</th>
                    <th scope="col" style="min-width: 300px;">Region</th>
                    <th scope="col" style="min-width: 300px;">Forest</th>
                    <th scope="col" style="min-width: 300px;">District</th>
                    <th scope="col">Total Volume</th>
                    <th scope="col">Modify By</th>
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="appraisalSalesForm.controls.length > 0">
                        <ng-container formArray="appraisalSales" *ngFor="let appraisalSale of appraisalSalesForm?.controls as FormArray; let i = index;">
                            <tr [ngClass]="appraisalSale.get('isEditable')?.value === true ? 'appraisal-sale-row edit-appraisal-sale-row': 'appraisal-sale-row'" (click)="selectRow($event, i)" (keypress)="selectRow($event, i)">
                                <th scope="row"> {{ appraisalSale.get('modifiedWhen_UTC')?.value | date:'medium'  }}</th>
                                <td>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === true" style="padding-top: 25px">
                                        <app-number-input [formControl]="$any(appraisalSale.get('saleNumber'))" [label]="'Sale Number'" [step]="0.01" [min]="0.00" [attr.aria-label]="ariaSale" ></app-number-input> 
                                    </div>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === false">
                                        {{ appraisalSale.get('saleNumber')?.value }}
                                    </div>
                                   
                                </td>
                                <td>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === true">
                                        <app-text-input [formControl]="$any(appraisalSale.get('saleName'))" [label]="'Sale Name'" [attr.aria-label]=" ariaSaleName"></app-text-input>
                                    </div>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === false">
                                        {{ appraisalSale.get('saleName')?.value }}
                                    </div>
                                </td>
                                <td style="min-width: 220px;">
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === true">
                                        <div class="usa-form-group margin-top">
                                            <ng-select [formControl]="$any(appraisalSale.get('regionId'))" name="regionId" id="regionId" class="usa-rfd selectbox" (change)="setForests()"  placeholder="Select a Region" [loading]="loadingHandler.isLoading"  [ariaLabel]="'Select a Region'">
                                                <ng-option *ngFor="let region of regions" [value]="region.id">{{region.regionCode}} - {{region.regionName}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === false">
                                        {{ appraisalSale.get('regionName')?.value }}
                                    </div>
                                    
                                </td>
                                <td style="min-width: 220px;">
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === true">
                                        <div class="usa-form-group margin-top">
                                            <ng-select [formControl]="$any(appraisalSale.get('forestId'))" name="forestId" id="forestId" class="usa-rfd selectbox" (change)="setDistricts()"  placeholder="Select a Forest" [ariaLabel]="'Select a Forest'">
                                                <ng-option *ngFor="let forest of forests" [value]="forest.id">{{forest.forestCode}} - {{forest.forestName}}</ng-option>
                                            </ng-select>
                                           
                                        </div>
                                    </div>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === false">
                                        {{ appraisalSale.get('forestName')?.value }}
                                    </div>
                                </td>
                                <td style="min-width: 220px;">
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === true">
                                        <div class="usa-form-group margin-top">
                                            <ng-select [formControl]="$any(appraisalSale.get('districtId'))" name="districtId" id="districtId" class="usa-rfd selectbox" (change)="setAppraisalZone()" placeholder="Select a District" [ariaLabel]="'Select a District'">
                                               <ng-option *ngFor="let district of districts" [value]="district.id">{{district.districtCode}} - {{district.districtName}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === false">
                                        {{ appraisalSale.get('districtName')?.value }}
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === true" style="padding-top: 25px">
                                        <app-number-input [formControl]="$any(appraisalSale.get('totalVolume'))" [label]="'Total volume'" [step]="0.01" [min]="0.00" [unitOfMeasure]="unitOfMeasure"></app-number-input> 
                                    </div>
                                    <div *ngIf="appraisalSale.get('isEditable')?.value === false">
                                        {{ appraisalSale.get('totalVolume')?.value }}
                                    </div>
                                </td>
                                <td>
                                    {{ appraisalSale.get('modifiedByName')?.value }}
                                </td>
                                </tr>
                        </ng-container>
                    </ng-container>
                   
                </tbody>
            </table>
            <div class="usa-form-group" style="overflow:hidden; display:flex; align-items: flex-start; padding-bottom:50px;">
                <button type="button" class="usa-button usa-button--outline continue-button" (click)="continue($event)">Continue</button>
            </div>
        </div>
        
    </div> 
    
    </form>
</div>
<app-modal id="modal-6" aria-modal="true" aria-labelledby="Upload Documents" >

    <div class="content-container" tabindex="-1" #modal Focustrap><!--Focustrap-->
      <button type="button"  class="closeBtn" (click)="modalService.close('modal-6')"  aria-label="Close Modal" tabindex="0" cdkFocusRegionStart>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M20.7456 3.33001C20.3551 2.93948 19.7219 2.93948 19.3314 3.33001L12.037 10.6244L4.74263 3.33001C4.35211 2.93948 3.71894 2.93948 3.32842 3.33001C2.93789 3.72053 2.93789 4.3537 3.32842 4.74422L10.6228 12.0386L3.32844 19.3329C2.93791 19.7235 2.93791 20.3566 3.32844 20.7472C3.71896 21.1377 4.35213 21.1377 4.74265 20.7472L12.037 13.4528L19.3314 20.7472C19.7219 21.1377 20.3551 21.1377 20.7456 20.7472C21.1361 20.3566 21.1361 19.7235 20.7456 19.333L13.4512 12.0386L20.7456 4.74422C21.1361 4.3537 21.1361 3.72053 20.7456 3.33001Z" fill="#757575"/>
        </svg>
      </button>
      <h2 class="heading"><strong>Upload one or more files.</strong></h2>
    
      <div class="modalForm">
        <div class="formContainer">
          <div>
            <div class="formControls">
                <form>
                    <div class="input-wrapper">
                        <input type="file" id="myFile" name="filename" multiple aria-label="Choose a file to upload">
                    </div>
                    <div class="input-button">
                        <button tabindex="0" type="button" class="usa-button-green">Submit</button>
                    </div>
                  </form>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
</app-modal>



