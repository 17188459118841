<div class="usa-width-one-whole nt-info-area">
    <div class="nt-title-text">
        <!-- <h2>National TEA - Landing Page</h2> -->
    </div>
    <div class="grid-container">
        <div *ngIf="(logout)" class="grid-row">
            <div class="grid-col-8">
                <h2>Logout <span class="usa-h2-para-text">You are now logged out.</span></h2>
                <p><a [routerLink]="['/landing']" (click)="login()" aria-label="Click here to return to eAuth login page.">Click here</a> to return to eAuth login page.</p>
                
            </div>
            <div class="grid-col-4">
            </div>
        </div>
    </div>
</div>



