import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { User } from '../../../../models/user/user';
import {ModalService} from '../../../../services/modal.service';
import { FocusService } from '../../../../services/focus.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AdminService } from '../../../../services/admin/admin.service';
import { UserRoleRequest } from '../../../../models/admin/user-role-requests/user-role-request';
import { RegionsService } from '../../../../services/regions/regions.service';
import { Region } from '../../../../models/region';
import { Forest } from '../../../../models/forest';
import {ForestsService} from '../../../../services/forests/forests.service'
import { UserRoleRequestsService } from '../../../../services/admin/user-role-requests.service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
})

export class UserManagementComponent implements OnInit {

    public user:User[] = []
    @ViewChild('main') main!:ElementRef;
    @ViewChild('modal') modal!:ElementRef;
     
 
    form!: FormGroup;
    regions: Region[] = [];
    forests!: Forest[] | null;

    districtItems:any[] = ['01 - Dillion Ranger District', '02 - Wisdom Ranger District', '03 - Butte Ranger District', '04 - Madison Ranger District', '05 - Pinler Ranger District'];

    availableItems:any[] = [ 
        { id: 1, name: '01 - Northern' },
        { id: 2, name: '02 - Rocky Mountain' },
        { id: 3, name: '03 - Southwestern' },
        { id: 4, name: '04 - Intermountain' }
        ];
    selectedItems: any[] = [];
    selectedItem:any | null = null;

    selectedDisItems: any[] =[];
    selectedDisItem: any | null = null;

    pendingRequests: UserRoleRequest[] = [];
    filteredRequests: UserRoleRequest[] = [];
    rowsForm!: FormArray;
    selectedRequest!: FormGroup;
    selectedIndex = 0;
    


    selectItem(item: any):void{
        this.selectedItem = item;
    }
    selectDisItem(item:any):void{
        this.selectedDisItem = item;
    }

    notes = this.fb.group({
        notes:new FormControl('')
    })

    constructor(private userRoleRequestsService: UserRoleRequestsService,
        public modalService:ModalService, 
        public focusService:FocusService,
        private fb:FormBuilder,
        private regionsService:RegionsService
    
        ) {
        

        this.focusService.onFocus.subscribe(()=>{
            this.showFocus();
          });
      
          this.modalService.onFoucsModal.subscribe(()=>{
            this.focusModal();
          })
    }


    showFocus(){
        this.main.nativeElement.focus();
      }
    
      focusModal(){
        this.modal.nativeElement.focus()
      }

    ngOnInit() {
        this.getPendingRequests();
        this.regionsService.regions$.subscribe({
            next: response => {
                if (response) {
                    this.regions = response;
                    console.log("ALL MY RICIONSDFGD",this.regions.filter(r => r.regionCode)                                                                 
                )                                              
                    //this.loadingHandler.finish();
                }
            }
        })
       /*this.userservice.getAllUser().subscribe({
        next:(response =>{
            console.log("ALL USERS:", response)
        })
       })*/
       if(this.pendingRequests != null){
        this.form = this.fb.group({
          rows: this.fb.array(this.pendingRequests.map(val => this.fb.group({
            id: new FormControl(val.id),
            userId: new FormControl(val.userId),
            firstName: new FormControl(val.user.firstName),
            lastName: new FormControl(val.user.lastName),
            email: new FormControl(val.user.username),
            roleName: new FormControl(val.role.name),
            regionName: new FormControl(val.region.regionCode + ' ' + val.region.regionName),
            roleId: new FormControl(val.roleId),
            status: new FormControl(val.status),
            regionId: new FormControl(val.regionId),

            action: new FormControl('existingRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(false),
          })
          ))
        }); 
  
        this.rowsForm = this.form.controls['rows'] as FormArray;
        console.log(this.rowsForm);
       }   
    }

    getPendingRequests(){
        this.userRoleRequestsService.getPendingRequests().subscribe({
            next: response => {
                console.log(response);
                  if(response != null){
                    this.pendingRequests = response;
                    this.filteredRequests = this.pendingRequests;
                    this.setRows(this.pendingRequests);
                  }
                    
                }
        })
    }
    
    setRows(pendingRequests: UserRoleRequest[]) {
        if(pendingRequests != null){
            console.log(pendingRequests);
            let controls = this.fb.array(this.pendingRequests.map(val => this.fb.group({
                id: new FormControl(val.id),
                userId: new FormControl(val.userId),
                firstName: new FormControl(val.user.firstName),
                lastName: new FormControl(val.user.lastName),
                email: new FormControl(val.user.username),
                roleName: new FormControl(val.role.name),
                regionName: new FormControl(val.region.regionCode + ' ' + val.region.regionName),
                roleId: new FormControl(val.roleId),
                status: new FormControl(val.status),
                regionId: new FormControl(val.regionId),
                action: new FormControl('existingRecord'),
                isEditable: new FormControl(false),
                isNewRow: new FormControl(false),
              })));
            console.log(controls);
            this.form.setControl('rows', controls);
            this.rowsForm = this.form.controls['rows'] as FormArray;
          }
    }

    viewStatus(id: number){
        this.modalService.open('modal-3');
        let request = this.rowsForm?.controls.filter(r => r.get('id')?.value == id)[0] as FormGroup;
        console.log(request);
        this.selectedRequest = request;

    }
 
    moveItemToSelected():void{
        if (this.selectedItem && this.availableItems.includes(this.selectedItem)){
            this.selectedItems.push(this.selectedItem);
            this.availableItems = this.availableItems.filter(item => item !== this.selectedItem);
            this.selectedItem = null;
        }
    }

    moveItemToAvailable():void{
        if (this.selectedItem && this.selectedItems.includes(this.selectedItem)){
            this.availableItems.push(this.selectedItem);
            this.selectedItems = this.selectedItems.filter(item => item !== this.selectedItem);
            this.selectedItem = null;
        }
    }

    moveItemToDistrictSelected():void{
        if(this.selectedDisItem && this.districtItems.includes(this.selectedDisItem)){
            this.selectedDisItems.push(this.selectedDisItem)
            this.districtItems = this.districtItems.filter(item => item !== this.selectedDisItem)
            this.selectedDisItem = null;
        }
    }

    moveItemToDistrictAvailable():void{
        if(this.selectedDisItem && this.selectedDisItems.includes(this.selectedDisItem )){
            this.districtItems.push(this.selectedDisItem );
            this.selectedDisItems = this.selectedDisItems.filter(item => item !== this.selectedDisItem);
            this.selectedItem = null; 
        }
    }
    /*===================================*/
    //Forest codes for keyboard Navigation
    onItemSelect(item:any){
        const index = this.availableItems.indexOf(item);

        if(index > -1){
            this.availableItems.splice(index, 1);
            this.selectedItems.push(item)
        }
    }

    onItemDeselect(item:any){
        const index = this.selectedItems.indexOf(item);
        if(index  > -1){
            this.selectedItems.splice(index, 1);
            this.availableItems.push(item)
        }
    }

    onKeyDown(event:KeyboardEvent){
       
        if(event.key === 'Enter'){
            const focusedItem = document.activeElement as HTMLElement;
            //const targetElement = event.target as HTMLElement;
            if(focusedItem.parentElement?.id === 'availableList'){
                this.onItemSelect(this.availableItems[0])
                
            }else if(focusedItem.parentElement?.id === 'selectedList'){
                this.onItemDeselect(this.selectedItems[0])
            }
            
        }
    }

    //District Code for keyboard Navigation.

    onItemDisSelect(item:any){
       const index = this.districtItems.indexOf(item)
       if(index > - 1){
          this.districtItems.splice(index, 1)
          this.selectedDisItems.push(item)
       }
    }

  

    onItemDisDeSelect(item:any){
        const index = this.selectedDisItems.indexOf(item);
        if(index  > -1){
            this.selectedDisItems.splice(index, 1);
            this.districtItems.push(item)
        }
    }
   

    

    handleKeyDown(event: KeyboardEvent){
        if(event.key === 'Enter'){
            const focusedItem = document.activeElement as HTMLElement;
            if(focusedItem.parentElement?.id === 'availableDistrictList'){
                this.onItemDisSelect(this.districtItems[0])
            }else if(focusedItem.parentElement?.id === 'selectedDistrictList'){
                this.onItemDisDeSelect(this.selectedDisItems[0])
            }
        }
    }
}


