import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { UsersService } from '../services/users/users.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private usersService: UsersService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.usersService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${user.token}`,
              UserRoleId: user.currentUserRole.roleId.toString()
            }
          })
        }
      }
    })
    return next.handle(request);
  }
}