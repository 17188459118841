import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandlecheckboxesService {
 

  private subject = new Subject<any>();
  private btnSject = new Subject<any>()

  checked:boolean = false;

  sendClickEvent(){
    this.subject.next(null)
  }

  getClickEvent():Observable<any>{
     return this.subject.asObservable()
  }


  sendDisabledClickEvent(){
    this.btnSject.next(null)
  }

  getDisabledClickEvent():Observable<any>{
     return this.btnSject.asObservable()
  }


}
