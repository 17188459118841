import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[disable-elements]'
})
export class DisabledirectiveDirective implements OnChanges{

  @Input() disable = false;
  
  elementTypes = ['input', 'select', 'button'];
  


  constructor(private _el:ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    const elements = this.getElements();
    this.doReadOnly(elements);
  }
 
  getElements(){
    const elements =  this._el.nativeElement.querySelectorAll(this.elementTypes.join(','));
    return elements;
  }
  
  doReadOnly(elements:any){
    
    for (let i = 0; i < elements.length; i++){
      elements[i].disabled = this.disable;
      
      if(elements[i].disabled){
        console.log(elements[i].children[1]?.firstChild.setAttribute("tabIndex", "-1"))
      }
    }
    
  }

}
