import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { User } from '../../models/user/user';
import { UsersService } from '../../services/users/users.service';
import { Region } from '../../models/region';
import { take } from 'rxjs';
import { UserRole } from '../../models/user/user-role';


@Component({
    selector: 'app-estimate-market-value',
    templateUrl: './estimate-market-value.component.html',
    styleUrls: ['./estimate-market-value.component.scss'],
    providers: [DatePipe],
   
})

export class EstimateMarketValueComponent implements OnInit { 
  @Output() cancelRegister = new EventEmitter();
  user?: User | null;
  userRoles?: UserRole[] | null;
  activeUserRole?: UserRole | null;
  guest: any;
  appraisalForm: FormGroup = new FormGroup({});
  validationErrors: string[] | undefined;
  baseUrl = environment.apiUrl;
  date: string | number | Date = new Date();
  appraisalZones: Region[] = [];
  form: any;

  constructor(private fb: FormBuilder, 
    private http: HttpClient, 
    private datePipe: DatePipe, 
    private usersService: UsersService) {

    
  }

  ngOnInit() {
    this.usersService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user){
          this.user = user;
          console.log(this.user);
          this.userRoles = this.user?.userRoles;
          if(this.userRoles != null)
            this.activeUserRole = this.userRoles[this.userRoles.length-1];
          console.log(this.activeUserRole);

        }
      }
    });
      this.createForm();
     
  }
  
  createForm() {
      this.appraisalForm = this.fb.group({
        appraiserName: [{value: this.user?.firstName + ' ' + this.user?.lastName, disabled: true}, Validators.required],
        generalInformation: this.fb.group({    
          appraiserName: [{value: this.user?.firstName + ' ' + this.user?.lastName, disabled: true}, Validators.required],    
          appraisalDate: [{value: this.date, disabled: true}, Validators.required],
          regionCode: [null, Validators.required],
          forestCode: [null, Validators.required],
          districtCode: [null, Validators.required],
          appraisalZoneCode: [{ value: "", disabled: true}, Validators.required],
          regionName: [{value:"", disabled: false}, Validators.required],
          forestName: [{value:"", disabled: false}, Validators.required],
          districtName: [{value:"", disabled: false}, Validators.required],
          appraisalZone: [{value:"", disabled: false}, Validators.required],
   
          saleTypes: this.fb.array([ 
            this.fb.control({sbaBiddersNote: [false]}),
            this.fb.control({specialConditionsCircumstances: [false]}),
            this.fb.control({roadConstruction: [false]}),
            this.fb.control({cableOrAreialLoggingSystems: [false]}),
            this.fb.control({tradeGoodsServices: [false]}), 
            this.fb.control({partiallyProcessedVolume: [false]}),
            this.fb.control({requiredReforestationofPrepSite: [false]})
          ])
        }),
        volumeInformation: this.fb.group({   
          treeCondition: ['Live', Validators.required],
          appraisalGroupId: [null],//remove required validator.
          appraisalGroupName: ['', Validators.required],
          appraisalGroup: [null, Validators.required],
          treeProductId: [null, Validators.required],
          //treeProduct: [{value: null, disabled: true}, Validators.required],
          treeSpeciesId: [null, Validators.required],
          //treeSpecies: [{value: null, disabled: true}, Validators.required],
          treeProductName: ['', Validators.required],
          treeSpeciesName: ['', Validators.required],
          uomId: [1, Validators.required],
          uomShortName: ['', Validators.required],
          totalVolume: [0, Validators.required],
        }),
        // volumeInformation: this.fb.group({
        //   appraisalGroups: this.fb.array([
        //     this.fb.group({
        //       treeCondition: ['Live', Validators.required],
        //       appraisalGroupId: [null],//remove required validator.
        //       appraisalGroupName: ['', Validators.required],
        //       appraisalGroup: [null, Validators.required],
        //       treeProductId: [null, Validators.required],
        //       //treeProduct: [{value: null, disabled: true}, Validators.required],
        //       treeSpeciesId: [null, Validators.required],
        //       //treeSpecies: [{value: null, disabled: true}, Validators.required],
        //       treeProductName: ['', Validators.required],
        //       treeSpeciesName: ['', Validators.required],
        //       uomId: [1, Validators.required],
        //       uomShortName: ['', Validators.required],
        //       totalVolume: [0, Validators.required],
        //     })
            
        //   ])   
         
        // }),
        chooseAppraisalType: this.fb.group({    
          appraisalType: [null, Validators.required]
        }),
        calculations: this.fb.group({  
          totalGroupValue: [0.00, Validators.required],  
          partiallyProcessed: [false, Validators.required],
          reforestationRequired: [false, Validators.required],
          timberPropertyValue: [0.00, Validators.required],
          regenerationCost: [0.00, Validators.required],
          requiredCollections: [{ value: 0.00, disabled: true}, Validators.required],
          uomId: [1, Validators.required],
          uom: ['', Validators.required],
          baseRate: [{ value: 0.00, disabled: true}, Validators.required],
          standardRate: [{ value: 0.00, disabled: true}, Validators.required],
          minimumRate: [{ value: 0.00, disabled: true}, Validators.required],
          totalSaleValue: [{ value: 0.00, disabled: true}, Validators.required], 
          totalVolume: [{value: null, disabled: true}, Validators.required], 
          standardRateCalculations: this.fb.group({
            test:['standard-rate-test', Validators.required],
          }),
          streamlinedTeCalculations: this.fb.group({ 
            //bpa price initiatiall avg rate per unit of measure
            //adjusted rate sliders subtract from bap price
            //inversely proportional (*-1 technically)
            //
            bpaRates: this.fb.group({
              baseSkidYard: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseHaul: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseRoadMaintenance: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseTempDevelopments: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              }),
              baseSlashDisposal: this.fb.group({
                rate: [0.00, Validators.required],
                initialValue: [0.00, Validators.required],
                percentage: [100, Validators.required]
              })
            }),
          })
        }),
        estimator: this.fb.group({   
          totalSaleValue: [0.00, Validators.required], 
          contractBaseValue: [0.00, Validators.required],
          appraisalRatePerUom: [0.00, Validators.required],
        })
      });

    }

    get appraisalFormDebug(){
      return this.appraisalForm.value;
    }
   
    continue(panel: number){
      console.log(panel);
    }
  
    submit(){
      return false;
    }

  
}


