import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  private discountSource = new BehaviorSubject<number>(0);
  currentDiscount = this.discountSource.asObservable();

  constructor() { }

  /*
  updatePrice(discount:any){
    this.discountSource.next(discount)
  }
*/

  changeDiscount(discount: any) {
    this.discountSource.next(discount);
  }
}
