import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, model } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { User } from '../../models/user/user';
import { environment } from '../../../environments/environment'; 
import { Authorize } from '../../models/authorize';
import { getListHeaders } from '../pagination/listHelper';
import { Router } from '@angular/router';
import { UserRole } from '../../models/user/user-role';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  baseUrl = environment.apiUrl;
  private currentUserSource = new BehaviorSubject<User | null>(null);
  currentUser$ = this.currentUserSource.asObservable();

  private currentUserRoleSource = new BehaviorSubject<UserRole | null>(null);
  currentUserRole$ = this.currentUserRoleSource.asObservable();

  constructor(private http: HttpClient, private router: Router,) { 

   
  }

  login(model: Authorize) {
    return this.http.post<User>(this.baseUrl + 'oidc/authorize', model).pipe(
      map((response: User) => {
        const user = response;
        if (user) {
          user.currentUserRole = user.userRoles[user.userRoles.length-1];
          this.setCurrentUser(user);
        }
      })
    )
  }

  setCurrentActiveRole(roleId: number) {
    const user = this.currentUserSource.value as User;
    const role = user.userRoles.find(ur => ur.roleId == roleId);
    if(role){
      user.currentUserRole = role;
      this.setCurrentUser(user);
      this.setCurrentUserRole(role);
      window.location.reload();
    }
  }

  checkCurrentUser() {
    const userString = localStorage.getItem('user');
    const userRoleString = localStorage.getItem('userRole');
    if (!userString) return;
    const user: User = JSON.parse(userString);
    this.setCurrentUser(user);
    if(userRoleString){
      const userRole: UserRole = JSON.parse(userString);
      this.setCurrentUserRole(userRole);
    }

    let params = getListHeaders();
    return this.http.get(this.baseUrl + 'oidc/verifytoken', { observe: 'response', params }).pipe(
      map(response => {
        if(response.status == 200){          
        }
      }),
      catchError(err => {
        console.log('error:', err);
        this.logout();
        return throwError(() => err);
      })
    ).subscribe();

  }

  setCurrentUser(user: User) {
    console.log("Updating User:", user)
    localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSource.next(user);
  }

  setCurrentUserRole(role: UserRole) {
    console.log("Updating User Role:", role)
    localStorage.setItem('userRole', JSON.stringify(role));
    this.currentUserRoleSource.next(role);
  }

  logout() {
    localStorage.removeItem('user');
    this.currentUserSource.next(null);   
  }

  getDecodedToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  get isLoggedIn() : boolean {
    return this.currentUserSource.value as User != null && (this.currentUserSource.value as User).token != null
  }

  reloadComponents(self: boolean, navigateToUrl?: string){
    //skipLocationChange:true means dont update the url to / when navigating
   //console.log("Current url:", this.router.url);
   //console.log("Reloading due to User Role change:", this.activeUserRole);
   const url = self ? this.router.url : navigateToUrl;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       //console.log(`After navigation url: ${this.router.url}`)
     })
   })
 }

}
