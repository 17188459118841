import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types';
import { CalculationsService } from '../../../../services/calculations/calculations.service';
import { IVisited } from '../../../../models/visitors/visited';
import { ModalService } from '../../../../services/modal.service';
import { FocusService } from '../../../../services/focus.service';
import { UserRole } from '../../../../models/user/user-roles/user-role';
import { take } from 'rxjs';
import { UsersService } from '../../../../services/users/users.service';
import { User } from '../../../../models/user/user';
import { UserRoles } from '../../../../models/enums/user-roles';
import { DiscountService } from '../../../../services/discount.service';
import { TooltipPosition } from '@angular/material/tooltip';


@Component({
    selector: 'app-contract-calculations',
    templateUrl: './contract-calculations.component.html',
    styleUrls: ['./contract-calculations.component.scss'],
    standalone: false
})

export class ContractCalculationsComponent implements OnInit, IVisited {

    positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
    position = this.positionOptions[0];

    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    @ViewChild('main') main!:ElementRef;
    @ViewChild('modal') modal!:ElementRef;
    
    range = new FormControl(0);
    form!: FormGroup;
    generalInfoForm!: FormGroup;
    volumeInfoForm!: FormGroup;
    saleTypes!: FormArray;
    totalVolumeControl!: FormControl;
    minimumRateControl!: FormControl;
    discountRateControl!:FormControl;
    standardRateControl!: FormControl;
    timberPropertyValueControl!: FormControl;
    regenerationCostControl!: FormControl;
    requiredCollectionsControl!: FormControl;
    activeUserRole!: UserRole;
    appraiser_RoleId!: number;
    user!: User;
    userRoles: UserRole[] = [];
    user_Roles = UserRoles;
    placeholder = 0;


    appraisalTypes = AppraisalTypes;
    appraisalGroupForms!: FormArray<FormGroup>;
    initialAppraisalGroupForm!: FormGroup;

    formatThumbLabel(value: number): string {
        return `${value}%`;
    }

    title:string = "Appraisal Groups";

    
    discount = new FormControl(0, Validators.required) // Set default value to 0

    
    constructor(private rootFormGroup: FormGroupDirective,
        public calculationsService: CalculationsService, 
        private usersService: UsersService, 
        public modalService:ModalService,
        public focusService:FocusService,
        private discountService:DiscountService) {

            this.focusService.onFocus.subscribe(()=>{
                this.showFocus();
            });
              
            this.modalService.onFoucsModal.subscribe(()=>{
                this.focusModal();
            });
           
    }

    public readProperty<T>(prop: string):T {
        let property = this[prop as keyof ContractCalculationsComponent];
        if (property == null) {
           throw Error(`Property "${prop}" does not exists`);
        }
        return property as T;
     }   

 

    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.generalInfoForm = this.rootFormGroup.control.get('generalInformation') as FormGroup;
        
        this.volumeInfoForm = this.rootFormGroup.control.get('volumeInformation') as FormGroup;
        this.appraisalGroupForms = this.volumeInfoForm.controls["appraisalGroups"] as FormArray;
        this.initialAppraisalGroupForm = this.appraisalGroupForms.controls[0];
        
        this.minimumRateControl = this.initialAppraisalGroupForm.controls['minimumRate'] as FormControl;
        this.standardRateControl = this.initialAppraisalGroupForm.controls['standardRate'] as FormControl;
        this.saleTypes = this.generalInfoForm.controls["saleTypes"] as FormArray;
        this.totalVolumeControl = this.volumeInfoForm.controls['totalVolume'] as FormControl;
        this.timberPropertyValueControl = this.form.controls["timberPropertyValue"] as FormControl;
        this.regenerationCostControl = this.form.controls["regenerationCost"] as FormControl;
        this.requiredCollectionsControl = this.form.controls["requiredCollections"] as FormControl;
        this.timberPropertyValueControl.disable();
        this.regenerationCostControl.disable();
        this.calculationsService.visitContractCalculationsComponent(this);

        this.calculationsService.requiredCollections$.subscribe({
            next: value => {
                if(value != null){
                    this.requiredCollectionsControl.patchValue(value.toFixed(2));
                } 
            }
        });

        this.appraiser_RoleId = this.appraiserRoleId;
        this.usersService.currentUser$.pipe(take(1)).subscribe({
            next: user => {
                if (user){
                  this.user = user;
                  this.userRoles = this.user?.userRoles;
                  this.activeUserRole = user.currentUserRole;
                }
            }
        
        });

    }


    updateDiscount(){
        this.discountService.changeDiscount(this.discount.value)
    }

    get appraiserRoleId(): number {
        return this.activeUserRole?.roleId;
      }
    

    get uomControl(): FormControl{
        return this.initialAppraisalGroupForm.get('uomShortName') as FormControl;
    }

    get timberPropertyValue(): FormControl{
        if(this.saleTypes.controls[5].value === true){ 
            this.timberPropertyValueControl.enable();
        }else{
            this.timberPropertyValueControl.disable();
        }
        return this.timberPropertyValueControl;
    }

    get regenerationCost(): FormControl{
        if(this.saleTypes.controls[6].value === true){ 
            this.regenerationCostControl.enable();
        }else{
            this.regenerationCostControl.disable();
        }
        return this.regenerationCostControl;
    }

    get requiredCollections(): FormControl{ 
        return this.requiredCollectionsControl;       
    }
    
    get minimumRate(): FormControl{
        return this.minimumRateControl;
    }


    get standardRate(): FormControl{
      return this.standardRateControl;
    }
   
    onVolumeProcessedChange(event: any) {
        this.form.controls['partiallyProcessed'].patchValue(event.value);
    }
    onReforestationChange(event: any) {
        this.form.controls['reforestationRequired'].patchValue(event.value);
    }

    showFocus(){
        this.main.nativeElement.focus();
    }
    
    focusModal(){
        this.modal.nativeElement.focus()
    }

    
    
}
